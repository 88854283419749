import { Action, createReducer, on } from '@ngrx/store';

import { ICotizationDetail } from 'src/app/data/interfaces/ICotizationDetail.interface';
import { cotizationsDetailsActions } from './actions';

interface CotizationsDetailsState {
  cotizationsDetails: ICotizationDetail | null;
  error: any;
  message: any;
  isLoading: boolean;
  created: boolean;
  found: { search: boolean; found: boolean };
  edit: boolean;
  updated: boolean;
  saved: boolean;
}
const initialState: CotizationsDetailsState = {
  cotizationsDetails: null,
  error: null,
  message: null,
  isLoading: false,
  created: false,
  found: { search: false, found: false },
  edit: false,
  updated: false,
  saved: false,
};
const featureReducer = createReducer(
  initialState,
  on(cotizationsDetailsActions.load, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(cotizationsDetailsActions.createCotizationsDetails, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    cotizationsDetailsActions.createCotizationsDetailsSuccess,
    (state, { cotizationsDetails }) => ({
      ...updateFlags(state),
      created: true,
    })
  ),
  on(
    cotizationsDetailsActions.setCotizationsDetailsToStore,
    (state, { cotizationsDetails }) => {
      return {
        ...updateFlags(state),
        cotizationsDetails,
        saved: true,
      };
    }
  ),
  on(cotizationsDetailsActions.resetFlags, (state) => ({
    ...updateFlags(state),
  })),
  on(cotizationsDetailsActions.resetFlagsTrue, (state) => ({
    ...updateFlags(state),
    cotizationsDetails: null
  })),
  on(cotizationsDetailsActions.findCotizationsDetailsByCIB, (state) => ({
    ...updateFlags(state),
    cotizationsDetails: null,
    isLoading: true,
  })),
  on(
    cotizationsDetailsActions.findCotizationsDetailsByCIBSuccess,
    (state, { cotizationsDetails }) => ({
      ...updateFlags(state),
      cotizationsDetails,
    })
  ),
  /** database/cotizationsDetailsTransport?transportType=LOCAL */
  on(cotizationsDetailsActions.findCotizationsDetailsLocalByCIBAlly, (state) => ({
    ...updateFlags(state),
    cotizationsDetails: null,
    isLoading: true,
  })),
  on(
    cotizationsDetailsActions.findCotizationsDetailsLocalByCIBAllySuccess,
    (state, { cotizationsDetails }) => ({
      ...updateFlags(state),
      cotizationsDetails,
    })
  ),
  /** database/cotizationsDetailsTransport?transportType=INTERNATIONAL */
  on(cotizationsDetailsActions.findCotizationsDetailsInternatByCIBAlly, (state) => ({
    ...updateFlags(state),
    cotizationsDetails: null,
    isLoading: true,
  })),
  on(
    cotizationsDetailsActions.findCotizationsDetailsInternatByCIBAllySuccess,
    (state, { cotizationsDetails }) => ({
      ...updateFlags(state),
      cotizationsDetails,
    })
  ),

  on(cotizationsDetailsActions.updateCotizationsDetailTransLocal, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(
    cotizationsDetailsActions.updateCotizationsDetailTransLocalSuccess,
    (state, { updated }) => ({
      ...updateFlags(state),
      updated,
    })
  ),
  on(cotizationsDetailsActions.updateCotizationsDetailTransInter, (state) => ({
    ...updateFlags(state),
  })),
  on(
    cotizationsDetailsActions.updateCotizationsDetailTransInterSuccess,
    (state, { updated }) => ({
      ...updateFlags(state),
      updated,
    })
  ),
  on(cotizationsDetailsActions.updateCotizationsDetailCustom, (state) => ({
    ...updateFlags(state),
  })),
  on(
    cotizationsDetailsActions.updateCotizationsDetailCustomSuccess,
    (state, { updated }) => ({
      ...updateFlags(state),
      updated,
    })
  ),
  /** database/cotizationCustoms */
  on(cotizationsDetailsActions.getCotizationsCustom, (state) => ({
    ...updateFlags(state),
  })),
  on(
    cotizationsDetailsActions.getCotizationsCustomSuccess,
    (state, { cotizationCustoms }) => ({
      ...updateFlags(state),
      cotizationCustoms,
    })
  ),

  on(cotizationsDetailsActions.onError, (state, { error }) => ({
    ...updateFlags(state),
    error,
  }))
);
const updateFlags = (
  state: CotizationsDetailsState
): CotizationsDetailsState => {
  return {
    ...state,
    error: null,
    isLoading: false,
    found: { search: false, found: false },
    edit: false,
    created: false,
    updated: false,
    message: null,
    saved: false,
  };
};

function reducer(state: CotizationsDetailsState | undefined, action: Action) {
  return featureReducer(state, action);
}

const getError = (state: CotizationsDetailsState) => state.error;
const getMessage = (state: CotizationsDetailsState) => state.message;
const getIsLoading = (state: CotizationsDetailsState) => state.isLoading;
const getIsCreated = (state: CotizationsDetailsState) => state.created;
const getIsSaved = (state: CotizationsDetailsState) => state.saved;
const getIsFound = (state: CotizationsDetailsState) => state.found;
const onEdit = (state: CotizationsDetailsState) => state.edit;
const onUpdated = (state: CotizationsDetailsState) => state.updated;

const getCotizationsDetails = (state: CotizationsDetailsState) =>
  state.cotizationsDetails;
export const CotizationsDetailsReducer = {
  reducer,
  getError,
  getMessage,
  getIsLoading,
  getIsCreated,
  getIsFound,
  onEdit,
  getCotizationsDetails,
  getIsSaved,
  onUpdated,
};
export { CotizationsDetailsState };
