import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { StepperOrientation } from '@angular/material/stepper';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CotizationsFacadeService } from 'src/app/modules/master/quotation/commercial/components/cotizations-store/cotizations.facade.service';
import { ICotization } from 'src/app/data/interfaces/ICotization.interface';
import { ICotizationDetail } from 'src/app/data/interfaces/ICotizationDetail.interface';
import { Numbers } from 'src/app/core/constants/numbers';

@Component({
  selector: 'bcs-stepper',
  templateUrl: './bcs-stepper.component.html',
  styleUrls: ['./bcs-stepper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BcsStepperComponent implements OnInit, OnDestroy {

  stepperOrientation: Observable<StepperOrientation>;

  adminAproval: boolean = false;
  nationalAproval: boolean = false;
  customHouseAproval: boolean = false;
  internationalAproval: boolean = false;
  international: boolean = false;
  local: boolean = false;
  custom: boolean = false;

  subs: Subscription = new Subscription();
  cotization!: ICotization | null;
  cotizationDetail!: ICotizationDetail;
  indexStepper: number = Numbers._1;
  approved: boolean = false;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private cotizationsFacadeService: CotizationsFacadeService,
  ) {
    this.stepperOrientation = breakpointObserver?.observe('(min-width: 800px)')
      .pipe(
        map(
          ({ matches }) =>
            matches ? 'horizontal' : 'vertical'
        )
      );
  }

  ngOnInit(): void {
    this.subscriptions();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  subscriptions() {
    const sub1 = this.cotizationsFacadeService?.getCotization().subscribe(
      (cotization) => {
        if (cotization) {
          this.cotization = cotization;
          this.international = cotization.allies.international;
          this.local = cotization.allies.local;
          this.custom = cotization.allies.custom;
          this.getState();
        }
      });
    this.subs.add(sub1);
    const sub2 = this.cotizationsFacadeService?.getCotizationDetail()
      .subscribe(
        (cotizationDetail) => {
          if (cotizationDetail) {
            this.cotizationDetail = cotizationDetail;
          }
        }
      );
    this.subs.add(sub2);
  }

  getState() {
    if (this.cotization?.adminAproval) {
      this.adminAproval = true;
      this.indexStepper = 2
    }
    if (this.cotization?.nationalAproval) {
      this.nationalAproval = true;
      this.indexStepper = 3
    }
    if (this.cotization?.customHouseAproval) {
      this.customHouseAproval = true;
      this.indexStepper = 4
    }
    if (this.cotization?.internationalAproval) {
      this.internationalAproval = true;
      this.indexStepper = 5
    }
    if (this.adminAproval && this.customHouseAproval) {
      this.approved = true;
    }
  }
}
