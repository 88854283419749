export const AppRoutes = {
  consultaGeneralDeImportaciones:
  {
    name: "Consulta_General_de_Importaciones",
    path: '/app/cotizaciones/formulario-solicitud-cotizacion/datos-cliente'
  },
  consultarSolicitud:
  {
    name: "Consultar_Solicitud",
    path: '/app/cotizaciones/consultar-solicitud'
  },
  clientData:
  {
    name: "Client_Data",
    path: '/app/cotizaciones/formulario-solicitud-cotizacion/datos-cliente'
  },
  providerData:
  {
    name: "Provider_Data",
    path: '/app/cotizaciones/formulario-solicitud-cotizacion/datos-proveedor',
  },
  transportationAssetInformation:
  {
    name: "Transportation-asset-information",
    path: '/app/cotizaciones/formulario-solicitud-cotizacion/informacion-activos-transporte',
  },
  paymentMethods:
  {
    name: "Payment-Methods",
    path: '/app/cotizaciones/formulario-solicitud-cotizacion/medios-pago',
  },
  solicitudCotizacion:
  {
    name: "solicitud-cotizacion",
    path: '/app/cotizaciones/comercial/solicitud-cotizacion',
  },
  validateCustomer:
  {
    name: "Validate-Customer",
    path: '/app/cotizaciones/comercial/solicitud-cotizacion/validar-cliente',
  },
  nuevaSolicitud:
  {
    name: "Nueva-Solicitud",
    path: '/app/cotizaciones/comercial/solicitud-cotizacion/nueva-solicitud',
  },
  consultEditRequest:
  {
    name: "Comercial-Consult-Edit-Request",
    path: '/app/cotizaciones/comercial/solicitud-cotizacion/consultar-editar-solicitud',
  },
  vinculacionClientes:
  {
    name: "Vinculacion-Clientes",
    path: '/app/cotizaciones/comercial/vinculacion-clientes',
  },
  newCustomer:
  {
    name: "Comercial-New-Customer",
    path: '/app/cotizaciones/comercial/vinculacion-clientes/nuevo-cliente',
  },
  consultarEditarCliente:
  {
    name: "Consultar-Editar-Cliente",
    path: '/app/cotizaciones/comercial/vinculacion-clientes/consultar-editar-cliente',
  },
  consultarEditarCotizacion:
  {
    name: "Consultar-Editar-Cotizacion",
    path: '/app/cotizaciones/comercial/solicitud-cotizacion/editar-cotizacion',
  },
  localTransportQuote:
  {
    name: "Local-Transport-Quote",
    path: '/app/cotizaciones/transporte-local/consultar-solicitud/cotizacion-transporte-local',
  },
  homeInternationalTransporter:
  {
    name: "Home-International-Transporter",
    path: '/app/cotizaciones/transportador-internacional',
  },
  aduanasAprobacion:
  {
    name: "Aduanas-Aprobacion",
    path: '/app/cotizaciones/aduanas/aprobacion',
  },
  administradorConsultarSolicitud:
  {
    name: "Administrador-Consultar-Solicitud",
    path: '/app/cotizaciones/administrador/consultar-solicitud',
  },
  administradorConsultarEditarCotizacion:
  {
    name: "Administrador-consultar-editar-cotizacion",
    path: '/app/cotizaciones/administrador/consultar-solicitud/consultar-editar-cotizacion',
  },
  homeGeneral:
  {
    name: "Home-General",
    path: '/app/generales',
  },
  newImport:
  {
    name: "New-Import",
    path: '/app/generales/nueva-importacion',
  },
  newImportDetail:
  {
    name: "New-Import-Detail",
    path: '/app/generales/nueva-importacion/detalle-importacion',
  },
  associateContractQuote:
  {
    name: "Associate-Contract-Quote",
    path: '/app/generales/nueva-importacion/asociar-contrato-cotizacion',
  },
  addDispatch:
  {
    name: "Add-Dispatch",
    path: '/app/generales/adicionar-despacho',
  },
  existingImportAddNewDispatch:
  {
    name: "Existing-Import-Add-New-Dispatch",
    path: '/app/generales/adicionar-despacho/importacion-existente-agregar',
  },
  associateContractDispatch:
  {
    name: "Associate-Contract-Dispatch",
    path: '/app/generales/adicionar-despacho/asociar-contrato-despacho',
  },
  generalImportInquiry:
  {
    name: "General-Import-Inquiry",
    path: 'app/inicio/consulta-general-importaciones',
  },
  comercialCliente:
  {
    name: "Comercial-Cliente",
    path: '/app/cotizaciones/comercial/cliente',
  },
  vinculacionSolicitudCotizacion:
  {
    name: "Vinculacion-Solicitud-Cotizacion",
    path: '/app/cotizaciones/administrador/vinculacion-solicitud-cotizacion',
  },
  transportadorInternacional:
  {
    name: "Transportador-Internacional",
    path: '/app/cotizaciones/transportador-internacional',
  },
  logisticsCoordination:
  {
    name: "Logistics-Coordination",
    path: '/app/inicio/coordinacion-logistica',
  },
  internationalTransport_lc:
  {
    name: "International-Transport",
    path: '/app/inicio/coordinacion-logistica/transporte-internacional',
  },
  formTransporteInternacional_lc:
  {
    name: "Form-Transporte-Internacional",
    path: '/app/inicio/coordinacion-logistica/form-transporte-internacional',
  },
  formTransporteLocal_lc:
  {
    name: "Form-Transporte-Local",
    path: '/app/inicio/coordinacion-logistica/form-transporte-local',
  },
  blogMensajes_lc:
  {
    name: "Blog-mensajes-LC",
    path: '/app/inicio/coordinacion-logistica/blog-mensajes',
  },
  agenciamientoAduanero_lc:
  {
    name: "Agenciamiento-Aduanero",
    path: '/app/inicio/coordinacion-logistica/agenciamiento-aduanero',
  },
  formAgenciamientoAduanero_lc:
  {
    name: "Formulario-Aduanero",
    path: '/app/inicio/coordinacion-logistica/formulario-aduanero',
  },
  formNationalitationAgenciamientoAduanero_lc:
  {
    name: "Formulario-Nacionalizacion",
    path: '/app/inicio/coordinacion-logistica/formulario-aduanero/formulario-nacionalizacion',
  },
  declarationAgenciamientoAduanero_lc:
  {
    name: "Declaraciones-Importacion",
    path: '/app/inicio/coordinacion-logistica/formulario-aduanero/formulario-nacionalizacion/declaraciones-importacion',
  },
  transporteLocal_lc:
  {
    name: "Transporte-Local-Coordinacion-Logistica",
    path: '/app/inicio/coordinacion-logistica/transporte-local',
  },
  tramites_lc:
  {
    name: "Tramites-Coordinacion-Logistica",
    path: '/app/inicio/coordinacion-logistica/tramites',
  },
  pagos_lc:
  {
    name: "Pagos-Coordinacion-Logistica",
    path: '/app/inicio/coordinacion-logistica/pagos',
  },
  reports:
  {
    name: "Informes",
    path: '/app/informes',
  },
  generals:
  {
    name: "Generales",
    path: '/app/generales',
  },
  consultarEditarImportacion:
  {
    name: "Consultar-Editar-Importacion",
    path: '/app/generales/consultar-editar-importacion',
  },
  detalleImportacionExistente:
  {
    name: "Detalle-Importacion-Existente",
    path: '/app/generales/consultar-editar-importacion/detalle-importacion-existente',
  },
  detalleDespachoAsociado:
  {
    name: "Detalle-Despacho-Asociado",
    path: '/app/generales/consultar-editar-importacion/detalle-despacho-asociado',
  },
  adicionardespacho:
  {
    name: "Adicionar-Despacho",
    path: '/app/generales/adicionar-despacho',
  },
  adminRol:
  {
    name: "Admin-Rol",
    path: '/app/generales/admin-rol',
  },
  adminRolGestionar:
  {
    name: "Admin-Rol-Gestionar",
    path: '/app/generales/admin-rol/gestionar',
  },
  adminRolEditarRol:
  {
    name: "Admin-Rol-Editar-Rol",
    path: '/app/generales/admin-rol/editar-rol',
  },
  adminRolEditar:
  {
    name: "Admin-Rol-Editar",
    path: '/app/generales/admin-rol/editar',
  },
  adminRolEliminar:
  {
    name: "Admin-Rol-Eliminar",
    path: '/app/generales/admin-rol/eliminar-rol',
  },
  adminUsuarioGestionar:
  {
    name: "Admin-Usuario-gestionar",
    path: '/app/generales/admin-usuario/gestionar',
  },
  adminUsuarioEditarUsuario:
  {
    name: "Admin-Usuario-Editar-Usuario",
    path: '/app/generales/admin-usuario/editar-usuario',
  },
  adminUsuarioEditar:
  {
    name: "Admin-Usuario-Edita",
    path: '/app/generales/admin-usuario/editar',
  },
  adminUsuarioEliminarUsuario:
  {
    name: "Admin-Usuario-Eliminar-Usuario",
    path: '/app/generales/admin-usuario/eliminar-usuario',
  },
  liquidacion:
  {
    name: "Liquidacion",
    path: '/app/generales/liquidacion',
  },
  iniciarLiquidacion:
  {
    name: "Iniciar-Liquidacion",
    path: '/app/generales/liquidacion/iniciar-liquidacion',
  },
  temporales:
  {
    name: "Temporales",
    path: '/app/temporales',
  },
  temporalesBuscar:
  {
    name: "Temporales-Buscar",
    path: '/app/temporales/buscar',
  },
  temporal:
  {
    name: "Temporal",
    path: '/app/temporales/temporal',
  },
  statement:
  {
    name: "Statement",
    path: '/app/temporales/statement',
  },
  statementDoc:
  {
    name: "Statement-doc",
    path: '/app/temporales/statement-doc',
  },
  statementDocRep:
  {
    name: "Statement-doc-rep",
    path: '/app/temporales/statement-doc-rep',
  },
  statementBlog:
  {
    name: "Statement-blog",
    path: '/app/temporales/blog',
  },
  statementCierreBuscar:
  {
    name: "Statement-Cierre-Buscar",
    path: '/app/temporales/cierre/buscar',
  },
  statementCierreTemporal:
  {
    name: "Statement-Cierre-Temporal",
    path: 'app/temporales/cierre/temporal',
  },
  statementCierreDocumentos:
  {
    name: "Statement-Cierre-Documentos",
    path: 'app/temporales/cierre/documentos',
  },
  statementCierreNotificacion:
  {
    name: "Statement-Cierre-Notificacion",
    path: 'app/temporales/cierre/notificacion',
  },
  consultarLiquidacion:
  {
    name: "Consultar-Liquidacion",
    path: '/app/generales/consultar-liquidacion',
  },
  internationalTransport:
  {
    name: "",
    path: '',
  },
}
