import { Injectable } from '@angular/core';
import { ApiHelperService } from 'src/app/data/services/api-helper/api-helper.service';
import { APIS_URL } from 'src/app/core/constants/url';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ICotizationTransportLocal, ICotizationTransportLocalUp, IListCotizationTranspLocal } from '../../interfaces/ITranspLocal.interface';
import { ICotizationAduanas, ICotizationAduanasAssets, ICotizationAduanasDutyUp, ICotizationAduanasUp, ICotizationsDetailsCustomsFeasibility, IListCotizationAduanas, } from '../../interfaces/ICotizationAduanas.interface';
import { ICotizationTransportInternationalUp, ICotizationTransportIntertational, IListCotizationTranspInternational } from '../../interfaces/ITranspInternational.interface';

@Injectable({
  providedIn: 'root'
})
export class ApisPostProdService {

  private endPointCotizationsDetailTransp = APIS_URL.cotizationsDetailsTransport;
  private endPointCotizationsCustoms = APIS_URL.cotizationCustoms;
  private endPointCotizationCustomsFeasibility = APIS_URL.cotizationCustomsFeasibility;
  private endPointCotizationsDetails = APIS_URL.cotizationsDetails;

  constructor(private apiHelperService: ApiHelperService) {
    // This is intentional
  }

  /** GET: database/cotizationsDetailsTransport?transportType::LOCAL */
  searchCotizationsDetailsLocal(
    cib: string, allyId: number
  ): Observable<ICotizationTransportLocal> {
    const params = `?transportType=LOCAL&cib=${cib}&allyId=${allyId}`;
    return this.apiHelperService.get(
      this.endPointCotizationsDetailTransp + params
    ).pipe(
      map((cust: any) => cust.Cotization_Transport)
    );
  }

  /** GET: database/cotizationsDetailsTransport?transportType::INTERNACIONAL */
  searchCotizationsDetailsInternational(
    cib: string, allyId: number
  ): Observable<ICotizationTransportIntertational> {
    const params = `?transportType=INTERNATIONAL&cib=${cib}&allyId=${allyId}`;
    return this.apiHelperService.get(
      this.endPointCotizationsDetailTransp + params
    ).pipe(map((cust: any) => cust.Cotization_Transport));
  }

  /** PATCH: database/cotizationsDetailsTransport?transportType::LOCAL */
  patchCotizationsDetailsTranspLocal(
    id: number,
    cotizationDataLocal: ICotizationTransportLocalUp
  ): Observable<boolean> {
    const params = `/localTransport?id=${id}`;
    return this.apiHelperService.patch<boolean>(
      this.endPointCotizationsDetailTransp + params,
      cotizationDataLocal
    ).pipe(
      map((resp) => {
        return true;
      })
    );
  }

  /** PATCH: database/cotizationsDetailsTransport?transportType::INTERNACIONAL */
  patchCotizationsDetailsTransInternacional(
    id: number,
    cotizationDataInternational: ICotizationTransportInternationalUp
  ): Observable<boolean> {
    const params = `/internationalTransport?id=${id}`;
    return this.apiHelperService.patch<boolean>(
      this.endPointCotizationsDetailTransp + params,
      cotizationDataInternational
    ).pipe(
      map((resp) => {
        return true;
      })
    );
  }

  /** GET: database/cotizationCustoms::ADUANAS */
  searchCotizationsCustoms(cib: string, allyId: number): Observable<ICotizationAduanas> {
    const params = `/filter?cib=${cib}&allyId=${allyId}`;
    return this.apiHelperService.get(
      this.endPointCotizationsCustoms + params
    ).pipe(map((cust: any) => cust.Cotization_Customs));
  }

  /** PUT: database/cotizationCustoms::ADUANAS */
  putCotizationsCustoms(
    id: number,
    customsData: ICotizationAduanasUp
  ): Observable<any> {
    const params = `?id=${id}`;
    return this.apiHelperService.put(
      this.endPointCotizationsCustoms + params, customsData
    ).pipe(
      map((resp) => { return true; })
    );
  }

  /** GET: consultar descripción activos de aduanas: tabla */
  consultAssetsCustoms(
    cib: string, page: number, size: number
  ): Observable<ICotizationAduanasAssets> {
    const params = `/get-assets?cib=${cib}&page=${page}&size=${size}`;
    return this.apiHelperService.get(
      this.endPointCotizationsDetails + params
    ).pipe(
      map((cust: any) => cust._embedded)
    );
  }

  /** GET: consultar información impuestos y partida arancelaria: tabla */
  consultInpuestosDuties(
    id: number, page: number, size: number
  ): Observable<ICotizationsDetailsCustomsFeasibility> {
    const params = `/filter?ib=${id}&page=${page}&size=${size}`;
    return this.apiHelperService.get(
      this.endPointCotizationCustomsFeasibility + params
    ).pipe(
      map((cust: any) => cust._embedded)
    );
  }

  /** PUT: consultar información impuestos y partida arancelaria: tabla */
  consultInpuestosDutiesUp(id: number): Observable<ICotizationAduanasDutyUp> {
    const params = `?ib=${id}`;
    return this.apiHelperService.put(
      this.endPointCotizationCustomsFeasibility + params
    ).pipe(
      map((cust: any) => cust._embedded)
    );
  }

  /** ******************************** Para listar cotizaciones en el Equipo Administrativo ******************************** */
  /** GET: listar cotizaciones en Transporte Internacional */
  searchListCotizationsDetailsTranspInternational(
    cib: string, page: number, size: number
  ): Observable<IListCotizationTranspInternational> {
    const params = `/get-transports?cib=${cib}&transportType=INTERNATIONAL&page=${page}&size=${size}`;
    return this.apiHelperService.get(
      this.endPointCotizationsDetailTransp + params
    ).pipe(
      map((cust: any) => cust)
    );
  }

  /** GET: listar cotizaciones en Transporte Local */
  searchListCotizationsDetailsTranspLocal(
    cib: string, page: number, size: number
  ): Observable<IListCotizationTranspLocal> {
    const params = `/get-transports?cib=${cib}&transportType=LOCAL&page=${page}&size=${size}`;
    return this.apiHelperService.get(
      this.endPointCotizationsDetailTransp + params
    ).pipe(
      map((cust: any) => cust)
    );
  }

  /** GET: listar cotizaciones en Aduanas */
  searchListCotizationsDetailsAduanas(cib: string): Observable<IListCotizationAduanas> {
    const params = `/allByCib?cib=${cib}`;
    return this.apiHelperService.get(
      this.endPointCotizationsCustoms + params
    ).pipe(
      map((cust: any) => cust)
    );
  }

}
