import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bcs-modal',
  templateUrl: './bcs-modal.component.html',
  styleUrls: ['./bcs-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BcsModalComponent implements OnInit {

  @Input() titulo!: string;
  @Input() mensaje!: string;
  @Input() LabelButton: string = 'Ok';
  @Input() typeModal: string = 'ok';
  @Input() urlSrc!: string;
  @Input() Icon: string = '';
  @Input() textButton: string = 'Crear';
  @Input() modalType: string = 'info';

  @Input()  paymentChildId!: number ;
  @Input() importNumber!: string  ;

  constructor(public activeModal: NgbActiveModal) {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

  onClick(opcion: string) {
    this.activeModal.close(opcion);
  }

  close() {
    this.activeModal.close('close');
  }
}
