import { createAction, props } from '@ngrx/store';
import { CreditLetterDtoList, ICreditLetterDtoList, GirosDtoList } from '../../interfaces/ICreditLetterDtoList.interface';
import { CommissionCredit, IHistoricCommission } from '../../interfaces/ICommissionCredit';
import { ParameterTypeList } from '../../interfaces/paymentMethod.interface';
import { IPayment } from '../../interfaces/payment.interface';
import { ICardValues } from '../../interfaces/paymentsValuesCard.interface';
import { IFilesPayment } from 'src/app/data/interfaces/IFilesPayment.interface';

enum PaymentsActionType {
  getPaymentsValuess = '[payments Component] get payments values',
  getPaymentsValuesActionSuccess = '[payments Component] get payments values Success',
  findCreditLetterAction = '[payments Component] get creditLetter',
  findCreditLetterSuccessAction = '[payments Component] get creditLetter Success',
  findCreditTransferAction = '[payments Component] get Transfer',
  findCreditTransferSuccessAction = '[payments Component] get Transfer Success',
  findCreditServicesAction = '[payments Component] get Services',
  findCreditServicesSuccessAction = '[payments Component] get Services Success',
  findEveryCommissionAction = '[payments Component] get every commission',
  findEveryCommissionSuccessAction = '[payments Component] get every commission Success',
  historicCommissionAction = '[payments Component] get historic commission',
  historicCommissionSuccessAction = '[payments Component] get historic commission Success',
  addCommissionAction = '[payments Component] add Commission',
  addCommissionSuccessAction = '[payments Component] add Commission Success',
  updateCommissionAction = '[payments Component] update Commission',
  updateCommissionSuccessAction = '[payments Component] update Commission Success',
  deleteCommissionAction = '[payments Component] delete Commission',
  deleteCommissionSuccessAction = '[payments Component] delete Commission Success',
  getTypeMethodAction = '[payments Component] get Type Method',
  getTypeMethodSuccessAction = '[payments Component] get Type Method Success',
  getAgreedDocumentAction = '[payments Component] get Agreed Document',
  getAgreedDocumentSuccessAction = '[payments Component] get Agreed Document Success',
  sendEmailCustomAction = '[payments Component] send Email Custom',
  resetSendEmailCustomAction = '[payments Component] reset send Email Custom',
  sendEmailCustomSuccessAction = '[payments Component] send Email Custom Success',
  getFilesPaymentAction = '[payments Component] get Files Payment',
  getFilesPaymentSuccessAction = '[payments Component] get Files Payment Success',
  updatedPaymentLetterAction = '[payments Component] updated Payment Letter',
  updatedPaymentLetterSuccessAction = '[payments Component] updated Payment Letter Success',
  deletePaymentAction = '[payments Component] delete Payment',
  deletePaymentSuccessAction = '[payments Component] delete Payment Success',
  updatedPaymentTransferAction = '[payments Component] updated Payment Transfer',
  updatedPaymentServicesAction = '[payments Component] updated Payment Services',
  updatedPaymentTransferSuccessAction = '[payments Component] updated Payment Transfer Success',
  updatedPaymentServicesSuccessAction = '[payments Component] updated Payment Services Success',
  updatePaymentStatusAction = '[payments Component] update Payment Status',
  updatePaymentStatusSuccessAction = '[payments Component] update Payment Status Success',
  createPaymentsAction = '[payments Component] create Payments',
  createPaymentsSuccessAction = '[payments Component] create Payments Success',
  onError = '[payments Component] ups error',
  onResetFlagsAction = '[payments Component] payments reset flags',
}

const getPaymentsValues = createAction(
  PaymentsActionType.getPaymentsValuess,
  props<{ ImportId: number }>()
);

const getPaymentsValuesSuccess = createAction(
  PaymentsActionType.getPaymentsValuesActionSuccess,
  props<{ cardValues: ICardValues }>()
);

const findCreditLetter = createAction(
  PaymentsActionType.findCreditLetterAction,
  props<{ importNumber: string, page: number, size: number }>()
);

const findCreditLetterSuccess = createAction(
  PaymentsActionType.findCreditLetterSuccessAction,
  props<{ creditLetterDtoList: ICreditLetterDtoList }>()
);

const findTransfer = createAction(
  PaymentsActionType.findCreditTransferAction,
  props<{ importNumber: string, page: number, size: number }>()
);

const findTransferSuccess = createAction(
  PaymentsActionType.findCreditTransferSuccessAction,
  props<{ transferDtoList: ICreditLetterDtoList }>()
);

const findServices = createAction(
  PaymentsActionType.findCreditServicesAction,
  props<{ importNumber: string, page: number, size: number }>()
);

const findServicesSuccess = createAction(
  PaymentsActionType.findCreditServicesSuccessAction,
  props<{ servicesDtoList: ICreditLetterDtoList }>()
);

const addCommission = createAction(
  PaymentsActionType.addCommissionAction,
  props<{ commissionCredit: CommissionCredit, importNumber: string, lcNumber: string }>()
);

const addCommissionSuccess = createAction(
  PaymentsActionType.addCommissionSuccessAction,
  props<{ data: any }>()
);

const findEveryCommission = createAction(
  PaymentsActionType.findEveryCommissionAction,
  props<{ importNumber: string, lcNumber: string }>()
);

const findEveryCommissionSuccess = createAction(
  PaymentsActionType.findEveryCommissionSuccessAction,
  props<{ commissionCredit: CommissionCredit[] }>()
);

const updateCommission = createAction(
  PaymentsActionType.updateCommissionAction,
  props<{ commissionCredit: CommissionCredit[], importNumber: string, lcNumber: string }>()
);

const updateCommissionSuccess = createAction(
  PaymentsActionType.updateCommissionSuccessAction,
  props<{ data: any }>()
);

const deleteCommission = createAction(
  PaymentsActionType.deleteCommissionAction,
  props<{ importNumber: string, lcNumber: string }>()
);

const deleteCommissionSuccess = createAction(
  PaymentsActionType.deleteCommissionSuccessAction,
  props<{ deleteComissionData: any }>()
);

const historicCommission = createAction(
  PaymentsActionType.historicCommissionAction,
  props<{ importNumber: string, lcNumber: string, event: string, page: number, size: number }>()
);

const historicCommissionSuccess = createAction(
  PaymentsActionType.historicCommissionSuccessAction,
  props<{ historicCommission: IHistoricCommission }>()
);

//******************** Payments Component ********************
const getTypeMethod = createAction(
  PaymentsActionType.getTypeMethodAction,
  // props<{ data: any }>()
);

const getTypeMethodSuccess = createAction(
  PaymentsActionType.getTypeMethodSuccessAction,
  props<{ typeMethodValues: ParameterTypeList[] }>()
);

const getAgreedDocument = createAction(
  PaymentsActionType.getAgreedDocumentAction,
  // props<{ data: any }>()
);

const getAgreedDocumentSuccess = createAction(
  PaymentsActionType.getAgreedDocumentSuccessAction,
  props<{ agreedDocumentValues: ParameterTypeList[] }>()
);
const createPayments = createAction(
  PaymentsActionType.createPaymentsAction,
  props<{ payments: IPayment[], importNumber: string }>()
);

const createPaymentsSuccess = createAction(
  PaymentsActionType.createPaymentsSuccessAction,
  props<{ createdPaymentData: any[] }>()
);

const updatedPaymentLetter = createAction(
  PaymentsActionType.updatedPaymentLetterAction,
  props<{ payment: CreditLetterDtoList }>()
);

const updatedPaymentLetterSuccess = createAction(
  PaymentsActionType.updatedPaymentLetterSuccessAction,
  props<{ updatedPaymentLetterData: any }>()
);
const deletePayment = createAction(
  PaymentsActionType.deletePaymentAction,
  props<{ payment: CreditLetterDtoList }>()
);

const deletePaymentSuccess = createAction(
  PaymentsActionType.deletePaymentSuccessAction,
  props<{ deletePaymentData: any }>()
);

const updatedPaymentTransfer = createAction(
  PaymentsActionType.updatedPaymentTransferAction,
  props<{ payment: GirosDtoList }>()
);

const updatedPaymentTransferSuccess = createAction(
  PaymentsActionType.updatedPaymentTransferSuccessAction,
  props<{ updatedPaymentTransferData: GirosDtoList }>()
);

const updatedPaymentServices = createAction(
  PaymentsActionType.updatedPaymentServicesAction,
  props<{ payment: CreditLetterDtoList }>()
);

const updatedPaymentServicesSuccess = createAction(
  PaymentsActionType.updatedPaymentServicesSuccessAction,
  props<{ updatedPaymentServicesData: any }>()
);
const updatePaymentStatus = createAction(
  PaymentsActionType.updatePaymentStatusAction,
  props<{ payments: number[], typeUpdate: string, customs: string }>()
);

const updatePaymentStatusSuccess = createAction(
  PaymentsActionType.updatePaymentStatusSuccessAction,
  props<{ updatePaymentStatusData: string }>()
);

const sendEmailCustom = createAction(
  PaymentsActionType.sendEmailCustomAction,
  props<{ importNumber: string, billNumber: number, DatePayment: string, customs: string }>()
);


const resetSendEmailCustom = createAction(
  PaymentsActionType.resetSendEmailCustomAction,
  props<{ sendEmailCustomData: any }>()
);

const sendEmailCustomSuccess = createAction(
  PaymentsActionType.sendEmailCustomSuccessAction,
  props<{ sendEmailCustomData: any }>()
);
const getFilesPayment = createAction(
  PaymentsActionType.getFilesPaymentAction,
  props<{ importNumber: string }>()
);
const getFilesPaymentSuccess = createAction(
  PaymentsActionType.getFilesPaymentSuccessAction,
  props<{ getFilesPaymentData: IFilesPayment }>()
);
//******************** Payments Component ********************

const onErrors = createAction(
  PaymentsActionType.onError,
  props<{ error: any }>()
);

const onResetFlags = createAction(PaymentsActionType.onResetFlagsAction);

export const PaymentsActions = {
  sendEmailCustom,
  sendEmailCustomSuccess,
  resetSendEmailCustom,
  getFilesPayment,
  getFilesPaymentSuccess,
  createPayments,
  createPaymentsSuccess,
  updatePaymentStatus,
  updatePaymentStatusSuccess,
  deletePayment,
  deletePaymentSuccess,
  updatedPaymentLetter,
  updatedPaymentLetterSuccess,
  updatedPaymentTransfer,
  updatedPaymentTransferSuccess,
  updatedPaymentServices,
  updatedPaymentServicesSuccess,
  getTypeMethod,
  getTypeMethodSuccess,
  getAgreedDocument,
  getAgreedDocumentSuccess,
  getPaymentsValues,
  getPaymentsValuesSuccess,
  findCreditLetter,
  findCreditLetterSuccess,
  findTransfer,
  findTransferSuccess,
  findServices,
  findServicesSuccess,
  addCommission,
  addCommissionSuccess,
  findEveryCommission,
  findEveryCommissionSuccess,
  updateCommission,
  updateCommissionSuccess,
  historicCommission,
  historicCommissionSuccess,
  deleteCommission,
  deleteCommissionSuccess,
  onErrors,
  onResetFlags,
  };
