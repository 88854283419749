export enum ParametersTypeEnum {
  DOCUMENT_TYPE = 'TIPOS DE DOCUMENTO',
  REGIONAL = 'REGIONAL',
  LEASING_TYPE = 'TIPO DE LEASING',
  ASSET_TYPE = 'TIPO DE ACTIVO',
  STATUS = 'ESTADOS - COTIZACIÓN',
  MEANS_OF_PAYMENTS = 'MEDIOS DE PAGOS',
  CLIENT_CERTIFICATIONS = 'CERTIFICACIONES CLIENTE',
  PROVIDERS_CERTIFICATIONS = 'CERTIFICACIONES PROVEEDOR',
  TYPE_OF_TRANSPORT = 'TIPO DE TRANSPORTE',
  TYPE_OF_LOAD = 'TIPO DE CARGA',
  INCOTERM = 'INCOTERM',
  CURRENCIES = 'CURRENCIES',
  TYPE_OF_IMPORT = 'MODALIDAD DE LA IMPORTACIÓN',
  YES_OR_NOT = 'SI O NO',
  UPLOAD_FILE_CORRECT = "Se han guardado correctamente los archivos.",
  NEW_OR_USED = 'NUEVO O USADO',
  IMPORT_STATUS = 'ESTADO IMPORTACION',
  TYPE_OF_CONTAINER = 'TIPO DE CONTENEDOR',
  WEIGHT_UNIT = 'UNIDAD DE PESO',
  UNIT_OF_MEASUREMENT = 'UNIDAD DE MEDIDA',
  LETTER_OF_CREDIT = 'CARTA DE CREDITO',
  ALLY_TYPE = 'TIPO DE ALIADO',
  CITIES = 'CITIES',
  COUNTRIES = 'COUNTRIES',
  TRANSPORT_DOCUMENT_TYPE = 'TIPO DE DOCUMENTO DE TRANSPORTE',
  ASSET_INSURANCE = 'SEGUROS DE ACTIVOS',
  LIST_OF_DOCUMENTS = 'LISTA DE DOCUMENTOS',
  IMPORT_MANAGER = 'GESTOR DE LA IMPORTACIÓN',
  NEW_OR_USED_CUSTOMS = 'ESTADO ACTIVO - ADUANAS', //Este es el parametro puesto en la base de datos
  ZONE_LEADER = 'LIDER DE ZONA',
  SECURITY_GROUP = 'GRUPO DE SEGURIDAD',
  LIST_ROLES = 'ROLES',
  MODULOS_FRONT = 'MODULOS FRONT',
  LABEL_INICIAR_LIQUIDACION = 'Iniciar Liquidación',
  LABEL_APROBAR = 'Aprobar, Guardar y Continuar',
  LABEL_GUARDAR = 'Guardar y Continuar',
  LABEL_CONTINUAR = 'Continuar',
  TYPE_VEHICLE = "VEHÍCULO",
  TYPE_AGREEMENTVEHICLE = "VEHÍCULO DE CONVENIO",
  TYPE_MACHINERY = "MAQUINARIA",
  TYPE_TECHNOLOGY = "TECNOLOGÍA",
  TYPE_OTHERS = "OTROS",
  STATUS_PAID = "Pagado",
  FARE = "tarifas",
  ROL_TRANSPORTE_LOCAL = "ROL_TRANSPORTE_LOCAL",
  ROL_TRANSPORTE_INTERNACIONAL = "ROL_TRANSPORTE_INTERNACIONAL"
}

export enum FILEDOCUMENT {
 COTIZACION_DE_IMPORTACION = 'cotizacion-de-importacion',
 COMENTARIOS_ADUANEROS = 'comentarios-aduaneros-y-cambios',
 MOMENTOS_DE_LA_IMPORTACION = 'momentos-de-la-importacion'
}
