import { createAction, props } from '@ngrx/store';
import { ICotizationCreate } from 'src/app/data/interfaces/ICotizationCreate.interface';
import { ICotization } from 'src/app/data/interfaces/ICotization.interface';
import { ICotizationFilter } from 'src/app/data/interfaces/ICotizationFilter.interface';

enum CotizationsActionType {
  Load = '[Cotizations Component] Load',
  searchCotizationByFilter = '[Cotizations Component] Search Cotization',
  searchCotizationByFilterSuccess = '[Cotizations Component] Search Cotization By Filter Success',
  searchCotizationByFilterToDeleteAction = '[Cotizations Component] Search Cotization To Delete',
  searchCotizationByFilterToDeleteSuccessAction = '[Cotizations Component] Search Cotization By Filter To Delete Success',
  deleteCotizationsAction = '[Cotizations Component] Delete Cotizations',
  deleteCotizationsSuccessAction = '[Cotizations Component] Delete Cotizations Success',
  searchCotizationByCIBAction = '[Cotizations Component] Search Cotization by CIB',
  searchCotizationByCIBSuccessAction = '[Cotizations Component] Search Cotization By CIB Success',
  updateCotizations = '[Cotizations Component] Update Cotization ',
  updateCotizationsSuccess = '[Cotizations Component] Update Cotization Success',
  createCotizationsAction = '[Cotizations Component] Create Cotization',
  createCotizationsSuccessAction = '[Cotizations Component] Create Cotization Success',
  setCotization = '[Cotizations Component] Set Cotization into Storage',
  onEditCotizationAction = '[Cotizations Component] Set true edit cotization',
  error = '[Cotizations Component] Execute action failure',
  actionSuccess = '[Cotizations Component] Execute action success',
  loadSuccess = '[Cotizations Component] Load Success',
  refresh = '[Cotizations Page] Refresh',
  selected = '[Cotizations Page] Select',
  submitSuccess = '[Cotizations API] Submit Success',
  onResetFlagsAction = '[Cotizations API] Cotization reset flags',
  onResetFlagsActionTrue = '[Cotizations Component] Cotization res true',
  
  onResetFlagsCotizationDetail = '[Cotizations API] Cotization detail reset flags',

  findCotizationByParamsAction = '[Cotizations Component] Find Cotization By Params',
  findCotizationByParamsSuccessAction = '[Cotizations Component] Find Cotization By Params Success',

  cotizationApprovalAction = '[Cotizations Component] Cotization Approval',
  cotizationAdminAprovalSuccessAction = '[Cotizations Component] Cotization Approval success',

  cotizationCustomHouseAprovalResponseAction = '[Cotizations Component] Cotization Approval Response',
  cotizationCustomHouseAprovalAction = '[Cotizations Component] Cotization Custom House Aproval ',

  updateCotizationAction = '[Cotizations Component] Cotization comercial before detail',
  updateCotizationSuccessAction = '[Cotizations Component] Cotization comercial before detail Success',
  
  updateZoneLeaderAction = '[Cotizations Component] update Zone Leader',
  updateZoneLeaderSuccessAction = '[Cotizations Component] update Zone Leader Success',

  
  getTermsAndConditionsAction = '[Cotizations Component] Get terms and conditions',
  getTermsAndConditionsSuccessAction = '[Cotizations Component] Get terms and conditions Success',
}

const load = createAction(CotizationsActionType.Load);
const searchCotizations = createAction(
  CotizationsActionType.searchCotizationByFilter,
  props<{ cotization: ICotizationCreate }>()
);

const createCotizations = createAction(
  CotizationsActionType.createCotizationsAction,
  props<{ cotization: ICotizationCreate }>()
);
const createCotizationsSuccess = createAction(
  CotizationsActionType.createCotizationsSuccessAction,
  props<{ cotization: ICotization }>()
);

const SearchCotizationByFilter = createAction(
  CotizationsActionType.searchCotizationByFilter,
  props<{ params: ICotizationFilter; page: number; size: number }>()
);
const SearchCotizationByFilterSuccess = createAction(
  CotizationsActionType.searchCotizationByFilterSuccess,
  props<{ cotizations: ICotization }>()
);

const SearchCotizationByFilterToDelete = createAction(
  CotizationsActionType.searchCotizationByFilterToDeleteAction,
  props<{ params: ICotizationFilter; page: number; size: number }>()
);
const SearchCotizationByFilterToDeleteSuccess = createAction(
  CotizationsActionType.searchCotizationByFilterToDeleteSuccessAction,
  props<{ cotizations: ICotization }>()
);

const deleteCotizations = createAction(
  CotizationsActionType.deleteCotizationsAction,
  props<{ body: any }>()
);
const deleteCotizationsSuccess = createAction(
  CotizationsActionType.deleteCotizationsSuccessAction,
  props<{ edit: boolean }>()
);

const searchCotizationByCIB = createAction(
  CotizationsActionType.searchCotizationByCIBAction,
  props<{ CIB: string; clientSection: boolean }>()
);
const searchCotizationByCIBSuccess = createAction(
  CotizationsActionType.searchCotizationByCIBSuccessAction,
  props<{ cotization: ICotization }>()
);

const SetCotization = createAction(
  CotizationsActionType.setCotization,
  props<{ cotization: ICotization | null }>()
);
const onEditCotization = createAction(
  CotizationsActionType.onEditCotizationAction,
  props<{ edit: boolean }>()
);
const onResetFlags = createAction(CotizationsActionType.onResetFlagsAction);

const onResetFlagsTrue = createAction(CotizationsActionType.onResetFlagsActionTrue);

const onError = createAction(
  CotizationsActionType.error,
  props<{ error: any }>()
);

const findCotizationByParams = createAction(
  CotizationsActionType.findCotizationByParamsAction,
  props<{
    documentType?: string;
    documentNumber?: string;
    clientName?: string;
    cib?: string;
    initDate?: string;
    endDate?: string;
    pagination?: { page?: number; size?: number; }
  }>()
);
const findCotizationByParamsSuccess = createAction(
  CotizationsActionType.findCotizationByParamsSuccessAction,
  props<{ cotizations: ICotization }>()
);

const cotizationAdminAproval = createAction(
  CotizationsActionType.cotizationApprovalAction,
  props<{
    cotizationId: number;
    adminAproval: boolean;
  }>()
);
const cotizationAdminAprovalSuccess = createAction(
  CotizationsActionType.cotizationAdminAprovalSuccessAction,
  props<{
    message: any;
  }>()
);
const cotizationCustomHouseAproval = createAction(
  CotizationsActionType.cotizationCustomHouseAprovalAction,
  props<{
    cotizationId: number;
    customHouseAproval: boolean;
  }>()
);
const cotizationCustomHouseAprovalResponse = createAction(
  CotizationsActionType.cotizationCustomHouseAprovalResponseAction,
  props<{
    message: any;
  }>()
);

const updateCotization = createAction(
  CotizationsActionType.updateCotizationAction,
  props<{
    cotization: any;
    cotizationId: number;
  }>()
);
const updateCotizationSuccess = createAction(
  CotizationsActionType.updateCotizationSuccessAction,
  props<{ message: string }>()
);

const updateZoneLeader = createAction(
  CotizationsActionType.updateZoneLeaderAction,
  props<{
    idZoneLeader: number;
    cib: string;
  }>()
);
const updateZoneLeaderSuccess = createAction(
  CotizationsActionType.updateZoneLeaderSuccessAction,
  props<{ message: string }>()
)

const getTermsAndConditions = createAction(CotizationsActionType.getTermsAndConditionsAction);

const getTermsAndConditionsSuccess = createAction(
  CotizationsActionType.getTermsAndConditionsSuccessAction,
  props<{ termsAndConditions: any }>()
);

export const CotizationsActions = {
  load,
  searchCotizations,
  createCotizations,
  createCotizationsSuccess,
  SearchCotizationByFilter,
  SearchCotizationByFilterSuccess,
  SearchCotizationByFilterToDelete,
  SearchCotizationByFilterToDeleteSuccess,
  deleteCotizations,
  deleteCotizationsSuccess,
  onResetFlagsTrue,
  searchCotizationByCIB,
  searchCotizationByCIBSuccess,
  SetCotization,
  onEditCotization,
  onResetFlags,
  onError,
  findCotizationByParams,
  findCotizationByParamsSuccess,
  cotizationAdminAproval,
  cotizationAdminAprovalSuccess,
  cotizationCustomHouseAproval,
  cotizationCustomHouseAprovalResponse,
  updateCotization,
  updateCotizationSuccess,
  updateZoneLeader,
  updateZoneLeaderSuccess,
  getTermsAndConditions,
  getTermsAndConditionsSuccess
};
