import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { OptionsMask } from '@constants/options-mask';

@Component({
  selector: 'bcs-text-box',
  templateUrl: './bcs-text-box.component.html',
  styleUrls: ['./bcs-text-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BcsTextBoxComponent implements OnInit {
  @Input() Label: string = '';
  @Input() disabled: boolean = false;
  @Input() readonly: boolean | any = false;
  @Input() Type: any = 'text';
  @Input() formatPercentage: boolean = false;
  @Input() HelpText: string = '';
  @Input() Icon: string = '';
  @Input() IconPosition: 'Left' | 'Right' = 'Right';
  @Input() Id: string = 'input-default';
  @Input() InputFormControl: any = new UntypedFormControl(null, []);
  @Input() submited: boolean = false;
  @Input() formControlName!: string | number | null;
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'lx' = 'sm';
  @Input() capitalizeInputDisable: boolean = false;
  @Input() locked: boolean = false;
  @Input() typeTransform: 'UpperCase' | 'Lowercase' | 'Capitalize' =
    'Capitalize';
  @Input() max!: number;
  @Input() min!: number;

  @Input() iconTooltipText: string = '';
  @Input() iconTooltipTitle!: string;
  @Input() iconTooltipPosition: 'top' | 'right' | 'bottom' | 'left' = 'bottom';
  @Input() iconTooltip: boolean = false;
  @Input() valores: any = '';
  @Input() value: any = '';

  @Output() inputEvent = new EventEmitter();

  state: boolean = false;
  optionCurrencyMask: any = OptionsMask.percentages;
  yearControl: any;
  optionCurrencyMaskThousand: any = OptionsMask.decimals;
  constructor() {
    // This is intentional
  }

  ngOnInit(): void {
    if (this.valores !== '') {
      this.InputFormControl = new UntypedFormControl(this.valores, []);
    }
  }

  get control() {
    return this.InputFormControl;
  }

  get isError(): boolean {
    return ((this.submited == !this.state || this.control?.touched == !this.state) && this.control?.invalid) ?? false;
  }

  get errorText(): string {
    return this.makeMessage();
  }

  makeMessage() {
    let msg = '';
    if (this.control.errors?.required == !this.state) { msg = `El campo ${this.Label} es requerido`; }
    if (msg == '' && this.control.errors?.minlength) { msg = `El campo ${this.Label} debe tener minimo ${this.control.errors?.minlength.requiredLength} caracteres`; }
    if (msg == '' && this.control.errors?.maxlength) { msg = `El campo ${this.Label} debe tener máximo ${this.control.errors?.maxlength.requiredLength} caracteres`; }
    if (msg == '' && this.control.errors?.max) { msg = `El campo ${this.Label} debe ser ${this.Label?.includes('Porcentaje') ? 'menor o igual a' : 'un año menor a'} ${this.control.errors?.max.max}`; }
    if (msg == '' && this.control.errors?.min) { msg = `El campo ${this.Label} debe ser un año mayor a ${this.control.errors?.min.min}`; }
    if (msg == '' && this.control.errors?.email) { msg = `El campo ${this.Label} debe tener un formato válido de correo electrónico`; }
    if (msg == '' && this.control.errors) { msg = `El campo ${this.Label} contiene errores.`; }
    return msg;
  }

  onKeyPressEvent() {
    this.inputEvent.emit('true');
  }

}



type input =
  | 'number'
  | 'text'
  | 'date'
  | 'email'
  | 'file'
  | 'button'
  | 'submit'
  | 'password'
  | 'format';
