import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { liquidationActions } from './actions';
import { LiquidationService } from '@services/apis/liquidation/liquidation.service';

@Injectable()
export class LiquidationEffects {

  constructor(
    private liquidationService: LiquidationService,
    private actions$: Actions
  ) {
    // This is intentional
  }

  findSettlementImportSummaryEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.findSettlementImportSummary),
      switchMap(({ importNumber, settlementUser }) =>
        this.liquidationService.getSettlementImportSummary(importNumber, settlementUser).pipe(
          map((settlementImportSummary) =>
            liquidationActions.findSettlementImportSummarySuccess({
              settlementImportSummary,
            })
          ),
          catchError((error) => of(liquidationActions.onError(error)))
        )
      )
    )
  );

  findDispatchsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.findDispatchs),
      switchMap(({ importNumber }) =>
        this.liquidationService.getDispatchs(importNumber).pipe(
          map((dispatchs) =>
            liquidationActions.findDispatchsSuccess({
              dispatchs
            })
          ),
          catchError((error) => of(liquidationActions.onError(error)))
        )
      )
    )
  );

  findSettlementsStepsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.findSettlementsSteps),
      switchMap(({ importNumber }) =>
        this.liquidationService.getSettlementsSteps(importNumber).pipe(
          map((settlementsSteps) =>
            liquidationActions.findSettlementsStepsSuccess({
              settlementsSteps
            })
          ),
          catchError((error) => of(liquidationActions.onError(error)))
        ))
    )
  );

  approveStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.approveStep),
      switchMap(({ settlementId, body }) =>
        this.liquidationService
          .approveStep(settlementId, body)
          .pipe(
            map((response) =>
              liquidationActions.approveStepSuccess({
                update: true,
              })
            ),
            catchError((error) => of(liquidationActions.onError(error)))
          )
      )
    )
  );

  findformalitiesDocumentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.findformalitiesDocuments),
      switchMap(({ importNumber }) =>
        this.liquidationService.getFormalitiesDocuments(importNumber).pipe(
          map((formalitiesDocuments) =>
            liquidationActions.findformalitiesDocumentsSuccess({
              formalitiesDocuments
            })
          ),
          catchError((error) => of(liquidationActions.onError(error)))
        )
      )
    )
  );

  findExchangeAuditEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.findExchangeAudit),
      switchMap(({ importNumber }) =>
        this.liquidationService.getExchangeAudit(importNumber).pipe(
          map((exchangeAudit) =>
            liquidationActions.findExchangeAuditSuccess({
              exchangeAudit
            })
          ),
          catchError((error) => of(liquidationActions.onError(error)))
        )
      )
    )
  );

  postExchangeAuditEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.postExchangeAudit),
      switchMap(({ settlementId, exchangeAudit }) =>
        this.liquidationService.postExchangeAudit(settlementId, exchangeAudit).pipe(
          map((exchangeAudit) =>
            liquidationActions.postExchangeAuditSuccess({
              exchangeAudit
            })
          ),
          catchError((error) => of(liquidationActions.onError(error)))
        )
      )
    )
  );

  findImportationByParamsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.findIntTransportContainerAllByFilter),
      switchMap(({ importNumber, page, size }) =>
        this.liquidationService
          .getIntTransportContainerAll(importNumber, page, size)
          .pipe(
            map((intTransportContainerAll) =>
              liquidationActions.findIntTransportContainerAllByFilterSuccess({
                intTransportContainerAll,
              })
            ),
            catchError((error) => of(liquidationActions.onError(error)))
          )
      )
    )
  );

  changeExpensesCausedAndPaidEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.changeExpensesCausedAndPaid),
      switchMap(({ settlementId }) =>
        this.liquidationService
          .changeExpensesCausedAndPaid(settlementId)
          .pipe(
            map((message) =>
              liquidationActions.changeExpensesCausedAndPaidSuccess({
                message,
              })
            ),
            catchError((error) => of(liquidationActions.onError(error)))
          )
      )
    )
  );

  changeTransportPolicyCausesAndPaidEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.changeTransportPolicyCausesAndPaid),
      switchMap(({ settlementId }) =>
        this.liquidationService
          .changeTransportPolicyCausesAndPaid(settlementId)
          .pipe(
            map((message) =>
              liquidationActions.changeTransportPolicyCausesAndPaidSuccess({
                message,
              })
            ),
            catchError((error) => of(liquidationActions.onError(error)))
          )
      )
    )
  );

  findCommissionValueEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.findCommissionValue),
      switchMap(({ importNumber, settlementId }) =>
        this.liquidationService
          .getCommissionValue(importNumber, settlementId)
          .pipe(
            map((commissionValue) =>
              liquidationActions.findCommissionValueSuccess({
                commissionValue,
              })
            ),
            catchError((error) => of(liquidationActions.onError(error)))
          )
      )
    )
  );

  putCommissionValueEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.putCommissionValue),
      switchMap(({ id, body }) =>
        this.liquidationService
          .putCommissionValue(id, body)
          .pipe(
            map((message) =>
              liquidationActions.putCommissionValueSuccess({
                message
              })
            ),
            catchError((error) => of(liquidationActions.onError(error)))
          )
      )
    )
  );

  findLegalProvPoliciesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.findLegalProvPolicies),
      switchMap(({ settlementId, pageindex, pageSize, }) =>
        this.liquidationService
          .getLegalProvPolicies(settlementId, pageindex, pageSize)
          .pipe(
            map((legalProvPolicieseEmb) =>
              liquidationActions.findLegalProvPoliciesSuccess({
                legalProvPolicieseEmb,
              })
            ),
            catchError((error) => of(liquidationActions.onError(error)))
          )
      )
    )
  );

  postLegalProvPoliciesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.postLegalProvPolicies),
      switchMap(({ settlementId, body }) =>
        this.liquidationService
          .postLegalProvPolicies(settlementId, body)
          .pipe(
            map((message) =>
              liquidationActions.postLegalProvPoliciesSuccess({
                message,
              })
            ),
            catchError((error) => of(liquidationActions.onError(error)))
          )
      )
    )
  );

  putLegalProvPoliciesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.putLegalProvPolicies),
      switchMap(({ id, body }) =>
        this.liquidationService
          .putLegalProvPolicies(id, body)
          .pipe(
            map((message) =>
              liquidationActions.putLegalProvPoliciesSuccess({
                message,
              })
            ),
            catchError((error) => of(liquidationActions.onError(error)))
          )
      )
    )
  );

  deleteLegalProvPoliciesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.deleteLegalProvPolicies),
      switchMap(({ id }) =>
        this.liquidationService
          .deleteLegalProvPolicies(id)
          .pipe(
            map((message) =>
              liquidationActions.deleteLegalProvPoliciesSuccess({
                message,
              })
            ),
            catchError((error) => of(liquidationActions.onError(error)))
          )
      )
    )
  );

  reportInconsistencyEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.reportInconsistency),
      switchMap(({ body }) =>
        this.liquidationService
          .reportInconsistency(body)
          .pipe(
            map((message) =>
              liquidationActions.reportInconsistencySuccess({
                message,
              })
            ),
            catchError((error) => of(liquidationActions.onError(error)))
          )
      )
    )
  );

  postClientNotificationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.postClientNotification),
      switchMap(({ importNumber, importValueCop, importCommission, files }) =>
        this.liquidationService
          .postClientNotification(importNumber, importValueCop, importCommission, files)
          .pipe(
            map((message) =>
              liquidationActions.postClientNotificationSuccess({
                message,
              })
            ),
            catchError((error) => of(liquidationActions.onError(error)))
          )
      )
    )
  );

  postContractActivationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.postContractActivation),
      switchMap(({ settlementId, body }) =>
        this.liquidationService
          .postContractActivation(settlementId, body)
          .pipe(
            map((message) =>
              liquidationActions.postContractActivationSuccess({
                message,
              })
            ),
            catchError((error) => of(liquidationActions.onError(error)))
          )
      )
    )
  );

  getContractActivationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liquidationActions.getContractActivation),
      switchMap(({ importNumber }) =>
        this.liquidationService
          .getContractActivation(importNumber)
          .pipe(
            map((contractActivation) =>
              liquidationActions.getContractActivationSuccess({
                contractActivation,
              })
            ),
            catchError((error) => of(liquidationActions.onError(error)))
          )
      )
    )
  );


}
