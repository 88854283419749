import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ImportationActions } from './actions';
import { ImportationService } from 'src/app/data/services/apis/importation/importation.service';

@Injectable()
export class ImportationsEffects {
  constructor(
    private importationService: ImportationService,
    private actions$: Actions
  ) {
    // This is intentional
  }

  saveImportationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.saveImportationData),
      switchMap(({ importationData }) =>
        this.importationService.saveImportationData(importationData).pipe(
          map((importation) =>
            ImportationActions.saveImportationDataSuccess({ importation })
          ),
          catchError((error) => of(ImportationActions.onError(error)))
        )
      )
    )
  );

  createImportationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.createImportationData),
      switchMap(({ importationDataAlly }) =>
        this.importationService.createImportationData(importationDataAlly).pipe(
          map((importation) =>
            ImportationActions.createImportationDataSuccess({ importation })
          ),
          catchError((error) => of(ImportationActions.onError(error)))
        )
      )
    )
  );

  updateImportationDataEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.putImportationDataUp),
      switchMap(({ importNumber, importationData }) =>
        this.importationService.editImportationData(importNumber, importationData).pipe(
          map((importation) =>
            ImportationActions.putImportationDataUpSuccess({ importation })
          ),
          catchError((error) => of(ImportationActions.onError(error)))
        )
      )
    )
  );

  createDispatchEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.createDispatchData),
      switchMap(({ dispatchData, dispatchNumber }) =>
        this.importationService
          .createDispatch(dispatchData, dispatchNumber)
          .pipe(
            map((dispatch) =>
              ImportationActions.createDispatchDataSuccess({ dispatch })
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  searchImportationByFilterEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.searchImportationByFilter),
      switchMap(({ params, page, size, isImport }) =>
        this.importationService
          .searchImportationByFilter(params, page, size, isImport)
          .pipe(
            map((importations) =>
              ImportationActions.searchImportationByFilterSuccess({ importations })
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  searchImportationByFilterToDeleteEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.searchImportationByFilterToDelete),
      switchMap(({ params, page, size }) =>
        this.importationService
          .searchImportationByFilterToDelete(params, page, size)
          .pipe(
            map((importations) =>
              ImportationActions.searchImportationByFilterToDeleteSuccess({ importations })
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  deleteImportationsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.deleteImportations),
      switchMap(({ body }) =>
        this.importationService
          .deleteImportations(body)
          .pipe(
            map((importations) =>
              ImportationActions.deleteImportationsSuccess({ edit: true })
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  updateCustomsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.updateCustomsData),
      switchMap(({ id, customsDataUpdate }) =>
        this.importationService.updateCustomsData(id, customsDataUpdate).pipe(
          map((customs) =>
            ImportationActions.updateCustomsDataSuccess({ customs })
          ),
          catchError((error) => of(ImportationActions.onError(error)))
        )
      )
    )
  );

  updateCustomsNaturalizationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.updateCustomsNatData),
      switchMap(({ id, customsNatDataUpdate }) =>
        this.importationService
          .updateCustomsNatData(id, customsNatDataUpdate)
          .pipe(
            map((customsNaturalization) =>
              ImportationActions.updateCustomsNatDataSuccess({
                customsNaturalization,
              })
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  findImportationByParamsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.findImportationByParams),
      switchMap(({ params, page, size }) =>
        this.importationService
          .findImportationByParams(params, page, size)
          .pipe(
            map((importationListPag) =>
              ImportationActions.findImportationByParamsSuccess({
                importationListPag,
              })
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

/** Buscar por Número de importación */
  findImportationByImportNumberEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.findImportationByImportNumber),
      switchMap(({ importNumber }) =>
        this.importationService.getImportationByImportNumber(importNumber).pipe(
          map((importation) =>
            ImportationActions.findImportationByImportNumberSuccess({
              importation,
            })
          ),
          catchError((error) => of(ImportationActions.onError(error)))
        )
      )
    )
  );

/** Busqueda aduanera por número de importación */
  findCustomsByImportNumberEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.findCustomsByImportNumber),
      switchMap(({ importNumber }) =>
        this.importationService.getCustomsByImportNumber(importNumber).pipe(
          map((customs) =>
            ImportationActions.findCustomsByImportNumberSuccess({
              customs,
            })
          ),
          catchError((error) => of(ImportationActions.onError(error)))
        )
      )
    )
  );

  // ADUANAS ACTIVOS
  postFilterImpAssetsCustomsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.postFilterImpAssetsCustoms),
      switchMap(({ importNumber, page, size }) =>
        this.importationService
          .postFilterImpAssetsCustoms(importNumber, page, size)
          .pipe(
            map((impCustomsAssetses) =>
              ImportationActions.postFilterImpAssetsCustomsSuccess({
                impCustomsAssetses,
              })
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  deleteImpAssetsCustomsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.deleteImpAssetsCustoms),
      switchMap(({ id }) =>
        this.importationService.deleteImpAssetsCustomsById(id).pipe(
          map((response) =>
            ImportationActions.deleteImpAssetsCustomsSuccess({
              message: response,
            })
          ),
          catchError((error) => of(ImportationActions.onError(error)))
        )
      )
    )
  );

  putImpAssetsCustomsByIdEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.putImpAssetsCustomsById),
      switchMap(({ id, impAssetsCustomsDataUpdate }) =>
        this.importationService
          .putImpAssetsCustomsById(id, impAssetsCustomsDataUpdate)
          .pipe(
            map((data) =>
              ImportationActions.putImpAssetsCustomsByIdSuccess({
                impAssetsCustomsDataUpdate: data,
              })
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  saveImpAssetsCustomsDataEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.saveImpAssetsCustomsData),
      switchMap(({ importNumber, impAssetsCustomsData }) =>
        this.importationService
          .saveImpAssetsCustomsData(importNumber, impAssetsCustomsData)
          .pipe(
            map((data) =>
              ImportationActions.saveImpAssetsCustomsDataSuccess({
                impAssetsCustomsData: data,
              })
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  // END ADUANAS ACTIVOS

  // TRAMITES
  getImpoProceduresFilterEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.getImpProceduresFilter),
      switchMap(({ importNumber }) =>
        this.importationService
          .getImpProceduresListFilter(importNumber)
          .pipe(
            map((data) =>
              ImportationActions.getImpProceduresFilterSuccess(
                {
                  impProceduresList: data,
                }
              )
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  postImpoProceduresDataEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.postImpProceduresData),
      switchMap(({ importNumber, body }) =>
        this.importationService
          .postImpProceduresData(importNumber, body)
          .pipe(
            map((data) =>
              ImportationActions.postImpProceduresDataSuccess(
                {
                  response: data,
                }
              )
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  deleteImpoProceduresDataEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.deleteImpProceduresData),
      switchMap(({ id }) =>
        this.importationService.deleteImpProceduresData(id).pipe(
          map((response) =>
            ImportationActions.deleteImpProceduresDataSuccess({
              message: response,
            })
          ),
          catchError((error) => of(ImportationActions.onError(error)))
        )
      )
    )
  );

  putImpoProceduresDataEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.putImpProceduresData),
      switchMap(({ id, impProceduresDataUpdate }) =>
        this.importationService
          .putImpProceduresDataById(
            id,
            impProceduresDataUpdate
          )
          .pipe(
            map((data) =>
              ImportationActions.putImpProceduresDataSuccess(
                {
                  impProceduresDataUpdate: data,
                }
              )
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  // END TRAMITES

  getImportationTransporteLocalCargaSueltaEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.getImportationTransporteLocalCargaSuelta),
      switchMap(({ typeLoad, importNumber, pageSize, pageindex }) =>
        this.importationService
          .getImportationTransporteLocalCargaSuelta(
            typeLoad,
            importNumber,
            pageSize,
            pageindex
          )
          .pipe(
            map((data) =>
              ImportationActions.getImportationTransporteLocalCargaSueltaSuccess(
                {
                  pageLocalTransportContainers: data,
                }
              )
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  getImportationInternationalContainersPEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.getImportationInternationalContainersP),
      switchMap(({ importNumber, pageSize, pageindex }) =>
        this.importationService
          .getImportationInternationalContainersP(
            importNumber,
            pageSize,
            pageindex
          )
          .pipe(
            map((data) =>
              ImportationActions.getImportationInternationalContainersPSuccess({
                pageIntTranportContainer: data,
              })
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  getImportatioInternationalCargaSueltaPEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.getImportatioInternationalCargaSueltaP),
      switchMap(({ importNumber, pageSize, pageindex }) =>
        this.importationService
          .getImportatioInternationalCargaSueltaP(
            importNumber,
            pageSize,
            pageindex
          )
          .pipe(
            map((data) =>
              ImportationActions.getImportatioInternationalCargaSueltaPSuccess({
                pageIntTranportCargaSuelta: data,
              })
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  getImportationTransporteLocalContenedoresEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.getImportationTransporteLocalContenedores),
      switchMap(({ typeLoad, importNumber, pageSize, pageindex }) =>
        this.importationService
          .getImportationTransporteLocalContenedores(
            typeLoad,
            importNumber,
            pageSize,
            pageindex
          )
          .pipe(
            map((data) =>
              ImportationActions.getImportationTransporteLocalContenedoresSuccess(
                {
                  pageLocalTransportContainers: data,
                }
              )
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );
  deleteImportationContainerInternationalEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.deleteImportationContainerInternational),
      switchMap(({ id }) =>
        this.importationService
          .deleteImportationContainerInternational(id)
          .pipe(
            map((res) =>
              ImportationActions.deleteImportationContainerInternationalSuccess(
                {
                  mess: res,
                }
              )
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );
  deleteImportationContainerEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.deleteImportationContainer),
      switchMap(({ containerId }) =>
        this.importationService.deleteImportationContainer(containerId).pipe(
          map((responseContainer) =>
            ImportationActions.deleteImportationContainerSuccess({
              message: responseContainer,
            })
          ),
          catchError((error) => of(ImportationActions.onError(error)))
        )
      )
    )
  );

  putImportationTransporteLocalContenedoresEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.putImportationTransporteLocalContenedores),
      switchMap(({ containerId, imcontainerData }) =>
        this.importationService
          .updateImportationTransporteLocalContenedores(
            containerId,
            imcontainerData
          )
          .pipe(
            map((responseContainer) =>
              ImportationActions.putImportationTransporteLocalContenedoresSuccess(
                {
                  container: responseContainer,
                }
              )
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  deleteImportationCargaSueltaInternationalEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.deleteImportationCargaSueltaInternational),
      switchMap(({ id }) =>
        this.importationService
          .deleteImportationCargaSueltaInternational(id)
          .pipe(
            map((responseContainer) =>
              ImportationActions.deleteImportationCargaSueltaInternationalSuccess(
                {
                  message: responseContainer,
                }
              )
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  putImportationContainerInternationalEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.putImportationContainerInternational),
      switchMap(({ contId, intContainerData }) =>
        this.importationService
          .updateImportationTransporteInternationalContenedores(
            contId,
            intContainerData
          )
          .pipe(
            map((responseContainer) =>
              ImportationActions.putImportationContainerInternationalSuccess({
                intContainerData: responseContainer,
              })
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  putImportationCargaSueltaInternationalEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.putImportationCargaSueltaInternational),
      switchMap(({ loadId, intLoadData }) =>
        this.importationService
          .updateImportationTransporteInternationalCarga(loadId, intLoadData)
          .pipe(
            map((responseContainer) =>
              ImportationActions.putImportationCargaSueltaInternationalSuccess({
                intLoadData: responseContainer,
              })
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  )
  getDeclarationsDataPEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.getDeclarationsDataP),
      switchMap(({ importNumber, pageSize, pageindex }) =>
        this.importationService
          .getDeclarationsDataPaginados(importNumber, pageSize, pageindex)
          .pipe(
            map((response) =>
              ImportationActions.getDeclarationsDataPSuccess(
                {
                  pageAduanaDeclaraciones: response,
                }
              )
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );
  saveDeclaracionesExcelEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.saveFileDeclraraciones),
      switchMap(({ importNumber, file }) =>
        this.importationService
          .saveFileDeclraraciones(importNumber, file)
          .pipe(
            map((responseContainer) =>
              ImportationActions.saveFileDeclracionesSuccess(
                {
                  saveFileDeclraracionesData: responseContainer,
                }
              )
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );
  saveCargaSueltaDataEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.saveCargaSueltaData),
      switchMap(({ importIdIntC, importDataIntCarg }) =>
        this.importationService
          .saveCargaSueltaDataInt(importIdIntC, importDataIntCarg)
          .pipe(
            map((responseContainer) =>
              ImportationActions.saveCargaSueltaDataSuccess(
                {
                  container: responseContainer,
                }
              )
            ))
      )
    )
  );
  saveContainerDataIntEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.saveContainerDataInt),
      switchMap(({ importIdInt, importDataIntCont }) =>
        this.importationService
          .saveContainerDataInt(importIdInt, importDataIntCont)
          .pipe(
            map((responseContainer) =>
              ImportationActions.saveContainerDataIntSuccess({
                container: responseContainer,
              })
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );
  saveImportationTransporteLocalContenedoresEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.saveImportationTransporteLocalContenedores),
      switchMap(({ importId, imcontainerData }) =>
        this.importationService
          .saveImportationTransporteLocalContenedores(importId, imcontainerData)
          .pipe(
            map((responseContainer) =>
              ImportationActions.saveImportationTransporteLocalContenedoresSuccess(
                {
                  container: responseContainer,
                }
              )
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );
  findCustomsNaturalizationByImportNumberEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.findCustomsNaturalizationByImportNumber),
      switchMap(({ importNumber }) =>
        this.importationService
          .getCustomsNaturalizationByImportNumber(importNumber)
          .pipe(
            map((customsNaturalization) =>
              ImportationActions.findCustomsNaturalizationByImportNumberSuccess(
                {
                  customsNaturalization,
                }
              )
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  getImpLocalTransportByImportNumberEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.getLocalTranspByImportNumber),
      switchMap(({ importNumber }) =>
        this.importationService
          .getLocalTransportByImportNumber(importNumber)
          .pipe(
            map((impLocalTransport) =>
              ImportationActions.getLocalTranspByImportNumberSuccess({
                impLocalTransport,
              })
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  putImpLocalTransportByIdEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.putImpLocalTransportById),
      switchMap(({ id, impLocalTransportUpdate }) =>
        this.importationService
          .putImpLocalTransportById(id, impLocalTransportUpdate)
          .pipe(
            map((impLocalTransport) =>
              ImportationActions.putImpLocalTransportByIdSuccess({
                impLocalTransport,
              })
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  // Importacion -> Transporte Internacional
  getImpIntTransportByImportNumberEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.getIntTranspByImportNumber),
      switchMap(({ importNumber }) =>
        this.importationService
          .getIntTransportByImportNumber(importNumber)
          .pipe(
            map((impIntTransport) =>
              ImportationActions.getIntTranspByImportNumberSuccess({
                impIntTransport,
              })
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );

  putImpIntTransportByIdEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ImportationActions.putImpIntTransportById),
      switchMap(({ id, impIntTransportUpdate }) =>
        this.importationService
          .putImpIntTransportById(id, impIntTransportUpdate)
          .pipe(
            map((impIntTransport) =>
              ImportationActions.putImpIntTransportByIdSuccess({
                impIntTransport,
              })
            ),
            catchError((error) => of(ImportationActions.onError(error)))
          )
      )
    )
  );
}
