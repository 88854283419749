import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'bcs-button',
  templateUrl: './bcs-button.component.html',
  styleUrls: ['./bcs-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BcsButtonComponent implements OnInit {

  @Input() Label: string = '';
  @Input() TypeButton: string | 'pnal' | 'primary' | 'secondary' | 'terciary' | 'background' | 'ghost' = 'principal';
  @Input() Type!: string;
  @Input() urlSrc: string = '';
  @Input() Icon: string = '';
  @Input() IconPosition: string = 'Left';
  @Input() disabled!: boolean;
  @Input() size: 'default' | 'small' | 'puffy' = 'default';
  @Input() Width: string = '';
  @Input() MaxWidth: string = '280px';

  @Output() onClickEvent = new EventEmitter();

  constructor() {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

  onClick() {
    if (this.onClickEvent) { this.onClickEvent.emit(); }
  }
}
