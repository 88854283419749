import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'bcs-select-box',
  templateUrl: './bcs-select-box.component.html',
  styleUrls: ['./bcs-select-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BcsSelectBoxComponent implements OnInit {

  @Input() Label: string = '';
  @Input() HelpText: string = '';
  @Input() Values: any[] = [];
  @Input() FormControl: any;
  @Input() nombreDescripcion: string = 'Description';
  @Input() submited: boolean = false;
  @Input() multiple: boolean = false;
  @Input() name: string = '';
  @Input() IdName: string = '';
  @Input() Icon: string = '';
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'lx' = 'sm';
  @Input() IconPosition: 'Left' | 'Right' = 'Right';
  @Input() iconTooltipText: string = ':)';
  @Input() iconTooltipTitle!: string;
  @Input() iconTooltipPosition: 'top' | 'right' | 'bottom' | 'left' = 'bottom';
  @Input() iconTooltip: boolean = false;
  @Input() nombreId: string = 'Id';

  /** variables para el modulo de Creación y Administración de Roles */
  @Output() selectValue = new EventEmitter<any>();
  @Input() disabled: boolean = false;

  state: boolean = false;

  constructor() {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

  getId(object: any) {
    if (!this.nombreId) { return object; }
    if (object != undefined) {
      return object[this.nombreId as keyof typeof object];
    }
  }

  getDescripcion(object: any) {
    if (object != undefined) {
      return object[this.nombreDescripcion as keyof typeof object];
    }
  }

  get control() { return this.FormControl; }

  get isError(): boolean {
    const isInputError = ((this.submited == !this.state || this.control?.touched == !this.state) && this.control?.invalid) ?? false;
    if (!this.control?.touched && this.submited == !this.state) {
      const value = this.control?.value;
      this.control?.setValue(value);
    }
    return isInputError;
  }

  get showIcon(): boolean { return this.control?.value === null; }

  get errorText(): string {
    let msg = '';
    if (this.control?.errors?.required == !this.state) {
      msg = `El campo ${this.Label} es requerido`;
    }
    if (msg == '' && this.control?.errors?.minlength) {
      msg = `El campo ${this.Label} debe tener minimo ${this.control.errors?.minlength.requiredLength} caracteres`;
    }
    if (msg == '' && this.control?.errors?.maxlength) {
      msg = `El campo ${this.Label} debe tener maximo ${this.control.errors?.maxlength.requiredLength} caracteres`;
    }
    return msg;
  }

  onClick() {
    // This is intentional
  }

  /** función para el control de las listas 'SELECT' en el modulo de Creación y Administración de Roles */
  clickSelect(event: any) {
    this.selectValue.emit(event)
  }
}
