import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';

import { environment } from 'src/environments/environment';

import { EffectsModule } from '@ngrx/effects';
import { ROOT_REDUCERS } from './store/reducers';
import { effects } from './store/effects';
import { RechargeDirective } from './directives/recharge.directive';
@NgModule({
  declarations: [
    RechargeDirective,
  ],
  imports: [
    CommonModule,
    EffectsModule.forRoot([...effects]),
    StoreModule.forRoot(ROOT_REDUCERS, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 100, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  exports: [],
})
export class CoreModule {
  static forRoot() {
    return {
      ngModule: CoreModule,
      providers: [],
    };
  }
}
