import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-base-component',
  template: '',
  styles: []
})
export class BaseComponent implements OnDestroy {
  protected subscriptions: Subscription = new Subscription();
  constructor() {
    // This is intentional
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  addSubscription(subscription: Subscription) {
    this.subscriptions.add(subscription);
  }
}
