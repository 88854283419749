import { ICommissionValue } from 'src/app/data/interfaces/IComissionValue.interface';
import { IContractActivation } from 'src/app/data/interfaces/IContractActivation.interface';
import { IExchangeAudit } from 'src/app/data/interfaces/IExchangeAudit.interface';
import { IIntTransportContainerAll } from 'src/app/data/interfaces/IIntTransportContainerAll.interface';
import { BodyLegalProvPoliciese, ILegalProvPolicieseEmb } from 'src/app/data/interfaces/ILegalProvPoliciese.interface';
import { ISettlementImportSummary } from 'src/app/data/interfaces/ISettlementImportSummary.interface';
import { SettlementSteps } from 'src/app/data/interfaces/ISettlementsSteps.interface';
import { createAction, props } from '@ngrx/store';



enum LiquidationActionType {
findSettlementImportSummaryAction = '[Liquidation Component] find a SettlementImportSummary',
findSettlementImportSummarySuccessAction = '[Liquidation Component] find SettlementImportSummary Success',
approveStepAction = '[Liquidation Component] approve Step',
approveStepSuccessAction = '[Liquidation Component] approve Step Succes',
findSettlementsStepsAction = '[Liquidation Component] find settlementsSteps',
findSettlementsStepsSuccessAction = '[Liquidation Component] find settlementsSteps Success',
findDispatchsAction = '[Liquidation Component] find a Dispatchs',
findDispatchsSuccessAction = '[Liquidation Component] find Dispatchs Success',
onErrorAction = '[Liquidation Component] Ups Error',
findformalitiesDocumentsAction = '[DocumentaryAuditComponent] find formalities Documents',
findformalitiesDocumentsSuccessAction = '[DocumentaryAuditComponent] find formalities Documents Success',
findExchangeAuditAction = '[ExchangeAuditComponent] find a ExchangeAudit',
findExchangeAuditSuccessAction = '[ExchangeAuditComponent] find ExchangeAudit Success',
postExchangeAuditAction = '[ExchangeAuditComponent] post ExchangeAudit',
postExchangeAuditSuccessAction = '[ExchangeAuditComponent] post ExchangeAudit Success',
findIntTransportContainerAllByFilterAction = '[ExpenseReviewComponent] find list intTransportContainerAll',
findIntTransportContainerAllByFilterSuccessAction = '[ExpenseReviewComponent] find list intTransportContainerAll Success',
changeExpensesCausedAndPaidAction = '[ExpenseReviewComponent] approve changeExpensesCausedAndPaid',
changeExpensesCausedAndPaidSuccessAction = '[ExpenseReviewComponent] approve changeExpensesCausedAndPaid Success',
changeTransportPolicyCausesAndPaidAction = '[PolicyPaymentComponent] approve changeTransportPolicyCausesAndPaid',
changeTransportPolicyCausesAndPaidSuccessAction = '[PolicyPaymentComponent] approve changeTransportPolicyCausesAndPaid Success',
findCommissionValueAction = '[ImportCommissionComponent] find a CommissionValue',
findCommissionValueSuccessAction = '[ImportCommissionComponent] find CommissionValue Success',
putCommissionValueAction = '[ImportCommissionComponent] put a CommissionValue',
putCommissionValueSuccessAction = '[ImportCommissionComponent] put CommissionValue Success',
findLegalProvPoliciesAction = '[ImportCommissionComponent] find a LegalProvPolicies',
findLegalProvPoliciesSuccessAction = '[ImportCommissionComponent] find LegalProvPolicies Success',
postLegalProvPoliciesAction = '[ImportCommissionComponent] post a LegalProvPolicies',
postLegalProvPoliciesSuccessAction = '[ImportCommissionComponent] post LegalProvPolicies Success',
putLegalProvPoliciesAction = '[ImportCommissionComponent] put a LegalProvPolicies',
putLegalProvPoliciesSuccessAction = '[ImportCommissionComponent] put LegalProvPolicies Success',
deleteLegalProvPoliciesAction = '[ImportCommissionComponent] delete a LegalProvPolicies',
deleteLegalProvPoliciesSuccessAction = '[ImportCommissionComponent] delete LegalProvPolicies Success',
chargeInformationToInconsistencyAction = '[ReportInconsistencyComponent] charge information to inconsistency',
reportInconsistencyAction = '[ReportInconsistencyComponent] report Inconsistency',
reportInconsistencySuccessAction = '[ReportInconsistencyComponent] report Inconsistency Success',
resetStateAction = '[Liquidation Component] reset State',
postClientNotificationAction = '[SendNotification Component] Send Notification',
postClientNotificationSuccessAction = '[SendNotification Component] Send Notification success',
postContractActivationAction = '[SendNotification Component] Contract Activation',
postContractActivationSuccessAction = '[ContractActivation Component] Contract Activation Success',
getContractActivationAction = '[SendNotification Component] get Contract Activation',
getContractActivationSuccessAction = '[ContractActivation Component] get Contract Activation Success',

}
// ======================================[Inicio Liquidacion]======================================\\

const findSettlementImportSummary = createAction(
  LiquidationActionType.findSettlementImportSummaryAction,
  props<{ importNumber: string, settlementUser: string }>()
);

const findSettlementImportSummarySuccess = createAction(
  LiquidationActionType.findSettlementImportSummarySuccessAction,
  props<{ settlementImportSummary: ISettlementImportSummary }>()
);

const findDispatchs = createAction(
  LiquidationActionType.findDispatchsAction,
  props<{ importNumber: string }>()
);

const findDispatchsSuccess = createAction(
  LiquidationActionType.findDispatchsSuccessAction,
  props<{ dispatchs: any[] }>()
);

const findSettlementsSteps = createAction(
  LiquidationActionType.findSettlementsStepsAction,
  props<{ importNumber: string }>()
);

const findSettlementsStepsSuccess = createAction(
  LiquidationActionType.findSettlementsStepsSuccessAction,
  props<{ settlementsSteps: SettlementSteps }>()
);

const approveStep = createAction(
  LiquidationActionType.approveStepAction,
  props<{ settlementId: number, body: any }>()
);

const approveStepSuccess = createAction(
  LiquidationActionType.approveStepSuccessAction,
  props<{ update: boolean }>()
);
const onError = createAction(
  LiquidationActionType.onErrorAction,
  props<{ error: any }>()
);

const chargeInformationToInconsistency = createAction(
  LiquidationActionType.chargeInformationToInconsistencyAction,
  props<{ information: any }>()
);

const resetState = createAction(
  LiquidationActionType.resetStateAction
);

// ======================================[Step 1]======================================\\
const findformalitiesDocuments = createAction(
  LiquidationActionType.findformalitiesDocumentsAction,
  props<{ importNumber: string }>()
);

const findformalitiesDocumentsSuccess = createAction(
  LiquidationActionType.findformalitiesDocumentsSuccessAction,
  props<{ formalitiesDocuments: any[] }>()
);
// ======================================[Step 3]======================================\\
const findExchangeAudit = createAction(
  LiquidationActionType.findExchangeAuditAction,
  props<{ importNumber: string }>()
);

const findExchangeAuditSuccess = createAction(
  LiquidationActionType.findExchangeAuditSuccessAction,
  props<{ exchangeAudit: IExchangeAudit[] }>()
);

const postExchangeAudit = createAction(
  LiquidationActionType.postExchangeAuditAction,
  props<{ settlementId: number, exchangeAudit: IExchangeAudit[]}>()
);

const postExchangeAuditSuccess = createAction(
  LiquidationActionType.postExchangeAuditSuccessAction,
  props<{ exchangeAudit: IExchangeAudit[] }>()
);
// ======================================[Step 4]======================================\\

const findIntTransportContainerAllByFilter = createAction(
  LiquidationActionType.findIntTransportContainerAllByFilterAction,
  props<{
    importNumber: string;
    page: number;
    size: number;
  }>()
);
const findIntTransportContainerAllByFilterSuccess = createAction(
  LiquidationActionType.findIntTransportContainerAllByFilterSuccessAction,
  props<{ intTransportContainerAll: IIntTransportContainerAll }>()
);

const changeExpensesCausedAndPaid = createAction(
  LiquidationActionType.changeExpensesCausedAndPaidAction,
  props<{settlementId: number}>()
);
const changeExpensesCausedAndPaidSuccess = createAction(
  LiquidationActionType.changeExpensesCausedAndPaidSuccessAction,
  props<{ message: string}>()
);

// ======================================[Step 5]======================================\\


const changeTransportPolicyCausesAndPaid = createAction(
  LiquidationActionType.changeTransportPolicyCausesAndPaidAction,
  props<{settlementId: number}>()
);
const changeTransportPolicyCausesAndPaidSuccess = createAction(
  LiquidationActionType.changeTransportPolicyCausesAndPaidSuccessAction,
  props<{ message: string}>()
);

// ======================================[Step 6]======================================\\

const findCommissionValue = createAction(
  LiquidationActionType.findCommissionValueAction,
  props<{importNumber: string, settlementId: number}>()
);
const findCommissionValueSuccess = createAction(
  LiquidationActionType.findCommissionValueSuccessAction,
  props<{ commissionValue: ICommissionValue }>()
);

const putCommissionValue = createAction(
  LiquidationActionType.putCommissionValueAction,
  props<{ id: number, body: any }>()
);
const putCommissionValueSuccess = createAction(
  LiquidationActionType.putCommissionValueSuccessAction,
  props<{ message: any }>()
);

const findLegalProvPolicies = createAction(
  LiquidationActionType.findLegalProvPoliciesAction,
  props<{settlementId: number, pageindex: number, pageSize: number}>()
);
const findLegalProvPoliciesSuccess = createAction(
  LiquidationActionType.findLegalProvPoliciesSuccessAction,
  props<{ legalProvPolicieseEmb: ILegalProvPolicieseEmb }>()
);

const postLegalProvPolicies = createAction(
  LiquidationActionType.postLegalProvPoliciesAction,
  props<{settlementId: number, body: BodyLegalProvPoliciese}>()
);
const postLegalProvPoliciesSuccess = createAction(
  LiquidationActionType.postLegalProvPoliciesSuccessAction,
  props<{ message: any }>()
);

const putLegalProvPolicies = createAction(
  LiquidationActionType.putLegalProvPoliciesAction,
  props<{id: number, body: BodyLegalProvPoliciese}>()
);
const putLegalProvPoliciesSuccess = createAction(
  LiquidationActionType.putLegalProvPoliciesSuccessAction,
  props<{ message: any }>()
);

const deleteLegalProvPolicies = createAction(
  LiquidationActionType.deleteLegalProvPoliciesAction,
  props<{id: number}>()
);
const deleteLegalProvPoliciesSuccess = createAction(
  LiquidationActionType.deleteLegalProvPoliciesSuccessAction,
  props<{ message: any }>()
);

// ======================================[Step 7]======================================\\

const postClientNotification = createAction(
  LiquidationActionType.postClientNotificationAction,
  props<{importNumber: string, importValueCop: string, importCommission: string, files: any []}>()
);
const postClientNotificationSuccess = createAction(
  LiquidationActionType.postClientNotificationSuccessAction,
  props<{ message: any }>()
);

// ======================================[Step 8]======================================\\


const getContractActivation = createAction(
  LiquidationActionType.getContractActivationAction,
  props<{importNumber: string}>()
);
const getContractActivationSuccess = createAction(
  LiquidationActionType.getContractActivationSuccessAction,
  props<{ contractActivation: IContractActivation[] }>()
);

const postContractActivation = createAction(
  LiquidationActionType.postContractActivationAction,
  props<{settlementId: number, body: any}>()
);
const postContractActivationSuccess = createAction(
  LiquidationActionType.postContractActivationSuccessAction,
  props<{ message: any }>()
);

// ======================================[Inconsistency]======================================\\

const reportInconsistency = createAction(
  LiquidationActionType.reportInconsistencyAction,
  props<{body: any}>()
);

const reportInconsistencySuccess = createAction(
  LiquidationActionType.reportInconsistencySuccessAction,
  props<{message: any}>()
);


export const liquidationActions = {
  findSettlementImportSummary,
  findSettlementImportSummarySuccess,
  findExchangeAudit,
  findExchangeAuditSuccess,
  findDispatchs,
  findDispatchsSuccess,
  findformalitiesDocuments,
  findformalitiesDocumentsSuccess,
  findSettlementsSteps,
  findSettlementsStepsSuccess,
  onError,
  findIntTransportContainerAllByFilter,
  findIntTransportContainerAllByFilterSuccess,
  approveStep,
  approveStepSuccess,
  postExchangeAudit,
  postExchangeAuditSuccess,
  changeExpensesCausedAndPaid,
  changeExpensesCausedAndPaidSuccess,
  changeTransportPolicyCausesAndPaid,
  changeTransportPolicyCausesAndPaidSuccess,
  findCommissionValue,
  findCommissionValueSuccess,
  putCommissionValue,
  putCommissionValueSuccess,
  findLegalProvPolicies,
  findLegalProvPoliciesSuccess,
  postLegalProvPolicies,
  postLegalProvPoliciesSuccess,
  putLegalProvPolicies,
  putLegalProvPoliciesSuccess,
  deleteLegalProvPolicies,
  deleteLegalProvPoliciesSuccess,
  chargeInformationToInconsistency,
  reportInconsistency,
  reportInconsistencySuccess,
  resetState,
  postClientNotification,
  postClientNotificationSuccess,
  postContractActivation,
  postContractActivationSuccess,
  getContractActivation,
  getContractActivationSuccess,
};
