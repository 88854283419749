import { Action, createReducer, on } from '@ngrx/store';
import { adminRolesAction } from './actions';
import { INewRole } from 'src/app/data/interfaces/IRoles.itnterface';
import { IAllRoles } from '../../../../../data/interfaces/IRolAllBD.itnterface';
import { IScreensUpdate } from '../../../../../data/interfaces/IScreensUpdate.interface';
import { IScreens } from '../../../../../data/interfaces/IScreens.interface';

interface RoleState {
  role: INewRole | null;
  rol: IAllRoles | null;
  roles: IAllRoles[] | null;
  screens: IScreens[] | null;
  screensUpdateByRole: IScreensUpdate[] | null;
  error: any;
  message: any;
  isLoading: boolean;
  created: boolean;
  found: { search: boolean; found: boolean };
  edit: boolean;
  updated: boolean;
}

const initialState: RoleState = {
  role: null,
  rol: null,
  roles: null,
  screens: [],
  screensUpdateByRole: null,
  error: null,
  message: null,
  isLoading: false,
  created: false,
  found: { search: false, found: false },
  edit: false,
  updated: false,
};

const featureReducer = createReducer(
  initialState,
  on(adminRolesAction.load, state => ({
    ...updateFlags(state),
    isLoading: true,
    error: null
  })),
  on(adminRolesAction.onResetFlags, (state) => ({
    ...updateFlags(state)
  })),
  on(adminRolesAction.onError, (state, { error }) => ({
    ...state,
    isLoading: false,
    error
  })),

  /** Crear Nuevo Rol */
  on(adminRolesAction.createNewRol, state => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(adminRolesAction.createNewRolSuccess, (state, { role }) => ({
    ...updateFlags(state),
    role,
    created: true,
  })),

  /** Eliminar Rol */
  on(adminRolesAction.deleteRol, state => ({
    ...updateFlags(state),
  })),
  on(adminRolesAction.deleteRolSuccess, (state, { role }) => ({
    ...updateFlags(state),
    role
  })),

  /** Consultar Rol por Id */
  on(adminRolesAction.getRolById, state => ({
    ...updateFlags(state),
  })),
  on(adminRolesAction.getRolByIdSuccess, (state, { rol }) => ({
    ...updateFlags(state),
    rol,
    // found: { search: true, found: !!rol}
  })),

  /** Consultar Screens por Módulo, Flujo y Rol */
  on(adminRolesAction.searchScreens, state => ({
    ...updateFlags(state),
  })),
  on(adminRolesAction.solicitScreensSuccess, (state, { screens }) => ({
    ...updateFlags(state),
    screens: {...screens},
    found: { search: true, found: !!screens}
  })),

  /** Actualizar Rol por Nombre del Rol */
  on(adminRolesAction.updateScreensByRole, state => ({
    ...updateFlags(state),
  })),
  on(adminRolesAction.updateScreensByRoleSuccess, (state, { screens }) => ({
    ...updateFlags(state),
    screens,
  })),

  /** Consultar Static-Screens por Módulo, Flujo y Rol */
  on(adminRolesAction.searchStaticScreens, state => ({
    ...updateFlags(state),
  })),
  on(adminRolesAction.solicitStaticScreensSuccess, (state, { screens }) => ({
    ...updateFlags(state),
    screens: {...screens},
    found: { search: true, found: !!screens}
  })),
);

const updateFlags = (state: RoleState): RoleState => {
  return {
    ...state,
    error: null,
    isLoading: false,
    found: { search: false, found: false },
    edit: false,
    created: false,
    updated: false,
    message: null,
  };
};

function reducer(state: RoleState | undefined, action: Action) {
  return featureReducer(state, action);
}

const getError = (state: RoleState) => state.error;
const getIsLoading = (state: RoleState) => state.isLoading;
const getIsFound = (state: RoleState) => state.found;
const onEdit = (state: RoleState) => state.edit;
const getIsCreated = (state: RoleState) => state.created;
const getMessage = (state: RoleState) => state.message;

const getRole = (state: RoleState) => state.role;
const getRol = (state: RoleState) => state.rol;
const getRolesList = (state: RoleState) => state.roles;
const getScreensList = (state: RoleState) => state.screens;
const postScreensListByRole = (state: RoleState) => state.screensUpdateByRole;
const getStaticScreensList = (state: RoleState) => state.screens;

export const adminRolesReducer = {
  reducer,
  getError,
  getIsLoading,
  getIsFound,
  onEdit,
  getIsCreated,
  getMessage,
  getRole,
  getRol,
  getRolesList,
  getScreensList,
  postScreensListByRole,
  getStaticScreensList,
};
export { RoleState };
