import { Component, Input, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ModalService } from '@services/modal/modal.service';
import { Numbers } from 'src/app/core/constants/numbers';
import { Profiles } from 'src/app/core/mocks/profiles.enum';
import { FilesService } from 'src/app/data/services/apis/files/files.service';
import { ImportationService } from 'src/app/data/services/apis/importation/importation.service';
import { ImportationsFacadeService } from 'src/app/modules/master/general/components/import-store/importations.facade.service';
import { ProfileFacadeService } from 'src/app/modules/master/profile-store/profile.facade.service';
import { Utils } from 'src/app/shared/utils';

@Component({
  selector: 'document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
})
export class DocumentViewerComponent implements OnInit {
  @Input() assetsList: any[] = [];
  @Input() size: 'xs' | 'sm' | 'st' | 'md' | 'lg' | 'lx' = 'sm';
  @Input() params: any;

  paginador: any;
  listaContenedores: any;
  showListnew: any;
  listNewContenedores: any;
  tittleDocuments: string = '';
  tableColumnsDoc: any = [];
  tableColumnsDocOriginal: any = [];
  documents: any = [];
  movements: any[] = [[], [], []];
  tableAccCurrentPage: any = [];
  tableAccCurrentPageEmpty = [];
  dataActionClicked: any = null;
  decodedToken!: any;
  helper: any;
  file!: File;
  showAlertError: boolean = false;
  showAlertDocument: boolean = false;
  showAlertDeleteDocument: boolean = false;
  dateNow = new Date();
  listDataTramites: any = [];
  pages: Array<any> = [];
  currentPage: any[] = [];
  queryProfile: boolean = false;
  profile: Profiles | null = null;
  estado: boolean = true;
  havePermission = false;
  maxSize: number = Numbers._10240;

  constructor(
    private importationServices: ImportationService,
    private utils: Utils,
    private importationsFacadeService: ImportationsFacadeService,
    private filesService: FilesService,
    private modalService: ModalService,
    private profileFacadeService: ProfileFacadeService,
  ) {
    this.helper = new JwtHelperService();
  }

  ngOnInit(): void {
    this.getToken();
    this.loadList();
    this.validateProfile();
  }

  loadList() {
    this.documents = this.validateStatus(this.params?.documentsDtoList);
    this.tittleDocuments = `Documentos adjuntos ${this.params?.label}`;
    if (this.params?.havePermission) {
      this.havePermission = true;
    }
  }

  validateProfile() {
    switch (this.profile) {
      case Profiles.CUSTOMS:
      case Profiles.ADMINISTRATOR:
      case Profiles.LOCAL_TRANSPORTATION:
      case Profiles.INTERNATIONAL_TRANSPORT:
      case Profiles.COMMERCIAL_TRADE:
        this.queryProfile = true;
        break;
      case Profiles.CLIENT:
      case Profiles.COMMERCIAL:
        this.queryProfile = false;
        break;
      default:
    }
  }

  getToken() {
    let token;
    this.profileFacadeService.getTokenSession().subscribe(
      (tokenSession) => {
        if (tokenSession) {
          token = tokenSession;
        }
      }
    )
    this.decodedToken = this.helper.decodeToken(token);
    this.profile = this.decodedToken?.authorities[0];
  }

  optionSelected(data: any) {
    if (data.optionSeleted.indexOf('_') !== -1) {
      for (const row of this.tableAccCurrentPage) {
        if (row === data.rowData) {
          row.name = 'EDITED';
          return row.name;
        }
      }
    }
    return null;
  }

  makeDtoDocument(): any {
    this.dateNow = this.utils.formatDate(new Date(), Numbers._3);
    const formData = new FormData();
    formData.append(
      'documentDtoString',
      JSON.stringify({
        formalitiesId: this.params?.id,
        documentRoute: '',
        updateAt: this.dateNow,
        userRole: this.decodedToken?.username,
        status: true,
      })
    );
    formData.append('file', this.file);
    return formData;
  }

  procesarArchivo(event: any) {
    if (
      event?.target?.files.length > 0 &&
      event?.target?.files.length != undefined
    ) {
      this.file = event?.target.files[0];
      if ((this.file.size / Numbers._1024) > this.maxSize) {
        this.showAlertError = true;
        return
      }
      setTimeout(() => {
        this.importationServices
          .postFileProceduresData(
            this.params.importNumber,
            this.makeDtoDocument()
          )
          .subscribe((data) => {
            if (data != undefined && data != null) {
              this.documents = this.validateStatus(
                data?.FormalitiesDocuments.documentsDtoList
              );
              this.importationsFacadeService.getImpProceduresFilter(
                this.params.importNumber
              );
              this.showAlertDocument = true;
            }
          },
            (error) => {
              if (error) {
                this.showAlertError = true;
              }
            })
      }, Numbers._2000);
    } else {
      this.showAlertError = true;
    }
    this.showAlertError = false;
    this.showAlertDocument = false;
  }

  rowSelected(data: any) {
    return data;
  }

  export(rows: any) {
    return rows;
  }

  delete(rows: any) {
    return rows;
  }

  onClickDownload(itemBack: any) {
    this.filesService.downloadFileByKey(itemBack?.documentRoute).subscribe();
  }

  downloadAll() {
    this.documents?.forEach((element: any) => {
      this.filesService.downloadFileByKey(element?.documentRoute).subscribe();
    });
  }

  onDeleteFile(item: any) {
    this.importationServices
      .deleteFileProceduresData(this.params?.id, item?.updateAt)
      .subscribe();
    setTimeout(() => {
      this.importationsFacadeService.getImpProceduresFilter(
        this.params?.importNumber
      );
      setTimeout(() => {
        this.importationsFacadeService.getImpProcedures().subscribe((data) => {
          if (data != null && data != undefined) {
            this.listDataTramites = data;
            this.listDataTramites?.forEach((element: any) => {
              if (this.params?.label == element.documentType) {
                this.documents = this.validateStatus(element.documentsDtoList);
              }
            });
            this.showAlertDeleteDocument = true;
          } else {
            this.showAlertError = true;
          }
        });
      }, Numbers._1000);
    }, Numbers._1000);
    this.showAlertError = false;
    this.showAlertDeleteDocument = false;
  }

  validateStatus(documentList: any): any {
    const documents: any[] = [];
    documentList?.forEach((element: any) => {
      if (element.status == this.estado) {
        documents.push(element);
      }
    });
    return documents;
  }

  goBack() {
    this.modalService.close();
  }
}
