import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';

import { newCustomerActions } from './actions';
import { ApiClientsService } from 'src/app/data/services/apis/client/api-clients.service';
import { of } from 'rxjs';

@Injectable()
export class NewCustomerStoreEffects {
  constructor(
    private apiClientsService: ApiClientsService,
    private actions$: Actions
  ) {
    // This is intentional
  }

  createNewCustomersEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(newCustomerActions.createCustomer),
      switchMap(({ newCustomer }) =>
        this.apiClientsService.createClient(newCustomer).pipe(
          map((customer) =>
            newCustomerActions.createCustomerSuccess({ customer })
          ),
          catchError((error) => of(newCustomerActions.onError(error)))
        )
      )
    )
  );
  getCustomerByFilterEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(newCustomerActions.getCustomerByFilter),
      switchMap(({ typeDocumentId, documentNumber, name }) =>
        this.apiClientsService
          .getClientByFilter(typeDocumentId, documentNumber, name)
          .pipe(
            map((customer) =>
              newCustomerActions.getCustomerByFilterSuccess({ customer })
            ),
            catchError((error) => of(newCustomerActions.onErrorByFilter(error)))
          )
      )
    )
  );

  updateNewCustomersEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(newCustomerActions.updateCustomer),
      switchMap(({ customer }) =>
        this.apiClientsService.updateClient(customer).pipe(
          map((customer) =>
            newCustomerActions.updateCustomerSuccess({ customer })
          ),
          catchError((error) => of(newCustomerActions.onError(error)))
        )
      )
    )
  );

  updateCertificationsCustomerEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(newCustomerActions.updateCustomerCertifications),
      switchMap(({ clientId, certificationsId }) =>
        this.apiClientsService
          .updateCertifications(clientId, certificationsId)
          .pipe(
            map((message) =>
              newCustomerActions.updateCustomerCertificationsSuccess({
                message,
              })
            ),
            catchError((error) => of(newCustomerActions.onError(error)))
          )
      )
    )
  );
}
