<mat-form-field appearance="legacy" class="w-100">
  <mat-label>{{ Label }}</mat-label>
  <input autocomplete="off"
    matInput
    [max]="max"
    [min]="min"
    class="w-98"
    [type]="Type == 'format' ? 'text' : Type"
    [formControl]="InputFormControl"
    autocomplete="off"
    capitalizeInput
    [capitalizeInputDisable]="capitalizeInputDisable"
    [typeTransform]="typeTransform"
    [locked]="true"
    [readonly]="readonly"
    [disabled]="disabled"
    [value]="value"
    *ngIf="Type != 'format'"
(keyup)="onKeyPressEvent()"
  />

  <input autocomplete="off"
    matInput
    [max]="max"
    [min]="min"
    class="w-98"
    [type]="Type == 'format' ? 'text' : Type"
    [formControl]="InputFormControl"
    autocomplete="off"
    capitalizeInput
    [capitalizeInputDisable]="capitalizeInputDisable"
    [typeTransform]="typeTransform"
    [locked]="locked"
    currencyMask
    [options]="formatPercentage ?  optionCurrencyMask : optionCurrencyMaskThousand"
    [readonly]="readonly"
    [disabled]="disabled"
    *ngIf="Type === 'format'"

    (keyup)="onKeyPressEvent()"
  />

  <bc-icon
    [name]="Icon"
    aria-hidden="true"
    [aria-label]="Icon"
    [size]="size"
    [ngClass]="{
      'mat-icon-custom-right': IconPosition == 'Right',
      'mat-icon-custom-left': IconPosition == 'Left'
    }"
    *ngIf="!iconTooltip"
  ></bc-icon>

  <bc-icon
    [name]="Icon"
    aria-hidden="true"
    [aria-label]="Icon"
    [size]="size"
    [ngClass]="{
      'mat-icon-custom-right': IconPosition == 'Right',
      'mat-icon-custom-left': IconPosition == 'Left'
    }"
    bc-tooltip
    [bcTooltipText]="iconTooltipText"
    [bcTooltipTitle]="iconTooltipTitle"
    [bcTooltipPosition]="iconTooltipPosition"
    *ngIf="iconTooltip"
  ></bc-icon>

  <mat-hint class="mat-hint" *ngIf="!isError">{{ HelpText }}</mat-hint>
  <mat-error class="mat-hint-error" *ngIf="isError">{{ errorText }}</mat-error>
</mat-form-field>
