<div class="document-viewer">
  <bc-table-container class="bc-col-12 bc-w-100" [dataTable]="currentPage">
    <bc-table-header [title]="tittleDocuments">
      <bc-icon name="error" (click)="goBack()" class="bcs-pointer"></bc-icon>
    </bc-table-header>

    <bc-table-content>
      <table caption="tabla" bc-table [selection]="false" [sort]="false">
        <caption></caption>
        <thead>
          <tr>
            <th scope="row" bc-cell type="text">Fecha de Subida</th>
            <th scope="row" bc-cell type="text">Ruta</th>
            <th scope="row" bc-cell type="text">Usuario que sube</th>
            <th scope="row" bc-cell type="text">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let itemBack of documents">
            <td bc-cell>{{ itemBack.updateAt | date : 'dd/MM/yyyy HH:mmZ' }}</td>
            <td bc-cell>{{ itemBack.documentRoute }}</td>
            <td bc-cell>{{ itemBack.userRole }}</td>
            <td bc-cell>
              <bc-icon name="download" aria-hidden="false" aria-label="download" [size]="size" class="bcs-pointer mr-3"
                (click)="onClickDownload(itemBack)"></bc-icon>
              <bc-icon name="erase" aria-hidden="false" *ngIf="queryProfile && havePermission" aria-label="erase" [size]="size"
                class="bcs-pointer" (click)="onDeleteFile(itemBack)" ></bc-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </bc-table-content>
    <br /><br />
  </bc-table-container>
  <div class="row">
    <div class="col-12 d-flex justify-content-center" >
      <input autocomplete="off" type="file" class="file" id="fileInput" #file1 (change)="procesarArchivo($event)" />
      <label class="input-file" for="fileInput" *ngIf="queryProfile && havePermission">
        <img src="../../../../../../assets/icons/load_file.svg" alt="load_file.svg" />
        Agregar Documento
      </label>
      <bcs-button class="btn-tramites ml-3" Label="Descargar todos" (click)="downloadAll()"></bcs-button>      
    </div>
  </div>
</div>

<app-bcs-alert [Show]="showAlertDocument" AlertTitle="Registro de archivo cargado con éxito"
  AlertText="Se adicionó el archivo al trámite seleccionado." AlertType="bc-alert-success"
  AlertIcon="ok"></app-bcs-alert>
<app-bcs-alert [Show]="showAlertDeleteDocument" AlertTitle="Eliminación de archivo con éxito"
  AlertText="Se eliminó el archivo del trámite seleccionado." AlertType="bc-alert-success"
  AlertIcon="ok"></app-bcs-alert>
<app-bcs-alert [Show]="showAlertError" AlertTitle="Error en el proceso"
  AlertText="La extensión no es permitida o el tamaño del archivo no es correcto" AlertType="bc-alert-error"
  AlertIcon="error"></app-bcs-alert>