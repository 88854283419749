import { ISettlementImportSummary } from '@interfaces/ISettlementImportSummary.interface';
import { Action, createReducer, on } from '@ngrx/store';
import { liquidationActions } from './actions';
import { IExchangeAudit } from '@interfaces/IExchangeAudit.interface';
import { IFormalitiesDocument } from '@interfaces/IFormalitiesDocument.interface';
import { SettlementSteps } from '@interfaces/ISettlementsSteps.interface';
import { IIntTransportContainerAll } from '@interfaces/IIntTransportContainerAll.interface';
import { ICommissionValue } from '@interfaces/IComissionValue.interface';
import { ILegalProvPolicieseEmb } from '@interfaces/ILegalProvPoliciese.interface';
import { IContractActivation } from '@interfaces/IContractActivation.interface';

interface LiquidationsState {
  settlementImportSummary: ISettlementImportSummary | null;
  exchangeAudit: IExchangeAudit[] | null;
  formalitiesDocuments: IFormalitiesDocument[] | null;
  settlementsSteps: SettlementSteps | null;
  commissionValue: ICommissionValue | null;
  legalProvPolicieseEmb: ILegalProvPolicieseEmb | null;
  contractActivation: IContractActivation[] | null;
  information: any | null;
  dispatchs: any[] | null;
  error: any;
  message: any;
  isLoading: boolean;
  created: boolean;
  found: { search: boolean; found: boolean };
  edit: boolean;
  updated: any;
  intTransportContainerAll: IIntTransportContainerAll | null;
}

const initialState: LiquidationsState = {
  settlementImportSummary: null,
  exchangeAudit: null,
  formalitiesDocuments: null,
  settlementsSteps: null,
  commissionValue: null,
  legalProvPolicieseEmb: null,
  contractActivation: null,
  dispatchs: null,
  information: null,
  error: null,
  message: null,
  isLoading: false,
  created: false,
  found: { search: false, found: false },
  edit: false,
  updated: false,
  intTransportContainerAll: null,
};

const featureReducer = createReducer(
  initialState,

  on(
    liquidationActions.findSettlementImportSummarySuccess,
    (state, { settlementImportSummary }) => ({
      ...updateFlags(state),
      settlementImportSummary,
      found: { search: true, found: !!settlementImportSummary },
    })
  ),

  on(
    liquidationActions.findExchangeAuditSuccess,
    (state, { exchangeAudit }) => ({
      ...updateFlags(state),
      exchangeAudit,
    })
  ),

  on(liquidationActions.findDispatchsSuccess, (state, { dispatchs }) => ({
    ...updateFlags(state),
    dispatchs,
  })),

  on(
    liquidationActions.findformalitiesDocumentsSuccess,
    (state, { formalitiesDocuments }) => ({
      ...updateFlags(state),
      formalitiesDocuments,
    })
  ),

  on(liquidationActions.findSettlementsStepsSuccess, (state, { settlementsSteps }) => ({
    ...updateFlags(state),
    settlementsSteps
  })),

  on(liquidationActions.onError, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(liquidationActions.findIntTransportContainerAllByFilter, (state) => ({
    ...updateFlags(state),
  })),

  on(liquidationActions.findIntTransportContainerAllByFilterSuccess,
    (state, { intTransportContainerAll }) => ({
      ...updateFlags(state),
      intTransportContainerAll,
    })
  ),

  on(liquidationActions.findCommissionValue, (state) => ({
    ...updateFlags(state),
  })),

  on(liquidationActions.findCommissionValueSuccess,
    (state, { commissionValue }) => ({
      ...updateFlags(state),
      commissionValue,
    })
  ),

  on(liquidationActions.postExchangeAuditSuccess,
    (state, { exchangeAudit }) => ({
      ...updateFlags(state),
      edit: true
    })
  ),

  on(liquidationActions.putCommissionValueSuccess,
    (state, { message }) => ({
      ...updateFlags(state),
      edit: true,
    })
  ),
  on(liquidationActions.postLegalProvPoliciesSuccess,
    (state, { message }) => ({
      ...updateFlags(state),
      edit: true,
    })
  ),
  on(liquidationActions.putLegalProvPoliciesSuccess,
    (state, { message }) => ({
      ...updateFlags(state),
      edit: true,
    })
  ),

  on(liquidationActions.deleteLegalProvPoliciesSuccess,
    (state, { message }) => ({
      ...updateFlags(state),
      edit: true,
    })
  ),

  on(liquidationActions.findLegalProvPolicies, (state) => ({
    ...updateFlags(state),
  })),

  on(liquidationActions.findLegalProvPoliciesSuccess,
    (state, { legalProvPolicieseEmb }) => ({
      ...updateFlags(state),
      legalProvPolicieseEmb,
    })
  ),

  on(liquidationActions.getContractActivationSuccess,
    (state, { contractActivation }) => ({
      ...updateFlags(state),
      contractActivation,
    })
  ),

  on(liquidationActions.chargeInformationToInconsistency,
    (state, { information }) => ({
      ...updateFlags(state),
      information,
    })
  ),

  on(liquidationActions.postClientNotificationSuccess,
    (state, { message }) => ({
      ...updateFlags(state),
      updated: { updated: true, type: 1 },
    })
  ),

  on(liquidationActions.postContractActivationSuccess,
    (state, { message }) => ({
      ...updateFlags(state),
      updated: { updated: true, type: 2 },
    })
  ),

  on(liquidationActions.reportInconsistencySuccess,
    (state, { message }) => ({
      ...updateFlags(state),
      updated: true
    })
  ),

  on(liquidationActions.approveStepSuccess,
    (state, { update }) => ({
      ...updateFlags(state),
      updated: true,
    })
  ),

  on(liquidationActions.resetState,
    (state) =>
      initialState
  ),
);

const updateFlags = (state: LiquidationsState): LiquidationsState => {
  return {
    ...state,
    error: null,
    isLoading: false,
    found: { search: false, found: false },
    edit: false,
    created: false,
    updated: false,
    message: null,
  };
};

function reducer(state: LiquidationsState | undefined, action: Action) {
  return featureReducer(state, action);
}

const getError = (state: LiquidationsState) => state.error;
const getMessage = (state: LiquidationsState) => state.message;
const getIsLoading = (state: LiquidationsState) => state.isLoading;
const getIsCreated = (state: LiquidationsState) => state.created;
const getIsFound = (state: LiquidationsState) => state.found;
const onEdit = (state: LiquidationsState) => state.edit;
const getIsUpdate = (state: LiquidationsState) => state.updated;
const getInformation = (state: LiquidationsState) => state.information;

const getSettlementImportSummary = (state: LiquidationsState) => state.settlementImportSummary;
const getExchangeAudit = (state: LiquidationsState) => state.exchangeAudit;
const getDispatchs = (state: LiquidationsState) => state.dispatchs;
const getFormalitiesDocuments = (state: LiquidationsState) => state.formalitiesDocuments;
const getSettlementsSteps = (state: LiquidationsState) => state.settlementsSteps;
const getIntTransportContainerAll = (state: LiquidationsState) => state.intTransportContainerAll;
const getCommissionValue = (state: LiquidationsState) => state.commissionValue;
const getLegalProvPolicies = (state: LiquidationsState) => state.legalProvPolicieseEmb;
const getContractActivation = (state: LiquidationsState) => state.contractActivation;


export const liquidationsReducer = {
  initialState,
  reducer,
  getError,
  getMessage,
  getIsLoading,
  getIsCreated,
  getIsFound,
  onEdit,
  getIsUpdate,
  getInformation,
  getSettlementImportSummary,
  getExchangeAudit,
  getFormalitiesDocuments,
  getDispatchs,
  getSettlementsSteps,
  getIntTransportContainerAll,
  getCommissionValue,
  getLegalProvPolicies,
  getContractActivation,
};
export { LiquidationsState };
