import { createAction, props } from '@ngrx/store';
import {
  ICotizationTransportLocal,
  ICotizationTransportLocalUp,
  IListCotizationTranspLocal
} from '../../../../data/interfaces/ITranspLocal.interface';
import {
  ICotizationTransportInternationalUp,
  ICotizationTransportIntertational,
  IListCotizationTranspInternational
} from '../../../../data/interfaces/ITranspInternational.interface';
import {
  ICotizationAduanas,
  ICotizationAduanasAssets,
  ICotizationAduanasDutyUp,
  ICotizationAduanasUp,
  ICotizationsDetailsCustomsFeasibility,
  IListCotizationAduanas
} from '../../../../data/interfaces/ICotizationAduanas.interface';

enum PostProductionActionType { /** Se crea el CRUD */
  Load = '[PostProduction Component] Load',
  searchPostProductions = '[PostProduction Component] Search PostProduction',
  updatePostProduction = '[PostProduction Component] Update PostProduction',
  createPostProduction = '[PostProduction Component] Create PostProduction',
  updateTotal = '[PostProduction Component] Update Total',
  LoadSuccess = '[PostProduction API] Load Success',
  error = '[PostProduction Component] Ups Error',
  onResetFlagsAction = '[PostProduction API] Cotization reset flags',
  searchPostProductionsLocalAction = '[PostProduction Component] Search PostProduction Local',
  searchPostProductionsLocalSuccessAction = '[PostProduction Component] Search PostProduction Local Success',
  searchPostProductionsInternationalAction = '[PostProduction Component] Search PostProduction International',
  searchPostProductionsInternationalSuccessAction = '[PostProduction Component] Search PostProduction International Success',
  upCotizationsDetailsTranspLocalAction = '[PostProduction Component] Up PostProduction Local',
  upCotizationsDetailsTranspLocalSuccessAction = '[PostProduction Component] Up PostProduction Local Success',
  upCotizationsDetailsTranspInternationalAction = '[PostProduction Component] Up PostProduction International',
  upCotizationsDetailsTranspInternationalSuccessAction = '[PostProduction Component] Up PostProduction International Success',
  searchCotizationsDetailsAduanasAction = '[PostProduction Component] Search Cotizations Details Aduanas',
  searchCotizationsDetailsAduanasSuccessAction = '[PostProduction Component] Search Cotizations Details Aduanas Success',
  upCotizationsDetailsAduanasAction = '[PostProduction Component] Up Cotizations Details Aduanas',
  upCotizationsDetailsAduanasSuccessAction = '[PostProduction Component] Up Cotizations Details Aduanas Success',
  searchAssetsAduanasDetailsAction = '[PostProduction Component] Search Assets Details Aduanas',
  searchAssetsAduanasDetailsSuccessAction = '[PostProduction Component] Search Assets Details Aduanas Success',
  searchTaxesDutyAduanasDetailsAction = '[PostProduction Component] Search Taxes Duty Aduanas Details',
  searchTaxesDutyAduanasDetailsSuccessAction = '[PostProduction Component] Search Taxes Duty Aduanas Details Success',
  upTaxesDutyAduanasDetailsAction = '[PostProduction Component] Up Taxes Duty Aduanas Details',
  upTaxesDutyAduanasDetailsSuccessAction = '[PostProduction Component] Up Taxes Duty Aduanas Details Success',
  searchListCotizationsDetailsTranspInternationalAction = '[PostProduction Component] Search Cotizations Details Transport International',
  searchListCotizationsDetailsTranspInternationalSuccessAction = '[PostProduction Component] Search Cotizations Details Transport International Success',
  searchListCotizationsDetailsTranspLocalAction = '[PostProduction Component] Search Cotizations Details Transport Local',
  searchListCotizationsDetailsTranspLocalSuccessAction = '[PostProduction Component] Search Cotizations Details Transport Local Success',
  searchListCotizationsDetailsAduanasAction = '[PostProduction Component] Search Cotizations Details Aduanas',
  searchListCotizationsDetailsAduanasSuccessAction = '[PostProduction Component] Search Cotizations Details Aduanas Success',
}

const load = createAction(PostProductionActionType.Load);

/** GET: database/cotizationsDetailsTransport?transportType=LOCAL */
const searchPostProductionsLocal = createAction(
  PostProductionActionType.searchPostProductionsLocalAction,
  props<{ cib: string, allyId: number }>()
);
const searchPostProductionsLocalSuccess = createAction(
  PostProductionActionType.searchPostProductionsLocalSuccessAction,
  props<{ getCotizationsLocal: ICotizationTransportLocal }>()
);

/** PATCH: database/cotizationsDetailsTransport?transportType=LOCAL */
const upCotizationsDetailsTranspLocal = createAction(
  PostProductionActionType.upCotizationsDetailsTranspLocalAction,
  props<{ id: number, upCotizationLocalData: ICotizationTransportLocalUp }>()
);
const upCotizationsDetailsTranspLocalSuccess = createAction(
  PostProductionActionType.upCotizationsDetailsTranspLocalSuccessAction,
  props<{ upCotizationLocalData: ICotizationTransportLocalUp }>()
);

/** GET: database/cotizationsDetailsTransport?transportType=INTERNATIONAL */
const searchPostProductionsInternational = createAction(
  PostProductionActionType.searchPostProductionsInternationalAction,
  props<{ cib: string, allyId: number }>()
);
const searchPostProductionsInternationalSuccess = createAction(
  PostProductionActionType.searchPostProductionsInternationalSuccessAction,
  props<{ getCotizationInternational: ICotizationTransportIntertational }>()
);

/** PATCH: database/cotizationsDetailsTransport?transportType=INTERNATIONAL */
const upCotizationsDetailsTranspInternational = createAction(
  PostProductionActionType.upCotizationsDetailsTranspInternationalAction,
  props<{ id: number, upCotizationInternationalData: ICotizationTransportInternationalUp }>()
);
const upCotizationsDetailsTranspInternationalSuccess = createAction(
  PostProductionActionType.upCotizationsDetailsTranspInternationalSuccessAction,
  props<{ upCotizationInternationalData: ICotizationTransportInternationalUp }>()
);

/** GET: database/cotizationCustoms::ADUANAS */
const searchCotizationsAduanasDetails = createAction(
  PostProductionActionType.searchCotizationsDetailsAduanasAction,
  props<{ cib: string, allyId: number }>()
);
const searchCotizationsAduanasDetailsSuccess = createAction(
  PostProductionActionType.searchCotizationsDetailsAduanasSuccessAction,
  props<{ getCotizationAduanasData: ICotizationAduanas }>()
);

/** PUT: database/cotizationCustoms::ADUANAS */
const putCotizationsAduanasDetails = createAction(
  PostProductionActionType.upCotizationsDetailsAduanasAction,
  props<{ id: number, upCotizationAduanasData: ICotizationAduanasUp }>()
);
const putCotizationsAduanasDetailsSuccess = createAction(
  PostProductionActionType.upCotizationsDetailsAduanasSuccessAction,
  props<{ upCotizationAduanasData: ICotizationAduanasUp }>()
);

/** GET: consultar descripción activos de aduanas: tabla */
const searchAssetsAduanasDetails = createAction(
  PostProductionActionType.searchAssetsAduanasDetailsAction,
  props<{ cib: string, page: number, size: number }>()
);
const searchAssetsAduanasDetailsSuccess = createAction(
  PostProductionActionType.searchAssetsAduanasDetailsSuccessAction,
  props<{ getCotizationsAduanasData: ICotizationAduanasAssets }>()
);

/** GET: consultar información impuestos y partida arancelaria: tabla */
const searchTaxesDutyAduanasDetails = createAction(
  PostProductionActionType.searchTaxesDutyAduanasDetailsAction,
  props<{ id: number, page: number, size: number }>()
);
const searchTaxesDutyAduanasDetailsSuccess = createAction(
  PostProductionActionType.searchTaxesDutyAduanasDetailsSuccessAction,
  props<{ getTaxesDutyAduanasData: ICotizationsDetailsCustomsFeasibility }>()
);

/** PUT: consultar información impuestos y partida arancelaria: tabla */
const upTaxesDutyAduanasDetails = createAction(
  PostProductionActionType.upTaxesDutyAduanasDetailsAction,
  props<{ id: number, upTaxesDutyAduanasData: ICotizationAduanasDutyUp }>()
);
const upTaxesDutyAduanasDetailsSuccess = createAction(
  PostProductionActionType.upTaxesDutyAduanasDetailsSuccessAction,
  props<{ upTaxesDutyAduanasData: ICotizationAduanasDutyUp }>()
);

/** ******************************** Para listar cotizaciones en el Equipo Administrativo ******************************** */
/** GET: listar cotizaciones en Transporte Internacional */
const searchListCotizationsTranspInternationalDetails = createAction(
  PostProductionActionType.searchListCotizationsDetailsTranspInternationalAction,
  props<{ cib: string, page: number, size: number }>()
);
const searchListCotizationsTranspInternationalDetailsSuccess = createAction(
  PostProductionActionType.searchListCotizationsDetailsTranspInternationalSuccessAction,
  props<{ getListCotizationsTranspInternationalDetails: IListCotizationTranspInternational }>()
);

/** GET: listar cotizaciones en Transporte Local */
const searchListCotizationsTranspLocalDetails = createAction(
  PostProductionActionType.searchListCotizationsDetailsTranspLocalAction,
  props<{ cib: string, page: number, size: number }>()
);
const searchListCotizationsTranspLocalDetailsSuccess = createAction(
  PostProductionActionType.searchListCotizationsDetailsTranspLocalSuccessAction,
  props<{ getListCotizationsTranspLocalDetails: IListCotizationTranspLocal }>()
);

/** GET: listar cotizaciones en Aduanas */
const searchListCotizationsAduanasDetails = createAction(
  PostProductionActionType.searchListCotizationsDetailsAduanasAction,
  props<{ cib: string }>()
);
const searchListCotizationsAduanasDetailsSuccess = createAction(
  PostProductionActionType.searchListCotizationsDetailsAduanasSuccessAction,
  props<{ getListCotizationsAduanasDetails: IListCotizationAduanas }>()
);


const onResetFlags = createAction(PostProductionActionType.onResetFlagsAction);
const onError = createAction(PostProductionActionType.error,
  props<{ error: any }>()
);

export const PostProductionActions = {
  load,
  searchPostProductionsLocal,
  searchPostProductionsLocalSuccess,
  upCotizationsDetailsTranspLocal,
  upCotizationsDetailsTranspLocalSuccess,
  searchPostProductionsInternational,
  searchPostProductionsInternationalSuccess,
  upCotizationsDetailsTranspInternational,
  upCotizationsDetailsTranspInternationalSuccess,
  searchCotizationsAduanasDetails,
  searchCotizationsAduanasDetailsSuccess,
  putCotizationsAduanasDetails,
  putCotizationsAduanasDetailsSuccess,
  searchAssetsAduanasDetails,
  searchAssetsAduanasDetailsSuccess,
  searchTaxesDutyAduanasDetails,
  searchTaxesDutyAduanasDetailsSuccess,
  upTaxesDutyAduanasDetails,
  upTaxesDutyAduanasDetailsSuccess,
  searchListCotizationsTranspInternationalDetails,
  searchListCotizationsTranspInternationalDetailsSuccess,
  searchListCotizationsTranspLocalDetails,
  searchListCotizationsTranspLocalDetailsSuccess,
  searchListCotizationsAduanasDetails,
  searchListCotizationsAduanasDetailsSuccess,
  onError,
  onResetFlags,
}

