import { Injectable } from '@angular/core';
import { Numbers } from '@constants/numbers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIS_URL } from 'src/app/core/constants/url';
import { IMessagePost } from 'src/app/data/interfaces/IMessagePost.interface';
import { ApiHelperService } from 'src/app/data/services/api-helper/api-helper.service';

@Injectable({
  providedIn: 'root'
})
export class MessagePostService {

  private endPointMessagePost = APIS_URL.messagePost;
  private endpointMessageGet = APIS_URL.messageGet;
  private endpointMessageGetDownload = APIS_URL.messageGetDownload;

  constructor(private apiHelperService: ApiHelperService) {
    // This is intentional
  }

  /** Endpoint para listar todos los mensajes */
  listBlogMessages(
    criterianDescription: string,
    criterianNumber: number
  ): Observable<IMessagePost[]> {
    let endpoint = ""
    if(criterianNumber == Numbers._1){
      endpoint = this.endpointMessageGet + `?cotizacionCIB=${criterianDescription}`
    }else{
      endpoint = this.endpointMessageGet + `?importNumber=${criterianDescription}`
    }
    return this.apiHelperService
      .get(endpoint)
      .pipe(
        map((data: any) => data.Message_Post)
      );
  }

   /** Endpoint para listar todos los mensajes */
   downdloadMessages(
    criterianDescription: string,
    criterianNumber: number
  ): Observable<Blob> {
    let endpoint = ""
    if(criterianNumber == Numbers._1){
      endpoint = this.endpointMessageGetDownload + `?cotizacionCIB=${criterianDescription}`
    }else{
      endpoint = this.endpointMessageGetDownload + `?importNumber=${criterianDescription}`
    }
    return this.apiHelperService
      .get(endpoint,undefined,{
        responseType: 'blob',
    });
  }

  /** Endpoint para crear un mensaje */
  createMessagePost(cotizationId: number, importNumber: string, blogMessageData: IMessagePost): Observable<IMessagePost> {
    let url = ""
    if (cotizationId) {
      url = `${this.endPointMessagePost}?cotizationId=${cotizationId}&importNumber=${importNumber}`
    } else {
      url = `${this.endPointMessagePost}?cotizationId=${0}&importNumber=${importNumber}`
    }
    return this.apiHelperService.post<IMessagePost>(url, blogMessageData).pipe(map((data: any) => data.Message));
  }

  /** Endpoint para buscar actualizar un mensaje */
  updateMessagePost(messageId: number, blogMessageUpData: any): Observable<any> {
    const { emailTo, emailFrom, subject, message } = blogMessageUpData
    return this.apiHelperService
      .put<any>(this.endPointMessagePost + `?messageId=${messageId}`, { emailTo, emailFrom, subject, message })
      .pipe(map(data => { return data.Message })
      );
  }

}
