import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { fromPayments, State } from 'src/app/core/store/reducers';
import { PaymentsActions } from './actions';
import { CreditLetterDtoList, GirosDtoList, ICreditLetterDtoList } from '../../interfaces/ICreditLetterDtoList.interface';
import { CommissionCredit, IHistoricCommission } from '../../interfaces/ICommissionCredit';
import { IPayment } from '../../interfaces/payment.interface';
import { IFilesPayment } from 'src/app/data/interfaces/IFilesPayment.interface';

@Injectable({
  providedIn: 'root',
})
export class PaymentsFacadeService {
  constructor(private store: Store<State>) {
    // This is intentional
  }
  getErrors(): Observable<any> {
    return this.store.select(fromPayments.getErrorPayments);
  }
  resetPaymentsFlags(): void {
    this.store.dispatch(PaymentsActions.onResetFlags());
  }
  setError() {
    this.store.dispatch(PaymentsActions.onErrors({ error: null }));
  }
  sendEmailCustom(importNumber: string, billNumber: number, DatePayment: string, customs: string) {
    this.store.dispatch(PaymentsActions.sendEmailCustom({ importNumber, billNumber, DatePayment, customs }));
  }
  resetSendEmailCustom() {
    this.store.dispatch(PaymentsActions.resetSendEmailCustom({ sendEmailCustomData: null }));
  }
  sendEmailCustomSuccess() {
    return this.store.select(fromPayments.sendEmailCustomData);
  }
  createdPayment(payments: IPayment[], importNumber: string) {
    this.store.dispatch(PaymentsActions.createPayments({ payments, importNumber }));
  }
  createdPaymentSuccess() {
    return this.store.select(fromPayments.createdPaymentData);
  }
  updatePaymentStatus(payments: number[], typeUpdate: string, customs: string) {
    this.store.dispatch(PaymentsActions.updatePaymentStatus({ payments, typeUpdate, customs }));
  }
  updatePaymentStatusSuccess() {
    return this.store.select(fromPayments.updatePaymentStatusData);
  }
  updatedPaymentLetter(payment: CreditLetterDtoList) {
    this.store.dispatch(PaymentsActions.updatedPaymentLetter({ payment }));
  }
  updatedPaymentLetterSuccess() {
    return this.store.select(fromPayments.updatedPaymentLetterData);
  }
  deletePayment(payment: CreditLetterDtoList) {
    this.store.dispatch(PaymentsActions.deletePayment({ payment }));
  }
  deletePaymentSuccess() {
    return this.store.select(fromPayments.deletePaymentData);
  }
  updatedPaymentTransfer(payment: GirosDtoList) {
    this.store.dispatch(PaymentsActions.updatedPaymentTransfer({ payment }));
  }
  updatedPaymentTransferSuccess() {
    return this.store.select(fromPayments.updatedPaymentTransferData);
  }
  updatedPaymentServices(payment: CreditLetterDtoList) {
    this.store.dispatch(PaymentsActions.updatedPaymentServices({ payment }));
  }
  updatedPaymentServicesSuccess() {
    return this.store.select(fromPayments.updatedPaymentServicesData);
  }
  getTypeMethod() {
    this.store.dispatch(PaymentsActions.getTypeMethod());
  }
  getTypeMethodData() {
    return this.store.select(fromPayments.getTypeMethodData);
  }
  getAgreedDocument() {
    this.store.dispatch(PaymentsActions.getAgreedDocument());
  }
  getAgreedDocumentData() {
    return this.store.select(fromPayments.getAgreedDocumentData);
  }
  getPaymentsCardValues(ImportId: number) {
    this.store.dispatch(
      PaymentsActions.getPaymentsValues({ ImportId: ImportId })
    );
  }
  getPaymentsValuesCardData() {
    return this.store.select(fromPayments.getPaymentsValuesCardData);
  }
  findCreditLetter(importNumber: string, page: number, size: number) {
    this.store.dispatch(
      PaymentsActions.findCreditLetter({ importNumber: importNumber, page: page, size: size })
    );
  }

  deleteCommission(importNumber: string, lcNumber: string) {
    this.store.dispatch(
      PaymentsActions.deleteCommission({ importNumber, lcNumber})
    );
  }

  getDeleteCommission(): Observable<any> {
    return this.store.select(fromPayments.getDeleteCommissionData);
  }

  getFilesPayment(importNumber: string) {
    this.store.dispatch(
      PaymentsActions.getFilesPayment({ importNumber: importNumber })
    );
  }
  getFilesPaymentSuccess(): Observable<IFilesPayment | null> {
    return this.store.select(fromPayments.getFilesPaymentSuccess);
  }
  getCreditLetter(): Observable<ICreditLetterDtoList | null> {
    return this.store.select(fromPayments.getCreditLetter);
  }
  findTransfer(importNumber: string, page: number, size: number) {
    this.store.dispatch(
      PaymentsActions.findTransfer({ importNumber: importNumber, page: page, size: size })
    );
  }
  getTransfer(): Observable<ICreditLetterDtoList | null> {
    return this.store.select(fromPayments.getTransfer);
  }
  findServices(importNumber: string, page: number, size: number) {
    this.store.dispatch(
      PaymentsActions.findServices({ importNumber: importNumber, page: page, size: size })
    );
  }
  getServices(): Observable<ICreditLetterDtoList | null> {
    return this.store.select(fromPayments.getServices);
  }
  addCommission(commissionCredit: CommissionCredit, importNumber: string, lcNumber: string) {
    this.store.dispatch(
      PaymentsActions.addCommission({ commissionCredit: commissionCredit, importNumber: importNumber, lcNumber: lcNumber })
    );
  }
  updateCommission(commissionCredit: CommissionCredit[], importNumber: string, lcNumber: string) {
    this.store.dispatch(
      PaymentsActions.updateCommission({ commissionCredit: commissionCredit, importNumber: importNumber, lcNumber: lcNumber })
    );
  }
  findEveryCommission(importNumber: string, lcNumber: string) {
    this.store.dispatch(
      PaymentsActions.findEveryCommission({ importNumber: importNumber, lcNumber: lcNumber })
    );
  }
  historicCommission(importNumber: string, lcNumber: string, event: string, page: number, size: number) {
    this.store.dispatch(
      PaymentsActions.historicCommission({ importNumber: importNumber, lcNumber: lcNumber, event, page, size })
    );
  }
  getEveryCommission(): Observable<CommissionCredit[] | null> {
    return this.store.select(fromPayments.getEveryCommision);
  }
  getHistoricCommission(): Observable<IHistoricCommission | null> {
    return this.store.select(fromPayments.getHistoricCommission);
  }
  getMessage(): Observable<any> {
    return this.store.select(fromPayments.getMessagePayment);
  }
  getEdit(): Observable<any> {
    return this.store.select(fromPayments.getEditPayment);
  }
}
