<!-- -->
<bc-alert *ngIf="Show" >
    <article role="alert" class="bc-alert-active {{AlertType}} bc-alert-fixed" id="MyAlertId">
        <section class="bc-alert-status"><em class="bc-icon bc-st">{{AlertIcon}}</em></section>
        <section class="bc-alert-body">
            <section class="bc-alert-content">
                <p class="bc-alert-title">{{AlertTitle}}</p>
                <p class="bc-alert-text">{{AlertText}}</p>
            </section>
        </section>
        <section class="bc-my-2 bc-me-2 " *ngIf="ShowClose">
            <button type="button" class="bc-alert-close" aria-pressed="false" (click)="close()">
                <em aria-label="Cerrar" class="bc-icon">error</em>
            </button>
        </section>
    </article>
</bc-alert>