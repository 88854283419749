import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@services/modal/modal.service';
import { ColumnsTransport } from '../../../client/components/transportation-asset-information/constants/columns-transport';

@Component({
  selector: 'bsc-table-transport-view-model',
  templateUrl: './table-transport-view-model.component.html',
  styleUrls: ['./table-transport-view-model.component.scss']
})
export class TableTransportViewModelComponent implements OnInit {

  
  @Input() transportContainer: any[] = [];
  @Input() transportLooseLoad: any[] = [];
  @Input() isLocal: boolean = false;

  columnsTransportContainer = ColumnsTransport.columnsContainerAdminView;
  columnsTransportLooseLoad = ColumnsTransport.columnslooseLoad;

  constructor(
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.columnsTransportContainer = this.isLocal ? ColumnsTransport.columnsContainerView 
    : this.columnsTransportContainer
  }

  goBack() {
    this.modalService.close();
  }

}
