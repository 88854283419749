import {
  Component, OnInit, ViewEncapsulation,
  Output, EventEmitter
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Numbers } from 'src/app/core/constants/numbers';

@Component({
  selector: 'bcs-permissions',
  templateUrl: './bcs-permissions.component.html',
  styleUrls: ['./bcs-permissions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BcsPermissionsComponent implements OnInit {


  nameSwitch: string = 'Label'
  labelSwitchRoles: string[] = [
    'Guardar registro', 'Guardar ', 'Cargar documentos',
    'Descargar documentos', 'Blog de mensajes', 'Repositorio documental'
  ];
  labelSwitchAdmin: string[] = [
    'Consultar importación', 'Limpiar importación'
  ]

  /** Estado switch */
  checkedState: boolean = false;
  requiredState: boolean = false;
  disabledState: boolean = false;
  state: boolean = false;

  checkBoxGroup = new UntypedFormControl();
  @Output() onClickEvent = new EventEmitter<boolean>();

  totalRegisters: number | any = Numbers._50;
  perPage: number | any = Numbers._5;

  constructor() {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

  onClick() {
    if (this.onClickEvent) {
      this.onClickEvent.emit(this.state);
    }
  }
  onChangeCheckBox(e: any) { }
  onChangePage(event: any) { }

  // init() {
  //   this.totalRegisters = 10;
  //   this.strTotalRegisters = this.totalRegisters.toString()
  //   this.strPerPage = this.perPage.toString()
  // }


}
