import { Action, createReducer, on } from '@ngrx/store';
import { ProfileActions } from './actions';
import { IProfile } from 'src/app/data/interfaces/IProfile.interface';

interface ProfileState {
  profile: IProfile | null;
  tokenSession: string | null;
  tokenTmp: string | null;
  lastUrl: string | null;
  error: any;
  message: any;
  isLoading: boolean;
  created: boolean;
  found: { search: boolean; found: boolean };
  edit: boolean;
  updated: boolean;
}
const initialState: ProfileState = {
  profile: null,
  tokenSession: null,
  tokenTmp: null,
  lastUrl: null,
  error: null,
  message: null,
  isLoading: false,
  created: false,
  found: { search: false, found: false },
  edit: false,
  updated: false,
};
const profileReducer = createReducer(
  initialState,
  on(ProfileActions.setProfile, (state, { profile }) => ({
    ...updateFlags(state),
    profile,
  })),
  on(ProfileActions.setTokenSession, (state, { tokenSession }) => ({
    ...updateFlags(state),
    tokenSession,
  })),
  on(ProfileActions.setTokenTmp, (state, { tokenTmp }) => ({
    ...updateFlags(state),
    tokenTmp,
  })),
  on(ProfileActions.setLastUrl, (state, { lastUrl }) => ({
    ...updateFlags(state),
    lastUrl,
  })),
  on(ProfileActions.resetState,
    (state) =>
      initialState
  ),
);
const updateFlags = (state: ProfileState): ProfileState => {
  return {
    ...state,
    error: null,
    isLoading: false,
    found: { search: false, found: false },
    edit: false,
    created: false,
    updated: false,
    message: null,
  };
};

function reducer(state: ProfileState | undefined, action: Action) {
  return profileReducer(state, action);
}

const getError = (state: ProfileState) => state.error;
const getMessage = (state: ProfileState) => state.message;
const getIsLoading = (state: ProfileState) => state.isLoading;
const getIsCreated = (state: ProfileState) => state.created;
const getIsFound = (state: ProfileState) => state.found;
const onEdit = (state: ProfileState) => state.edit;

const getProfile = (state: ProfileState) => state.profile;
const getTokenSession = (state: ProfileState) => state.tokenSession;
const getTokenTmp = (state: ProfileState) => state.tokenTmp;
const getLastUrl = (state: ProfileState) => state.lastUrl;
export const ProfileReducer = {
  reducer,
  getError,
  getMessage,
  getIsLoading,
  getIsCreated,
  getIsFound,
  onEdit,

  getProfile,
  getTokenSession,
  getTokenTmp,
  getLastUrl,
};
export { ProfileState };
