import { Action, createReducer, on } from '@ngrx/store';
import { IAlly } from 'src/app/data/interfaces/IAlly.interface';
import { AlliesActions } from './actions';
import { IAllies } from 'src/app/data/interfaces/IAlliesData.interface';

interface AlliesState {
  ally: IAlly | null;
  allied: IAllies | null,
  error: any;
  message: any;
  isLoading: boolean;
  created: boolean;
  found: { search: boolean; found: boolean };
  edit: boolean;
  updated: boolean;
}
const initialState: AlliesState = {
  ally: null,
  allied: null,
  error: null,
  message: null,
  isLoading: false,
  created: false,
  found: { search: false, found: false },
  edit: false,
  updated: false,
};

const featureReducer = createReducer(
  initialState,

  /** Crear aliado */
  on(AlliesActions.createAllies, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(AlliesActions.createAlliesSuccess, (state, { ally }) => ({
    ...updateFlags(state),
    ally,
    created: !!ally,
  })),

  /** Editar aliado */
  on(AlliesActions.editAlly, (state) => ({
    ...updateFlags(state),
  })),
  on(AlliesActions.editAllySuccess, (state, { allies }) => ({
    ...updateFlags(state),
    allies,
  })),

  /** Buscar aliados por nit */
  on(AlliesActions.getAllyNit, (state) => ({
    ...updateFlags(state),
  })),
  on(AlliesActions.getAllyNitSuccess, (state, { ally }) => ({
    ...updateFlags(state),
    ally,
    found: { search: true, found: !!ally },
  })),

  /** Eliminar aliado por ID */
  on(AlliesActions.deactivateAllyId, (state) => ({
    ...updateFlags(state),
  })),
  on(AlliesActions.deactivateAllyIdSuccess, (state, { allied }) => ({
    ...updateFlags(state),
    allied,
  })),



  on(AlliesActions.resetFlags, (state) => ({
    ...updateFlags(state),
  })),
  on(AlliesActions.onError, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

const updateFlags = (state: AlliesState): AlliesState => {
  return {
    ...state,
    error: null,
    isLoading: false,
    found: { search: false, found: false },
    edit: false,
    created: false,
    updated: false,
    message: null,
  };
};
function reducer(state: AlliesState | undefined, action: Action) {
  return featureReducer(state, action);
}
const getError = (state: AlliesState) => state.error;
const getMessage = (state: AlliesState) => state.message;
const getIsLoading = (state: AlliesState) => state.isLoading;
const getIsCreated = (state: AlliesState) => state.created;
const getIsFound = (state: AlliesState) => state.found;
const getEditStatus = (state: AlliesState) => state.edit;

const getAlly = (state: AlliesState) => state.ally;
const getListAlly = (state: AlliesState) => state.allied;

export const AlliesReducer = {
  reducer,
  getError,
  getMessage,
  getIsLoading,
  getIsCreated,
  getIsFound,
  getEditStatus,
  getAlly,
  getListAlly,
};
export { AlliesState };
