export const ColumnsTransport = {

    columnsContainer: [
        {
            field: 'type',
            headerTitle: 'Tipo de contenedor',
            type: 'select',
            values: "kindOfContainerSelectBox",
            edited: true,
            required: true,
        },
        {
            field: 'quantity',
            headerTitle: 'Cantidad de contenedores',
            numberType: 1,
            type: 'number',
            edited: true,
            required: true,
        },
        {
            field: 'weight',
            headerTitle: 'Peso por contenedores',
            type: 'number',
            numberType: 2,
            edited: true,
            required: true,
        },
        {
            field: 'weightUnit',
            headerTitle: 'Unidad de peso',
            type: 'select',
            values: 'weightUnitSelectBox',
            edited: true,
            required: true,
        },
    ],

    columnsContainerView: [
        {
            field: 'type',
            headerTitle: 'Tipo de contenedor',
            type: 'select',
            values: "kindOfContainerSelectBox",
            edited: false,
        },
        {
            field: 'quantity',
            headerTitle: 'Cantidad de contenedores',
            type: 'string',
            edited: false,
        },
        {
            field: 'weight',
            headerTitle: 'Peso por contenedores',
            type: 'string',
            edited: false,
        },
        {
            field: 'weightUnit',
            headerTitle: 'Unidad de peso',
            type: 'select',
            values: 'weightUnitSelectBox',
            edited: false,
        },
    ],

    columnsContainerAdminView: [
        {
            field: 'type',
            headerTitle: 'Tipo de contenedor',
            type: 'select',
            values: "kindOfContainerSelectBox",
            edited: false,
        },
        {
            field: 'quantity',
            headerTitle: 'Cantidad de contenedores',
            type: 'string',
            edited: false,
        },
        {
            field: 'weight',
            headerTitle: 'Peso por contenedores',
            type: 'string',
            edited: false,
        },
        {
            field: 'weightUnit',
            headerTitle: 'Unidad de peso',
            type: 'select',
            values: 'weightUnitSelectBox',
            edited: false,
        },
        {
            field: 'freeDays',
            headerTitle: 'Días libres contenedor',
            type: 'string',
            edited: false,
        },
        {
            field: 'delaysDays',
            headerTitle: 'Costo por moras de contenedor por día',
            type: 'format',
            edited: false,
        },
    ],

    columnslooseLoad: [
        {
            field: 'quantityPieces',
            headerTitle: 'Cantidad de piezas',
            type: 'number',
            numberType: 2,
            edited: true,
            required: true,
        },
        {
            field: 'length',
            headerTitle: 'Largo',
            type: 'number',
            numberType: 2,
            edited: true,
            required: true,
        },
        {
            field: 'width',
            headerTitle: 'Ancho',
            type: 'number',
            numberType: 2,
            edited: true,
            required: true,
        },
        {
            field: 'tall',
            headerTitle: 'Alto',
            type: 'number',
            numberType: 2,
            edited: true,
            required: true,
        },
        {
            field: 'weightOfMeasurement',
            headerTitle: 'Unidad de medida de las dimensiones',
            type: 'select',
            values: 'unitMeasurementDimensionsSelectBox',
            edited: true,
            required: true,
        },
        {
            field: 'weight',
            headerTitle: 'Peso',
            type: 'number', 
            numberType: 2,
            edited: true,
            required: true,
        },
        {
            field: 'weightUnit',
            headerTitle: 'Unidad de medida del peso',
            type: 'select',
            values: 'weightUnitSelectBox',
            edited: true,
            required: true,
        },
    ],

    columnslooseLoadView: [
        {
            field: 'quantityPieces',
            headerTitle: 'Cantidad de piezas',
            type: 'string',
            edited: false,
        },
        {
            field: 'length',
            headerTitle: 'Largo',
            type: 'string',
            edited: false,
        },
        {
            field: 'width',
            headerTitle: 'Ancho',
            type: 'string',
            edited: false,
        },
        {
            field: 'tall',
            headerTitle: 'Alto',
            type: 'string',
            edited: false,
        },
        {
            field: 'weightOfMeasurement',
            headerTitle: 'Unidad de medida de las dimensiones',
            type: 'select',
            values: 'unitMeasurementDimensionsSelectBox',
            edited: false,
        },
        {
            field: 'weight',
            headerTitle: 'Peso',
            type: 'string',
            edited: false,
        },
        {
            field: 'weightUnit',
            headerTitle: 'Unidad de medida del peso',
            type: 'select',
            values: 'weightUnitSelectBox',
            edited: false,
        },
    ],

    columnsContainerTransport: [
        {
            field: 'type',
            headerTitle: 'Tipo de contenedor',
            type: 'select',
            values: "kindOfContainerSelectBox",
            edited: false,
        },
        {
            field: 'quantity',
            headerTitle: 'Cantidad de contenedores',
            type: 'string',
            edited: false,
        },
        {
            field: 'weight',
            headerTitle: 'Peso por contenedores',
            type: 'string',
            edited: false,
        },
        {
            field: 'weightUnit',
            headerTitle: 'Unidad de peso',
            type: 'select',
            values: 'weightUnitSelectBox',
            edited : false,
        },
        {
            field: 'freeDays',
            headerTitle: 'Días libres contenedor',
            type: 'number',
            edited: true,
            required: true,
        },
        {
            field: 'delaysDays',
            headerTitle: 'Costo por moras de contenedor por día',
            type: 'number',
            edited: true,
            required: true,
        },
    ],

    columnsAmountOfPieces: [
        {
            field: 'quantityPieces',
            headerTitle: 'Cantidad de piezas',
            type: 'string',
            edited: false,
        },
        {
            field: 'length',
            headerTitle: 'Largo',
            type: 'string',
            edited: false,
        },
        {
            field: 'width',
            headerTitle: 'Ancho',
            type: 'string',
            edited: false,
        },
        {
            field: 'tall',
            headerTitle: 'Alto',
            type: 'string',
            edited: false,
        },
        {
            field: 'weightOfMeasurement',
            headerTitle: 'Unidad de medida de las dimensiones',
            type: 'select',
            values: 'unitMeasurementDimensionsSelectBox',
            edited: false,
        },
        {
            field: 'weight',
            headerTitle: 'Peso',
            type: 'string',
            edited: false,
        },
        {
            field: 'weightUnit',
            headerTitle: 'Unidad de medida de peso',
            type: 'select',
            values: 'weightUnitSelectBox',
            edited: false,
        },
    ], 
}
