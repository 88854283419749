import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataService } from '../../../data/services/data/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
  lastVisit: number = Date.now();
  ipAdr: any;

  constructor(private dataService: DataService) {
    // This is intentional
  }

  ngOnInit(): void {
    this.ipAddress();
  }

  async ipAddress() {
    const URL_API = 'https://api.ipify.org/?format=json';
    await fetch(URL_API)
      .then((respuestaRaw) => respuestaRaw.json())
      .then((respuesta) => {
        this.ipAdr = respuesta.ip;
        this.dataService.setCurrentIp(this.ipAdr);
      })
      .catch((err) => {
        // This is intentional
      });
  }
}
