import { Directive, ElementRef } from '@angular/core';
import { Numbers } from 'src/app/core/constants/numbers';

@Directive({
  selector: '[Onlyletters]'
})
export class OnlylettersDirective {

  constructor(public elementRef: ElementRef) {
    this.validate(elementRef);
  }

  validate(elementRef: ElementRef) {
    elementRef.nativeElement.onkeypress = (evt: KeyboardEvent) => {
      const tecla = evt.key
      const letras = 'qwertyuiopasdfghjklñzxcvbnmQWERTYUIOPASDFGHJKLÑZXCVBNMáéíóúÁÉÍÓÚäëïöüÄËÏÖÜ ';
      const especiales = [Numbers._131];
      let teclaEspecial = false;
      for (const i in especiales) {
        if (evt.key.charCodeAt(0) == especiales[i]) {
          teclaEspecial = true; break;
        }
      }
      if (letras.indexOf(tecla) == -1 && !teclaEspecial) {
        evt.preventDefault();
      }
    };
  }

}
