<div class="row p-2" id="btn-admin-consults-quotesAllies" *ngIf="showInitialBtns">
  <div class="col-1"></div>
  <div class="col-10">
    <div class="row justify-content-center btn-allies-quotes">
      <div class="col-3 p-0 btn-item" *ngIf="!btnIntern && allies?.international == true">
        <bcs-button Label="Transporte internacional" Type="button" TypeButton="secondary"
          (click)="changeTypeBtn('internacional')"></bcs-button>
      </div>
      <div class="col-3 p-0 btn-item" *ngIf="!btnAduanas && allies?.custom == true">
        <bcs-button Label="Agenciamiento aduanero" Type="button" TypeButton="secondary"
          (click)="changeTypeBtn('aduanas')"></bcs-button>
      </div>
      <div class="col-3 p-0 btn-item" *ngIf="!btnLocal && allies?.local == true">
        <bcs-button Label="Transporte local" Type="button" TypeButton="secondary"
          (click)="changeTypeBtn('local')"></bcs-button>
      </div>
    </div>
  </div>
  <div class="col-1"></div>
</div>


<div class="row p-2" id="btn-admin-consults-quotes-allies-transpLocal" *ngIf="!showInitialBtns && btnLocal">
  <div class="col-1"></div>
  <div class="col-10">
    <div class="row justify-content-center btn-allies-quotes">
      <div class="col-3 p-0 btn-item" *ngIf="allies?.international == true">
        <bcs-button Label="Transporte internacional" Type="button" TypeButton="secondary"
          (click)="changeTypeBtn('internacional')"></bcs-button>
      </div>
      <div class="col-3 p-0 btn-item" *ngIf="allies?.custom == true">
        <bcs-button Label="Agenciamiento aduanero" Type="button" TypeButton="secondary"
          (click)="changeTypeBtn('aduanas')"></bcs-button>
      </div>
      <div class="col-3 p-0 btn-item" *ngIf="allies?.local == true">
        <bcs-button Label="Transporte local" Type="button" (click)="changeTypeBtn('local')"></bcs-button>
      </div>
    </div>
  </div>
  <div class="col-1"></div>
</div>

<div class="row p-2" id="btn-admin-consults-quotes-allies-aduanas" *ngIf="!showInitialBtns && !btnLocal && btnAduanas">
  <div class="col-1"></div>
  <div class="col-10">
    <div class="row justify-content-center btn-allies-quotes">
      <div class="col-3 p-0 btn-item" *ngIf="allies?.international == true">
        <bcs-button Label="Transporte internacional" Type="button" TypeButton="secondary"
          (click)="changeTypeBtn('internacional')"></bcs-button>
      </div>
      <div class="col-3 p-0 btn-item" *ngIf="allies?.custom == true">
        <bcs-button Label="Agenciamiento aduanero" Type="button" (click)="changeTypeBtn('aduanas')"></bcs-button>
      </div>
      <div class="col-3 p-0 btn-item" *ngIf="allies?.local == true">
        <bcs-button Label="Transporte local" Type="button" TypeButton="secondary"
          (click)="changeTypeBtn('local')"></bcs-button>
      </div>
    </div>
  </div>
  <div class="col-1"></div>
</div>

<div class="row p-2" id="btn-admin-consults-quotes-allies-transpInternacional"
  *ngIf="!showInitialBtns && !btnLocal && !btnAduanas && btnIntern">
  <div class="col-1"></div>
  <div class="col-10">
    <div class="row justify-content-center btn-allies-quotes">
      <div class="col-3 p-0 btn-item" *ngIf="allies?.international == true">
        <bcs-button Label="Transporte internacional" Type="button"
          (click)="changeTypeBtn('internacional')"></bcs-button>
      </div>
      <div class="col-3 p-0 btn-item" *ngIf="allies?.custom == true">
        <bcs-button Label="Agenciamiento aduanero" Type="button" TypeButton="secondary"
          (click)="changeTypeBtn('aduanas')"></bcs-button>
      </div>
      <div class="col-3 p-0 btn-item" *ngIf="allies?.local == true">
        <bcs-button Label="Transporte local" Type="button" TypeButton="secondary"
          (click)="changeTypeBtn('local')"></bcs-button>
      </div>
    </div>
  </div>
  <div class="col-1"></div>
</div>