import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ParametersTypeEnum } from 'src/app/core/enums/parameters-type.enum';
import { IParameter } from 'src/app/data/interfaces/IParameters.interface';
import { ISelectBox } from 'src/app/data/interfaces/ISelectBox.interface';
import { ICurrency } from 'src/app/data/interfaces/ICurrency.interface';
import { IAdminDataUpdate } from 'src/app/data/interfaces/IAdminDataUpdate.interface';
import { ApiConfigService } from 'src/app/data/services/apis/config/api-config.service';
import { ICities } from 'src/app/data/interfaces/ICities.interface';
import { ICountries } from 'src/app/data/interfaces/ICountries.interface';
import { ICotizationCreate } from 'src/app/data/interfaces/ICotizationCreate.interface';
import { IDispatchPreData } from 'src/app/data/interfaces/IDispatchPreData.interface';
import { ICotization } from 'src/app/data/interfaces/ICotization.interface';
import { IImportation } from 'src/app/data/interfaces/IImportation.interface';
import { IImportPreDataUp } from '../../interfaces/IImportationDataPreUp.interface';
import { Numbers } from 'src/app/core/constants/numbers';

@Injectable({
  providedIn: 'root',
})
export class DataService {

  public parameters: IParameter[] = [];
  public modules: IParameter[] = [];
  public currencies: ICurrency[] = [];
  public titleMenu = new Subject<string>();
  public adminDataAproval!: IAdminDataUpdate;
  public cities: ICities[] = [];
  public countries: ICountries[] = [];
  public cotizationCreateData!: ICotizationCreate;
  public dispatchPreData!: IDispatchPreData;
  public cotizationData!: ICotization;
  public importation!: IImportation;
  public importationUp!: any;
  public files: File[] = [];
  public lastConnection = new Subject<string>();
  public currentIp = new Subject<string>();
  public activity = new Subject<number>();
  public roles = new Subject<any>();
  public currentRole: any;

  constructor(private apiConfigService: ApiConfigService) {
    // This is intentional
  }

  public getCurrentRol(): any {
    return this.currentRole;
  }
  public getRol(): Observable<any> {
    return this.roles.asObservable();
  }
  public setRol(rol: any) {
    this.currentRole = rol;
    this.roles.next(rol);
  }

  public getActivity(): Observable<number> {
    return this.activity.asObservable();
  }
  public setActivity(activity: number) {
    this.activity.next(activity);
  }
  public getCurrentIp(): Observable<string> {
    return this.currentIp.asObservable();
  }

  public setCurrentIp(title: string) {
    this.currentIp.next(title);
  }

  setFilesCreateData(data: File[]) {
    this.files = data;
  }

  getFilesCreateData(): File[] {
    return this.files;
  }

  setCotizationCreateData(data: ICotizationCreate) {
    this.cotizationCreateData = data;
  }

  getCotizationCreateData(): ICotizationCreate {
    return this.cotizationCreateData;
  }

  setDispatchDetailData(data: IDispatchPreData) {
    this.dispatchPreData = data;
  }

  getDispatchDetailData(): IDispatchPreData {
    return this.dispatchPreData;
  }

  setImportationDetailPreData(data: IImportPreDataUp) {
    this.importationUp = data;
  }

  getImportationPreDataUp(): IImportPreDataUp {
    return this.importationUp
  }

  public getLastConnection(): Observable<string> {
    return this.lastConnection.asObservable();
  }

  public setlastConnection(title: string) {
    this.lastConnection.next(title);
  }

  setParameters(data: IParameter[]) {
    this.parameters = [...data];
  }

  getParameters(): IParameter[] {
    return this.parameters;
  }

  setCurrencies(data: ICurrency[]) {
    this.currencies = [...data];
  }

  getCurrencies(): ICurrency[] {
    return this.currencies;
  }

  setCities(data: ICities[]) {
    this.cities = [...data];
  }

  getCities(): ICities[] {
    return this.cities;
  }

  setCountries(data: ICountries[]) {
    this.countries = [...data];
  }

  getCountries(): ICountries[] {
    return this.countries;
  }

  setModules(data: IParameter[]) {
    this.modules = [...data];
  }
  getModules(): IParameter[] {
    return this.modules;
  }

  getParams(type: ParametersTypeEnum, subclass?: ParametersTypeEnum) {
    if (!this.parameters) { return []; }
    let typeDoc: ISelectBox[] = this.selectTypeParameteres(type);
    if (subclass) {
      const opl = typeDoc.filter(
        (subc) => subc.Description.toUpperCase() === subclass.toUpperCase()
      )[0].optionsParameterList;
      typeDoc = [];
      if (opl) {
        let i = 0;
        opl.forEach((obj) => {
          typeDoc.push({
            Id: i,
            Description: obj,
            defaultHttpParam: obj
          });
          i++;
        });
      }
    }
    return typeDoc;
  }

  selectTypeParameteres(type: ParametersTypeEnum) {
    const typeDoc: ISelectBox[] = [];
    switch (type) {
      case ParametersTypeEnum.CURRENCIES:
        this.currencies.forEach((obj) => { typeDoc.push({ Id: obj?.id, Description: obj?.name , defaultHttpParam: obj}); });
        break;
      case ParametersTypeEnum.YES_OR_NOT:
        [{ id: 1, name: 'SI' }, { id: 0, name: 'NO' },].forEach((obj) => {
          typeDoc.push({ Id: obj?.id, Description: obj?.name, defaultHttpParam: obj});
        });
        break;
      case ParametersTypeEnum.NEW_OR_USED:
        [{ id: 0, name: 'NUEVO' }, { id: 1, name: 'USADO' },].forEach((obj) => {
          typeDoc.push({ Id: obj?.id, Description: obj?.name, defaultHttpParam: obj });
        });
        break;
      case ParametersTypeEnum.CITIES:
        this.cities.forEach((obj) => { typeDoc.push({ Id: obj?.id, Description: obj?.name, defaultHttpParam: obj }); });
        break;
      case ParametersTypeEnum.COUNTRIES:
        this.countries.forEach((obj) => { typeDoc.push({ Id: obj?.id, Description: obj?.name, defaultHttpParam: obj }); });
        break;
      case ParametersTypeEnum.MODULOS_FRONT:
        this.modules.forEach((obj) => { typeDoc.push({ Id: obj?.id, Description: obj?.value, defaultHttpParam: obj }); });
        break;
      default:
        this.parameters.forEach((obj) => {
          if (obj.parameterTypesData.type.toUpperCase() === type.toUpperCase()) {
            typeDoc.push({ Id: obj?.id, Description: obj?.value, optionsParameterList: obj?.optionsParameterList, defaultHttpParam: obj });
          }
        });
    }
    return typeDoc;
  }

  setAdminData(adminDataAproval: IAdminDataUpdate) {
    this.adminDataAproval = adminDataAproval;
  }

  getAdminDataAproval(): IAdminDataUpdate {
    return this.adminDataAproval;
  }

  getAllParameters() {
    this.apiConfigService.getParameters().subscribe((data) => {
      this.setParameters(data);
    });
    this.apiConfigService
      .getCurrencyType()
      .subscribe((data) => this.setCurrencies(data));
    this.apiConfigService
      .getAllCities()
      .subscribe((data) => this.setCities(data));
    this.apiConfigService
      .getAllCountries()
      .subscribe((data) => this.setCountries(data));
  }

  getAllModulesParameter() {
    return this.apiConfigService.getParametersModule(Numbers._27)
  }

  getModalityTypeParameter() {
    return this.apiConfigService.getParametersModule(Numbers._33);
  }

  getDeclarationTypeParameter() {
    return this.apiConfigService.getParametersModule(Numbers._36);
  }

  getCompletionFormParameter() {
    return this.apiConfigService.getParametersModule(Numbers._32);
  }

  getStateStatementParameter() {
    return this.apiConfigService.getParametersModule(Numbers._37);
  }

  public getTitleMenu(): Observable<string> {
    return this.titleMenu.asObservable();
  }

  public setTitleMenu(title: string) {
    this.titleMenu.next(title);
  }

}
