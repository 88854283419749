export const currentlist = [
  'allies',
  'cities',
  'clients',
  'cotizations',
  'cotizationsDetails',
  'countries',
  'currency',
  'files',
  'formalitiesDocuments',
  'imports',
  'customs',
  'customsAssets',
  'customs-nationalization',
  'customs-statements',
  'impIntTransport',
  'impLocalTransport',
  'IntTransportContainer',
  'IntTransportLoad',
  'LocalTransportTypeLoad',
  'messagePost',
  'parameters',
  'allparametertypes',
  'roles',
  'termsAndConditions',
  'users',
  'settlements',
  'exchange-audit',
  'formalitiesDocuments',
  'commission-value',
  'legal-prov-policies',
  'contract-activation',
  /** post-producción */
  'cotizationsDetailsTransport',
  'cotizationCustoms',
  'payment-child',
  'payments',
  'mastersTypesPayments',
  'modulesFlow',
  'screens',
  'staticScreens',
  'customStatementTemps',
  'quotas',
];

