<h6 class="mt-5 mb-0">Descargar documentos cargados</h6>
<mat-divider class="mb-5"></mat-divider>

<div class="row p-2 mt-5">
    <div class="col-1"></div>
    <div class="col-4">
        <bsc-files-manager
        [showDownload]="showButtonDownloadAll"
         class="animate__fadeIn my-5" *ngIf="listFiles && listFiles.length > 0" [files]="listFiles"
            [showIncluded]="true"
            >
        </bsc-files-manager>
    </div>
    <div class="col-3"></div>
    <div class="col-3">
        <bcs-button
        *ngIf="showButtonDownloadAll"
        Label="Descargar seleccionados" Type="button" TypeButton="secondary" Icon="download"
            (onClickEvent)="downloadSelected()" [disabled]="listFilesSelected.length==0"></bcs-button>
    </div>
    <div class="col-1"></div>
</div>
