import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

import { Numbers } from 'src/app/core/constants/numbers';

export function fadeTransition() {
  return trigger('fade', [
    state('in', style({ opacity: '1' })),
    transition('void => *', [style({ opacity: '0' }), animate(Numbers._500)]),
    transition('* => void', [animate(Numbers._500, style({ opacity: '0' }))]),
  ]);
}
