<bc-header [menuInteraction]="true">
  <bc-header-left
    logoName="bancolombia-horizontal"
    [ariaLabelChannel]="title"
    [channelName]="title"
  ></bc-header-left>

  <bc-header-list>
    <div class="px-2 text-right"id="profiles-other" *ngIf="perfil">
      <p class="m-0">{{perfil.username}}</p>
      <small class="text-muted">{{perfil.authorities}}</small>
    </div>

    <button mat-button [matMenuTriggerFor]="perfilRentisMenu" id="profiles-rentis" class="d-none">
    <div class="px-2 text-right profiles-items" *ngIf="perfil">
      <p class="m-0">{{perfil.username}}</p>
      <small class="text-muted">{{perfil.authorities}}</small>
    </div>
      <em class="bc-icon bc-sm">arrow2-down</em>
    </button>
    <mat-menu #perfilRentisMenu="matMenu" xPosition="before">
      <small class="text-muted d-block text-center">Roles</small>
      <mat-divider></mat-divider>
      <button mat-menu-item>Comercial</button>
      <button mat-menu-item>Cliente</button>
    </mat-menu>
  </bc-header-list>
</bc-header>
