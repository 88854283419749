<div class="bc-col-12 centering-content">
  <div class="values-card">
    <h5 class="bc-col-12">Resumen desembolsos proveedor del exterior</h5>
    <div class="bc-col-12 values-container">
      <div class="value-content-box">
        <p class="value-label">Valor factura comercial</p>
        <p class="value">{{resumeValues.commercialValue|currency:resumeValues.currencyData?.abreviature:"symbol"}}</p>
      </div>
      <div class="value-content-box">
        <p class="value-label">Valor desembolsado al proveedor</p>
        <p class="value">{{resumeValues.amountPaidToSupplier|currency:resumeValues.currencyData?.abreviature:"symbol"}}</p>
      </div>
      <div class="value-content-box">
        <p class="value-label negative-val">Valor pendiente por desembolsar</p>
        <p class="value negative-val">{{resumeValues.pendingValue|currency:resumeValues.currencyData?.abreviature:"symbol"}}</p>
      </div>
      <div class="value-content-box">
        <p class="value-label">Valor desembolsado en pesos</p>
        <p class="value">COP{{resumeValues.amountInCop|currency:"COP":"symbol"}}</p>
      </div>
      <div class="value-content-box">
        <p class="value-label">Valor comisión en pesos</p>
        <p class="value">COP{{resumeValues.amountCommissionInCop|currency:"COP":"symbol"}}</p>
      </div>
    </div>
    <div class="bc-col-12 bc-flex bc-justify-content-center">
      <bcs-button
      *ngIf="showButton"
      Label="Ver detalle y movimientos"
      TypeButton="secondary"
      (onClickEvent)="onClick()"></bcs-button>
    </div>
  </div>
</div>
