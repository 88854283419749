
import { Action, createReducer, on } from '@ngrx/store';
import { BlogMessagesActions } from './actions';
import { IMessagePost, IMessagePostPut } from 'src/app/data/interfaces/IMessagePost.interface';

interface BlogMessagesState {
  blogMessage: IMessagePost | null;
  blogMessageList: IMessagePost[] | null;
  blogMessageUp: IMessagePostPut[] | null;
  error: any;
  message: any;
  isLoading: boolean;
  created: boolean;
  found: { search: boolean; found: boolean };
  edit: boolean;
  updated: boolean;
}

const initialState: BlogMessagesState = {
  blogMessage: null,
  blogMessageList: [],
  blogMessageUp: null,
  error: null,
  message: null,
  isLoading: false,
  created: false,
  found: { search: false, found: false },
  edit: false,
  updated: false,
};

const featureReducer = createReducer(
  initialState,
  on(BlogMessagesActions.setBlogList, (state, { blogMessageList }) => ({
    ...updateFlags(state),
    blogMessageList
  })),

  /** Listar mensajes */
  on(BlogMessagesActions.listBlogMessages, state => ({
    ...updateFlags(state),
  })),
  on(BlogMessagesActions.listBlogMessagesSuccess, (state, { blogMessages }) => ({
    ...updateFlags(state),
    blogMessageList: [...blogMessages],
    found: { search: true, found: !!blogMessages },
  })),

  on(BlogMessagesActions.createMessageData, state => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(BlogMessagesActions.createMessageDataSuccess, (state, { blogMessagesData }) => ({
    ...updateFlags(state),
    blogMessageList: [...<Array<IMessagePost>>state.blogMessageList, blogMessagesData],
    message: "El mensaje se ha guardado satisfactoriamente."
  })),

  on(BlogMessagesActions.updateBlogMessagesDataSuccess, (state, { message }) => ({
    ...updateFlags(state),
    message
  })),
  on(BlogMessagesActions.onError, (state, { error }) => ({
    ...updateFlags(state),
    error
  })),
  on(BlogMessagesActions.onResetFlagsBlogMessage, (state) => ({
    ...updateFlags(state),
    blogMessage: null,
    blogMessageList: [],
    blogMessageUp: null,
  })),
);

const updateFlags = (state: BlogMessagesState): BlogMessagesState => {
  return {
    ...state,
    error: null,
    isLoading: false,
    found: { search: false, found: false },
    edit: false,
    created: false,
    updated: false,
    message: null,
  };
};

function reducer(state: BlogMessagesState | undefined, action: Action) {
  return featureReducer(state, action);
}

const getError = (state: BlogMessagesState) => state.error;
const getMessage = (state: BlogMessagesState) => state.message;
const getIsLoading = (state: BlogMessagesState) => state.isLoading;
const getIsCreated = (state: BlogMessagesState) => state.created;
const getIsFound = (state: BlogMessagesState) => state.found;
const onEdit = (state: BlogMessagesState) => state.edit;

const getBlogMessage = (state: BlogMessagesState) => state.blogMessage;
const getListBlogMessage = (state: BlogMessagesState) => state.blogMessageList;

export const BlogMessagesReducer = {
  reducer,
  getError,
  getMessage,
  getIsLoading,
  getIsCreated,
  getIsFound,
  onEdit,
  getBlogMessage,
  getListBlogMessage,
};
export { BlogMessagesState };
