import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomTempDocumentCreate } from '../../data/models/custom-temp-document-create';
import { IColumn } from '../../data/interfaces/icolumn';
import { Utils } from 'src/app/shared/utils';
import { Numbers } from '@constants/numbers';
import { TemporaryFacadeService } from '../temporary-store/temporary.facade.service';
import { BaseComponent } from '../base-component/base.component';
import { IDocumentsDto } from '../../data/interfaces/icustom-temp-document';
import { FilesService } from '@services/apis/files/files.service';

@Component({
  selector: 'app-statement-documentation-modal',
  templateUrl: './statement-documentation-modal.component.html',
  styleUrls: ['./statement-documentation-modal.component.scss']
})
export class StatementDocumentationModalComponent extends BaseComponent implements OnInit {
  _CustomTempDocument!: CustomTempDocumentCreate;

  @Input() get CustomTempDocument(): CustomTempDocumentCreate {
    return this._CustomTempDocument;
  }
  set CustomTempDocument(value: CustomTempDocumentCreate) {
    this._CustomTempDocument = Object.assign({},value);
    this.files = Object.assign([],this.CustomTempDocument.documentsDtoList);
  }
  rowDelete!: IDocumentsDto
  @Input() importNumber!: string;
  @Input() username !: string;

  showUploadFile: boolean = false;
  files: IDocumentsDto[] = [];
  get filesfilter(): IDocumentsDto[] {
    return this.files.filter(x => x.status);
  }
  constructor(
    private utils: Utils,
    private activeModal: NgbActiveModal,
    private filesService: FilesService,
    private temporaryFacadeService: TemporaryFacadeService,
  ) {
    super();
    this.subscriptionsInit();

  }
  subscriptionsInit() {
    this.addSubscription(this.temporaryFacadeService.postFileProceduresDataSuccess()
      .subscribe((response) => this.postFileProceduresDataSuccess(response)));

    this.addSubscription(this.temporaryFacadeService.deleteFileProceduresDataSuccess()
      .subscribe((response) => this.deleteFileProceduresDataSuccess(response)));
  }
  deleteFileProceduresDataSuccess(response: any) {
    if (response == null) {
      return;
    }
    const listdocument: IDocumentsDto[] = [];
    this.CustomTempDocument.documentsDtoList.forEach(item => {
      const item2 = Object.assign({}, item);
      if (item2.documentRoute == this.rowDelete?.documentRoute) {
        item2.status = false;
      }
      listdocument.push(item2)
    });
    this.CustomTempDocument.documentsDtoList = Object.assign([], listdocument);
    this.files =  Object.assign([], listdocument);
  }
  columnsDocument: IColumn[] = [
    { field: "updateAt", headerTitle: "Fecha de subida", type: "date", edited: false, required: true, isOpen: false, sticky: false, isCurrency: false, select: undefined },
    { field: "documentRoute", headerTitle: "Ruta", type: "text", edited: true, required: true, isOpen: false, sticky: false, isCurrency: false, select: undefined },
    { field: "userRole", headerTitle: "Usuario que sube", type: "text", edited: false, required: true, isOpen: false, sticky: false, isCurrency: false, select: undefined },
  ];
  ngOnInit(): void {
    // this Intentional
  }
  postFileProceduresDataSuccess(response: any | null) {
    if (response == null) {
      return;
    }
    this.CustomTempDocument = Object.assign([], response.FormalitiesDocuments,{ editable: true });
  }
  closeModal() {
    this.activeModal.close('close');
  }
  uploadFile() {
    this.showUploadFile = true;
  }
  getfiles(archivos: File[], fileType: string) {
    const formaties = this.makeDtoDocument(archivos[0]);
    if(archivos?.length>0){
    this.temporaryFacadeService
    .postFileProceduresData(this.importNumber, formaties)
    }
    this.showUploadFile = false;
  }
  makeDtoDocument(file: File): any {
    const dateNow = this.utils.formatDate(new Date(), Numbers._3);
    const formData = new FormData();
    formData.append(
      'documentDtoString',
      JSON.stringify({
        formalitiesId: this.CustomTempDocument.id,
        documentRoute: '',
        updateAt: dateNow,
        userRole: this.username,
        status: true,
      })
    );
    formData.append('file', file);
    return formData;
  }
  deleteRowDocument(row: IDocumentsDto) {
    this.rowDelete = row;
    this.temporaryFacadeService.deleteFileProceduresData(row.formalitiesId, row.updateAt);
  }
  downloadRowDocument(row: IDocumentsDto) {
    this.filesService.downloadFileByKey(row?.documentRoute).subscribe();
  }
}
