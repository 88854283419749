<!-- Stepper responsive -->
<ng-container [ngSwitch]="stepperOrientation | async">
  <div *ngSwitchCase="'horizontal'" class="pt-5"></div>
  <div *ngSwitchCase="'vertical'" class="pt-5"></div>
</ng-container>

<!-- Stepper con estados personalizados -->
<mat-stepper
  class="bcs-stepper"
  labelPosition="bottom"
  [orientation]="(stepperOrientation | async)!"
  [selectedIndex]="indexStepper"
>

  <!-- Anulaciones de iconos || change default 'edit' icon -->
  <ng-template matStepperIcon="realizado">
    <mat-icon>done</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="edit" let-index="index">
    {{index + 1}}
  </ng-template>
  <ng-template matStepperIcon="approved">
    <mat-icon>done</mat-icon>
  </ng-template>

  <mat-step label="Equipo administrador" *ngIf="adminAproval" state="realizado"></mat-step>
  <mat-step label="Equipo administrador" *ngIf="!adminAproval"></mat-step>

  <ng-container *ngIf="local">
    <mat-step label="Transporte local" *ngIf="nationalAproval" state="realizado"></mat-step>
    <mat-step label="Transporte local" *ngIf="!nationalAproval"></mat-step>
  </ng-container>

  <ng-container *ngIf="custom">
  <mat-step label="Aduanas" *ngIf="customHouseAproval" state="realizado"></mat-step>
  <mat-step label="Aduanas" *ngIf="!customHouseAproval"></mat-step>
  </ng-container>

  <ng-container *ngIf="international">
  <mat-step label="Transporte internacional" *ngIf="internationalAproval" state="realizado"></mat-step>
  <mat-step label="Transporte internacional" *ngIf="!internationalAproval"></mat-step>
  </ng-container>

  <mat-step label="Solicitud Aprobada" *ngIf="approved" state="approved"></mat-step>
  <mat-step label="Solicitud Aprobada" *ngIf="!approved"></mat-step>

  <mat-step label="Completado" *ngIf="approved"></mat-step>
</mat-stepper>
