<div class="footer-divider"></div>

<div class="footer">
  <div class="footer-icons">
    <bc-icon class="bcs-icons-footer" name="bancolombia-horizontal" size="md"></bc-icon>
    <p class="footer-copyright">Copyright © 2024 Grupo Bancolombia.</p>
    <img class="logo-footer" src="../../../../assets/logos/vigilado.svg" alt="Vigilado">
  </div>

  <div class="footer-adress-ip-last-visit">
    <p class="bc-text-end bc-pe-3">Dirección IP: {{ ipAdr }}</p>
    <p class="bc-pe-3 ">Última visita {{ lastVisit | date: 'medium'}}</p>
  </div>

  <img class="bsc-trazo-footer" src="../../../../assets/logos/trazo.svg" alt="Trazo Bancolombia">
</div>
