import {
  HttpClient,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { Utils } from 'src/app/shared/utils';
import { Numbers } from 'src/app/core/constants/numbers';

@Injectable({
  providedIn: 'root',
})
export class ApiHelperService {
  constructor(private http: HttpClient, private utils: Utils,
    private router: Router) { }

  public baseurl = this.utils.getBaseUrl;

  errorHandler(error: any) {
    const errorObj = error;
    if (errorObj instanceof HttpErrorResponse) {
      if (errorObj.status == Numbers._401) {
        this.router.navigateByUrl("/");
      }
    }
    return throwError(errorObj);
  }


  complete<TResponse>(responseJson: any) {
    return responseJson;
  }

  completeDummy<TResponse>(responseJson: any, endPoint: string) {
    return responseJson[endPoint];
  }
  private getJSON(): Observable<any> {
    return this.http.get('./assets/mydata.json');
  }

  public getJSONV2(path: string): Observable<any> {
    return this.http.get(path);
  }

  // METHOD GET
  public apiGet<TResponse>(endPoint: string): Observable<TResponse> {
    return this.getJSON().pipe(
      map((responseJson) =>
        this.completeDummy<TResponse>(responseJson, endPoint)
      ),
      retry(1),
      catchError((error) => this.errorHandler(error))
    );
  }
  // METHOD GET
  /**
   * @param {string} endpoint
   * @param {string} [errorMsg]
   * @param {{}} [config]
   * @return {*}  {Observable<any>}
   * @memberof ApiHelperService
   */
  public get(
    endpoint: string,
    errorMsg?: string,
    config?: {}
  ): Observable<any> {
    return this.http
      .get(`${this.baseurl}/${endpoint}`, config)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => this.errorHandler(error))
      );
  }

  // METHOD DELETE
  /**
   * @param {string} endpoint
   * @param {string} [errorMsg]
   * @param {{}} [config]
   * @return {*}  {Observable<any>}
   * @memberof ApiHelperService
   */
  public delete(
    endpoint: string,
    errorMsg?: string,
    config?: {}
  ): Observable<any> {
    return this.http
      .delete(`${this.baseurl}/${endpoint}`, config)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return this.errorHandler(error);
        })
      );
  }

  // METHOD POST
  /**
   * @param {string} endpoint
   * @param {{}} [data]
   * @param {string} [errorMsg]
   * @param {{}} [config]
   * @return {*}  {Observable<any>}
   * @memberof ApiHelperService
   */
  public post<T>(
    endpoint: string,
    data?: {},
    errorMsg?: string,
    config?: {}
  ): Observable<T> {
    return this.http
      .post<T>(`${this.baseurl}/${endpoint}`, data, config)
      .pipe(
        map((response: T) => {
          return response;
        }),
        catchError((error) => this.errorHandler(error))
      );
  }

  // METHOD PUT
  public put<T>(
    endpoint: string,
    data?: {},
    errorMsg?: string,
    config?: {}
  ): Observable<T> {
    return this.http
      .put<T>(`${this.baseurl}/${endpoint}`, data, config)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => this.errorHandler(error))
      );
  }

  public patch<T>(
    endpoint: string,
    data?: any,
    errorMsg?: string,
    config?: {}
  ): Observable<T> {
    return this.http
      .patch<T>(`${this.baseurl}/${endpoint}`, data, config)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => this.errorHandler(error))
      );
  }

  downloadFile( endpoint: string, fileName: string) { 
    return this.http
      .get(`${this.baseurl}/${endpoint}`, {
        responseType: 'blob',
      })
      .pipe(
        tap((blob: any) => {
          const stringArray: string[] = `${fileName}`.split('/');
          const enlace = document.createElement('a');
          enlace.download = stringArray[stringArray.length - 1];
          enlace.href = URL.createObjectURL(blob);
          enlace.click();
        })
      );
  }
}
