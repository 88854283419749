import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, } from '@angular/common/http';
import { APIS_URL } from 'src/app/core/constants/url';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { currentlist } from 'src/app/core/constants/currentIpList.contstans';
import { DataService } from '../../data/services/data/data.service';
import { ProfileFacadeService } from 'src/app/modules/master/profile-store/profile.facade.service';

@Injectable({
  providedIn: 'root',
})
export class CustomHttpInterceptorBearer implements HttpInterceptor {
  private endpointAuth = APIS_URL.authAD;
  private endpointAuthClent = APIS_URL.authClient;
  currentIp: string = '127.0.0.1';

  constructor(private dataService: DataService,
    private profileFacadeService: ProfileFacadeService) {
    this.dataService.getCurrentIp().subscribe((currentIp) => (this.currentIp = currentIp));

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes(this.endpointAuth) && !request.url.includes(this.endpointAuthClent)) {
      let token: any;
      this.profileFacadeService.getTokenSession().subscribe((tokenSession) => { token = tokenSession; })
      if (token) {
        request = request.clone({setHeaders: {Authorization: `${token}`,},});
        currentlist.forEach((item) => {
          if (request.url.includes(item)) {
            request = request.clone({setHeaders: {Authorization: `${token}`,'Current-Ip': this.currentIp,},});
          }
        });
      }
    } else if (request.url.includes(this.endpointAuthClent)) {
      let token: any;
      this.profileFacadeService.getTokenTmp().subscribe((tokenSession) => {
          if (tokenSession) {token = tokenSession}
        }
      )
      request = this.addToken(request, token)
    } else if (request.url.includes(this.endpointAuth)) {
      const token = this.extractMSALToken();
      if (token) {request = this.addToken(request, token)}
    }
    return next.handle(request).pipe(map((data) => data));
  }

  addToken(request: any, token: any) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  extractMSALToken() {
    let token = null;
    for (const key of Object.keys(localStorage)) {
      if (key.includes('idtoken')) {
        const val: any = JSON.parse(localStorage.getItem(key)!);
        token = val.secret;
      }
    }
    return token;
  }
}
