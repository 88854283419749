import { Action, createReducer, on } from '@ngrx/store';
import { ImportationActions } from './actions';
import { IImportation, IImportationsPagGenerales } from 'src/app/data/interfaces/IImportation.interface';
import { ICustomsInquiry } from 'src/app/data/interfaces/ICustomsInquiry.interface';
import { ICustomnsNationalization, PageAduanaDeclaraciones } from 'src/app/data/interfaces/ICustomsNationalization.interface';
import { IImpLocalTransport, PageLocalTransportContainers } from 'src/app/data/interfaces/IImpLocalTransport.interface';
import { IDispatch } from 'src/app/data/interfaces/IDispatch.interface';
import { ImpcustomsAssetses } from '../../../../../data/interfaces/ICustomsInquiry.interface';
import { IImpIntTransport, PageIntTransportCargaSuelta, PageIntTransportContainers } from 'src/app/data/interfaces/IImpIntTransport.interface';
import { ImpProcedures } from '../../../../../data/interfaces/IImpProcedures.interface';
import { IImportationsPag } from '../../../../../data/interfaces/IImportationsPaginator.interface';

interface ImportationsState {
  importation: IImportation | null;
  importationList: IImportation[] | null;
  dispatch: IDispatch | null;
  customs: ICustomsInquiry | null;
  customsNaturalization: ICustomnsNationalization | null;
  impLocalTransport: IImpLocalTransport | null;
  impIntTransport: IImpIntTransport | null;
  importations: IImportationsPagGenerales | null;
  ImpLocalTransportContainers: PageLocalTransportContainers | null;
  impCustomsAssetses: ImpcustomsAssetses | null;
  ImpLocalTransportCargaSuelta: PageLocalTransportContainers | null;
  ImpIntTransportContainers: PageIntTransportContainers | null;
  ImpIntTransportCargaSuelta: PageIntTransportCargaSuelta | null;
  aduanaDeclarationsPag: PageAduanaDeclaraciones | null;
  impProceduresList: ImpProcedures | null;
  saveFileDeclraracionesData: any | null;
  importationListPag: IImportationsPag | null;
  error: any;
  message: string | null;
  isLoading: boolean;
  created: boolean;
  found: { search: boolean; found: boolean };
  edit: boolean;
  updated: boolean;
}

const initialState: ImportationsState = {
  importation: null,
  importationList: [],
  dispatch: null,
  customs: null,
  customsNaturalization: null,
  impLocalTransport: null,
  ImpLocalTransportContainers: null,
  impCustomsAssetses: null,
  ImpLocalTransportCargaSuelta: null,
  ImpIntTransportContainers: null,
  ImpIntTransportCargaSuelta: null,
  aduanaDeclarationsPag: null,
  impIntTransport: null,
  impProceduresList: null,
  importationListPag: null,
  importations: null,
  error: null,
  message: null,
  isLoading: false,
  created: false,
  found: { search: false, found: false },
  edit: false,
  updated: false,
  saveFileDeclraracionesData: null,
};

const featureReducer = createReducer(
  initialState,
  on(ImportationActions.onResetFlags, (state) => ({
    ...updateFlags(state),
  })),
  on(ImportationActions.onResetFlagsError, (state) => ({
    ...updateFlags(state),
    error: null,
    message: null
  })),
  on(ImportationActions.onResetFlagsTrue, (state) => ({
    ...updateFlags(state),
    importation: null,
    importations: null,
    importationListPag: null,
    dispatch: null,
    ImpIntTransportCargaSuelta: null,
    ImpIntTransportContainers: null,
    ImpLocalTransportCargaSuelta: null,
    ImpLocalTransportContainers: null,
    aduanaDeclarationsPag: null,
    customs: null,
    customsNaturalization: null,
    impProceduresList: null,
    impCustomsAssetses: null,
    impIntTransport: null,
    impLocalTransport: null,
    importationList: null
  })),
  on(ImportationActions.saveImportationData, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(ImportationActions.saveImportationDataSuccess, (state, { importation }) => ({
    ...updateFlags(state),
    importation,
  })
  ),
  on(ImportationActions.createImportationData, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(ImportationActions.createImportationDataSuccess, (state, { importation }) => ({
    ...updateFlags(state),
    importation,
  })
  ),
  on(ImportationActions.searchImportationByFilter, (state) => ({
    ...updateFlags(state),
    // isLoading: true,
  })),
  on(ImportationActions.searchImportationByFilterSuccess, (state, { importations }) => ({
    ...updateFlags(state),
    importations,
    found: { search: true, found: !!importations },
  })
  ),
  on(ImportationActions.searchImportationByFilterToDelete, (state) => ({
    ...updateFlags(state),
    // isLoading: true,
  })),
  on(ImportationActions.searchImportationByFilterToDeleteSuccess, (state, { importations }) => ({
    ...updateFlags(state),
    importations,
    found: { search: true, found: !!importations },
  })
  ),
  on(ImportationActions.deleteImportationsSuccess, (state, { edit }) => ({
    ...updateFlags(state),
    edit
  })
  ),
  on(ImportationActions.putImportationDataUp, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(ImportationActions.putImportationDataUpSuccess, (state, { importation }) => ({
    ...updateFlags(state),
    message: 'La importación ha sido Actualizada.'
  })
  ),
  on(ImportationActions.createDispatchData, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(ImportationActions.createDispatchDataSuccess, (state, { dispatch }) => ({
    ...updateFlags(state),
    dispatch,
  })
  ),
  on(ImportationActions.getImportationTransporteLocalContenedores, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(ImportationActions.getImportationTransporteLocalContenedoresSuccess, (state, { pageLocalTransportContainers }) => ({
    ...updateFlags(state),
    ImpLocalTransportContainers: pageLocalTransportContainers,
  })
  ), on(ImportationActions.getDeclarationsDataP, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(ImportationActions.getDeclarationsDataPSuccess, (state, { pageAduanaDeclaraciones }) => ({
    ...updateFlags(state),
    aduanaDeclarationsPag: pageAduanaDeclaraciones,
  })
  ),
  on(
    ImportationActions.deleteImpProceduresDataSuccess, (state, { message }) => ({
      ...updateFlags(state),
      message: message
    })
  ),
  on(ImportationActions.getImportationTransporteLocalCargaSuelta, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(ImportationActions.getImportationTransporteLocalCargaSueltaSuccess, (state, { pageLocalTransportContainers }) => ({
    ...updateFlags(state),
    ImpLocalTransportCargaSuelta: pageLocalTransportContainers,
  })
  ), on(ImportationActions.getImportationInternationalContainersP, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(ImportationActions.getImportationInternationalContainersPSuccess, (state, { pageIntTranportContainer }) => ({
    ...updateFlags(state),
    ImpIntTransportContainers: pageIntTranportContainer,
  })
  ), on(ImportationActions.getImportatioInternationalCargaSueltaP, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(ImportationActions.getImportatioInternationalCargaSueltaPSuccess, (state, { pageIntTranportCargaSuelta }) => ({
    ...updateFlags(state),
    ImpIntTransportCargaSuelta: pageIntTranportCargaSuelta,
  })
  ),

  on(ImportationActions.updateCustomsData, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(ImportationActions.updateCustomsDataSuccess, (state, { customs }) => ({
    ...updateFlags(state),
    customs,
  })),
  on(ImportationActions.updateCustomsNatData, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(ImportationActions.updateCustomsNatDataSuccess, (state, { customsNaturalization }) => ({
    ...updateFlags(state),
    customsNaturalization,
  })
  ),
  on(ImportationActions.findImportationByParams, (state) => ({
    ...updateFlags(state),
  })),
  on(ImportationActions.findImportationByParamsSuccess, (state, { importationListPag }) => ({
    ...updateFlags(state),
    importationListPag,
    found: { search: true, found: !!importationListPag },
  })
  ),

  /** Busqueda aduanera por número de importación */
  on(ImportationActions.findCustomsByImportNumber, (state) => ({
    ...updateFlags(state),
  })),
  on(ImportationActions.findCustomsByImportNumberSuccess, (state, { customs }) => ({
    ...updateFlags(state),
    customs,
    found: { search: true, found: !!customs },
  })
  ),
  /** Fin busqueda por número de importación */

  /** ACTIVOS ADUANAS */
  on(ImportationActions.postFilterImpAssetsCustoms, (state) => ({
    ...updateFlags(state),
  })),
  on(ImportationActions.postFilterImpAssetsCustomsSuccess, (state, { impCustomsAssetses }) => ({
    ...updateFlags(state),
    impCustomsAssetses,
    found: { search: true, found: !!impCustomsAssetses },
  })
  ),
  /** ACTIVOS TRAMITES */
  on(ImportationActions.getImpProceduresFilter, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(ImportationActions.getImpProceduresFilterSuccess, (state, { impProceduresList }) => ({
    ...updateFlags(state),
    impProceduresList: impProceduresList,
  })
  ),
  on(ImportationActions.findCustomsNaturalizationByImportNumber, (state) => ({
    ...updateFlags(state),
  })),
  on(ImportationActions.findCustomsNaturalizationByImportNumberSuccess, (state, { customsNaturalization }) => ({
    ...updateFlags(state),
    customsNaturalization,
    found: { search: true, found: !!customsNaturalization },
  })
  ),

  on(ImportationActions.setImportation, (state, { importation }) => ({
    ...updateFlags(state),
    importation,
  })),
  on(ImportationActions.findImportationByImportNumberSuccess, (state, { importation }) => ({
    ...updateFlags(state),
    importation,
  })),
  on(ImportationActions.getLocalTranspByImportNumber, (state) => ({
    ...updateFlags(state),
  })),
  on(ImportationActions.getLocalTranspByImportNumberSuccess, (state, { impLocalTransport }) => ({
    ...updateFlags(state),
    impLocalTransport,
    found: { search: true, found: !!impLocalTransport },
  })
  ),
  on(ImportationActions.getIntTranspByImportNumber, (state) => ({
    ...updateFlags(state),
  })),
  on(ImportationActions.getIntTranspByImportNumberSuccess, (state, { impIntTransport }) => ({
    ...updateFlags(state),
    impIntTransport,
    found: { search: true, found: !!impIntTransport },
  })
  ),
  on(ImportationActions.putImpLocalTransportById, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(ImportationActions.putImpLocalTransportByIdSuccess, (state, { impLocalTransport }) => ({
    ...updateFlags(state),
    impLocalTransport,
  })
  ),
  on(ImportationActions.putImpIntTransportById, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(ImportationActions.onResetImportationsTramitesFlags, (state) => ({
    ...updateFlags(state),
    impProceduresList: null
  })),
  on(ImportationActions.saveFileDeclracionesSuccess, (state, { saveFileDeclraracionesData }) => ({
    ...updateFlags(state),
    isLoading: true,
    saveFileDeclraracionesData,
    message: 'La declaración de importación se ha ingresado a la base de datos con éxito.'
  })),

  on(ImportationActions.putImpIntTransportByIdSuccess, (state, { impIntTransport }) => ({
    ...updateFlags(state),
    impIntTransport,
    message: 'Los datos fueron guardados satisfactoriamente.'
  })
  ),
  on(ImportationActions.saveImportationTransporteLocalContenedoresSuccess, (state, { container }) => ({
    ...updateFlags(state),
    edit: true
  })),
  on(ImportationActions.putImportationTransporteLocalContenedoresSuccess, (state, { container }) => ({
    ...updateFlags(state),
    edit: true
  })),
  on(ImportationActions.onError, (state, { error }) => ({
    ...updateFlags(state),
    error,
  })),
);

const updateFlags = (state: ImportationsState): ImportationsState => {
  return {
    ...state,
    error: null,
    isLoading: false,
    found: { search: false, found: false },
    edit: false,
    created: false,
    updated: false,
    message: null,
  };
};

function reducer(state: ImportationsState | undefined, action: Action) {
  return featureReducer(state, action);
}

const getError = (state: ImportationsState) => state.error;
const getMessage = (state: ImportationsState) => state.message;
const getIsLoading = (state: ImportationsState) => state.isLoading;
const getIsCreated = (state: ImportationsState) => state.created;
const getIsFound = (state: ImportationsState) => state.found;
const onEdit = (state: ImportationsState) => state.edit;

const getImportations = (state: ImportationsState) => state.importations;
const getImportation = (state: ImportationsState) => state.importation;
const getListImportations = (state: ImportationsState) => state.importationList;
const getCustoms = (state: ImportationsState) => state.customs;
const getAssetsCustoms = (state: ImportationsState) => state.impCustomsAssetses;
const getImportationListPag = (state: ImportationsState) => state.importationListPag;
const getCustomsNaturalization = (state: ImportationsState) => state.customsNaturalization;
const getImpLocalTransport = (state: ImportationsState) => state.impLocalTransport;
const getImportationTransporteLocalContenedores = (state: ImportationsState) => state.ImpLocalTransportContainers
const getImportationTransporteLocalCargaSuelta = (state: ImportationsState) => state.ImpLocalTransportCargaSuelta
const getImportationTransporteIntContenedores = (state: ImportationsState) => state.ImpIntTransportContainers
const getImportationTransporteIntCargaSuelta = (state: ImportationsState) => state.ImpIntTransportCargaSuelta
const getAduanaDeclaracionesPag = (state: ImportationsState) => state.aduanaDeclarationsPag
const getDispatch = (state: ImportationsState) => state.dispatch;
const getImpIntTransport = (state: ImportationsState) => state.impIntTransport;
const getImpProcedures = (state: ImportationsState) => state.impProceduresList;
const saveFileDeclaracionesData = (state: ImportationsState) => state.saveFileDeclraracionesData;


export const ImportationsReducer = {
  initialState,
  reducer,
  getError,
  getMessage,
  getIsLoading,
  getIsCreated,
  getIsFound,
  onEdit,
  getImportation,
  getImportations,
  getDispatch,
  getListImportations,
  getCustoms,
  getCustomsNaturalization,
  getImpLocalTransport,
  getImportationTransporteLocalContenedores,
  getImpIntTransport,
  getAssetsCustoms,
  getImportationTransporteLocalCargaSuelta,
  getImportationTransporteIntContenedores,
  getImportationTransporteIntCargaSuelta,
  getAduanaDeclaracionesPag,
  getImpProcedures,
  getImportationListPag,
  saveFileDeclaracionesData
};
export { ImportationsState };
