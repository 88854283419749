import { Injectable } from '@angular/core';
import { ICurrency } from 'src/app/data/interfaces/ICurrency.interface';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { APIS_URL } from 'src/app/core/constants/url';
import { IParameter } from 'src/app/data/interfaces/IParameters.interface';
import { ApiHelperService } from 'src/app/data/services/api-helper/api-helper.service';
import { IAuthPlatform } from 'src/app/data/interfaces/IAuthPlatform.interface';
import { IModules } from '../../../interfaces/IModules.interface';
import { UtilsServices } from 'src/app/shared/utils.services';
import { ProfileFacadeService } from 'src/app/modules/master/profile-store/profile.facade.service';

@Injectable({
  providedIn: 'root',
})
export class ApiConfigService {

  private endPoint = APIS_URL.parameters;
  private endpointAuth = APIS_URL.authAD;
  private endpointAuthClient = APIS_URL.authClient;
  private endPointCurrency = APIS_URL.currency;
  private endPointCities = APIS_URL.cities;
  private endPointCountries = APIS_URL.countries;
  private endPointModulesFlow = APIS_URL.modulesFlow

  constructor(private apiHelperService: ApiHelperService,
    private profileFacadeService: ProfileFacadeService,) {
    // This is intentional
  }

  getParameters(): Observable<IParameter[]> {
    return this.apiHelperService.get(this.endPoint).pipe(
      map((cust: any) => cust.parameterList)
    );
  }

  getTokenPlatform(): Observable<IAuthPlatform> {
    return this.apiHelperService.get(this.endpointAuth).pipe(tap(data=>{
      localStorage.setItem("token", JSON.stringify(data.Authorization))
    }));
  }

  getTokenfromClient(
    documentNumber: string,
    nit: string,
    token: string,
  ): Observable<IAuthPlatform> {
    this.profileFacadeService.setTokenTmp(token);
    const queryParams = `?documentNumber=${documentNumber}&nit=${nit}`;
    const url = this.endpointAuthClient + queryParams
    return this.apiHelperService.get(url,'').pipe(tap(data=>{
      localStorage.setItem("token", JSON.stringify(data.Authorization))
    }));;
  }

  getParametersByType(idParam: number): Observable<IParameter[]> {
    const params = `/by-type/${idParam}`;
    return this.apiHelperService.get(this.endPoint + params).pipe(
      map((data: any) => data.parameterTypeList)
    );
  }
  getParametersModule(paramId: number): Observable<IModules[]> {
    const params = `${paramId}`;
    return this.apiHelperService.get(this.endPointModulesFlow + params).pipe(
      tap(e => UtilsServices.consoleLog('getParametersModule => ', e)),
      map((data: any) => data.parameterTypeList)
    );
  }

  getParametersByTypeId(id: number): Observable<any> {
    const params = `/by-type/${id}`;
    return this.apiHelperService.get(this.endPoint + params).pipe(
      tap(e => UtilsServices.consoleLog('getParametersByTypeId => ', e)),
      map((response: any) => response)
    );
  }

  getCurrencyType(): Observable<ICurrency[]> {
    return this.apiHelperService.get(this.endPointCurrency).pipe(
      map((data: any) => data.Currencies)
    );
  }

  getAllCities(): Observable<ICurrency[]> {
    return this.apiHelperService.get(this.endPointCities).pipe(
      map((data: any) => data.Cities)
    );
  }
  getAllCountries(): Observable<ICurrency[]> {
    return this.apiHelperService.get(this.endPointCountries).pipe(
      map((data: any) => data.Countries)
    );
  }
}
