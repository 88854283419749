import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { State } from 'src/app/core/store/reducers';

import { ProfileActions } from './actions';
import { fromProfile } from '../../../core/store/reducers/index';
import { Observable } from 'rxjs';
import { IProfile } from 'src/app/data/interfaces/IProfile.interface';

@Injectable({
  providedIn: 'root',
})
export class ProfileFacadeService {
  constructor(private store: Store<State>) {
    // This is intentional
  }

  setProfile(profile: IProfile) {
    this.store.dispatch(ProfileActions.setProfile({ profile }));
  }
  getCurretProfile(): Observable<IProfile | null> {
    return this.store.select(fromProfile.getProfile);
  }
  setTokenSession(tokenSession: string) {
    this.store.dispatch(ProfileActions.setTokenSession({ tokenSession }));
  }
  getTokenSession(): Observable<string | null> {
    return this.store.select(fromProfile.getTokenSession);
  }
  setTokenTmp(tokenTmp: string) {
    this.store.dispatch(ProfileActions.setTokenTmp({ tokenTmp }));
  }
  getTokenTmp(): Observable<string | null> {
    return this.store.select(fromProfile.getTokenTmp);
  }
  setLastUrl(lastUrl: string) {
    this.store.dispatch(ProfileActions.setLastUrl({ lastUrl }));
  }
  getLastUrl(): Observable<string | null> {
    return this.store.select(fromProfile.getLastUrl);
  }
  resetState() {
    this.store.dispatch(
      ProfileActions.resetState()
    );
  }
}
