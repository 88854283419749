import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ISelectBox } from 'src/app/data/interfaces/ISelectBox.interface';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
@Component({
  selector: 'bcs-autocomplete',
  templateUrl: './bcs-autocomplete.component.html',
  styleUrls: ['./bcs-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BcsAutocompleteComponent implements OnInit, OnChanges {
  @Input() Values: ISelectBox[] = [];
  @Input() Label: string = '';
  @Input() Type: any = 'text';
  @Input() HelpText: string = '';
  @Input() Icon: string = '';
  @Input() IconPosition: 'Left' | 'Right' = 'Right';
  @Input() Id: string = 'input-default';
  @Input() InputFormControl: any = new UntypedFormControl(null, []);
  @Input() submited: boolean = false;
  @Input() readonly: boolean = false;
  @Input() nombreId: string = 'Description';
  @Input() nombreDescripcion: string = 'Description';
  filteredOptions!: Observable<ISelectBox[]>;
  state: boolean = false;
  constructor() {
    // This is intentional
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.subscriptions();
  }
  ngOnInit(): void {
    this.subscriptions();
  }
  displayFn(user: ISelectBox): any {
    return user && user.Description ? user.Description : user;
  }
  private _filter(name: string): ISelectBox[] {
    const filterValue = name.toLowerCase();
    return this.Values.filter((option) =>
      option.Description.toLowerCase().includes(filterValue)
    );
  }
  subscriptions() {
    this.filteredOptions = this.InputFormControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => {
        const name = typeof value === 'string' ? value : value?.Description;
        return name ? this._filter(name as string) : this.Values.slice();
      })
    );
  }
  getId(object: any) {
    if (!this.nombreId) { return object };
    if (object != undefined) { return object[this.nombreId as keyof typeof object] };
  }
  getDescripcion(object: any) {
    if (object != undefined) {
      return object[this.nombreDescripcion as keyof typeof object];
    }
  }
  get control() {
    return this.InputFormControl;
  }
  get isError(): boolean {
    return ((this.submited == !this.state || this.control?.touched == !this.state) && this.control?.invalid) ?? false;
  }
  get errorText(): string {
    return this.geMessageError()
  }
  geMessageError() {
    let msg = '';
    if (this.control.errors?.required == !this.state) { msg = `El campo ${this.Label} es requerido`; }
    else if (this.control.errors?.minlength) { msg = `El campo ${this.Label} debe tener minimo ${this.control.errors?.minlength.requiredLength} caracteres`; }
    else if (this.control.errors?.maxlength) { msg = `El campo ${this.Label} debe tener máximo ${this.control.errors?.maxlength.requiredLength} caracteres`; }
    else if (this.control.errors?.max) { msg = `El campo ${this.Label} debe ser un año menor a ${this.control.errors?.max.max}`; }
    else if (this.control.errors?.min) { msg = `El campo ${this.Label} debe ser un año mayor a ${this.control.errors?.min.min}`; }
    else if (this.control.errors?.email) { msg = `El campo ${this.Label} debe tener un formato válido de correo electrónico`; }
    else if (this.control.errors) { msg = `El campo ${this.Label} contiene errores.`; }
    return msg;
  }
}
