import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthGuardAD } from './core/guards/auth.guard.ad';
import { MasterComponent } from './modules/master/master.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardAD, MsalGuard],
    loadChildren: () => import('../app/modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    // Cuando viene desde la SVL
    path: 'auth/client',
    loadChildren: () => import('../app/modules/authClient/authClient.module').then((m) => m.AuthClientModule),
  },
  {
    path: 'app',
    canActivate: [AuthGuard],
    component: MasterComponent, loadChildren: () => import('./modules/master/master.module').then((m) => m.AdminModule),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
