import { ICotization } from 'src/app/data/interfaces/ICotization.interface';
import { Action, createReducer, on } from '@ngrx/store';
import { CotizationsActions } from './actions';

interface CotizationsState {
  cotization: ICotization | null;
  cotizations: ICotization | null;
  termsAndConditions: any | null;
  error: any;
  message: any;
  isLoading: boolean;
  created: boolean;
  found: { search: boolean; found: boolean };
  edit: boolean;
  updated: boolean;
}

const initialState: CotizationsState = {
  cotization: null,
  cotizations: null,
  termsAndConditions: null,
  error: null,
  message: null,
  isLoading: false,
  created: false,
  found: { search: false, found: false },
  edit: false,
  updated: false,
};

const featureReducer = createReducer(
  initialState,
  on(CotizationsActions.load, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    error: null,
  })),
  on(CotizationsActions.createCotizations, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(CotizationsActions.createCotizationsSuccess, (state, { cotization }) => ({
    ...updateFlags(state),
    cotization,
    created: true,
  })),
  on(CotizationsActions.SearchCotizationByFilter, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(CotizationsActions.SearchCotizationByFilterSuccess,
    (state, { cotizations }) => ({
      ...updateFlags(state),
      cotizations,
      found: { search: true, found: !!cotizations },
    })
  ),
  on(CotizationsActions.SearchCotizationByFilterToDelete, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(CotizationsActions.SearchCotizationByFilterToDeleteSuccess,
    (state, { cotizations }) => ({
      ...updateFlags(state),
      cotizations,
      found: { search: true, found: !!cotizations },
    })
  ),
  on(CotizationsActions.deleteCotizationsSuccess,
    (state, { edit }) => ({
      ...updateFlags(state),
      edit
    })
  ),
  on(CotizationsActions.searchCotizationByCIB, (state) => ({
    ...updateFlags(state),
    cotization: null,
    isLoading: true,
  })),
  on(CotizationsActions.searchCotizationByCIBSuccess,
    (state, { cotization }) => {
      return {
        ...updateFlags(state),
        cotization,
        found: { search: true, found: !!cotization },
      };
    }
  ),
  on(CotizationsActions.SetCotization, (state, { cotization }) => ({
    ...updateFlags(state),
    cotization,
  })),
  on(CotizationsActions.onEditCotization, (state, { edit }) => ({
    ...updateFlags(state),
    edit,
  })),
  on(CotizationsActions.onError, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(CotizationsActions.onResetFlags, (state) => ({
    ...updateFlags(state),
  })),
  on(CotizationsActions.onResetFlagsTrue, (state) => ({
    ...updateFlags(state),
    cotization: null,
    cotizations: null
  })),
  on(CotizationsActions.findCotizationByParams, (state) => ({
    ...updateFlags(state),
  })),
  on(CotizationsActions.findCotizationByParamsSuccess,
    (state, { cotizations }) => ({
      ...updateFlags(state),
      cotizations,
      found: { search: true, found: !!cotizations },
    })
  ),
  on(CotizationsActions.cotizationAdminAproval, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(CotizationsActions.cotizationAdminAprovalSuccess, (state, { message }) => ({
    ...updateFlags(state),
    message,
  })),

  on(CotizationsActions.cotizationCustomHouseAproval, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(CotizationsActions.cotizationCustomHouseAprovalResponse,
    (state, { message }) => ({
      ...updateFlags(state),
      message,
    })
  ),
  on(CotizationsActions.updateCotization, (state) => ({
    ...updateFlags(state),
  })),
  on(
    CotizationsActions.updateCotizationSuccess,
    (state, { message }) => ({
      ...updateFlags(state),
      message,
    })
  ),
  on(
    CotizationsActions.updateZoneLeaderSuccess,
    (state, { message }) => ({
      ...updateFlags(state),
      message,
    })
  ),
  on(CotizationsActions.getTermsAndConditionsSuccess, (state, {termsAndConditions}) => ({
    ...updateFlags(state),
    termsAndConditions
  })),
);

const updateFlags = (state: CotizationsState): CotizationsState => {
  return {
    ...state,
    error: null,
    isLoading: false,
    found: { search: false, found: false },
    edit: false,
    created: false,
    updated: false,
    message: null,
  };
};

function reducer(state: CotizationsState | undefined, action: Action) {
  return featureReducer(state, action);
}

const getError = (state: CotizationsState) => state.error;
const getMessage = (state: CotizationsState) => state.message;
const getIsLoading = (state: CotizationsState) => state.isLoading;
const getIsCreated = (state: CotizationsState) => state.created;
const getIsFound = (state: CotizationsState) => state.found;
const onEdit = (state: CotizationsState) => state.edit;
const getIListCotizations = (state: CotizationsState) => state.cotizations;
const getCotization = (state: CotizationsState) => state.cotization;
const getTermsAndConditions = (state: CotizationsState) => state.termsAndConditions;
const onUpdated = (state: CotizationsState) => state.message;

export const CotizationsReducer = {
  reducer,
  getCotization,
  getError,
  getMessage,
  getIsLoading,
  getIsCreated,
  getIsFound,
  getIListCotizations,
  getTermsAndConditions,
  onEdit,
  onUpdated
};
export { CotizationsState };
