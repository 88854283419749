import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IFilesManager } from 'src/app/data/interfaces/IFiles-manager.interface';
import { IconBCEnum } from 'src/app/core/enums/icon.enum';
import { FilesService } from '../../../data/services/apis/files/files.service';


@Component({
  selector: 'bsc-files-manager',
  templateUrl: './bsc-files-manager.component.html',
  styleUrls: ['./bsc-files-manager.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BscFilesManagerComponent implements OnInit {

  @Output() deleteFile: EventEmitter<number> = new EventEmitter();
  @Input() files: IFilesManager[] = [];
  @Input() urlDownloadAll: string = '';
  @Input() Icon: IconBCEnum = IconBCEnum.document;
  @Input() size: 'xs' | 'sm' | 'st' | 'md' | 'lg' | 'lx' = 'sm';
  @Input() showIcon: boolean = true;
  @Input() showBtnDelete: boolean = true;
  @Input() showIncluded: boolean = false;
  @Input() showDownload: boolean = true;
  constructor(private filesService: FilesService) {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

  onClickDownload(key: string) {
    this.filesService.downloadFileByKey(key).subscribe((data) => {
    });
  }

  delete(index: number) {
    this.files.splice(index, 1)
  }
}
