<div class="bc-table-container" id="table_result">
    <div class="bc-table-header-top">
        <h6 class="bc-table-header-top-title">{{title}}</h6>
        <div class="bc-table-header-top-right-container">
            <div class="bc-table-actions-container">
              <button type="button" mat-icon-button (click)="add()" *ngIf="!readOnly && canAdd">
                <mat-icon id="addNewReg">add</mat-icon>
              </button>
            </div>
        </div>
    </div>
    <div class="bc-table-content">
        <table class="bc-table"
        aria-label="{{title}}"
        >
            <thead>
                <tr>
                    <th *ngFor="let col of columns; let i = index">{{ col.headerTitle}}</th>
                    <th class="sticky-right"> </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of transportInfo; let i = index">
                    <td *ngFor="let col of columns; let j = index">
                        <ng-container *ngIf="i !== editable">
                            <ng-container *ngIf="col.type == 'select'">
                                {{row[col.field]}}
                            </ng-container>
                            <ng-container *ngIf="col.type == 'string' || col.type == 'number'">
                                {{row[col.field]}}
                            </ng-container>
                            <ng-container *ngIf="col.type == 'format'">
                                <input autocomplete="off" currencyMask class="border-0"
                                [options]="optionCurrencyMask"
                                value="{{row[col.field]}}" readonly="true">
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="i === editable">
                            <ng-container *ngIf="!col.edited">
                                <ng-container *ngIf="col.type == 'select'">
                                    {{row[col.field]}}
                                </ng-container>
                                <ng-container *ngIf="col.type == 'string' || col.type == 'number'">
                                    {{row[col.field]}}
                                </ng-container>
                                <ng-container *ngIf="col.type == 'format'">
                                    <input autocomplete="off" currencyMask class="border-0"
                                    [options]="optionCurrencyMask"
                                    value="{{row[col.field]}}" readonly="true">
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="col.edited">
                                <mat-form-field class="example-form-field">
                                    <input autocomplete="off" matInput *ngIf="col.type == 'string'"
                                        [disabled]="
                                          !col.edited ||
                                          (col.field == 'conversionFactor' && row.currency == 'USD')
                                        "
                                        [type]="col.type"
                                        [(ngModel)]="row[col.field]"
                                        [ngModelOptions]="{standalone: true}"
                                        [required]="col.required">

                                        <input autocomplete="off" matInput *ngIf="col.type == 'number'"
                                        [disabled]="
                                          !col.edited ||
                                          (col.field == 'conversionFactor' && row.currency == 'USD')
                                        "
                                        [type]="col.type"
                                        [(ngModel)]="row[col.field]"
                                        [ngModelOptions]="{standalone: true}"
                                        [required]="col.required"
                                        (keydown)="validarNumero($event, col.numberType)"
                                        >

                                        <input autocomplete="off" matInput *ngIf="col.type == 'format'"
                                        [disabled]="
                                          !col.edited ||
                                          (col.field == 'conversionFactor' && row.currency == 'USD')
                                        "
                                        currencyMask
                                        [options]="optionCurrencyMask"
                                        [type]="col.type"
                                        [(ngModel)]="row[col.field]"
                                        [ngModelOptions]="{standalone: true}"
                                        [required]="col.required">

                                        <mat-select
                                            [(ngModel)]="row[col.field]"
                                            *ngIf="col.type == 'select'"
                                        >
                                            <mat-option *ngFor="let item of selectArray[col.values]"
                                            [value]="item.Description">
                                            {{ item.Description }}
                                            </mat-option>
                                        </mat-select>
                                </mat-form-field>
                                <mat-error *ngIf="col.required && isError(row[col.field])">
                                  <strong>requerido</strong>
                                </mat-error>
                            </ng-container>
                        </ng-container>

                    </td>
                    <td class="personalized-td-buttons sticky-right" *ngIf="!readOnly && row.editable == true">
                        <button type="button" *ngIf="canEdit && i != editable" mat-icon-button (click)="edit(i)">
                            <mat-icon id="editItemFromPayments" >edit</mat-icon>
                        </button>
                        <button type="button" *ngIf="isEdit && i == editable"  mat-icon-button (click)="save(i,row)">
                          <mat-icon id="editItemFromPaymentsSaved" >save</mat-icon>
                      </button>
                        <button type="button" mat-icon-button (click)="cancel()" *ngIf="i === editable">
                            <mat-icon id="cancelItemFromPayments">cancel</mat-icon>
                        </button>
                        <button type="button" mat-icon-button *ngIf="i !== editable && canDelete" (click)="delete(row)">
                            <mat-icon id="deleteItemFromPayments">delete_outline</mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
