import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFilesManager } from 'src/app/data/interfaces/IFiles-manager.interface';
import { environment } from 'src/environments/environment';
import { IconBCEnum } from '../core/enums/icon.enum';
import { ModalService } from '../data/services/modal/modal.service';
import { UtilsServices } from './utils.services';
import { Numbers } from 'src/app/core/constants/numbers';
import { ProfileFacadeService } from '../modules/master/profile-store/profile.facade.service';
import { Profiles } from '../core/mocks/profiles.enum';
import { PDFDocument } from 'pdf-lib';

@Injectable({
  providedIn: 'root',
})
export class Utils {

  profileInformation = {
    sub: '',
    exp: 0,
    iat: 0,
    email: '',
    jti: '',
    authorities: '',
    username: ''
  }
  allyId!: number;

  constructor(
    private modalService: ModalService,
    private profileFacadeService: ProfileFacadeService,
  ) {
    // This is intentional
  }

  public get getBaseUrl() {
    return environment.DOMAIN_OPERATIONS;
  }

  public onError(error: any) {
    UtilsServices.loggerCallbackV2(error);
  }

  /**
   *
   *
   * @param {*} date
   * @return {*}  {string}
   * @description Salidas:
   * @description         1) dd/mm/yyyy
   * @description         2) Fri, 30 Dec 2022 13:20:17 GMT
   * @description         3) 2022-12-30T13:21:26.545Z
   * @description         4) dd-mm-yyyy
   * @description         5) yyyy
   * @description         6) 2023-01-01
   * @description         7) 2023-1-1
   * @description         8) 01-01-2023
   * @description   default) dd/mm/yyyy
   * @memberof Utils
   */
  public formatDate(date: any, formato?: number): any {
    if (!date) { return date; } let newDate: any;
    switch (formato) {
      case Numbers._1: newDate = new Date(date).toLocaleDateString('es-CO'); // formarto dd/mm/yyy
        break;
      case Numbers._2: newDate = new Date(date).toUTCString(); //Fri, 30 Dec 2022 13:20:17 GMT
        break;
      case Numbers._3: newDate = new Date(date).toISOString(); // 2022-12-30T13:21:26.545Z
        break;
      case Numbers._4: newDate = new Date(date).toLocaleDateString('es-CO'); // formarto dd/mm/yyyy
        newDate = newDate.replace('/', '-'); // formarto dd-mm-yyyy
        break;
      case Numbers._5: newDate = new Date(date).toLocaleDateString('es-CO'); // formarto dd/mm/yyyy
        newDate = newDate.split('/')[Numbers._2]; // yyyy
        break;
      case Numbers._6: newDate = new Date(date).toLocaleDateString('es-CO'); // formarto dd/mm/yyyy
        newDate = newDate.split('/'); // yyyy
        newDate = newDate[Numbers._2] + `-${newDate[Numbers._1] <= Numbers._9 ? '0' : ''}` + newDate[Numbers._1] + `-${newDate[Numbers._0] <= Numbers._9 ? '0' : ''}` + newDate[Numbers._0];
        break;
      case Numbers._7: newDate = date;
        newDate = newDate.split('/'); // yyyy-MM-dd
        newDate = newDate[Numbers._2] + `-` + newDate[Numbers._1] + `-` + newDate[Numbers._0];
        break;
      case Numbers._8: newDate = new Date(date).toLocaleDateString('es-CO'); /** Caso para pormato de fecha (dd-mm-yyyy) para la creación, busqueda de importacion por fechas */
        newDate = newDate.split('/'); newDate = newDate[Numbers._0] + `-${newDate[Numbers._1] <= Numbers._9 ? '0' : ''}` + newDate[Numbers._1] + `-${newDate[Numbers._2] <= Numbers._9 ? '0' : ''}` + newDate[Numbers._2];
        break;
      case Numbers._9: newDate = new Date(date).toLocaleDateString('es-CO'); /** Caso para pormato de fecha (dd/mm/yyy) en el Blog de mensajes */
        newDate = newDate.split('/'); newDate = newDate[Numbers._1] + `/${newDate[Numbers._0] <= Numbers._9 ? '0' : ''}` + newDate[Numbers._0] + `/${newDate[Numbers._2] <= Numbers._9 ? '0' : ''}` + newDate[Numbers._2]
        break;
      default: newDate = new Date(date).toLocaleDateString('es-CO'); // formarto dd/mm/yyyy
    } return newDate;
  }
  /**
     *
     *
     * @param {*} date
     * @return {*}  {string}
     * @description Salidas:
     * @description 2023-01-01
     * @memberof Utils
     */
  adjustDateFormat(dateString: string): any {
    if (dateString == null || dateString == '' || dateString == 'null') {
      return '';
    }
    if (typeof dateString != 'string') {
      dateString = this.formatDate(dateString, Numbers._6);
    }
    const [year, month, day] = dateString.split('-');
    if (day != null && month != null && year != null) {
      return new Date(Number(year), Number(month) - 1, Number(day));
    } else {
      return '';
    }
  }

  filesManagerFormat(
    files: File[],
    icon = IconBCEnum.document,
    downloadBtn: boolean = true,
    deleteBtn: boolean = true,
    editBtn: boolean = false
  ): IFilesManager[] {
    const arraFilesManager: IFilesManager[] = [];
    files.forEach((file) => {
      const item: IFilesManager = {
        icon: icon,
        file: file,
        download: downloadBtn,
        edit: editBtn,
        delete: deleteBtn,
      };
      arraFilesManager.push(item);
    });
    return arraFilesManager;
  }

  filesManagerFormatOnlyString(
    files: string[],
    icon = IconBCEnum.document,
    downloadBtn: boolean = true,
    deleteBtn: boolean = true,
    editBtn: boolean = false
  ): IFilesManager[] {
    const arraFilesManager: IFilesManager[] = [];
    files.forEach((file) => {
      const item: IFilesManager = {
        icon: icon,
        file: { name: file },
        download: downloadBtn,
        edit: editBtn,
        delete: deleteBtn,
      };
      arraFilesManager.push(item);
    });
    return arraFilesManager;
  }

  listFilesManagerFormatOnlyString(
    files: any[],
    icon = IconBCEnum.document,
    downloadBtn: boolean = true,
    deleteBtn: boolean = true,
    editBtn: boolean = false
  ): IFilesManager[] {
    const arraFilesManager: IFilesManager[] = [];
    files.forEach((file) => {
      const item: IFilesManager = {
        icon: icon,
        file: { name: file.routeDocument },
        download: downloadBtn,
        edit: editBtn,
        delete: deleteBtn,
      };
      arraFilesManager.push(item);
    });
    return arraFilesManager;
  }

  filesRaw(files: IFilesManager[]): File[] {
    const filesRaw: File[] = []
    files.forEach(file => {
      filesRaw.push(file.file)
    })
    return filesRaw;
  }

  errorManager(error: any, orgin?: string) {
    if (error?.status && error?.status == Numbers._401) { return };
    if (error instanceof HttpErrorResponse) {
      let title = `${error.error.error}`
      let message = `${error.message}`
      if (environment.production) {
        title = "Algo salió mal"
        message = "Ocurrió una falla en el sistema, estamos trabajando para ofrecerte una solución lo más pronto posible."
      }
      this.showInfoModal(
        title,
        message,
        'Aceptar',
        'error'
      );
    } else {
      if (error) {
        if (error.error) { this.showInfoModal(error.error, error.message, 'Continuar', 'error'); }
        else if (error.message || error.Message) { this.showInfoModal('Error', error.Message == undefined ? error.message : error.Message, 'Continuar', 'error'); }
      }
    }
  }

  showInfoModal(title: string, text: string, labelButton: string, icon: string) {
    this.modalService.showOk(title, text, labelButton, icon);
    this.modalService.modalRef.result
      .then((result: any) => { UtilsServices.loggerCallbackV2(result); })
      .catch((error: any) => { UtilsServices.loggerCallbackV2(error); });
  }

  decodeToken(token: string) {
    const base64Url = token?.split('.')[1];
    const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(atob(base64));
  }

  rolesFormat(rol: string) {
    if (rol === Profiles.ADMINISTRATOR) { rol = 'Administrador'; }
    else if (rol === Profiles.CUSTOMS) { rol = 'Agencia de Aduanas'; }
    else if (rol === Profiles.INTERNATIONAL_TRANSPORT) { rol = 'Transporte Internacional'; }
    else if (rol === Profiles.LOCAL_TRANSPORTATION) { rol = 'Transporte Local'; }
    else if (rol === Profiles.COMMERCIAL) { rol = 'Comercial'; }
    else if (rol === Profiles.RENTING) { rol = 'Renting'; }
    else if (rol === Profiles.COMMERCIAL_TRADE) { rol = 'Comercio Trade'; }
    else { rol = 'Cliente'; }
    return rol;
  }

  authentication() {
    let token = "";
    this.profileFacadeService.getTokenSession().subscribe(
      (tokenSession) => {
        if (tokenSession) {
          token = tokenSession;
        }
      }
    )
    const accessCredential = this.decodeToken(token);
    this.profileInformation.username = accessCredential.username;
    this.profileInformation.authorities = this.rolesFormat(accessCredential.authorities[0]);
    return {
      role: this.profileInformation,
      allyID: this.alliesId(accessCredential.authorities[0])
    };
  }

  alliesId(rol: string) {
    if (rol === Profiles.CLIENT) { this.allyId = Numbers._1 }
    else if (rol === Profiles.COMMERCIAL) { this.allyId = Numbers._2 }
    else if (rol === Profiles.LOCAL_TRANSPORTATION) { this.allyId = Numbers._3 }
    else if (rol === Profiles.INTERNATIONAL_TRANSPORT) { this.allyId = Numbers._4 }
    else if (rol === Profiles.CUSTOMS) { this.allyId = Numbers._5 }
    else if (rol === Profiles.ADMINISTRATOR) { this.allyId = Numbers._6 }
    else if (rol === Profiles.RENTING) { this.allyId = Numbers._7 }
    else if (rol === Profiles.COMMERCIAL_TRADE) { this.allyId = Numbers._8 }
    return this.allyId;
  }

  automaticScroll(id: string) {
    setTimeout(() => {
      document.getElementById(id)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      })
    }, Numbers._50)
  }

  setValuesForm(value: any) {
    if (value == '' || value == null || value == undefined) {
      return null
    }
    return { value, disabled: true }
  }

  formatCurrency(value: number): string {
    const regex = /\B(?=(\d{3})+(?!\d))/g;
    return value.toFixed(Numbers._2)
      .replace(regex, ',');
  }
  blobToFile(blob: Blob, nombreArchivo: string): File {
    return new File([blob], nombreArchivo, { type: blob.type });;
  }
  async validPdf(file: File): Promise<File> {
    try {
      const pdfBytes = await this.readFileAsUint8Array(file);
      const repairedPdfBytes = await this.checkFile(pdfBytes);
      const blob = new Blob([repairedPdfBytes], { type: 'application/pdf' });
      return this.blobToFile(blob, file.name,);
    } catch (error) {
      console.error('Error al reparar o enviar el PDF:', error);
      throw error;
    }
  }
  private async checkFile(pdfBytes: Uint8Array): Promise<Uint8Array> {
    try {
      const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true });
      return await pdfDoc.save();
    } catch (error) {
      console.error('Error al intentar reparar el PDF:', error);
      throw error;
    }
  }
  private readFileAsUint8Array(file: File): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const arrayBuffer = e.target?.result as ArrayBuffer;
        const uint8Array = new Uint8Array(arrayBuffer);
        resolve(uint8Array);
      };
      reader.onerror = (error: ProgressEvent<FileReader>) => {
        reject('Error al leer el archivo PDF.');
      };
      reader.readAsArrayBuffer(file);
    });
  }

  validateFiles(files: IFilesManager[], newFiles: File[]) {
    let isPresent = false;
    files.forEach(file => {
      const name = file.file.name?.split("_");
      name?.pop();
      const newName = name?.join('_')
      if (this.validNames(newFiles, newName)) {
        isPresent = true
      }
    })
    return isPresent;
  }
  private validNames(newFiles: File[], newName: string): boolean {
    let isPresent = false;
    newFiles.forEach(file => {
      if (file.name?.toLowerCase().indexOf(newName?.toLowerCase()) != -1) {
        isPresent = true
      }
    })
    return isPresent;
  }

  private  nameMap: { [key: string]: string } = {
    assetName: "Nombre del activo",
    tariffPosition: "Posición arancelaria",
    reference: "Referencia",
    brand: "Marca",
    model: "Modelo",
    productYear: "Año del producto",
    serial: "Serial",
    vin: "Vin",
    chassis: "Chasis",
    engineNumber: "Número del motor",
    tiresNumber: "Número de llantas",
  };

  public castNames(key: string): string {
    return " " +this.nameMap[key] || "";
  }

  validateFormatDate(date: string){
    if(date == ""){
      return false;
    }
    const regex = /^(19|20)\d{2}[-\/](0[1-9]|1[0-2])[-\/](0[1-9]|[12]\d|3[01])$/;

    if(!regex.test(date)){
      return false;
    }

    const parts = date.includes('-') ? date.split('-') : date.split('/');
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[2], 10);

    const dateObj = new Date(year, month, day);

    if(dateObj.getFullYear() !== year || dateObj.getMonth() !== month || dateObj.getDate() !== day){
      return false;
    }else{
      return true;
    }
  }
}
