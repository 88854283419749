import { Subscription } from 'rxjs';
import { Component, OnInit, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TokenJWTService } from 'src/app/data/services/tokens/token-jwt.service';
import { ActiveAccount } from 'src/app/models/ActiveAccount';
import { DataService } from '../../../data/services/data/data.service';
import { ProfileFacadeService } from 'src/app/modules/master/profile-store/profile.facade.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  datos: ActiveAccount = new ActiveAccount();
  @Input() title: string = 'Inicio';
  subs: Subscription = new Subscription();

  perfil = {
    authorities: '',
    username: ''
  }

  constructor(
    private tokenService: TokenJWTService,
    private router: Router,
    private dataService: DataService,
    private profileFacadeService: ProfileFacadeService,
  ) {
    this.subscriptions();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.getAccountActive();
    this.subscriptions();
    this.perfil = this.dataService.getCurrentRol();
  }

  subscriptions() {
    const sub = this.dataService.getRol().subscribe(rol => {
      this.perfil = rol
    })
    this.subs.add(sub);
  }

  getAccountActive() {
    this.tokenService.getAccountActive().then((response) => {
      this.datos = response;
    });
  }

  logout() {
    sessionStorage.clear();
    this.profileFacadeService.resetState();
    this.router.navigate(['']);
  }
}
