export interface ICardValues {
  id: number;
  commercialValue: number;
  amountPaidToSupplier: number;
  pendingValue: number;
  amountInCop: number;
  billNumber: any;
  dateToBePaid: any;
  importId: number;
  currencyData: ICurrencyData;
  amountCommissionInCop: number;
  totalPercentage: number;
}

export class CardValuesDto implements ICardValues {
  id: number = 0;
  commercialValue: number = 0;
  amountPaidToSupplier: number = 0;
  pendingValue: number = 0;
  amountInCop: number = 0;
  billNumber: any = null;
  dateToBePaid: any = null;
  importId: number = 0;
  currencyData: ICurrencyData = new CurrencyDataDto();
  amountCommissionInCop: number = 0;
  totalPercentage: number = 0;
}


export interface ICurrencyData {
  id: number;
  abreviature: string;
  name: string;
}

export class CurrencyDataDto implements ICurrencyData {
  id: number = 0;
  abreviature: string = "";
  name: string = "";
}