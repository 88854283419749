<div class="wrapper" id="modal-time">
    <div class="bsc-header-modal">
      <span (click)="close()" class="close">
        <bc-icon name="error" aria-label="close"></bc-icon>
      </span>
    </div>

    <div class="bsc-body-modal text-center">
      <img
        src="../../../../assets/logos/{{ 'time' }}.svg"
        alt="action"
        class="action"
      />
      <h4 class="mb-4">{{ titulo }}</h4>
      <p class="message my-2" [innerHTML]="mensaje"></p>

      <bc-progress-bar [percent]="percentaje"></bc-progress-bar>

      <p [ngClass]="{'message-red': countdown <= 30}" class="message my-2" >{{countdown}} seg.</p>
    </div>

    <div class="bsc-footer-modal">
      <div *ngIf="typeModal == 'ok'">
        <bcs-button
          [Label]="LabelButton"
          Type="button"
          (click)="onClick()"
        ></bcs-button>
      </div>

      <div *ngIf="typeModal !== 'ok'" class="yesOrNoButtons">
        <bcs-button
          class="btn-modal"
          Label="Salir"
          Type="button"
          TypeButton="secondary"
          (click)="logOut()"
          [ngClass]="'info-msg'"
        ></bcs-button>
        <bcs-button
          class="btn-custom"
          Type="button"
          Label="{{ textButton }}"
          typeButton="primary"
          (click)="onClick()"
          [ngClass]="'info-msg'"
        >
          <bc-icon class="icon-float-rigth">arrow-right</bc-icon>
        </bcs-button>
      </div>
    </div>
  </div>
