import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { State } from 'src/app/core/store/reducers';

import { fromImportations } from 'src/app/core/store/reducers/index';
import { IImportationData } from 'src/app/data/interfaces/IImportationData.interface';
import { ImportationActions } from './actions';
import { CustomsAssetseDetails, ICustomsInquiry, ImpcustomsAssetses } from 'src/app/data/interfaces/ICustomsInquiry.interface';
import { ICustomsDataUpdate } from 'src/app/data/interfaces/ICustomsDataUpdate.interface';
import { ICustomnsNationalization, PageAduanaDeclaraciones } from 'src/app/data/interfaces/ICustomsNationalization.interface';
import { ICustomsNatDataUpdate } from 'src/app/data/interfaces/ICustomsNatDataUpdate.interface';
import { IImpLocalTransport, PageLocalTransportContainers, PageLocalTransportContainersDetail } from 'src/app/data/interfaces/IImpLocalTransport.interface';
import { IImpLocalTransportUpdate } from 'src/app/data/interfaces/IImpLocalTransportUpdate.interface';
import { IImportation, IImportationsPagGenerales } from 'src/app/data/interfaces/IImportation.interface';
import { IImportationDataAlly } from 'src/app/data/interfaces/IImportationDataAlly.interface';
import { IImportationFilter } from 'src/app/data/interfaces/IImportationFilter.interface';
import { IImportationFilterByDate } from 'src/app/data/interfaces/IImportationFilterByDate.interfaces';
import { IDispatchData } from 'src/app/data/interfaces/IDispatchData.interface';
import { IImpIntTransport, PageIntTransportCargaSuelta, PageIntTransportCargaSueltaDetail, PageIntTransportContainers, PageIntTransportContainersDetail } from 'src/app/data/interfaces/IImpIntTransport.interface';
import { IImpIntTransportUpdate } from 'src/app/data/interfaces/IImpIntTransportUpdate.interface';
import { ImpProcedures, ImpProceduresDetails } from 'src/app/data/interfaces/IImpProcedures.interface';
import { IImportationsPag } from '../../../../../data/interfaces/IImportationsPaginator.interface';
import { IImportPreData } from '../../../../../data/interfaces/IImportationDataPreUp.interface';

@Injectable({
  providedIn: 'root',
})
export class ImportationsFacadeService {
  constructor(private store: Store<State>) {
    // This is intentional
  }

  // ======================================[IMPORTATIONS]======================================\\
  //#region IMPORTATIONS
  getImportation(): Observable<IImportation | null> {
    return this.store.select(fromImportations.getImportation);
  }

  getMessages(): Observable<string | null> {
    return this.store.select(fromImportations.getImpIntTransportMessages);
  }

  getImportationLocalContainersPaginators(): Observable<PageLocalTransportContainers | null> {
    return this.store.select(fromImportations.getImpLocalTransportContainers);
  }

  getImportationLocalCargaSueltaPaginators(): Observable<PageLocalTransportContainers | null> {
    return this.store.select(fromImportations.getImpLocalTransportCargaSuelta);
  }


  getImportationInternationalContainersPaginators(): Observable<PageIntTransportContainers | null> {
    return this.store.select(fromImportations.getImpIntTrasportContainers);
  }

  getImportatioInternationalCargaSueltaPaginators(): Observable<PageIntTransportCargaSuelta | null> {
    return this.store.select(fromImportations.getImpIntTrasportCargaSuelta);
  }

  getAduanaDeclaracionesPaginatods(): Observable<PageAduanaDeclaraciones | null> {
    return this.store.select(fromImportations.getPagAduanasDeclaracion);
  }

  getImportationInternationalContainersP(importNumber: string, pageSize: number, pageindex: number) {
    this.store.dispatch(
      ImportationActions.getImportationInternationalContainersP({ importNumber, pageSize, pageindex })
    );
  }

  getDeclarationsDataP(importNumber: string, pageSize: number, pageindex: number) {
    this.store.dispatch(
      ImportationActions.getDeclarationsDataP({ importNumber, pageSize, pageindex })
    );
  }

  saveFileDeclaraciones(importNumber: string, file: File) {
    this.store.dispatch(
      ImportationActions.saveFileDeclraraciones({ importNumber, file })
    );
  }
  saveFileDeclaracionesSuccess() {
    return this.store.select(
      fromImportations.saveFileDeclraracionesSuccess
    );
  }
  getImportatioInternationalCargaSueltaP(importNumber: string, pageSize: number, pageindex: number) {
    this.store.dispatch(
      ImportationActions.getImportatioInternationalCargaSueltaP({ importNumber, pageSize, pageindex })
    );
  }


  deleteImportationContainer(containerId: number) {
    this.store.dispatch(
      ImportationActions.deleteImportationContainer({ containerId })
    );
  }

  deleteImportationContainerInternational(id: number) {
    this.store.dispatch(
      ImportationActions.deleteImportationContainerInternational({ id })
    );
  }

  deleteImportationCargaSueltaInternational(id: number) {
    this.store.dispatch(
      ImportationActions.deleteImportationCargaSueltaInternational({ id })
    );
  }

  getImportationTransporteLocalContenedores(typeLoad: string, importNumber: string, pageSize: number, pageindex: number) {
    this.store.dispatch(
      ImportationActions.getImportationTransporteLocalContenedores({ typeLoad, importNumber, pageSize, pageindex })
    );
  }

  getImportationTransporteLocalCargaSuelta(typeLoad: string, importNumber: string, pageSize: number, pageindex: number) {
    this.store.dispatch(
      ImportationActions.getImportationTransporteLocalCargaSuelta({ typeLoad, importNumber, pageSize, pageindex })
    );
  }

  getCustoms(): Observable<ICustomsInquiry | null> {
    return this.store.select(fromImportations.getCustoms);
  }

  getAssetsCustoms(): Observable<ImpcustomsAssetses | null> {
    return this.store.select(fromImportations.getAssetsCustoms);
  }

  getImportationListPag(): Observable<IImportationsPag | null> {
    return this.store.select(fromImportations.getImportationListPag);
  }


  getCustomsNaturalization(): Observable<ICustomnsNationalization | null> {
    return this.store.select(fromImportations.getCustomsNaturalization);
  }




  putImportationDataUp(importNumber: string, importationData: IImportPreData) {
    this.store.dispatch(
      ImportationActions.putImportationDataUp({ importNumber, importationData })
    )
  }
  saveImportationData(importationData: IImportationData) {
    this.store.dispatch(
      ImportationActions.saveImportationData({ importationData })
    );
  }
  putImportationContainer(containerId: number, imcontainerData: PageLocalTransportContainersDetail) {
    this.store.dispatch(
      ImportationActions.putImportationTransporteLocalContenedores({ containerId, imcontainerData })
    );
  }

  putImportationContainerInternational(contId: number, intContainerData: PageIntTransportContainersDetail) {
    this.store.dispatch(
      ImportationActions.putImportationContainerInternational({ contId, intContainerData })
    );
  }

  putImportationCargaSueltaInternational(loadId: number, intLoadData: PageIntTransportCargaSueltaDetail) {
    this.store.dispatch(
      ImportationActions.putImportationCargaSueltaInternational({ loadId, intLoadData })
    );
  }


  saveContainerData(importId: number, imcontainerData: PageLocalTransportContainersDetail) {
    this.store.dispatch(
      ImportationActions.saveImportationTransporteLocalContenedores({ importId, imcontainerData })
    );
  }

  saveContainerDataInt(importIdInt: number, importDataIntCont: PageIntTransportContainersDetail) {
    this.store.dispatch(
      ImportationActions.saveContainerDataInt({ importIdInt, importDataIntCont })
    );
  }

  saveCargaSueltaData(importIdIntC: number, importDataIntCarg: PageIntTransportCargaSueltaDetail) {
    this.store.dispatch(
      ImportationActions.saveCargaSueltaData({ importIdIntC, importDataIntCarg })
    );
  }


  createImportationData(importationDataAlly: IImportationDataAlly) {
    this.store.dispatch(ImportationActions.createImportationData({ importationDataAlly }));
  }

  updateCustomsData(id: number, customsDataUpdate: ICustomsDataUpdate) {
    this.store.dispatch(
      ImportationActions.updateCustomsData({ id, customsDataUpdate })
    );
  }

  updateCustomsNatData(
    id: number,
    customsNatDataUpdate: ICustomsNatDataUpdate
  ) {
    this.store.dispatch(
      ImportationActions.updateCustomsNatData({ id, customsNatDataUpdate })
    );
  }

  getImportList(): Observable<IImportation[] | null> {
    return this.store.select(fromImportations.getListImportations);
  }

  getErrors(): Observable<any> {
    return this.store.select(fromImportations.getError);
  }

  getEdit(): Observable<any> {
    return this.store.select(fromImportations.getOnEdit);
  }

  setImportation(importation: IImportation) {
    this.store.dispatch(ImportationActions.setImportation({ importation }));
  }

  resetImportationsFlags(): void {
    this.store.dispatch(ImportationActions.onResetFlags());
  }

  resetImporationErrorFlags(): void {
    this.store.dispatch(ImportationActions.onResetFlags());
  }

  resetImportationsTramitesFlags(): void {
    this.store.dispatch(ImportationActions.onResetImportationsTramitesFlags());
  }

  resetImportationsFlagsTrue(): void {
    this.store.dispatch(ImportationActions.onResetFlagsTrue());
  }

  resetImportationsErrorTrue(): void {
    this.store.dispatch(ImportationActions.onResetFlagsError());
  }
  findImportationByImportNumber(importNumber: string) {
    this.store.dispatch(
      ImportationActions.findImportationByImportNumber({ importNumber })
    );
  }

  onSearch(): Observable<any> {
    return this.store.select(fromImportations.getImportationIsFound);
  }

  getImportations(): Observable<IImportationsPagGenerales | null> {
    return this.store.select(fromImportations.getImportations);
  }

  getListImportations(): Observable<IImportation[] | null> {
    return this.store.select(fromImportations.getListImportations); //funcion repetida
  }
  setImportaion(importation: IImportation) {
    this.store.dispatch(ImportationActions.setImportation({ importation })); //funcion repetida
  }
  onEdit(edit: boolean) {
    this.store.dispatch(ImportationActions.onEditImportation({ edit }));
  }


  findCustomsByImportNumber(importNumber: string) {
    this.store.dispatch(
      ImportationActions.findCustomsByImportNumber({ importNumber })
    );
  }

  //ADUANAS ACTIVOS

  postFilterImpAssetsCustoms(importNumber: string, page: number, size: number) {
    this.store.dispatch(
      ImportationActions.postFilterImpAssetsCustoms({ importNumber, page, size, })
    );
  }

  deleteImpAssetsCustoms(id: number) {
    this.store.dispatch(
      ImportationActions.deleteImpAssetsCustoms({ id })
    );
  }

  putImpAssetsCustomsById(id: number, impAssetsCustomsDataUpdate: CustomsAssetseDetails) {
    this.store.dispatch(
      ImportationActions.putImpAssetsCustomsById({ id, impAssetsCustomsDataUpdate })
    );
  }

  saveImpAssetsCustomsData(importNumber: string, impAssetsCustomsData: CustomsAssetseDetails) {
    this.store.dispatch(
      ImportationActions.saveImpAssetsCustomsData({ importNumber, impAssetsCustomsData })
    );
  }

  // END ADUANAS ACTIVOS


  // TRAMITES

  getImpProcedures(): Observable<ImpProcedures | null> {
    return this.store.select(fromImportations.getImpProcedures);
  }

  getImpProceduresFilter(importNumber: string) {
    this.store.dispatch(
      ImportationActions.getImpProceduresFilter({ importNumber })
    );
  }

  postImpProceduresData(importNumber: string, body: any) {
    this.store.dispatch(
      ImportationActions.postImpProceduresData({ importNumber, body })
    );
  }

  putImpProceduresData(id: number, impProceduresDataUpdate: ImpProceduresDetails) {
    this.store.dispatch(
      ImportationActions.putImpProceduresData({ id, impProceduresDataUpdate })
    );
  }

  deleteImpProceduresData(id: number) {
    this.store.dispatch(
      ImportationActions.deleteImpProceduresData({ id })
    );
  }

  getMessageDeleteData(): Observable<string | null> {
    return this.store.select(fromImportations.getImpProcedurestMessages);
  }

  // END TRAMITES

  findCustomsNaturalizationByImportNumber(importNumber: string) {
    this.store.dispatch(
      ImportationActions.findCustomsNaturalizationByImportNumber({
        importNumber,
      })
    );
  }

  findImportationByParams(
    params: IImportationFilterByDate,
    page: number,
    size: number
  ) {
    this.store.dispatch(
      ImportationActions.findImportationByParams({
        params,
        page,
        size,
      })
    );
  }

  getImpLocalTransport(): Observable<IImpLocalTransport | null> {
    return this.store.select(fromImportations.getImpLocalTransport);
  }

  getImpLocalTranspByImportNumber(importNumber: string) {
    this.store.dispatch(
      ImportationActions.getLocalTranspByImportNumber({ importNumber })
    );
  }

  putImpLocalTransportById(
    id: number,
    impLocalTransportUpdate: IImpLocalTransportUpdate
  ) {
    this.store.dispatch(
      ImportationActions.putImpLocalTransportById({
        id,
        impLocalTransportUpdate,
      })
    );
  }

  getImpIntTransport(): Observable<IImpIntTransport | null> {
    return this.store.select(fromImportations.getImpIntTransport);
  }

  getImpIntTranspByImportNumber(importNumber: string) {
    this.store.dispatch(
      ImportationActions.getIntTranspByImportNumber({ importNumber })
    );
  }

  putImpIntTransportById(id: number, impIntTransportUpdate: IImpIntTransportUpdate) {
    this.store.dispatch(ImportationActions.putImpIntTransportById({ id, impIntTransportUpdate, })
    );
  }
  // #endregion

  // ======================================[importationDetail]======================================\\
  //#region importationDetail
  searchImportationByFilter(params: IImportationFilter, page: number, size: number, isImport?: string) {
    this.store.dispatch(ImportationActions.searchImportationByFilter({ params, page, size, isImport }));
  }

  searchImportationByFilterToDelete(params: IImportationFilter, page: number, size: number) {
    this.store.dispatch(ImportationActions.searchImportationByFilterToDelete({ params, page, size}));
  }

  deleteImportations(body: any) {
    this.store.dispatch(ImportationActions.deleteImportations({ body }));
  }

  getImportationDetailErrors(): Observable<any> {
    return this.store.select(fromImportations.getImportationDetailOnErrors);
  }

  // ======================================[DISPATCH]======================================\\
  createDispatchData(dispatchData: IDispatchData, dispatchNumber: string) {
    this.store.dispatch(ImportationActions.createDispatchData({ dispatchData, dispatchNumber }));
  }

  getDispatchData(): Observable<any> {
    return this.store.select(fromImportations.getDispatch);
  }


  // ======================================[dispatchDetail]======================================\\

  // ======================================[createDispatch]======================================\\

}
