import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Profiles } from 'src/app/core/mocks/profiles.enum';
import { ProfileFacadeService } from 'src/app/modules/master/profile-store/profile.facade.service';
@Component({
  selector: 'app-button-link-action',
  templateUrl: './button-link-action.component.html',
  styleUrls: ['./button-link-action.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonLinkActionComponent implements OnInit {

  @Output() onClickBlogMessage = new EventEmitter<true>()
  profileSection: Profiles | null = null;
  documentRepository: boolean = false;
  proceduresSection: boolean = false;

  constructor(
    private router: Router,
    private profileFacadeService: ProfileFacadeService
  ) {
    this.subscriptionsBla();
  }

  ngOnInit(): void {
    this.enableSectionButtons();
  }

  subscriptionsBla(): void {
    this.profileFacadeService.getCurretProfile().subscribe((profile) => {
      if (profile) {
        this.profileSection = profile.authorities[0];
      }
    });
  }

  enableSectionButtons() {
    switch (this.profileSection) {
      case Profiles.CLIENT:
        break;
      case Profiles.COMMERCIAL:
        this.documentRepository = true;
        break;
      case Profiles.ADMINISTRATOR:
        this.proceduresSection = true;
        break;
      case Profiles.CUSTOMS:
        break;
      case Profiles.INTERNATIONAL_TRANSPORT:
        break;
      case Profiles.LOCAL_TRANSPORTATION:
        this.proceduresSection = true;
        break;
      default:
    }
  }

  goToLogisticsCoordination() {
    this.router.navigate(['app/inicio/coordinacion-logistica']);
  }

  goToproceduresSection() {
    this.router.navigate(['app/inicio/coordinacion-logistica/tramites']);
  }

  onClickBlog() {
    this.profileFacadeService.setLastUrl(this.router.url)
    this.onClickBlogMessage.emit(true)
  }
}
