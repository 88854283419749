import { Injectable } from '@angular/core';
import { APIS_URL } from 'src/app/core/constants/url';
import { ApiHelperService } from 'src/app/data/services/api-helper/api-helper.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Utils } from '../../../../shared/utils';
import { HttpClient } from '@angular/common/http';
import { IconBCEnum } from 'src/app/core/enums/icon.enum';
import { IFilesManager } from 'src/app/data/interfaces/IFiles-manager.interface';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private endPointFiles = APIS_URL.files;
  private endPointBlog = APIS_URL.messagePost;
  private endPointDataReports = APIS_URL.dataReports;
  public baseurl = this.utils.getBaseUrl;
  today = new Date();
  date = this.today.toLocaleDateString('es-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  constructor(
    private apiHelperService: ApiHelperService,
    private utils: Utils,
    private http: HttpClient
  ) {
  }

  downloadBlog(cib: string) {
    const queryParams = `/report/pdf?cotizacionCIB=${cib}`;
    return this.http
      .get(this.baseurl + '/' + this.endPointBlog + queryParams, {
        responseType: 'blob',
      })
      .pipe(
        tap((blob: any) => {
          const stringArray: string[] = `${'blog '}${cib}-${this.date}`.split('/');
          const enlace = document.createElement('a');
          enlace.download = stringArray[stringArray.length - 1];
          enlace.href = URL.createObjectURL(blob);
          enlace.click();
        })
      );
  }

  downloadAll(Files: IFilesManager[]) {
    for (const element of Files) {
      this.downloadFileByKey(element.file.name).subscribe()
    }
  }
  getAllFiles(): Observable<IFilesManager[]> {
    return this.apiHelperService
      .get(this.endPointFiles)
      .pipe(
        map((data) => this.utils.filesManagerFormatOnlyString(data.Files, IconBCEnum.document, true, false, false))
      );
  }

  saveFilesPayment(
    files: File[],
    paymentChildId: number,
    importNumber?: string
  ): Observable<any> {
    const formdata = new FormData();
    files?.forEach((file) => {
      formdata.append('file', file);
    });
    const params = `/payment-child?paymentChildId=${paymentChildId}&importNumber=${importNumber}`;
    return this.apiHelperService.post<any>(
      this.endPointFiles + params,
      formdata
    );
  }

  saveFiles(
    files: File[],
    cib?: string,
    importNumber?: string
  ): Observable<any> {
    const formdata = new FormData();
    files?.forEach((file) => {
      formdata.append('file', file);
    });
    const params = `?cib=${cib}&importNumber=${importNumber ? importNumber : ''}`;
    return this.apiHelperService.post<any>(
      this.endPointFiles + params,
      formdata
    );
  }

  downloadFile(key: string): Observable<any> {
    const queryParams = `?key=${key}`;
    return this.apiHelperService.get(this.endPointFiles + queryParams);
  }

  downloadFileByKey(key: string): Observable<any> {
    const queryParams = `/download?key=${key}`;
    return this.http
      .get(this.baseurl + '/' + this.endPointFiles + queryParams, {
        responseType: 'blob',
      })
      .pipe(
        tap((blob: any) => {
          const stringArray: string[] = key.split('/');
          const enlace = document.createElement('a');
          enlace.download = stringArray[stringArray.length - 1];
          enlace.href = URL.createObjectURL(blob);
          enlace.click();
        })
      );
  }

  downloadDataReports(): Observable<any> {
    return this.http
      .get(this.baseurl + '/' + this.endPointDataReports, {
        responseType: 'blob',
      })
      .pipe(
        tap((blob: any) => {
          const stringArray: string[] = `${'Informe sabana de datos cotización '}${this.date}`.split('/');
          const enlace = document.createElement('a');
          enlace.download = stringArray[stringArray.length - 1];
          enlace.href = URL.createObjectURL(blob);
          enlace.click();
        })
      );
  }

  downloadDataReportImportations(): Observable<any> {
    return this.http
      .get(this.baseurl + '/' + this.endPointDataReports + '/importation', {
        responseType: 'blob',
      })
      .pipe(
        tap((blob: any) => {
          const stringArray: string[] = `${'Informe sabana de datos importación '}${this.date}`.split('/');
          const enlace = document.createElement('a');
          enlace.download = stringArray[stringArray.length - 1];
          enlace.href = URL.createObjectURL(blob);
          enlace.click();
        })
      );
  }

  downloadDataReportSettlements(): Observable<any> {
    return this.http
      .get(this.baseurl + '/' + this.endPointDataReports + '/settlement', {
        responseType: 'blob',
      })
      .pipe(
        tap((blob: any) => {
          const stringArray: string[] = `${'Informe sabana de datos liquidación y temporal '}${this.date}`.split('/');
          const enlace = document.createElement('a');
          enlace.download = stringArray[stringArray.length - 1];
          enlace.href = URL.createObjectURL(blob);
          enlace.click();
        })
      );
  }

  downloadDataReportsCotizacion(transportType: any, allyId: any): Observable<any> {
    const params =  `/cotization?transportType=${transportType}&allyId=${allyId}`;
    return this.http
      .get(this.baseurl + '/' + this.endPointDataReports + params, {
        responseType: 'blob',
      })
      .pipe(
        tap((blob: any) => {
          const stringArray: string[] = `${'Informe_cotizaciones '}${this.date}`.split('/');
          const enlace = document.createElement('a');
          enlace.download = stringArray[stringArray.length - 1];
          enlace.href = URL.createObjectURL(blob);
          enlace.click();
        })
      );
  }
}
