import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[capitalizeInput]',
})
export class CapitalizeInputDirective {
  @Input() locked: boolean = false;
  @Input() capitalizeInputDisable: boolean = false;
  @Input() typeTransform: 'UpperCase' | 'Lowercase' | 'Capitalize' = 'Capitalize';

  constructor(private readonly elRef: ElementRef) {
    // This is intentional
  }

  @HostListener('input', ['$event'])
  onChangeInput(event: InputEvent): void {
    const inputValue: string = this.elRef.nativeElement.value;
    if (this.capitalizeInputDisable) {
      return;
    }
    if (this.elRef.nativeElement.type === 'email') {
      this.elRef.nativeElement.value = inputValue.toLowerCase();
    } else {
      switch (this.typeTransform) {
        case 'Capitalize':
          this.elRef.nativeElement.value = this.capitalizeFirstLetter(inputValue);
          break;

        case 'UpperCase':
          this.elRef.nativeElement.value = inputValue.toUpperCase();
          break;

        case 'Lowercase':
          this.elRef.nativeElement.value = inputValue.toLowerCase();
          break;
        default:

      }
    }
  }

  private capitalizeFirstLetter(inputValue: string): string {
    if (typeof inputValue !== 'string' || !inputValue || inputValue.length === 0) {
      return inputValue;
    }

    const words = inputValue.split(' ');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    return capitalizedWords.join(' ');
  }
}
