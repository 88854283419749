import { Injectable } from '@angular/core';
import { INewRole, IRoles } from 'src/app/data/interfaces/IRoles.itnterface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIS_URL } from 'src/app/core/constants/url';
import { ApiHelperService } from 'src/app/data/services/api-helper/api-helper.service';
import { IAllRoles } from '../../../interfaces/IRolAllBD.itnterface';
import { ISearchScreens } from '../../../interfaces/ISearchScreens.interface';
import { IScreensUpdate } from '../../../interfaces/IScreensUpdate.interface';
import { IScreens } from '../../../interfaces/IScreens.interface';

@Injectable({
  providedIn: 'root',
})
export class RolesService {

  private endPointRoles = APIS_URL.roles;
  private endPointScreens = APIS_URL.screens;
  private endPointStaticScreens = APIS_URL.staticScreens;

  constructor(private apiHelperService: ApiHelperService) {
    // This is intentional
  }

  getAllRoles(): Observable<IRoles[]> {
    return this.apiHelperService.get(this.endPointRoles).pipe(
      map((data) => data.Roles)
    );
  }

  /** Endpoint para crear un Rol */
  createRole(bodyRole: INewRole): Observable<INewRole> {
    return this.apiHelperService
      .post<INewRole>(this.endPointRoles, bodyRole)
      .pipe(
        map((data: any) => data.Rol)
      )
  }

  /** Endpoint para eliminar Rol */
  deleteRole(rol: string): Observable<any> {
    return this.apiHelperService
      .delete(this.endPointRoles + `?role=${rol}`)
      .pipe(
        map((data: any) => data)
      )
  }

  /** Listar Roles BD */
  getRoles(): Observable<IAllRoles[]> {
    return this.apiHelperService.get(this.endPointRoles)
      .pipe(
        map((data: any) => data.Roles)
      );
  }

  /** Consultar Rol por Id */
  searchRol(id: number): Observable<IAllRoles> {
    return this.apiHelperService
      .get(this.endPointRoles + `/by-id/${id}`)
      .pipe(
        map((data: any) => data.Rol)
      )
  }

  /** http://localhost:8080/database/screens?module=&flow=&Rol=ROL_CLIENTE&page=0&size=10 */
  /** Consultar Screens por Module */
  searchScreens(solicitScreens: ISearchScreens): Observable<IScreens[]> {
    const moduleValue = `?module=${solicitScreens?.module}`
    const flow = `&flow=${solicitScreens?.flow}`
    const rol = `&Rol=${solicitScreens?.Rol}`
    const params = `&page=${solicitScreens?.page}&size=${solicitScreens?.size}`
    return this.apiHelperService
      .get(this.endPointScreens + moduleValue + flow + rol + params)
      .pipe(
        map((data: any) => data)
      )
  }

  /** http://localhost:8081/database/screens?Rol=ROLE_EJEMPLO */
  /** Actualizar Rol por Nombre del Rol */
  updateScreensRole(rol: string, updateScreens: IScreensUpdate[]): Observable<any> {
    const params = `?Rol=${rol}`
    return this.apiHelperService
      .post(this.endPointScreens + params, updateScreens)
      .pipe(
        map((data: any) => data.Rol)
      )
  }

  /** http://localhost:8080/database/static-screens?module=&flow=&Rol=ROL_CLIENTE&page=0&size=10 */
  /** Consultar Static-Screens por Module */
  searchStaticScreens(solicitScreens: ISearchScreens): Observable<IScreens[]> {
    const moduleValue = `?module=${solicitScreens?.module}`
    const flow = `&flow=${solicitScreens?.flow}`
    const rol = `&Rol=${solicitScreens?.Rol}`
    const params = `&page=${solicitScreens?.page}&size=${solicitScreens?.size}`
    return this.apiHelperService
      .get(this.endPointStaticScreens + moduleValue + flow + rol + params)
      .pipe(
        map((data: any) => data)
      )
  }
}
