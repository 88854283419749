import { Action, createReducer, on } from '@ngrx/store';
import { TemporaryActions } from './actions';
import { ISearchDto } from '../../data/interfaces/isearch-response';
import { ICustomTempResponse } from '../../data/interfaces/icustom-temp-response';
import { IStatement, IStatementResponse } from '../../data/interfaces/istatement';
import { IQuotaResponse } from '../../data/interfaces/iquota';
import { ICustomTempDocumentCreateResponse } from '../../data/interfaces/icustom-temp-document';
import { INotificationTemporaryResponse } from '../../data/interfaces/inotification';
import { IDeserializable } from '../../data/interfaces/ideserializable';

interface ITemporaryState {
  isLoading: boolean;
  error: any | null;
  searchResponseData: any | null;
  temporaryData: ISearchDto | null;
  statementData: IStatement | null;
  customTempResponseData: ICustomTempResponse | null;
  statementResponseData: IStatementResponse | null;
  updateStatementResponseData: IStatementResponse | null;
  deleteStatementResponseData: IStatementResponse | null;
  quotaResponseData: IQuotaResponse | null;
  updateQuotaResponseData: IQuotaResponse | null;
  fileExcelCustomStatementTempFile: any | null;
  documentTypesData: any | null;
  customTempsDocumentsData: ICustomTempDocumentCreateResponse | null;
  createCustomTempDocumentData: ICustomTempDocumentCreateResponse | null;
  updateCustomTempDocumentData: ICustomTempDocumentCreateResponse | null;
  deleteCustomTempDocumentData: any | null;
  postFileProceduresData: any | null;
  deleteFileProceduresData: any | null;
  approvalCustomStatementTempsData: any | null;
  inconsistencieCustomStatementTempsData: any | null;
  notificationsData: INotificationTemporaryResponse | null;
}
class TemporaryState implements ITemporaryState, IDeserializable {
  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
  isLoading!: boolean;
  error!: any;
  searchResponseData!: any;
  temporaryData!: ISearchDto | null;
  statementData!: IStatement | null;
  customTempResponseData!: ICustomTempResponse | null;
  statementResponseData!: IStatementResponse | null;
  updateStatementResponseData!: IStatementResponse | null;
  deleteStatementResponseData!: IStatementResponse | null;
  quotaResponseData!: IQuotaResponse | null;
  updateQuotaResponseData!: IQuotaResponse | null;
  fileExcelCustomStatementTempFile!: any;
  documentTypesData!: any;
  customTempsDocumentsData!: ICustomTempDocumentCreateResponse | null;
  createCustomTempDocumentData!: ICustomTempDocumentCreateResponse | null;
  updateCustomTempDocumentData!: ICustomTempDocumentCreateResponse | null;
  deleteCustomTempDocumentData!: any;
  postFileProceduresData!: any;
  deleteFileProceduresData!: any;
  approvalCustomStatementTempsData!: any;
  inconsistencieCustomStatementTempsData!: any;
  notificationsData!: INotificationTemporaryResponse | null;

}
const initialState: ITemporaryState = {
  isLoading: false,
  error: null,
  searchResponseData: null,
  temporaryData: null,
  statementData: null,
  customTempResponseData: null,
  statementResponseData: null,
  updateStatementResponseData: null,
  deleteStatementResponseData: null,
  quotaResponseData: null,
  updateQuotaResponseData: null,
  fileExcelCustomStatementTempFile: null,
  documentTypesData: null,
  customTempsDocumentsData: null,
  createCustomTempDocumentData: null,
  updateCustomTempDocumentData: null,
  deleteCustomTempDocumentData: null,
  postFileProceduresData: null,
  deleteFileProceduresData: null,
  approvalCustomStatementTempsData: null,
  inconsistencieCustomStatementTempsData: null,
  notificationsData: null,
};

const featureReducer = createReducer(
  initialState,
  on(TemporaryActions.onError, (state, { error }) => ({
    ...updateFlags(state),
    error,
  })),
  on(TemporaryActions.onResetFlags, (state) => ({
    ...updateFlags(initialState),
  })),

  on(TemporaryActions.onResetProperty, (state, { nameProperty }) => ({
    ...resetFlags(state, nameProperty)
  })),
  on(TemporaryActions.setTemporary, (state, { temporaryData }) => ({
    ...updateFlags(state),
    temporaryData,
    searchResponseData: null
  })),

  on(TemporaryActions.setStatement, (state, { statementData }) => ({
    ...updateFlags(state),
    statementData,
  })),

  on(TemporaryActions.getImports, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    // searchResponseData: null
  })),

  on(TemporaryActions.getImportsSuccess, (state, { searchResponseData }) => ({
    ...updateFlags(state),
    searchResponseData,
  })),

  on(TemporaryActions.getCustomStatementTemps, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    customTempResponseData: null
  })),

  on(TemporaryActions.getCustomStatementTempsSuccess, (state, { customTempResponseData }) => ({
    ...updateFlags(state),
    customTempResponseData,
  })),

  on(TemporaryActions.createCustomStatementTemp, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    statementResponseData: null
  })),

  on(TemporaryActions.createCustomStatementTempSuccess, (state, { statementResponseData }) => ({
    ...updateFlags(state),
    statementResponseData,
  })),

  on(TemporaryActions.updateCustomStatementTemp, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    updateStatementResponseData: null
  })),

  on(TemporaryActions.updateCustomStatementTempSuccess, (state, { updateStatementResponseData }) => ({
    ...updateFlags(state),
    updateStatementResponseData,
  })),

  on(TemporaryActions.deleteCustomStatementTemp, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    deleteStatementResponseData: null
  })),

  on(TemporaryActions.deleteCustomStatementTempSuccess, (state, { deleteStatementResponseData }) => ({
    ...updateFlags(state),
    deleteStatementResponseData,
  })),

  on(TemporaryActions.getQuotas, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    quotaResponseData: null
  })),

  on(TemporaryActions.getQuotasSuccess, (state, { quotaResponseData }) => ({
    ...updateFlags(state),
    quotaResponseData,
  })),
  on(TemporaryActions.updateQuotas, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    updateQuotaResponseData: null
  })),

  on(TemporaryActions.updateQuotasSuccess, (state, { updateQuotaResponseData }) => ({
    ...updateFlags(state),
    updateQuotaResponseData,
  })),
  on(TemporaryActions.getDocumentTypes, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    documentTypesData: null
  })),
  on(TemporaryActions.getDocumentTypesSuccess, (state, { documentTypesData }) => ({
    ...updateFlags(state),
    documentTypesData,
  })),
  on(TemporaryActions.getCustomTempsDocuments, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    customTempsDocumentsData: null
  })),
  on(TemporaryActions.getCustomTempsDocumentsSuccess, (state, { customTempsDocumentsData }) => ({
    ...updateFlags(state),
    customTempsDocumentsData,
  })),
  on(TemporaryActions.createCustomTempDocument, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    createCustomTempDocumentData: null
  })),
  on(TemporaryActions.createCustomTempDocumentSuccess, (state, { createCustomTempDocumentData }) => ({
    ...updateFlags(state),
    createCustomTempDocumentData,
  })),
  on(TemporaryActions.updateCustomTempDocument, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    updateCustomTempDocumentData: null
  })),
  on(TemporaryActions.updateCustomTempDocumentSuccess, (state, { updateCustomTempDocumentData }) => ({
    ...updateFlags(state),
    updateCustomTempDocumentData,
  })),
  on(TemporaryActions.deleteCustomTempDocument, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    deleteCustomTempDocumentData: null
  })),
  on(TemporaryActions.deleteCustomTempDocumentSuccess, (state, { deleteCustomTempDocumentData }) => ({
    ...updateFlags(state),
    deleteCustomTempDocumentData,
  })),
  on(TemporaryActions.postFileProceduresData, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    postFileProceduresData: null
  })),
  on(TemporaryActions.postFileProceduresDataSuccess, (state, { postFileProceduresData }) => ({
    ...updateFlags(state),
    postFileProceduresData,
  })),
  on(TemporaryActions.deleteFileProceduresData, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    deleteFileProceduresData: null
  })),
  on(TemporaryActions.deleteFileProceduresDataSuccess, (state, { deleteFileProceduresData }) => ({
    ...updateFlags(state),
    deleteFileProceduresData,
  })),
  on(TemporaryActions.approvalCustomStatementTemps, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    approvalCustomStatementTempsData: null
  })),
  on(TemporaryActions.approvalCustomStatementTempsSuccess, (state, { approvalCustomStatementTempsData }) => ({
    ...updateFlags(state),
    approvalCustomStatementTempsData,
  })),
  on(TemporaryActions.inconsistencieCustomStatementTemps, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    inconsistencieCustomStatementTempsData: null
  })),
  on(TemporaryActions.inconsistencieCustomStatementTempsSuccess, (state, { inconsistencieCustomStatementTempsData }) => ({
    ...updateFlags(state),
    inconsistencieCustomStatementTempsData,
  })),
  on(TemporaryActions.getNotifications, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    notificationsData: null
  })),
  on(TemporaryActions.getNotificationsSuccess, (state, { notificationsData }) => ({
    ...updateFlags(state),
    notificationsData,
  })),
);

const updateFlags = (state: ITemporaryState): ITemporaryState => {
  return {
    ...state,
    error: null,
    isLoading: false,
  };
};
function resetFlags(state: ITemporaryState, nameProperty: string): ITemporaryState {
  state = new TemporaryState().deserialize(state);
  state[nameProperty as keyof typeof state] = null;
  return {
    ...state
  };
};
function reducer(state: ITemporaryState | undefined, action: Action) {
  return featureReducer(state, action);
}

const getError = (state: ITemporaryState) => state.error;
const getLoading = (state: ITemporaryState) => state.isLoading;
const searchResponseData = (state: ITemporaryState) => state.searchResponseData;
const getTemporaryData = (state: ITemporaryState) => state.temporaryData;
const getStatementData = (state: ITemporaryState) => state.statementData;
const getCustomTempResponseData = (state: ITemporaryState) => state.customTempResponseData;
const getCreateCustomStatementTempData = (state: ITemporaryState) => state.statementResponseData;
const getUpdateCustomStatementTempData = (state: ITemporaryState) => state.updateStatementResponseData;
const getDeleteCustomStatementTempData = (state: ITemporaryState) => state.deleteStatementResponseData;
const getQuotasData = (state: ITemporaryState) => state.quotaResponseData;
const getUpdateQuotasData = (state: ITemporaryState) => state.updateQuotaResponseData;
const getFileExcelCustomStatementTempFile = (state: ITemporaryState) => state.fileExcelCustomStatementTempFile;
const getDocumentTypesData = (state: ITemporaryState) => state.documentTypesData;
const getCustomTempsDocumentsData = (state: ITemporaryState) => state.customTempsDocumentsData;
const getCreateCustomTempDocumentData = (state: ITemporaryState) => state.createCustomTempDocumentData;
const setUpdateCustomTempDocumentData = (state: ITemporaryState) => state.updateCustomTempDocumentData;
const getDeleteCustomTempDocumentData = (state: ITemporaryState) => state.deleteCustomTempDocumentData;
const getPostFileProceduresData = (state: ITemporaryState) => state.postFileProceduresData;
const getDeleteFileProceduresData = (state: ITemporaryState) => state.deleteFileProceduresData;
const getApprovalCustomStatementTempsData = (state: ITemporaryState) => state.approvalCustomStatementTempsData;
const getInconsistencieCustomStatementTempsData = (state: ITemporaryState) => state.inconsistencieCustomStatementTempsData;
const getNotificationsData = (state: ITemporaryState) => state.notificationsData;

export const TemporaryReducer = {
  searchResponseData,
  customTempResponseData: getCustomTempResponseData,
  createCustomStatementTempData: getCreateCustomStatementTempData,
  updateCustomStatementTempData: getUpdateCustomStatementTempData,
  deleteCustomStatementTempData: getDeleteCustomStatementTempData,
  reducer,
  getError,
  getLoading,
  temporaryData: getTemporaryData,
  statementData: getStatementData,
  quotasData: getQuotasData,
  updateQuotasData: getUpdateQuotasData,
  fileExcelCustomStatementTempFile: getFileExcelCustomStatementTempFile,
  documentTypesData: getDocumentTypesData,
  customTempsDocumentsData: getCustomTempsDocumentsData,
  createCustomTempDocumentData: getCreateCustomTempDocumentData,
  updateCustomTempDocumentData: setUpdateCustomTempDocumentData,
  deleteCustomTempDocumentData: getDeleteCustomTempDocumentData,
  postFileProceduresData: getPostFileProceduresData,
  deleteFileProceduresData: getDeleteFileProceduresData,
  approvalCustomStatementTempsData: getApprovalCustomStatementTempsData,
  inconsistencieCustomStatementTempsData: getInconsistencieCustomStatementTempsData,
  notificationsData: getNotificationsData,
};

export { ITemporaryState };
