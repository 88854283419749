import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { APIS_URL } from '@constants/url';
import { ApiHelperService } from '@services/api-helper/api-helper.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IInconsistencie } from 'src/app/modules/master/temporary/data/interfaces/Inconsistencie';
import { ICustomTempDocumentCreate, ICustomTempDocumentCreateResponse, ICustomTempDocumentUpdate } from 'src/app/modules/master/temporary/data/interfaces/icustom-temp-document';
import { ICustomTempResponse } from 'src/app/modules/master/temporary/data/interfaces/icustom-temp-response';
import { INotificationTemporaryResponse } from 'src/app/modules/master/temporary/data/interfaces/inotification';
import { IQuota, IQuotaResponse } from 'src/app/modules/master/temporary/data/interfaces/iquota';
import { ISearchBody } from 'src/app/modules/master/temporary/data/interfaces/isearch-body';
import { ISearchResponse } from 'src/app/modules/master/temporary/data/interfaces/isearch-response';
import { IStatement, IStatementResponse } from 'src/app/modules/master/temporary/data/interfaces/istatement';

@Injectable({
  providedIn: 'root',
})
export class TemporaryService {
  private endPointTemporary = APIS_URL.temporary;
  private endPointQuotas = APIS_URL.quotas;
  private endPointProcedures = APIS_URL.impProcedures;
  constructor(
    private apiHelperService: ApiHelperService,
  ) {
  }
  getNotifications(): Observable<INotificationTemporaryResponse> {
    const dateNow = formatDate(Date.now(),'yyyy-MM-dd','en-US');
    const params = `/cuotasToDue?dateRequest=${dateNow}`;
    return this.apiHelperService
      .get(`${this.endPointQuotas}${params}`);
  }
  createCustomTempDocument(importNumber: string, declarationNumber: string, customTempDocument: ICustomTempDocumentCreate): Observable<ICustomTempDocumentCreateResponse> {
    const params = `/customsTemps?importNumber=${importNumber}&declarationNumber=${declarationNumber}`;
    return this.apiHelperService
      .post(`${this.endPointProcedures}${params}`, customTempDocument);
  }
  updateCustomTempDocument(formalitiesDocid: number, customTempDocument: ICustomTempDocumentUpdate): Observable<ICustomTempDocumentCreateResponse> {
    const params = `?formalitiesDocid=${formalitiesDocid}`;
    return this.apiHelperService
      .put(`${this.endPointProcedures}${params}`, customTempDocument);
  }
  deleteCustomTempDocument(formalitiesDocid: number,): Observable<any> {
    const params = `/by-id/${formalitiesDocid}`;
    return this.apiHelperService
      .delete(`${this.endPointProcedures}${params}`);
  }
  getImports(SearchBody: ISearchBody, page: number, size: number, isClosure: boolean): Observable<ISearchResponse> {
    const closure = isClosure ? "/lp" : "";
    const params = `${closure}/filter-by-date?page=${page}&size=${size}`;
    return this.apiHelperService
      .post(`${this.endPointTemporary}${params}`, SearchBody);
  }
  createCustomStatementTemp(importNumber: string, statement: IStatement): Observable<IStatementResponse> {
    const params = `?iNum=${importNumber}`;
    return this.apiHelperService
      .post(`${this.endPointTemporary}${params}`, statement);
  }
  updateCustomStatementTemp(customTempId: number, statement: IStatement): Observable<IStatementResponse> {
    const params = `?customTempId=${customTempId}`;
    return this.apiHelperService
      .put(`${this.endPointTemporary}${params}`, statement);
  }
  deleteCustomStatementTemp(customTempId: number): Observable<IStatementResponse> {
    const params = `/inactive?customTempId=${customTempId}`;
    return this.apiHelperService
      .patch(`${this.endPointTemporary}${params}`);
  }
  getCustomStatementTemps(importNumber: string, closure: boolean, page: number, size: number): Observable<ICustomTempResponse> {
    const isClosure = closure ? "LP" : "";
    const params = `/filter${isClosure}-by-importNumber?page=${page}&size=${size}&iNumber=${importNumber}`;
    return this.apiHelperService
      .get(`${this.endPointTemporary}${params}`);
  }
  approvalCustomStatementTemps(customTempId: number): Observable<any> {
    const params = `?customTempId=${customTempId}`;
    return this.apiHelperService
      .put(`${this.endPointTemporary}${params}`,
        {
          estado: "Cerrada"
        }
      );
  }
  inconsistencieCustomStatementTemps(inconsistencie: IInconsistencie): Observable<any> {
    const params = `/send-inconsistencies`;
    return this.apiHelperService
      .post(`${this.endPointTemporary}${params}`, inconsistencie);
  }
  getCustomTempsDocuments(declarationNumber: string): Observable<ICustomTempDocumentCreateResponse> {
    const params = `/filter-by-declaration?declarationNumber=${declarationNumber}`;
    return this.apiHelperService
      .get(`${this.endPointProcedures}${params}`);
  }
  getQuotas(declarationNumb: number): Observable<IQuotaResponse> {
    const params = `/by-DeclarationNumber?declarationNumb=${declarationNumb}`;
    return this.apiHelperService
      .get(`${this.endPointQuotas}${params}`);
  }
  updateQuotas(quotaId: number, quota: IQuota): Observable<IQuotaResponse> {
    const params = `?quotaId=${quotaId}`;
    return this.apiHelperService
      .put(`${this.endPointQuotas}${params}`, quota);
  }
  getFileExcelCustomStatementTemp(importNumber: string, fileName: string): Observable<any> {
    const params = `/report/excel?importNumber=${importNumber}`;
    return this.apiHelperService
      .downloadFile(`${this.endPointTemporary}${params}`, fileName);
  }
  getFileExcelQuotas(declarationNumb: string, fileName: string): Observable<any> {
    const params = `/report/excel?declarationNumb=${declarationNumb}`;
    return this.apiHelperService
      .downloadFile(`${this.endPointQuotas}${params}`, fileName);
  }
  getDocumentTypes(): Observable<any> {
    return this.apiHelperService
      .getJSONV2("./assets/temporary.json")
      .pipe(
        map((responseJson: any) =>
          responseJson.documentTypes
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  postFileProceduresData(importNumber: string, body: any): Observable<any> {
    const params = `/addDocuments?importNumber=${importNumber}`;
    return this.apiHelperService.post(
      this.endPointProcedures + params,
      body
    ).pipe(map((data: any) => data));
  }
  deleteFileProceduresData(id: number, updateAt: string): Observable<any> {
    const params = `/deleteFile/by-id?id=${id}&updateAt=${updateAt}`;
    return this.apiHelperService.delete(this.endPointProcedures + params).pipe(
      map((data: any) => data.Message)
    );
  }
}
