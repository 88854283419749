import { AdministerRolesStoreEffects } from 'src/app/modules/master/general/components/administer-roles-store/effects';
import { ImportationsEffects } from 'src/app/modules/master/general/components/import-store/effects';
import { LiquidationEffects } from 'src/app/modules/master/general/components/liquidation-store/effects';
import { PaymentEffects } from 'src/app/modules/master/logistics-coordination/components/payments/componentes/payments-store/effects';
import { AlliesEffects } from 'src/app/modules/master/quotation/admin/components/allies-store/effects';
import { BlogMessagesStoreEffects } from 'src/app/modules/master/quotation/blog-messages-store/effects';
import { CotizationsDetailsEffects } from 'src/app/modules/master/quotation/commercial/components/cotizations-details-store/effects';
import { CotizationsEffects } from 'src/app/modules/master/quotation/commercial/components/cotizations-store/effects';
import { NewCustomerStoreEffects } from 'src/app/modules/master/quotation/commercial/components/new-customer/new-customer-store/effects';
import { PostProductionStoreEffects } from 'src/app/modules/master/quotation/post-production-store/effects';
import { TemporaryEffects } from 'src/app/modules/master/temporary/components/temporary-store/effects';

export const effects = [
  NewCustomerStoreEffects,
  CotizationsEffects,
  CotizationsDetailsEffects,
  AlliesEffects,
  ImportationsEffects,
  BlogMessagesStoreEffects,
  LiquidationEffects,
  PostProductionStoreEffects,
  AdministerRolesStoreEffects,
  PaymentEffects,
  TemporaryEffects,
];
