import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ICotization } from '../../../data/interfaces/ICotization.interface';
import { CotizationsFacadeService } from '../../../modules/master/quotation/commercial/components/cotizations-store/cotizations.facade.service';
import { BcTableOptionMenu, BcTableActionButton } from 'bancolombia-design-system/bc-behaviors';
import { IDynamicTable } from 'src/app/data/interfaces/IDynamicTable';

@Component({
  selector: 'app-bcs-dynamic-table',
  templateUrl: './bcs-dynamic-table.component.html',
  styleUrls: ['./bcs-dynamic-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BcsDynamicTableComponent implements OnInit {

  tableDisplayColumns: any[] = ['uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete']
  tableColums: IDynamicTable[] = [];
  dataSource: any = [];

  @Input() tableTitle: string = 'Solicitudes de cotización';
  @Input() tableData: any | null = []; //any se cambia por el tipo de data
  @Input() scroll: boolean = false;
  @Input() set columns(columns: IDynamicTable[]) {
    this.tableColums = columns;
    this.tableDisplayColumns = this.tableColums.map(col => col.label)
  }

  movements: any[] = [[], [], []];
  tableAccCurrentPage: any = [];
  tableAccCurrentPageEmpty = [];
  dataActionClicked: any = null;

  _pages: any[] = [
    [
      { name: 'Cristian', lastname: 'Vargas', email: 'test@gmail.com', status: 'active' },
      { name: 'Felipe', lastname: 'Vargas', email: 'test@gmail.com', status: 'active' },
      { name: 'Luis', lastname: 'Vargas', email: 'test@gmail.com', status: 'active' },
      { name: 'Tomas', lastname: 'Vargas', email: 'test@gmail.com', status: 'active' },
      { name: 'Caro', lastname: 'Vargas', email: 'test@gmail.com', status: 'active' },
    ],
    [
      { name: 'Marce', lastname: 'Vargas', email: 'test@gmail.com', status: 'active' },
      { name: 'Heli', lastname: 'Vargas', email: 'test@gmail.com', status: 'active' },
      { name: 'Cristian', lastname: 'Vargas', email: 'test@gmail.com', status: 'active' },
      { name: 'Cristian', lastname: 'Vargas', email: 'test@gmail.com', status: 'active' },
      { name: 'Felipe', lastname: 'Vargas', email: 'test@gmail.com', status: 'active' },
    ],
    [
      { name: 'Luis', lastname: 'Vargas', email: 'test@gmail.com', status: 'active' },
      { name: 'Tomas', lastname: 'Vargas', email: 'test@gmail.com', status: 'active' },
      { name: 'Caro', lastname: 'Vargas', email: 'test@gmail.com', status: 'active' },
      { name: 'Marce', lastname: 'Vargas', email: 'test@gmail.com', status: 'active' },
      { name: 'Heli', lastname: 'Vargas', email: 'test@gmail.com', status: 'active' },
    ],
    [{ name: 'Luis', lastname: 'Vargas', email: 'test@gmail.com', status: 'active' }],
  ];

  dropdownOptions: BcTableOptionMenu[] = [];

  actions: BcTableActionButton[] = [
    { icon: 'download-document', text: 'EXPORTAR', id: 'export' },
    { icon: 'remove', text: 'ELIMINAR', id: 'delete' },
    { icon: 'house', text: 'PRUEBA', id: 'prueba' },
  ];

  dropdownOptions2: any[] = [
    { id: 'opt1', text: 'Opción 1', icon: 'search', action: this.optionSelected },
    { id: 'opt2', text: 'Opción 2', icon: 'search', action: this.optionSelected },
    { id: 'opt3', text: 'Opción 3', icon: 'search', action: this.optionSelected },
    { id: 'opt4', text: 'Opción 4', icon: 'search', action: this.optionSelected },
  ];

  dropdownOptions3: any[] = [
    { id: '_opt1', text: 'Opción 1', icon: 'search', action: this.optionSelected },
    { id: '_opt2', text: 'Opción 2', icon: 'search', action: this.optionSelected },
  ];

  activeOptions: BcTableActionButton[] = [
    { id: 'lock', icon: 'lock', text: 'Bloquear' },
    { id: 'remove', icon: 'remove', text: 'Eliminar' },
    { id: 'detail', icon: 'detail', text: 'Detalle' },
  ];

  disabledOptions: BcTableActionButton[] = [
    { id: 'unlock', icon: 'unlock', text: 'Desbloquear' },
  ];

  pages: Array<any> = [];
  currentPage: any[] = [];

  constructor(private cotizationsFacadeService: CotizationsFacadeService) {
    this.currentPage = this._pages[0];
    this.tableAccCurrentPage = this.movements[0];
  }

  ngOnInit(): void {
    // This is intentional
  }

  optionSelected(data: any) {
    if (data.optionSeleted.indexOf('_') !== -1) {
      for (const row of this.tableAccCurrentPage) {
        if (row === data.rowData) {
          row.name = 'EDITED'
          return (row.name);
        }
      }
    }
    return null;
  }

  rowSelected(data: any) {
    return data;
  }

  export(rows: any) {
    return rows;
  }

  delete(rows: any) {
    return rows;
  }

  onChangePage(data: any) {
    this.tableAccCurrentPage = this.movements[data.CurrentPage - 1];
  }

  onChangePageEmpty(data: any) {
    this.tableAccCurrentPageEmpty = this.movements[data.CurrentPage - 1];
  }

  onChangePageTableScroll(data: any) {
    this.currentPage = this._pages[data.CurrentPage - 1];
  }

  getOptionsByStatus(status: string): any {
    let opt = this.activeOptions;
    if (status === 'disabled') {
      opt = this.disabledOptions;
    }
    return opt;
  }

  optionSelectedScroll(data: any) {
    if (data.optionSeleted === 'lock') {
      data.rowData.status = 'disabled';
    } else {
      data.rowData.status = 'active';
    }
  }

  onClickAction(data: any) {
    this.dataActionClicked = data;
  }

  selected(cotization: ICotization) {
    this.cotizationsFacadeService.setCotization(cotization);
    this.cotizationsFacadeService.onEdit(true);
  }

}
