<h5 class="mt-5">
  Medio de pago #1
</h5>

<mat-divider class="mb-4 mt-1"></mat-divider>

<form class="mt-5" form="" autocomplete="off">
  <div class="bc-row p-2">
    <div class="col-1"></div>
    <div class="col-4">
      <bcs-select-box class="w-100" Label="Medio de pago" nombreId="Id"></bcs-select-box>
    </div>
    <div class="col-2"></div>
    <div class="col-4">
      <bcs-select-box class="w-100" Label="Opciones de medio de pago" nombreId="Id"></bcs-select-box>
    </div>
    <div class="col-4">
      <bcs-select-box class="w-100" Label="Forma de utilización" nombreId="Id"></bcs-select-box>
    </div>
    <div class="col-1"></div>
  </div>

  <div class="bc-row p-2">
    <div class="col-1"></div>
    <div class="col-4">
      <bcs-select-box class="w-100" Label="Documento acordado" nombreId="Id"></bcs-select-box>
    </div>
    <div class="col-2"></div>
    <div class="col-4">
      <bcs-text-box class="w-100" Label="Número de días acordado" Type="email"></bcs-text-box>
    </div>
    <div class="col-1"></div>
  </div>

  <div class="bc-row p-2">
    <div class="col-1"></div>
    <div class="col-4">
      <bcs-text-box class="w-100" Label="Porcentaje de desembolso" Type="text"></bcs-text-box>
    </div>
    <div class="col-2"></div>
    <div class="col-4">
      <bcs-text-box class="w-100" Label="Valor a pagar" Type="email"></bcs-text-box>
    </div>
    <div class="col-1"></div>
  </div>
</form>
