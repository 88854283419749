
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { MessagePostService } from '../../../../data/services/apis/message-post/message-post.service';
import { BlogMessagesActions } from './actions';

@Injectable()
export class BlogMessagesStoreEffects {

  constructor(
    private messagePostService: MessagePostService,
    private actions$: Actions,
  ) {
    // This is intentional
  }

  listBlogMessagesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogMessagesActions.listBlogMessages),
      switchMap(({ criterianDescription, criterianNumber }) =>
        this.messagePostService.listBlogMessages(criterianDescription, criterianNumber)
          .pipe(
            map((blogMessages) =>
              BlogMessagesActions.listBlogMessagesSuccess({ blogMessages })
            ),
            catchError((error) => of(BlogMessagesActions.onError(error)))
          )
      )
    )
  );

  createMessageDataEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogMessagesActions.createMessageData),
      switchMap(({ cotizationId, importNumber, blogMessageData }) =>
        this.messagePostService.createMessagePost(cotizationId, importNumber, blogMessageData)
          .pipe(
            map((blogMessagesData) => BlogMessagesActions.createMessageDataSuccess({ blogMessagesData })),
            catchError((error) => of(BlogMessagesActions.onError(error)))
          )
      )
    )
  );

  updateBlogMessagesDataEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogMessagesActions.updateBlogMessagesData),
      switchMap(({ messageId, blogMessageUpData }) =>
        this.messagePostService.updateMessagePost(messageId, blogMessageUpData).pipe(
          map((message) =>
            BlogMessagesActions.updateBlogMessagesDataSuccess({ message })
          ),
          catchError((error) => of(BlogMessagesActions.onError(error)))
        )
      )
    )
  );
}
