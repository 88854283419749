import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../../../data/services/auth/auth.service';
import { SessionTimeService } from '../../../../data/services/session-time/session-time.service';
import { Numbers } from '../../../../core/constants/numbers';
import { ProfileFacadeService } from 'src/app/modules/master/profile-store/profile.facade.service';
import { ModalResponse } from 'src/app/core/enums/modal.enum';

@Component({
  selector: 'session-time',
  templateUrl: './session-time.component.html',
  styleUrls: ['./session-time.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SessionTimeComponent implements OnInit {

  @Input() titulo: string = 'Sesión';
  @Input() mensaje: string = 'Su sesión expirará en:';
  @Input() LabelButton: string = 'Ok';
  @Input() typeModal: string = 'ok';
  @Input() urlSrc!: string;
  @Input() Icon: string = 'time';
  @Input() textButton: string = 'Continuar';
  @Output() response: EventEmitter<string> = new EventEmitter();

  countdown: number = Numbers._120;
  percentaje: number = Numbers._0;
  timeInterval: any;

  constructor(
    private authService: AuthService,
    private sessionTimeService: SessionTimeService,
    private profileFacadeService: ProfileFacadeService,
  ) {
    // This is intentional
  }

  ngOnInit(): void {
    this.time();
  }

  onClick() {
    this.sessionTimeService.refreshToken().subscribe(
      (data) => {
        this.profileFacadeService.setTokenSession(data.Authorization);
        this.sessionTimeService.startSessionTime();
        clearInterval(this.timeInterval);
        this.response.emit(ModalResponse.CONTINUAR);
      },
      (error) => {
        this.response.emit('close');
      }
    );
  }

  close() {
    this.onClick()
  }

  time() {
    const timeTemp = this.countdown;
    this.timeInterval = setInterval(() => {
      this.countdown--;
      this.percentaje = (this.countdown / timeTemp) * Numbers._100;
      if (this.countdown <= 0) {
        this.percentaje = Numbers._0;
        this.logOut();
        clearInterval(this.timeInterval);
      }
    }, Numbers._1000);
  }

  logOut() {
    this.authService.logOut();
  }
}
