import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'bcs-card',
  templateUrl: './bcs-card.component.html',
  styleUrls: ['./bcs-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BcsCardComponent implements OnInit {

  @Input() title: string = '';

  constructor() {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

}
