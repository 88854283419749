<div class="wrapper" id="modal-info">
  <div class="bsc-header-modal">
    <span (click)="closeModal()" class="close">
      <bc-icon name="error" aria-label="close"></bc-icon>
    </span>
  </div>
  <div class="bsc-body-modal">
    <ng-container *ngIf="!showUploadFile"
      ><app-table-temporary
        class="w-100"
        title="Documentos adjuntos {{ CustomTempDocument?.documentType }}"
        [isPaginator]="false"
        [columns]="columnsDocument"
        [data]="filesfilter"
        [isDeleteBtn]="CustomTempDocument!.editable"
        [isDownload]="true"
        (DeleteRow)="deleteRowDocument($event)"
        (DownloadRow)="downloadRowDocument($event)"
      ></app-table-temporary>
    </ng-container>
    <ng-container *ngIf="showUploadFile">
      <div class="drop-files-box">
        <bsc-files
          [description]="''"
          [maxFiles]="20"
          (onFilesChange)="getfiles($event, 'support')"
        ></bsc-files>
      </div>
    </ng-container>
  </div>
  <div class="bsc-footer-modal">
    <div class="row p-4 d-flex justify-content-end align-content-end">
      <bcs-button
      *ngIf="CustomTempDocument?.editable"
        TypeButton="secondary"
        IconPosition="Left"
        Label="Adjuntar documento"
        Icon="file_upload"
        (click)="uploadFile()"
      ></bcs-button>
      <bcs-button
        TypeButton="secondary"
        Label="Regresar"
        (click)="closeModal()"
      ></bcs-button>
    </div>
  </div>
</div>
