import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TemporaryService } from '@services/apis/temporary/temporary.service';
import { TemporaryActions } from './actions';

@Injectable()
export class TemporaryEffects {
  constructor(
    private temporaryService: TemporaryService,
    private actions$: Actions
  ) {
    // This is intentional
  }

  getCustomStatementTempsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.getCustomStatementTemps),
      switchMap(({ importNumber, closure, page, size }) =>
        this.temporaryService.getCustomStatementTemps(importNumber, closure, page, size).pipe(
          map((response) =>
            TemporaryActions.getCustomStatementTempsSuccess({ customTempResponseData: response })
          ),
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );

  getImportsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.getImports),
      switchMap(({ SearchBody, page, size, isClosure }) =>
        this.temporaryService.getImports(SearchBody, page, size, isClosure).pipe(
          map((response) =>
            TemporaryActions.getImportsSuccess({ searchResponseData: response })
          ),
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );

  createCustomStatementTempEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.createCustomStatementTemp),
      switchMap(({ importNumber, statement }) =>
        this.temporaryService.createCustomStatementTemp(importNumber, statement).pipe(
          map((response) =>
            TemporaryActions.createCustomStatementTempSuccess({ statementResponseData: response })
          ),
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );

  updateCustomStatementTempEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.updateCustomStatementTemp),
      switchMap(({ customTempId, statement }) =>
        this.temporaryService.updateCustomStatementTemp(customTempId, statement).pipe(
          map((response) =>
            TemporaryActions.updateCustomStatementTempSuccess({ updateStatementResponseData: response })
          ),
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );

  deleteCustomStatementTempEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.deleteCustomStatementTemp),
      switchMap(({ customTempId }) =>
        this.temporaryService.deleteCustomStatementTemp(customTempId).pipe(
          map((response) =>
            TemporaryActions.deleteCustomStatementTempSuccess({ deleteStatementResponseData: response })
          ),
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );

  getQuotasEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.getQuotas),
      switchMap(({ declarationNumb }) =>
        this.temporaryService.getQuotas(declarationNumb).pipe(
          map((response) =>
            TemporaryActions.getQuotasSuccess({ quotaResponseData: response })
          ),
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );

  updateQuotasEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.updateQuotas),
      switchMap(({ quotaId, quota }) =>
        this.temporaryService.updateQuotas(quotaId, quota).pipe(
          map((response) =>
            TemporaryActions.updateQuotasSuccess({ updateQuotaResponseData: response })
          ),
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );

  getFileExcelCustomStatementTempEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.getFileExcelCustomStatementTemp),
      switchMap(({ importNumber, fileName }) =>
        this.temporaryService.getFileExcelCustomStatementTemp(importNumber, fileName).pipe(
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );

  getFileExcelQuotasEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.getFileExcelQuotas),
      switchMap(({ declarationNumb, fileName }) =>
        this.temporaryService.getFileExcelQuotas(declarationNumb, fileName).pipe(
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );

  getDocumentTypesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.getDocumentTypes),
      switchMap(() =>
        this.temporaryService.getDocumentTypes().pipe(
          map((response) =>
            TemporaryActions.getDocumentTypesSuccess({ documentTypesData: response })
          ),
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );

  getCustomTempsDocumentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.getCustomTempsDocuments),
      switchMap(({ declarationNumber }) =>
        this.temporaryService.getCustomTempsDocuments(declarationNumber).pipe(
          map((response) =>
            TemporaryActions.getCustomTempsDocumentsSuccess({ customTempsDocumentsData: response })
          ),
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );

  createCustomTempDocumentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.createCustomTempDocument),
      switchMap(({ importNumber, declarationNumber, customTempDocument }) =>
        this.temporaryService.createCustomTempDocument(importNumber, declarationNumber, customTempDocument).pipe(
          map((response) =>
            TemporaryActions.createCustomTempDocumentSuccess({ createCustomTempDocumentData: response })
          ),
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );
  updateCustomTempDocumentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.updateCustomTempDocument),
      switchMap(({ formalitiesDocid, customTempDocument }) =>
        this.temporaryService.updateCustomTempDocument(formalitiesDocid, customTempDocument).pipe(
          map((response) =>
            TemporaryActions.updateCustomTempDocumentSuccess({ updateCustomTempDocumentData: response })
          ),
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );
  deleteCustomTempDocumentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.deleteCustomTempDocument),
      switchMap(({ formalitiesDocid }) =>
        this.temporaryService.deleteCustomTempDocument(formalitiesDocid).pipe(
          map((response) =>
            TemporaryActions.deleteCustomTempDocumentSuccess({ deleteCustomTempDocumentData: response })
          ),
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );
  postFileProceduresDataEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.postFileProceduresData),
      switchMap(({ importNumber, body }) =>
        this.temporaryService.postFileProceduresData(importNumber, body).pipe(
          map((response) =>
            TemporaryActions.postFileProceduresDataSuccess({ postFileProceduresData: response })
          ),
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );
  deleteFileProceduresDataEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.deleteFileProceduresData),
      switchMap(({ id, updateAt }) =>
        this.temporaryService.deleteFileProceduresData(id, updateAt).pipe(
          map((response) =>
            TemporaryActions.deleteFileProceduresDataSuccess({ deleteFileProceduresData: response })
          ),
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );
  approvalCustomStatementTempsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.approvalCustomStatementTemps),
      switchMap(({ customTempId }) =>
        this.temporaryService.approvalCustomStatementTemps(customTempId).pipe(
          map((response) =>
            TemporaryActions.approvalCustomStatementTempsSuccess({ approvalCustomStatementTempsData: response })
          ),
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );
  inconsistencieCustomStatementTempsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.inconsistencieCustomStatementTemps),
      switchMap(({ inconsistencie }) =>
        this.temporaryService.inconsistencieCustomStatementTemps(inconsistencie).pipe(
          map((response) =>
            TemporaryActions.inconsistencieCustomStatementTempsSuccess({ inconsistencieCustomStatementTempsData: response })
          ),
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );
  getNotificationsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemporaryActions.getNotifications),
      switchMap(() =>
        this.temporaryService.getNotifications().pipe(
          map((response) =>
            TemporaryActions.getNotificationsSuccess({ notificationsData: response })
          ),
          catchError((error) => of(TemporaryActions.onError({ error: error })))
        )
      )
    )
  );
}
