import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AlliesActions } from './actions';
import { AlliesService } from 'src/app/data/services/apis/allies/allies.service';

@Injectable()
export class AlliesEffects {

  constructor(
    private alliesService: AlliesService,
    private actions$: Actions
  ) {
    // This is intentional
  }

  /** Crear aliado */
  createAlliesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlliesActions.createAllies),
      switchMap(({ ally }) =>
        this.alliesService.createAlly(ally)
          .pipe(
            map((ally) => AlliesActions.createAlliesSuccess({ ally })),
            catchError((error) => of(AlliesActions.onError(error)))
          )
      )
    )
  );

  /** Editar aliado */
  editAlliesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlliesActions.editAlly),
      switchMap(({ allyId, allyData }) =>
        this.alliesService.editAlly(allyId, allyData)
          .pipe(
            map((allies) => AlliesActions.editAllySuccess({ allies })),
            catchError((error) => of(AlliesActions.onError(error)))
          )
      )
    )
  );

  /** Buscar aliados por nit */
  searchAllyNitEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlliesActions.getAllyNit),
      switchMap(({ nit }) =>
        this.alliesService.searchAllyNit(nit)
          .pipe(
            map((ally) => AlliesActions.getAllyNitSuccess({ ally })),
            catchError((error) => of(AlliesActions.onError(error)))
          )
      )
    )
  );

  /** Endpoint para eliminar aliados por ID */
  deactivateAllyIdEffect$ = createEffect(() =>
  this.actions$.pipe(
    ofType(AlliesActions.deactivateAllyId),
    switchMap(({ allyId }) =>
      this.alliesService.deleteAllyId(allyId)
        .pipe(
          map((allied) => AlliesActions.deactivateAllyIdSuccess({ allied })),
          catchError((error) => of(AlliesActions.onError(error)))
        )
    )
  )
);
}
