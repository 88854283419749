
import { createAction, props } from '@ngrx/store';
import { IMessagePost, IMessagePostPut } from 'src/app/data/interfaces/IMessagePost.interface';

enum BlogMessagesActionType {
  searchBlogMessagess = '[BlogMessages Component] Search BlogMessages',
  searchBlogMessagessSuccess = '[BlogMessages Component] Search BlogMessages Success',
  createMessageDataActionType = '[BlogMessages Component] Create Message Data', /** Acción */
  createMessageDataSuccessActionType = '[BlogMessages Component] Create Message Data Success', /** Reacción */
  updateTotal = '[BlogMessages Component] Update Total',
  updateTotalSuccess = '[BlogMessages Component] Update Total Success',
  onResetFlagsActionType = '[BlogMessages Component] BlogMessages reset flags',
  error = '[BlogMessages Component] Ups Error',

  saveBlogMessagesData = '[BlogMessages Component] Save BlogMessages', /** Acción */
  saveBlogMessagesDataSuccess = '[BlogMessages Component] Save BlogMessages Success', /** Reacción */
  listBlogMessagesActionType = '[BlogMessages Component] List BlogMessages',
  listBlogMessagesSuccessActionType = '[BlogMessages Component] List BlogMessages Success',
  updateBlogMessagesDataActionType = '[BlogMessages Component] Update BlogMessages Data',
  updateBlogMessagesDataSuccessActionType = '[BlogMessages Component] Update BlogMessages Data Success',
  setBlogListActionType = '[BlogMessages Component] set List  BlogMessages in storage',
}

/** Listar mensajes */
const setBlogList = createAction(
  BlogMessagesActionType.setBlogListActionType,
  props<{ blogMessageList: IMessagePost[] }>()
);

/** Listar mensajes */
const listBlogMessages = createAction(
  BlogMessagesActionType.listBlogMessagesActionType,
  props<{ criterianDescription: string; criterianNumber: number }>()
);
const listBlogMessagesSuccess = createAction(
  BlogMessagesActionType.listBlogMessagesSuccessActionType,
  props<{ blogMessages: IMessagePost[] }>()
);

/** crear un mensaje */
const createMessageData = createAction(
  BlogMessagesActionType.createMessageDataActionType,
  props<{
    cotizationId: number;
    importNumber: string;
    blogMessageData: IMessagePost
  }>()
);
const createMessageDataSuccess = createAction(
  BlogMessagesActionType.createMessageDataSuccessActionType,
  props<{ blogMessagesData: IMessagePost }>()
);

/** Actualizar mensaje */
const updateBlogMessagesData = createAction(
  BlogMessagesActionType.updateBlogMessagesDataActionType,
  props<{
    messageId: number;
    blogMessageUpData: IMessagePostPut
  }>()
);
const updateBlogMessagesDataSuccess = createAction(
  BlogMessagesActionType.updateBlogMessagesDataSuccessActionType,
  props<{ message: any }>()
);

const onResetFlagsBlogMessage = createAction(
  BlogMessagesActionType.onResetFlagsActionType
);
const onError = createAction(
  BlogMessagesActionType.error,
  props<{ error: any }>()
);

export const BlogMessagesActions = {
  listBlogMessages,
  listBlogMessagesSuccess,
  createMessageData,
  createMessageDataSuccess,
  updateBlogMessagesData,
  updateBlogMessagesDataSuccess,
  onResetFlagsBlogMessage,
  onError,
  setBlogList,
}
