export const APIS_URL = {
  parameters: 'database/parameters',
  // Directorio Activo
  authAD: 'autenticacion/validator',
  // Viende desde La SVL
  authClient: 'autenticacion/validator/clients',
  clients: 'database/clients',
  cotizations: 'database/cotizations',
  cotizationsDetails: 'database/cotizationsDetails',
  currency: 'database/currency',
  files: 'database/files',
  dataReports: 'database/reports',
  allies: 'database/allies',
  localTransportContainers: 'database/LocalTransportTypeLoad',
  internationalTransportContainer: 'database/IntTransportContainer',
  internationalTransportCargaSuelta: 'database/IntTransportLoad',
  saveFilesDeclarations: 'database/customs-statements',
  saveFilesDeclarationsfiles: 'database/files/customs-statements',
  roles: 'database/roles',
  cities: 'database/cities',
  countries: 'database/countries',
  importations: 'database/imports',
  lCoordinationCustoms: 'database/customs',
  lCoordinationCustomsAssetses: 'database/customsAssets',
  lCoordinationCustomsNat: 'database/customs-nationalization',
  ImpLocalTransp: 'database/impLocalTransport',
  ImpIntTransp: 'database/impIntTransport',
  impProcedures: 'database/formalitiesDocuments',
  refresToken: 'autenticacion/refresh',
  messagePost: 'database/messagePost',
  messageGet: 'database/messagePost/findAllByParameter',
  messageGetDownload: 'database/messagePost/report/pdf',
  settlements: 'database/settlements',
  exchangeAudit: 'database/exchange-audit',
  formalitiesDocuments: 'database/formalitiesDocuments',
  commissionValue: 'database/commission-value',
  legalProvPolicies: 'database/legal-prov-policies',
  contractActivation: 'database/contract-activation',

  /** post-producción */
  cotizationsDetailsTransport: 'database/cotizationsDetailsTransport',
  cotizationCustoms: 'database/cotizationCustoms',
  cotizationCustomsFeasibility: 'database/cotizationCustomsFeasibility',
  payments: 'database/payments',
  termsAndConditions: 'database/termsAndConditions',
  temporary: 'database/customStatementTemps',
  quotas: 'database/quotas',
  paymentsChild: 'database/payment-child',
  mastersTypesPayments: "database/parameters/by-type/",
  modulesFlow: "database/parameters/by-type/",
  screens: "database/screens",
  staticScreens: "database/static-screens",
  users: "database/users",
};
