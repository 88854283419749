/**
 * Colección de constantes para controlar N° magic number
 * @since 29/12/2022
 */
const D1 = 1.000000001
const D0 = 0.001
const M1 = -1;
const N0 = 0;
const N0_015 = 0.015;
const N1 = 1;
const N1_5 = 1.5;
const N2 = 2;
const N3 = 3;
const N4 = 4;
const N5 = 5;
const N6 = 6;
const N7 = 7;
const N8 = 8;
const N9 = 9;
const N10 = 10;
const N11 = 11;
const N12 = 12;
const N13 = 13;
const N14 = 14;
const N15 = 15;
const N16 = 16;
const N17 = 17;
const N18 = 18;
const N19 = 19;
const N20 = 20;
const N21 = 21;
const N22 = 22;
const N23 = 23;
const N24 = 24;
const N25 = 25;
const N26 = 26;
const N29 = 29;
const N27 = 27;
const N28 = 28;
const N30 = 30;
const N31 = 31;
const N32 = 32;
const N33 = 33;
const N34 = 34;
const N36 = 36;
const N37 = 37;
const N39 = 39;
const N40 = 40;
const N41 = 41;
const N45 = 45;
const N48 = 48;
const N50 = 50;
const N57 = 57;
const N60 = 60;
const N63 = 63;
const N65 = 65;
const N68 = 68;
const N71 = 71;
const N74 = 74;
const N75 = 75;
const N76 = 76;
const N77 = 77;
const N78 = 78;
const N79 = 79;
const N80 = 80;
const N81 = 81;
const N82 = 82;
const N99 = 99;
const N100 = 100;
const N118 = 118;
const N116 = 116;
const N119 = 119;
const N120 = 120;
const N121 = 121;
const N131 = 131;
const N183 = 183;
const N185 = 185;
const N200 = 200;
const N240 = 240;
const N255 = 255;
const N300 = 300;
const N365 = 365;
const N400 = 400;
const N401 = 401;
const N420 = 420;
const N500 = 500;
const N640 = 640;
const N787 = 787;
const N840 = 840;
const N900 = 900;
const N1000 = 1000;
const N1024 = 1024;
const N1048_576 = 1048.576;
const N1900 = 1900;
const N2000 = 2000;
const N1200 = 1200;
const N1800 = 1800;
const N3000 = 3000;
const N4000 = 4000;
const N9999 = 9999;
const N10000 = 10000;
const N10240 = 10240;
const N60000 = 60000;
const N120000 = 120000;
const N300000 = 300000;
const N600000 = 600000;
const N1000000 = 1000000;

/** Constantes numéricas */
export class Numbers {
  static readonly _D0 = D0;
  static readonly _D1 = D1;
  static readonly _m1 = M1;
  static readonly _0 = N0;
  static readonly _0_015 = N0_015;
  static readonly _1 = N1;
  static readonly _1_5 = N1_5;
  static readonly _2 = N2;
  static readonly _3 = N3;
  static readonly _4 = N4;
  static readonly _5 = N5;
  static readonly _6 = N6;
  static readonly _7 = N7;
  static readonly _8 = N8;
  static readonly _9 = N9;
  static readonly _10 = N10;
  static readonly _11 = N11;
  static readonly _12 = N12;
  static readonly _13 = N13;
  static readonly _14 = N14;
  static readonly _15 = N15;
  static readonly _16 = N16;
  static readonly _17 = N17;
  static readonly _18 = N18;
  static readonly _19 = N19;
  static readonly _20 = N20;
  static readonly _21 = N21;
  static readonly _22 = N22;
  static readonly _23 = N23
  static readonly _24 = N24;
  static readonly _25 = N25;
  static readonly _26 = N26;
  static readonly _29 = N29;
  static readonly _27 = N27;
  static readonly _28 = N28;
  static readonly _30 = N30;
  static readonly _31 = N31;
  static readonly _32 = N32;
  static readonly _33 = N33;
  static readonly _34 = N34;
  static readonly _36 = N36;
  static readonly _37 = N37;
  static readonly _39 = N39;
  static readonly _40 = N40;
  static readonly _41 = N41;
  static readonly _48 = N48;
  static readonly _45 = N45;
  static readonly _50 = N50;
  static readonly _57 = N57;
  static readonly _60 = N60;
  static readonly _63 = N63;
  static readonly _65 = N65;
  static readonly _68 = N68;
  static readonly _71 = N71;
  static readonly _74 = N74;
  static readonly _75 = N75;
  static readonly _76 = N76;
  static readonly _77 = N77;
  static readonly _78 = N78;
  static readonly _79 = N79;
  static readonly _80 = N80;
  static readonly _81 = N81;
  static readonly _82 = N82;
  static readonly _99 = N99;
  static readonly _100 = N100;
  static readonly _118 = N118;
  static readonly _116 = N116;
  static readonly _119 = N119;
  static readonly _120 = N120;
  static readonly _121 = N121;
  static readonly _131 = N131;
  static readonly _183 = N183;
  static readonly _185 = N185;
  static readonly _200 = N200;
  static readonly _240 = N240;
  static readonly _255 = N255;
  static readonly _300 = N300;
  static readonly _365 = N365;
  static readonly _400 = N400;
  static readonly _401 = N401;
  static readonly _420 = N420;
  static readonly _500 = N500;
  static readonly _640 = N640;
  static readonly _787 = N787;
  static readonly _840 = N840;
  static readonly _900 = N900;
  static readonly _1000 = N1000;
  static readonly _1024 = N1024;
  static readonly _1048_576 = N1048_576;
  static readonly _2000 = N2000;
  static readonly _1200 = N1200;
  static readonly _1800 = N1800;
  static readonly _1900 = N1900;
  static readonly _3000 = N3000;
  static readonly _4000 = N4000;
  static readonly _9999 = N9999;
  static readonly _10000 = N10000;
  static readonly _10240 = N10240;
  static readonly _60000 = N60000;
  static readonly _600000 = N600000;
  static readonly _120_000 = N120000;
  static readonly _300_000 = N300000;
  static readonly _1000000 = N1000000;
}

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const celularRegex = /^(300|301|302|303|304|305|310|311|312|313|314|315|316|317|318|319|320|321|322|323|324|350|351)[0-9]+$/;
