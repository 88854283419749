import { Injectable } from '@angular/core';
import { ApiHelperService } from 'src/app/data/services/api-helper/api-helper.service';
import { Observable } from 'rxjs';
import { APIS_URL } from 'src/app/core/constants/url';
import { IAllies, IAlliesData } from 'src/app/data/interfaces/IAlliesData.interface';
import { IAlly } from 'src/app/data/interfaces/IAlly.interface';
import { map } from 'rxjs/operators';
import { IAlliesUpdate } from '../../../interfaces/IAlliesUpdate.interface';

@Injectable({
  providedIn: 'root',
})
export class AlliesService {

  private endPointAllies = APIS_URL.allies;
  private endPoinCotization = APIS_URL.cotizations;

  constructor(private apiHelperService: ApiHelperService) {
    // This is intentional
  }

  /** Enpoint para crear aliado */
  createAlly(ally: IAlliesData): Observable<IAlly> {
    return this.apiHelperService.post<IAlly>(this.endPointAllies, ally);
  }

  findAlly(typeId: number): Observable<IAlly[]> {
    const queryParams = `/by-type/${typeId}`;
    return this.apiHelperService
      .get(this.endPointAllies + queryParams)
      .pipe(map((data) => data.Allies));
  }

  updateCotizationsAllies(
    cib: string,
    clientSection: boolean,
    alliesData: IAlliesUpdate
  ): Observable<any> {
    const queryParams = `/updateAlliesAndCustom?cib=${cib}&clientSection=${clientSection}`;
    return this.apiHelperService.put(
      this.endPoinCotization + queryParams,
      alliesData?.listId
    );
  }

  /** Enpoint para buscar aliados por nit */
  searchAllyNit(nit: string): Observable<IAlly> {
    const params = `/filter/by-nit/${nit}`;
    return this.apiHelperService
      .get(this.endPointAllies + params)
      .pipe(
        map((ally: any) => ally.Allies)
      );
  }

  /** Endpoint para eliminar aliados por ID */
  deleteAllyId(id: number): Observable<IAllies> {
    const params = `/by-id/${id}`;
    return this.apiHelperService
      .delete(this.endPointAllies + params)
      .pipe(
        map((data: any) => data)
      );
  }

  /** Endpoint para editar aliados por ID */
  editAlly(allyId: number, allyData: IAlliesData): Observable<any> {
    const params = `?allyId=${allyId}`;
    return this.apiHelperService
      .put(this.endPointAllies + params, allyData)
      .pipe(
        map((data: any) => data)
      );
  }
}
