import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiHelperService } from 'src/app/data/services/api-helper/api-helper.service';
import { Observable } from 'rxjs';
import { APIS_URL } from 'src/app/core/constants/url';
import { ModalService } from '../modal/modal.service';
import { Numbers } from 'src/app/core/constants/numbers';
import { DataService } from 'src/app/data/services/data/data.service';
import { ProfileFacadeService } from 'src/app/modules/master/profile-store/profile.facade.service';
import { ModalResponse } from 'src/app/core/enums/modal.enum';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SessionTimeService {

  private endPointrefresToken = APIS_URL.refresToken;
  helper: JwtHelperService;
  interval: any;
  lastActivity: number = new Date().getTime();
  expirationTime: number = Numbers._300;
  requestDone = false;
  showModal = true;
  tokenExpirationDate!: Date | null;
  constructor(
    private apiHelperService: ApiHelperService,
    private modalService: ModalService,
    private dataService: DataService,
    private profileFacadeService: ProfileFacadeService,
  ) {
    this.helper = new JwtHelperService();
    this.dataService.getActivity().subscribe(data => {
      if (data) {
        this.lastActivity = data;
      }
    })
    this.profileFacadeService.getTokenSession().subscribe(data => {
      if (data) {
        this.tokenExpirationDate = this.helper.getTokenExpirationDate(data) ?? new Date()
      }
    })
    this.initInterval()
  }


  initInterval() {
    setInterval(() => this.launchModal(), Numbers._1000);
  }

  launchModal() {
    const currentTime = new Date().getTime()
    const inactivity = Math.round((currentTime - this.lastActivity) / Numbers._1000);
    this.verifiToken()
    if (inactivity >= Numbers._300 && this.showModal) {
      this.showModal = false;
      this.modalService.sessionTime().subscribe((data) => {
        if (data === ModalResponse.CONTINUAR) {
          this.lastActivity = new Date().getTime()
          this.verifiToken()
          this.showModal = true;
        }
      });
    }
  }
  verifiToken() {
    if (!this.tokenExpirationDate) {
      console.warn("No hay token en el storage");
      const tokenString = localStorage.getItem("token");
      if (tokenString) {
        try {
          const token = JSON.parse(tokenString);
          this.tokenExpirationDate = this.helper.getTokenExpirationDate(token);
        } catch (error) {
          console.error("El token no es un JSON válido.", error);
        }
      } else {
        console.warn("No se encontró un token en localStorage.");
      }
    }
    this.verifyTokenExpirationDate(this.tokenExpirationDate)
  }

  verifyTokenExpirationDate(tokenExpirationDate: Date | null) {
    if (!tokenExpirationDate) { return }
    const currentTime = new Date().getTime()
    if (currentTime >= tokenExpirationDate.getTime()) {
      console.warn("Salir");
    } else {
      const deadLine = Math.round((tokenExpirationDate.getTime() - currentTime) / Numbers._1000);
      this.verifyDeadLineToken(deadLine)
    }
    return true
  }

  verifyDeadLineToken(deadLine: number) {
    if (deadLine <= Numbers._200) {
      this.refreshToken().subscribe(
        (data) => {
          this.profileFacadeService.setTokenSession(data.Authorization);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  startSessionTime() {
    this.interval = setInterval(() => {
      this.refreshToken().subscribe(
        (data) => {
          this.profileFacadeService.setTokenSession(data.Authorization);
        },
        (error) => {
          console.error(error);
        }
      );
    }, Numbers._300_000)
  }

  stopSessionTime() {
    clearInterval(this.interval);
  }

  refreshToken(): Observable<any> {
    let token;
    this.profileFacadeService.getTokenSession().subscribe(
      (tokenSession) => {
        if (tokenSession) {
          token = tokenSession;
        }
      }
    )
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `${token}`,
      }),
    };
    return this.apiHelperService.get(
      this.endPointrefresToken,
      undefined,
      httpOptions
    ).pipe(tap(data => {
      localStorage.setItem("token", JSON.stringify(data.Authorization))
    }));
  }
}
