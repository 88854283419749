<bc-table-container
  class="bc-col-12 bc-w-100"
  *ngIf="currentPage.length > 0"
  [dataTable]="currentPage"
  [cellOptions]="dropdownOptions"
>
  <bc-table-header
    [title]="tableTitle"
    [actionsSelections]="actions"
    (actionClicked)="onClickAction($event)"
  >
    <bc-paginator
      type="full"
      [totalRegisters]="'10'"
      [perPage]="'5'"
      (onChangePage)="onChangePageTableScroll($event)"
      [perMultiple]="'5'"
    ></bc-paginator>
  </bc-table-header>
  <bc-table-content>
    <table
      aria-label="Solicitudes"
      caption="tabla"
      bc-table
      [selection]="true"
      [scroll]="false"
      [sort]="true"
      [inlineActions]="false"
    >
      <thead>
        <tr>
          <th scope="row" [fixed]="true" class="firstCellHeaders" bc-cell>Nro Consecutivo</th>
          <th scope="row" bc-cell class="secondCell" type="action">Cliente</th>
          <th scope="row" bc-cell type="text">Tipo de leasing</th>
          <th scope="row" bc-cell type="text">Tipo de activo</th>
          <th scope="row" bc-cell type="text">Zona</th>
          <th scope="row" bc-cell type="text">Ciudad</th>
          <th scope="row" bc-cell type="text">Estado</th>
          <th scope="row" bc-cell scope="col" type="action"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of tableData; let i = index">
          <td bc-cell type="action"  class="firstCell"  > <a (click)="selected( item )"> {{ item.cib}}  </a>  </td>
          <td bc-cell>{{ item.clientId.usersData.name }}</td>
          <td bc-cell>{{ item.leasingTypeId.value }}</td>
          <td bc-cell>{{ item.activeType.value }}</td>
          <td bc-cell>{{ item.clientId.regionalData.value }}</td>
          <td bc-cell>{{ item.clientId.usersData.city}}</td>
          <td bc-cell>{{ item.statusId.value }}</td>
          <td bc-cell type="action">
            <bc-table-dropdown [row]="item" 
            [options]="item.clientId.usersData.name == 'Cristian'
            ? dropdownOptions3
            : dropdownOptions2" (onChange)="optionSelected($event)"></bc-table-dropdown></td>
        </tr>
      </tbody>
    </table>
  </bc-table-content>
  <bc-table-footer>
  </bc-table-footer>
</bc-table-container>

