import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIS_URL } from 'src/app/core/constants/url';
import { ICotization } from 'src/app/data/interfaces/ICotization.interface';
import { ICotizationCreate } from 'src/app/data/interfaces/ICotizationCreate.interface';
import { ICotizationDetail } from 'src/app/data/interfaces/ICotizationDetail.interface';
import { ICotizationFilter } from 'src/app/data/interfaces/ICotizationFilter.interface';
import {
  ICotizationDetailUpdTranspLocal
} from 'src/app/data/interfaces/IcotizationDetailUpdTranspLocal.interface';
import { ICustomData } from 'src/app/data/interfaces/ICustomsData.interface';
import { IAdminDataUpdate } from 'src/app/data/interfaces/IAdminDataUpdate.interface';
import { ILocalTransportInfo } from 'src/app/data/interfaces/ILocalTransportInfo.interface';
import { TypeTransportEnum } from 'src/app/core/enums/type-transport.enum';
import { ICotizationCustomData } from 'src/app/data/interfaces/ICotizationCustomData.interface';
import { IAssetsListData } from 'src/app/data/interfaces/IAssetsListData.interface';
import { ICotizationsDetailsCustomsFeasibility } from 'src/app/data/interfaces/ICotizationsDetailsCustomsFeasibilities.interface';
import { Numbers } from 'src/app/core/constants/numbers';
import { ApiHelperService } from 'src/app/data/services/api-helper/api-helper.service';
import { Profiles } from 'src/app/core/mocks/profiles.enum';

@Injectable({
  providedIn: 'root',
})
export class ApiCotizationsService {
  private endPointCotizations = APIS_URL.cotizations;
  private endPointCotizationsDetails = APIS_URL.cotizationsDetails;
  private endPointCotizationsDetailsTransport = APIS_URL.cotizationsDetailsTransport;
  private endPointCotizationCustoms = APIS_URL.cotizationCustoms;
  private endPointCotizationCustomsFeasibility = APIS_URL.cotizationCustomsFeasibility;
  private endPointTermsAndConditions = APIS_URL.termsAndConditions;

  constructor(private apiHelperService: ApiHelperService) {
    // This is intentional
  }

  createCotization(cotization: ICotizationCreate): Observable<ICotization> {
    return this.apiHelperService.post<ICotization>(
      this.endPointCotizations,
      cotization
    ).pipe(map((cust: any) => cust.Cotizations));
  }

  getCotizationByFilter(params: ICotizationFilter, page: number, size: number): Observable<ICotization> {
    return this.apiHelperService.post<ICotization>(
      this.endPointCotizations + `/filter?page=${page}&size=${size}`,
      params
    ).pipe(map((cust: any) => cust));
  }

  getCotizationByFilterToDelete(params: ICotizationFilter, page: number, size: number): Observable<ICotization> {
    return this.apiHelperService.post<ICotization>(
      this.endPointCotizations + `/remove-filter?page=${page}&size=${size}`,
      params
    ).pipe(map((cust: any) => cust));
  }

  deleteCotizations(body: any): Observable<any> {
    return this.apiHelperService.delete(
      this.endPointCotizations + `/remove-by-id`, "",
      {body}    
      ).pipe(map((cust: any) => cust));
  }

  getCotizationByCIB(CIB: string, clientSection: boolean): Observable<ICotization> {
    const params = `/by-cib?cib=${CIB}&clientSection=${clientSection}`;
    return this.apiHelperService.get(this.endPointCotizations + params).pipe(
      map((cust: any) => cust.Cotizations)
    );
  }

  createCotizationDetails(
    cotizationsDetails: ICotizationDetail
  ): Observable<ICotizationDetail> {
    return this.apiHelperService.post<ICotizationDetail>(
      this.endPointCotizationsDetails,
      cotizationsDetails
    );
  }

  findCotizationsDetailsByCIB(cib: string): Observable<ICotizationDetail> {
    const params = `/by-cib?cib=${cib}`;
    return this.apiHelperService.get(
      this.endPointCotizationsDetails + params
    ).pipe(map((cust: any) => cust.DCotization));
  }

  updCotizationsDetailsTranspLocal(
    cotization: ICotizationDetailUpdTranspLocal,
    cotizationId: number
  ): Observable<boolean> {
    const params = `/localTransport?id=${cotizationId}`;
    return this.apiHelperService.patch<boolean>(
      this.endPointCotizationsDetailsTransport + params,
      cotization
    ).pipe(
      map((resp) => {
        return true;
      })
    );
  }

  updCotizationsDetailsTransInter(
    cotization: any,
    cotizationId: number
  ): Observable<boolean> {
    const params = `/internationalTransport?id=${cotizationId}`;
    return this.apiHelperService.patch<boolean>(
      this.endPointCotizationsDetailsTransport + params,
      cotization
    ).pipe(
      map((resp) => {
        return true;
      })
    );
  }

  /** customsAgency?cotizationId=1 */
  updCotizationsDetailsCustoms(customsData: ICustomData, id: number): Observable<boolean> {
    const url = `${this.endPointCotizationCustoms}?id=${id}`;
    return this.apiHelperService.put<boolean>(url, customsData)
      .pipe(map((resp) => { return true; })
      );
  }

  updateCotization(cotization: any, id: number): Observable<any> {
    const url = `${this.endPointCotizations}?cotizationId=${id}`;
    return this.apiHelperService.put<any>(url, cotization)
      .pipe(map((resp) => { return resp; })
      );
  }

  updCotizationsAdmin(
    aminDataAproval: IAdminDataUpdate,
    cotizationId: number
  ): Observable<boolean> {
    /// //administrator?cotizationId=1
    const params = `/administrator?cotizationId=${cotizationId}`;
    return this.apiHelperService.patch<boolean>(
      this.endPointCotizationsDetails + params,
      aminDataAproval
    ).pipe(
      map((resp) => {
        return true;
      })
    );
  }

  public findCotiztionByParasms(
    documentType?: string,
    documentNumber?: string,
    clientName?: string,
    cib?: string,
    initDate?: string,
    endDate?: string,
    pagination?: any,
  ): Observable<ICotization> {
    const filter = `filter-by-date?page=${pagination.page}&size=${pagination.size}`;
    const body = {
      documentType,
      documentNumber,
      clientName,
      cib,
      initDate,
      endDate,
    };
    return this.apiHelperService.post<ICotization>(
      `${this.endPointCotizations}/${filter}`,
      body
    ).pipe(map((data: any) => data));
  }

  savePartialCotizationDetail(
    cib: string,
    data: ICotizationDetail
  ): Observable<any> {
    const params = `/updatePartialCotizationDetail?cib=${cib}`;
    const body = JSON.stringify(data);
    return this.apiHelperService.patch<any>(
      `${this.endPointCotizations + params}`,
      body
    );
  }

  cotizationAdminAproval(cotizationId: number, adminAproval: boolean): Observable<any> {
    const approvals = { adminAproval };
    const params = `/admin-aproval?cotizationId=${cotizationId}`;
    return this.apiHelperService.put(
      this.endPointCotizations + params,
      approvals
    ).pipe(map((data: any) => data.Message));
  }
  cotizationCustomHouseAproval(cotizationId: number, customHouseAproval: boolean): Observable<any> {
    const approvals = { customHouseAproval };
    const params = `/custom-house-aproval?cotizationId=${cotizationId}`;
    return this.apiHelperService.put(
      this.endPointCotizations + params,
      approvals
    ).pipe(map((data: any) => data.Message));
  }
  getDataTransport(cib: string, allyId: number, transportType: TypeTransportEnum): Observable<ILocalTransportInfo> {
    const url = `${this.endPointCotizationsDetailsTransport}?transportType=${transportType}&cib=${cib}&allyId=${allyId}`;
    return this.apiHelperService.get(url).pipe(map(data => data.Cotization_Transport));
  }
  getDataCustom(cib: string, allyId: number): Observable<ICotizationCustomData> {
    const url = `${this.endPointCotizationCustoms}/filter?cib=${cib}&allyId=${allyId}`;
    return this.apiHelperService.get(url).pipe(map(data => data.Cotization_Customs));
  }
  getAssets(cib: string, page = Numbers._0, size = Numbers._5): Observable<IAssetsListData> {
    const url = `${this.endPointCotizationsDetails}/get-assets?cib=${cib}&page=${page}&size=${size}`;
    return this.apiHelperService.get(url);
  }
  getTaxesTariffItem(id: number, page = Numbers._0, size = Numbers._5): Observable<ICotizationsDetailsCustomsFeasibility> {
    const url = `${this.endPointCotizationCustomsFeasibility}/filter?id=${id}&page=${page}&size=${size}`;
    return this.apiHelperService.get(url);
  }
  postTaxesTariffItem(cotizationsDetailsCustomsId: number, data: any): Observable<boolean> {
    const url = `${this.endPointCotizationCustomsFeasibility}?cotizationsDetailsCustomsId=${cotizationsDetailsCustomsId}`;
    return this.apiHelperService.post(url, data);
  }
  putTaxesTariffItem(id: number, data: any): Observable<boolean> {
    const url = `${this.endPointCotizationCustomsFeasibility}?id=${id}`;
    return this.apiHelperService.put(url, data);
  }

  putChangeStatus(value: boolean, cotizationId: number, profile: Profiles): Observable<boolean> {
    const url = `${this.endPointCotizationsDetails}/send-updates?role=${profile}&value=${value}&cotizationId=${cotizationId}`;
    return this.apiHelperService.patch(url);
  }

  putUpdateZoneLeader(idZoneLeader: number, cib: string): Observable<any> {
    const url = `${this.endPointCotizations}/update-zone-leader?idZoneLeader=${idZoneLeader}&cib=${cib}`;
    return this.apiHelperService.put(url);
  }

  getTermsAndConditions(): Observable<any> {
    const url = `${this.endPointTermsAndConditions}`;
    return this.apiHelperService.get(url)
      .pipe(map((data: any) => data.TermsAndConditions));
  }
}
