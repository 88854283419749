<div class="row btn-logistics-coordination-sections justify-content-center">
  <div class="col-3 btn-item">
    <bcs-button
      TypeButton="secondary"
      Label="Blog de mensajes"
      Icon="mail-open"
      (onClickEvent)="onClickBlog()"
    >
    </bcs-button>
  </div>
  <div class="col-3 btn-item" *ngIf="documentRepository">
    <bcs-button
      TypeButton="secondary"
      (onClickEvent)="goToproceduresSection()"
      Label="Sección tramites"
    ></bcs-button>
  </div>
  <div class="col-3 btn-item" *ngIf="proceduresSection">
    <bcs-button
      TypeButton="secondary"
      Label="Sección tramites"
      (onClickEvent)="goToproceduresSection()"
    ></bcs-button>
  </div>
  <div class="col-3 btn-item">
    <bcs-button
      TypeButton="secondary"
      Label="Volver coordinacion logística"
      (onClickEvent)="goToLogisticsCoordination()"
    ></bcs-button>
  </div>
</div>
