import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { adminRolesAction } from './actions';
import { RolesService } from 'src/app/data/services/apis/roles/roles.service';
import { UtilsServices } from 'src/app/shared/utils.services';

@Injectable()
export class AdministerRolesStoreEffects {

  constructor(
    private rolesService: RolesService,
    private actions$: Actions,
  ) {
    // This is intentional
  }

  createRolEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminRolesAction.createNewRol),
      switchMap(({ newRole }) =>
        this.rolesService.createRole(newRole)
          .pipe(
            map(role =>
              adminRolesAction.createNewRolSuccess({ role })
            ),
            catchError(error => of(adminRolesAction.onError({ error })))
          )
      )
    )
  );

  deleteRolEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminRolesAction.deleteRol),
      switchMap(({ nameRole }) =>
        this.rolesService.deleteRole(nameRole)
          .pipe(
            map(role =>
              adminRolesAction.deleteRolSuccess({ role })
            ),
            catchError(error => of(adminRolesAction.onError({ error })))
          )
      )
    )
  );

  searchRolEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminRolesAction.getRolById),
      switchMap(({ rolId }) =>
        this.rolesService.searchRol(rolId)
          .pipe(
            map(rol =>
              adminRolesAction.getRolByIdSuccess({ rol })
            ),
            catchError(error => of(adminRolesAction.onError({ error })))
          )
      )
    )
  );

  searchScreensEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminRolesAction.searchScreens),
      switchMap(({ requestScreens }) =>
        this.rolesService.searchScreens(requestScreens)
          .pipe(
            map(screens =>
              adminRolesAction.solicitScreensSuccess({ screens })
            ),
            catchError(error => of(adminRolesAction.onError({ error })))
          )
      )
    )
  );

  searchStaticScreensEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminRolesAction.searchStaticScreens),
      switchMap(({ requestScreens }) =>
        this.rolesService.searchStaticScreens(requestScreens)
          .pipe(
            tap((e: any) => UtilsServices.consoleLog('searchStaticScreensEffect => ', e)),
            map(screens =>
              adminRolesAction.solicitStaticScreensSuccess({ screens })
            ),
            catchError(error => of(adminRolesAction.onError({ error })))
          )
      )
    )
  );

  updateScreensRoleEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminRolesAction.updateScreensByRole),
      switchMap(({ nameRole, updateScreens }) =>
        this.rolesService.updateScreensRole(nameRole, updateScreens)
          .pipe(
            tap(e => UtilsServices.consoleLog('updateScreensRoleEffect => ', e)),
            map(screens =>
              adminRolesAction.updateScreensByRoleSuccess({ screens })
            ),
            catchError(error => of(adminRolesAction.onError({ error })))
          )
      )
    )
  );
}
