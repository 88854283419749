import { Action, createReducer, on } from '@ngrx/store';
import { PostProductionActions } from './actions';
import {
  ICotizationTransportLocal,
  ICotizationTransportLocalUp,
  IListCotizationTranspLocal
} from '../../../../data/interfaces/ITranspLocal.interface';
import {
  ICotizationTransportInternationalUp,
  ICotizationTransportIntertational,
  IListCotizationTranspInternational
} from '../../../../data/interfaces/ITranspInternational.interface';
import {
  ICotizationAduanas,
  ICotizationAduanasAssets,
  ICotizationAduanasDutyUp,
  ICotizationAduanasUp,
  ICotizationsDetailsCustomsFeasibility,
  IListCotizationAduanas
} from '../../../../data/interfaces/ICotizationAduanas.interface';

interface PostProductionState {
  getCotizationsLocal: ICotizationTransportLocal | null;
  upCotizationLocalData: ICotizationTransportLocalUp | null;
  getCotizationInternational: ICotizationTransportIntertational | null;
  upCotizationInternationalData: ICotizationTransportInternationalUp | null;
  getCotizationAduanasData: ICotizationAduanas | null;
  upCotizationAduanasData: ICotizationAduanasUp | null;
  getCotizationsAduanasData: ICotizationAduanasAssets | null;
  getTaxesDutyAduanasData: ICotizationsDetailsCustomsFeasibility | null;
  upTaxesDutyAduanasData: ICotizationAduanasDutyUp | null;
  getListCotizationsTranspInternationalDetails: IListCotizationTranspInternational | null;
  getListCotizationsTranspLocalDetails: IListCotizationTranspLocal | null;
  getListCotizationsAduanasDetails: IListCotizationAduanas | null;

  error: any;
  message: any;
  isLoading: boolean;
  created: boolean;
  found: { search: boolean; found: boolean };
  edit: boolean;
  updated: boolean;
}

const initialState: PostProductionState = {
  getCotizationsLocal: null,
  upCotizationLocalData: null,
  getCotizationInternational: null,
  upCotizationInternationalData: null,
  getCotizationAduanasData: null,
  upCotizationAduanasData: null,
  getCotizationsAduanasData: null,
  getTaxesDutyAduanasData: null,
  upTaxesDutyAduanasData: null,
  getListCotizationsTranspInternationalDetails: null,
  getListCotizationsTranspLocalDetails: null,
  getListCotizationsAduanasDetails: null,

  error: null,
  message: null,
  isLoading: false,
  created: false,
  found: { search: false, found: false },
  edit: false,
  updated: false,
};

const featureReducer = createReducer(
  initialState,
  on(PostProductionActions.load, (state) => ({
    ...updateFlags(state),
    isLoading: true,
    error: null
  })),
  on(PostProductionActions.searchPostProductionsLocal, (state) => ({
    ...updateFlags(state),
    getCotizationsLocal: null,
    isLoading: true,
  })),
  on(PostProductionActions.searchPostProductionsLocalSuccess,
    (state, { getCotizationsLocal }) => ({
      ...updateFlags(state),
      getCotizationsLocal
    })),
  on(PostProductionActions.upCotizationsDetailsTranspLocal, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(PostProductionActions.upCotizationsDetailsTranspLocalSuccess,
    (state, { upCotizationLocalData }) => ({
      ...updateFlags(state),
      upCotizationLocalData
    })),
  on(PostProductionActions.searchPostProductionsInternational, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(PostProductionActions.searchPostProductionsInternationalSuccess,
    (state, { getCotizationInternational }) => ({
      ...updateFlags(state),
      getCotizationInternational
    })),
  on(PostProductionActions.upCotizationsDetailsTranspInternational, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(PostProductionActions.upCotizationsDetailsTranspInternationalSuccess,
    (state, { upCotizationInternationalData }) => ({
      ...updateFlags(state),
      upCotizationInternationalData
    })),
  on(PostProductionActions.searchCotizationsAduanasDetails, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(PostProductionActions.searchCotizationsAduanasDetailsSuccess,
    (state, { getCotizationAduanasData }) => ({
      ...updateFlags(state),
      getCotizationAduanasData
    })),
  on(PostProductionActions.putCotizationsAduanasDetails, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(PostProductionActions.putCotizationsAduanasDetailsSuccess,
    (state, { upCotizationAduanasData }) => ({
      ...updateFlags(state),
      upCotizationAduanasData
    })),
  on(PostProductionActions.searchAssetsAduanasDetails, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(PostProductionActions.searchAssetsAduanasDetailsSuccess,
    (state, { getCotizationsAduanasData }) => ({
      ...updateFlags(state),
      getCotizationsAduanasData
    })),
  on(PostProductionActions.searchTaxesDutyAduanasDetails, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(PostProductionActions.searchTaxesDutyAduanasDetailsSuccess,
    (state, { getTaxesDutyAduanasData }) => ({
      ...updateFlags(state),
      getTaxesDutyAduanasData
    })),
  on(PostProductionActions.upTaxesDutyAduanasDetails, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(PostProductionActions.upTaxesDutyAduanasDetailsSuccess,
    (state, { upTaxesDutyAduanasData }) => ({
      ...updateFlags(state),
      upTaxesDutyAduanasData
    })),
  on(PostProductionActions.searchListCotizationsTranspInternationalDetails, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(PostProductionActions.searchListCotizationsTranspInternationalDetailsSuccess,
    (state, { getListCotizationsTranspInternationalDetails }) => ({
      ...updateFlags(state),
      getListCotizationsTranspInternationalDetails
    })),
  on(PostProductionActions.searchListCotizationsTranspLocalDetails, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(PostProductionActions.searchListCotizationsTranspLocalDetailsSuccess,
    (state, { getListCotizationsTranspLocalDetails }) => ({
      ...updateFlags(state),
      getListCotizationsTranspLocalDetails
    })),


  on(PostProductionActions.searchListCotizationsAduanasDetails, (state) => ({
    ...updateFlags(state),
    getListCotizationsAduanasDetails: null,
    isLoading: true,
  })),
  on(PostProductionActions.searchListCotizationsAduanasDetailsSuccess,
    (state, { getListCotizationsAduanasDetails }) => ({
      ...updateFlags(state),
      getListCotizationsAduanasDetails
    })),

  // on(PostProductionActions.onError, (state, { error }) => ({
  //   ...state,
  //   isLoading: false,
  //   error
  // })),
);

const updateFlags = (state: PostProductionState): PostProductionState => {
  return {
    ...state,
    error: null,
    isLoading: false,
    found: { search: false, found: false },
    edit: false,
    created: false,
    updated: false,
    message: null,
  };
};

function reducer(state: PostProductionState | undefined, action: Action) {
  return featureReducer(state, action);
}

const getError = (state: PostProductionState) => state.error;
const getMessage = (state: PostProductionState) => state.message;
const getIsLoading = (state: PostProductionState) => state.isLoading;
const getIsCreated = (state: PostProductionState) => state.created;
const getIsFound = (state: PostProductionState) => state.found;
const onEdit = (state: PostProductionState) => state.edit;

const getSearchCotizationsLocal = (state: PostProductionState) => state.getCotizationsLocal;
const getUpCotizationsLocalData = (state: PostProductionState) => state.upCotizationLocalData;
const getSearchgetCotizationInternational = (state: PostProductionState) => state.getCotizationInternational;
const getUpCotizationInternationalData = (state: PostProductionState) => state.upCotizationInternationalData;
const getSearchCotizationAduanasData = (state: PostProductionState) => state.getCotizationAduanasData;
const getUpCotizationAduanasData = (state: PostProductionState) => state.upCotizationAduanasData;
const getSearchCotizationsAduanasData = (state: PostProductionState) => state.getCotizationsAduanasData;
const getSearchTaxesDutyAduanasData = (state: PostProductionState) => state.getTaxesDutyAduanasData;
const getUpTaxesDutyAduanasData = (state: PostProductionState) => state.upTaxesDutyAduanasData;
const getSearchListCotizationsTranspInternationalDetails = (state: PostProductionState) => state.getListCotizationsTranspInternationalDetails;
const getSearchListCotizationsTranspLocalDetails = (state: PostProductionState) => state.getListCotizationsTranspLocalDetails;
const getSearchListCotizationsAduanasDetails = (state: PostProductionState) => state.getListCotizationsAduanasDetails;

export const PostProductionReducer = {
  reducer,
  getError,
  getMessage,
  getIsLoading,
  getIsCreated,
  getIsFound,
  onEdit,
  getSearchCotizationsLocal,
  getUpCotizationsLocalData,
  getSearchgetCotizationInternational,
  getUpCotizationInternationalData,
  getSearchCotizationAduanasData,
  getUpCotizationAduanasData,
  getSearchCotizationsAduanasData,
  getSearchTaxesDutyAduanasData,
  getUpTaxesDutyAduanasData,
  getSearchListCotizationsTranspInternationalDetails,
  getSearchListCotizationsTranspLocalDetails,
  getSearchListCotizationsAduanasDetails,
};
export { PostProductionState };
