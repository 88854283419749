import { ICustomTempDocumentCreate, ICustomTempDocumentUpdate, IDocumentsClosure, IDocumentsDto } from "../interfaces/icustom-temp-document";
import { IDeserializable } from "../interfaces/ideserializable";

export class CustomTempDocumentCreate implements ICustomTempDocumentCreate, IDeserializable {
    documentsDtoList: IDocumentsDto[] = [];
    deserialize(input: any): this {
        Object.assign(this, input);
        this.documentsDtoList = this.documentsDtoList.map(x => new DocumentsDto().deserialize(x));
        return this;
    }
    editable: boolean = true;
    id!: number;
    documentType!: string;
    requestDate!: string;
    approvedDate!: string;
    validityDate!: string;
    observations!: string;
    userRole!: string;
    formalities: boolean = false;

}

export class DocumentsDto implements IDocumentsDto, IDeserializable {
    documentRoute!: string;
    formalitiesId!: number;
    status!: boolean;
    updateAt!: string;
    userRole!: string;
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

}

export class CustomTempDocumentUpdate implements ICustomTempDocumentUpdate, IDeserializable {
    documentType!: string;
    requestDate!: string;
    approvedDate!: string;
    validityDate!: string;
    observations!: string;
    userRole!: string;
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

export class DocumentsClosure implements IDocumentsClosure, IDeserializable {
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
    documentType!: string;
    isvalid: boolean = false;

}