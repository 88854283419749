import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApisPostProdService } from '../../../../data/services/apis-post-prod/apis-post-prod.service';
import { PostProductionActions } from './actions';

@Injectable()
export class PostProductionStoreEffects {

  constructor(
    private apisPostProdService: ApisPostProdService,
    private actions$: Actions,
  ) {
    // This is intentional
  }

  /** database/cotizationsDetailsTransport?transportType=LOCAL */
  findCotizationsLocalByCIBAllyEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostProductionActions.searchPostProductionsLocal),
      switchMap(({ cib, allyId }) =>
        this.apisPostProdService.searchCotizationsDetailsLocal(cib, allyId)
          .pipe(
            map((getCotizationsLocal) =>
              PostProductionActions.searchPostProductionsLocalSuccess({
                getCotizationsLocal,
              })
            ),
            catchError((error) => of(PostProductionActions.onError(error)))
          )
      )
    )
  );
  /** database/cotizationsDetailsTransport?transportType=INTERNATIONAL */
  findCotizationsInternationalByCIBAllyEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostProductionActions.searchPostProductionsInternational),
      switchMap(({ cib, allyId }) =>
        this.apisPostProdService.searchCotizationsDetailsInternational(cib, allyId)
          .pipe(
            map((getCotizationInternational) =>
              PostProductionActions.searchPostProductionsInternationalSuccess({
                getCotizationInternational,
              })
            ),
            catchError((error) => of(PostProductionActions.onError(error)))
          )
      )
    )
  );

  /** ******************************** Para listar cotizaciones en el Equipo Administrativo ******************************** */
  /** GET: listar cotizaciones en Transporte Internacional */
  getCotizationsTranspInternationalAdminByCIBEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostProductionActions.searchListCotizationsTranspInternationalDetails),
      switchMap(({ cib, page, size }) =>
        this.apisPostProdService.searchListCotizationsDetailsTranspInternational(cib, page, size)
          .pipe(
            map((getListCotizationsTranspInternationalDetails) =>
              PostProductionActions.searchListCotizationsTranspInternationalDetailsSuccess({
                getListCotizationsTranspInternationalDetails,
              })
            ),
            catchError((error) => of(PostProductionActions.onError(error)))
          )
      )
    )
  );
  /** GET: listar cotizaciones en Aduanas */
  getCotizationsAduanasAdminByCIBEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostProductionActions.searchListCotizationsAduanasDetails),
      switchMap(({ cib }) =>
        this.apisPostProdService.searchListCotizationsDetailsAduanas(cib)
          .pipe(
            map((getListCotizationsAduanasDetails) =>
              PostProductionActions.searchListCotizationsAduanasDetailsSuccess({
                getListCotizationsAduanasDetails,
              })
            ),
            catchError((error) => of(PostProductionActions.onError(error)))
          )
      )
    )
  );
  /** GET: listar cotizaciones en Transporte Local */
  getCotizationsTranspLocalAdminByCIBEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostProductionActions.searchListCotizationsTranspLocalDetails),
      switchMap(({ cib, page, size }) =>
        this.apisPostProdService.searchListCotizationsDetailsTranspLocal(cib, page, size)
          .pipe(
            map((getListCotizationsTranspLocalDetails) =>
              PostProductionActions.searchListCotizationsTranspLocalDetailsSuccess({
                getListCotizationsTranspLocalDetails,
              })
            ),
            catchError((error) => of(PostProductionActions.onError(error)))
          )
      )
    )
  );
}
