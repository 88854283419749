
<div class="text-right m-2">
    <bc-icon name="error" (click)="goBack()" class="bcs-pointer"></bc-icon>
</div>
<div class="container-information" *ngIf="transportContainer.length > 0">
    <h6 class="text-center">Información adicional de los contenedores</h6>
    <mat-divider class="mb-5"></mat-divider>

      <!-- ----------------------------------------------------------------------- -->
      <!--                               TABLA CONTENEDORES                        -->
      <!-- ----------------------------------------------------------------------- -->
      <div class="bc-row m-5">
        <div class="bc-col-12">
            <app-table-transport class="w-100" [transportInfo]="transportContainer" title="Información -  Contenedores"
              [columns]="columnsTransportContainer" [totalElements]="transportContainer.length"
              [readOnly]="true"></app-table-transport>
        </div>
      </div>
</div>

<div class="loose-cargo-information" *ngIf="transportLooseLoad.length > 0">
    <h6 class="text-center">Información de la carga suelta</h6>
    <mat-divider class="mb-5"></mat-divider>

      <!-- ----------------------------------------------------------------------- -->
      <!--                               TABLA CARGA SUELTA                        -->
      <!-- ----------------------------------------------------------------------- -->
      <div class="bc-row m-5">
        <div class="bc-col-12">
            <app-table-transport class="w-100" [transportInfo]="transportLooseLoad" title="Información - Carga Suelta"
              [columns]="columnsTransportLooseLoad" [totalElements]="transportContainer.length" 
              [readOnly]="true"></app-table-transport>
        </div>
      </div>

</div>

<div class="text-center pb-3">
  <bcs-button
  Label="Regresar"
  Type="button"
  (onClickEvent)="goBack()"
></bcs-button>
</div>
