<mat-form-field appearance="legacy" class="w-100 select">
  <ng-template
    *ngIf="iconTooltip; then withTooltips; else withoutTooltips"
  ></ng-template>

  <ng-template #withTooltips>
    <bc-icon
      *ngIf="Icon && showIcon"
      [name]="Icon"
      aria-hidden="false"
      [aria-label]="Icon"
      [size]="size"
      (click)="onClick()"
      class="icon"
      bc-tooltip
      [bcTooltipText]="iconTooltipText"
      [bcTooltipTitle]="iconTooltipTitle"
      [bcTooltipPosition]="iconTooltipPosition"
    ></bc-icon>
  </ng-template>
  <ng-template #withoutTooltips>
    <bc-icon
      *ngIf="Icon && showIcon"
      [name]="Icon"
      aria-hidden="false"
      [aria-label]="Icon"
      [size]="size"
      (click)="onClick()"
      class="icon"
    ></bc-icon>
  </ng-template>
  <mat-label [ngClass]="{ space: showIcon && Icon }">
    {{ Label }}
  </mat-label>

  <mat-select
    [formControl]="FormControl"
    [name]="name"
    [id]="IdName"
    [multiple]="multiple"
    [disabled]="disabled"
  >
    <mat-option *ngFor="let item of Values" [value]="getId(item)" (click)="clickSelect(getId(item))">
      {{ getDescripcion(item) }}
    </mat-option>
  </mat-select>

  <mat-error class="mat-hint-error" *ngIf="isError">{{ errorText }}</mat-error>
</mat-form-field>
<mat-hint class="helpText" *ngIf="!isError">{{ HelpText }}</mat-hint>
