import { createAction, props } from "@ngrx/store";
import { ISearchBody } from "../../data/interfaces/isearch-body";
import { ISearchDto, ISearchResponse } from "../../data/interfaces/isearch-response";
import { ICustomTempResponse } from "../../data/interfaces/icustom-temp-response";
import { IStatement, IStatementResponse } from "../../data/interfaces/istatement";
import { IQuota, IQuotaResponse } from "../../data/interfaces/iquota";
import { ICustomTempDocumentCreate, ICustomTempDocumentCreateResponse, ICustomTempDocumentUpdate } from "../../data/interfaces/icustom-temp-document";
import { IInconsistencie } from "../../data/interfaces/Inconsistencie";
import { INotificationTemporaryResponse } from "../../data/interfaces/inotification";


enum TemporaryActionType {
  onErrorAction = '[temporary Component] get error',
  onResetFlagsAction = '[temporary Component] Temporary reset flags',
  onResetPropertyAction = '[temporary Component] Reset Property flags',
  setTemporaryAction = '[temporary Component] set temporary',
  setStatementAction = '[temporary Component] set statement',
  getCustomStatementTempsAction = '[temporary Component] get custom Statement Temps',
  getCustomStatementTempsSuccessAction = '[temporary Component] get custom Statement Temps Success',
  getImportsAction = '[temporary Component] get Imports',
  getImportsSuccessAction = '[temporary Component] get Imports Success',
  createCustomStatementTempAction = '[temporary Component] create Custom Statement Temp',
  createCustomStatementTempSuccessAction = '[temporary Component] create Custom Statement Temp Success',
  updateCustomStatementTempAction = '[temporary Component] update Custom Statement Temp',
  updateCustomStatementTempSuccessAction = '[temporary Component] update Custom Statement Temp Success',
  deleteCustomStatementTempAction = '[temporary Component] delete Custom Statement Temp',
  deleteCustomStatementTempSuccessAction = '[temporary Component] delete Custom Statement Temp Success',
  getQuotasAction = '[temporary Component] get Quotas',
  getQuotasSuccessAction = '[temporary Component] get Quotas Success',
  updateQuotasAction = '[temporary Component] update Quotas',
  updateQuotasSuccessAction = '[temporary Component] update Quotas Success',
  getFileExcelCustomStatementTempAction = '[temporary Component] get File Excel Custom Statement Temp',
  getFileExcelQuotasAction = '[temporary Component] get File Excel Quotas',
  getFileExcelCustomStatementTempSuccessAction = '[temporary Component] get File Excel Custom Statement Temp Success',
  getDocumentTypesAction = '[temporary Component] get Document Types',
  getDocumentTypesSuccessAction = '[temporary Component] get Document Types Success',
  getCustomTempsDocumentsAction = '[temporary Component] get Custom Temps Documents',
  resetCustomTempsDocumentsAction = '[temporary Component] reset Custom Temps Documents',
  getCustomTempsDocumentsSuccessAction = '[temporary Component] get Custom Temps Documents Success',
  createCustomTempDocumentAction = '[temporary Component] create Custom Temp Document',
  createCustomTempDocumentSuccessAction = '[temporary Component] create Custom Temp Document Success',
  updateCustomTempDocumentAction = '[temporary Component] update Custom Temp Document',
  updateCustomTempDocumentSuccessAction = '[temporary Component] update Custom Temp Document Success',
  deleteCustomTempDocumentAction = '[temporary Component] delete Custom Temp Document',
  deleteCustomTempDocumentSuccessAction = '[temporary Component] delete Custom Temp Document Success',
  postFileProceduresDataAction = '[temporary Component] post File Procedures Data',
  postFileProceduresDataSuccessAction = '[temporary Component] post File Procedures Data Success',
  deleteFileProceduresDataAction = '[temporary Component] delete File Procedures Data',
  deleteFileProceduresDataSuccessAction = '[temporary Component] delete File Procedures Data Success',
  approvalCustomStatementTempsAction = '[temporary Component] approval Custom Statement Temps',
  approvalCustomStatementTempsSuccessAction = '[temporary Component] approval Custom Statement Temps Success',
  resetApprovalCustomTempsDocumentsAction = '[temporary Component] reset Approval Custom Temps Documents',
  inconsistencieCustomStatementTempsAction = '[temporary Component] inconsistencie Custom Statement Temps',
  inconsistencieCustomStatementTempsSuccessAction = '[temporary Component] inconsistencie Custom Statement Temps Success',
  resetInconsistencieCustomTempsDocumentsAction = '[temporary Component] reset Inconsistencie Custom Temps Documents',
  getNotificationsAction = '[temporary Component] get Notifications',
  getNotificationsSuccessAction = '[temporary Component] get Notifications Documents',
}

const getCustomStatementTemps = createAction(
  TemporaryActionType.getCustomStatementTempsAction,
  props<{ importNumber: string, closure: boolean, page: number, size: number }>()
);

const getCustomStatementTempsSuccess = createAction(
  TemporaryActionType.getCustomStatementTempsSuccessAction,
  props<{ customTempResponseData: ICustomTempResponse | null }>()
);

const getImports = createAction(
  TemporaryActionType.getImportsAction,
  props<{ SearchBody: ISearchBody, page: number, size: number, isClosure: boolean }>()
);

const getImportsSuccess = createAction(
  TemporaryActionType.getImportsSuccessAction,
  props<{ searchResponseData: ISearchResponse | null }>()
);

const onError = createAction(
  TemporaryActionType.onErrorAction,
  props<{ error: any }>()
);
const onResetFlags = createAction(
  TemporaryActionType.onResetFlagsAction
);
const setTemporary = createAction(
  TemporaryActionType.setTemporaryAction,
  props<{ temporaryData: ISearchDto }>()
);
const onResetProperty = createAction(
  TemporaryActionType.onResetPropertyAction,
  props<{ nameProperty: string }>()
);

const setStatement = createAction(
  TemporaryActionType.setStatementAction,
  props<{ statementData: IStatement }>()
);

const createCustomStatementTemp = createAction(
  TemporaryActionType.createCustomStatementTempAction,
  props<{ importNumber: string, statement: IStatement }>()
);

const createCustomStatementTempSuccess = createAction(
  TemporaryActionType.createCustomStatementTempSuccessAction,
  props<{ statementResponseData: IStatementResponse | null }>()
);
const updateCustomStatementTemp = createAction(
  TemporaryActionType.updateCustomStatementTempAction,
  props<{ customTempId: number, statement: IStatement }>()
);

const updateCustomStatementTempSuccess = createAction(
  TemporaryActionType.updateCustomStatementTempSuccessAction,
  props<{ updateStatementResponseData: IStatementResponse | null }>()
);

const deleteCustomStatementTemp = createAction(
  TemporaryActionType.deleteCustomStatementTempAction,
  props<{ customTempId: number }>()
);

const deleteCustomStatementTempSuccess = createAction(
  TemporaryActionType.deleteCustomStatementTempSuccessAction,
  props<{ deleteStatementResponseData: IStatementResponse | null }>()
);


const getQuotas = createAction(
  TemporaryActionType.getQuotasAction,
  props<{ declarationNumb: number }>()
);

const getQuotasSuccess = createAction(
  TemporaryActionType.getQuotasSuccessAction,
  props<{ quotaResponseData: IQuotaResponse | null }>()
);

const updateQuotas = createAction(
  TemporaryActionType.updateQuotasAction,
  props<{ quotaId: number, quota: IQuota }>()
);

const updateQuotasSuccess = createAction(
  TemporaryActionType.updateQuotasSuccessAction,
  props<{ updateQuotaResponseData: IQuotaResponse | null }>()
);

const getFileExcelCustomStatementTemp = createAction(
  TemporaryActionType.getFileExcelCustomStatementTempAction,
  props<{ importNumber: string, fileName: string }>()
);

const getFileExcelQuotas = createAction(
  TemporaryActionType.getFileExcelQuotasAction,
  props<{ declarationNumb: string, fileName: string }>()
);

const getDocumentTypes = createAction(
  TemporaryActionType.getDocumentTypesAction,
  // props<{ data: any }>()
);

const getDocumentTypesSuccess = createAction(
  TemporaryActionType.getDocumentTypesSuccessAction,
  props<{ documentTypesData: any }>()
);
const getCustomTempsDocuments = createAction(
  TemporaryActionType.getCustomTempsDocumentsAction,
  props<{ declarationNumber: string }>()
);
const getCustomTempsDocumentsSuccess = createAction(
  TemporaryActionType.getCustomTempsDocumentsSuccessAction,
  props<{ customTempsDocumentsData: ICustomTempDocumentCreateResponse }>()
);
const createCustomTempDocument = createAction(
  TemporaryActionType.createCustomTempDocumentAction,
  props<{ importNumber: string, declarationNumber: string, customTempDocument: ICustomTempDocumentCreate }>()
);
const createCustomTempDocumentSuccess = createAction(
  TemporaryActionType.createCustomTempDocumentSuccessAction,
  props<{ createCustomTempDocumentData: ICustomTempDocumentCreateResponse }>()
);
const updateCustomTempDocument = createAction(
  TemporaryActionType.updateCustomTempDocumentAction,
  props<{ formalitiesDocid: number, customTempDocument: ICustomTempDocumentUpdate }>()
);
const updateCustomTempDocumentSuccess = createAction(
  TemporaryActionType.updateCustomTempDocumentSuccessAction,
  props<{ updateCustomTempDocumentData: ICustomTempDocumentCreateResponse }>()
);
const deleteCustomTempDocument = createAction(
  TemporaryActionType.deleteCustomTempDocumentAction,
  props<{ formalitiesDocid: number }>()
);
const deleteCustomTempDocumentSuccess = createAction(
  TemporaryActionType.deleteCustomTempDocumentSuccessAction,
  props<{ deleteCustomTempDocumentData: any }>()
);
const postFileProceduresData = createAction(
  TemporaryActionType.postFileProceduresDataAction,
  props<{ importNumber: string, body: any }>()
);
const postFileProceduresDataSuccess = createAction(
  TemporaryActionType.postFileProceduresDataSuccessAction,
  props<{ postFileProceduresData: any }>()
);
const deleteFileProceduresData = createAction(
  TemporaryActionType.deleteFileProceduresDataAction,
  props<{ id: number, updateAt: string }>()
);
const deleteFileProceduresDataSuccess = createAction(
  TemporaryActionType.deleteFileProceduresDataSuccessAction,
  props<{ deleteFileProceduresData: any }>()
);
const approvalCustomStatementTemps = createAction(
  TemporaryActionType.approvalCustomStatementTempsAction,
  props<{ customTempId: number }>()
);
const approvalCustomStatementTempsSuccess = createAction(
  TemporaryActionType.approvalCustomStatementTempsSuccessAction,
  props<{ approvalCustomStatementTempsData: any }>()
);
const inconsistencieCustomStatementTemps = createAction(
  TemporaryActionType.inconsistencieCustomStatementTempsAction,
  props<{ inconsistencie: IInconsistencie }>()
);
const inconsistencieCustomStatementTempsSuccess = createAction(
  TemporaryActionType.inconsistencieCustomStatementTempsSuccessAction,
  props<{ inconsistencieCustomStatementTempsData: any }>()
);
const getNotifications = createAction(
  TemporaryActionType.getNotificationsAction,
  // props<{ declarationNumber: string }>()
);
const getNotificationsSuccess = createAction(
  TemporaryActionType.getNotificationsSuccessAction,
  props<{ notificationsData: INotificationTemporaryResponse }>()
);
export const TemporaryActions = {
  getCustomStatementTemps,
  getCustomStatementTempsSuccess,
  getImports,
  getImportsSuccess,
  onError,
  onResetFlags,
  onResetProperty,
  setTemporary,
  setStatement,
  createCustomStatementTemp,
  createCustomStatementTempSuccess,
  updateCustomStatementTemp,
  updateCustomStatementTempSuccess,
  deleteCustomStatementTemp,
  deleteCustomStatementTempSuccess,
  getQuotas,
  getQuotasSuccess,
  getFileExcelCustomStatementTemp,
  getFileExcelQuotas,
  updateQuotas,
  updateQuotasSuccess,
  getDocumentTypes,
  getDocumentTypesSuccess,
  getCustomTempsDocuments,
  getCustomTempsDocumentsSuccess,
  createCustomTempDocument,
  createCustomTempDocumentSuccess,
  updateCustomTempDocument,
  updateCustomTempDocumentSuccess,
  deleteCustomTempDocument,
  deleteCustomTempDocumentSuccess,
  postFileProceduresData,
  postFileProceduresDataSuccess,
  deleteFileProceduresData,
  deleteFileProceduresDataSuccess,
  approvalCustomStatementTemps,
  approvalCustomStatementTempsSuccess,
  inconsistencieCustomStatementTemps,
  inconsistencieCustomStatementTempsSuccess,
  getNotifications,
  getNotificationsSuccess,
};
