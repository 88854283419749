<app-header></app-header>

<bc-menu  class="menu-app-showcase">
  <bc-menu-drawer>
    <ng-container *ngIf="canActivateInicio">
      <bc-menu-item
        [routerLink]="['/app/inicio/consulta-general-importaciones']"
        identifierContent="id1"
        text="Inicio"
        iconName="home"
        (click)="changeTitle('Inicio')"
      ></bc-menu-item>
    </ng-container>

    <ng-container *ngIf="canActivateCotizaciones">
      <bc-menu-item
        [routerLink]="[urlCotization]"
        identifierContent="id2"
        text="Cotización"
        iconName="file-invoice"
        (click)="changeTitle('Cotización')"
      ></bc-menu-item>
    </ng-container>

    <ng-container *ngIf="canActivateGenerales">
      <bc-menu-item
        [routerLink]="['/app/generales']"
        identifierContent="id3"
        text="Generales"
        iconName="preparer-User"
        (click)="changeTitle('Generales')"
      ></bc-menu-item>
    </ng-container>

    <ng-container *ngIf="canActivateGenerales">
      <bc-menu-item
        [routerLink]="['/app/informes']"
        identifierContent="id4"
        text="Informes"
        iconName="download-document"
        (click)="changeTitle('Informes')"
      ></bc-menu-item>
    </ng-container>

    <ng-container *ngIf="canActivateTemporales">
      <bc-menu-item
        [routerLink]="['/app/temporales']"
        identifierContent="id5"
        text="Temporales"
        iconName="calendar-edit"
        (click)="changeTitle('temporales')"
      ></bc-menu-item>
    </ng-container>

    <ng-container>
      <div class="log-out bc-menu-submenu btn-personalized">
        <div class="log-out-items bc-submenu-footer-container">
          <button
            class="log-out-btn bc-fab-button bc-fab-button-mini"
            (click)="logOut()">
            <bc-icon class="bc-icon bc-sm">quit</bc-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </bc-menu-drawer>

  <bc-menu-main></bc-menu-main>

  <div class="bc-content-body">
    <div class="bc-content-router">
      <div class="d-flex justify-content-end align-items-center">
        <p class="m-0 status">{{ date }} {{ hour }}</p>
      </div>
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</bc-menu>
