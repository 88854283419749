<div>
  <bc-paginator
    class="pag-super d-none"
    [type]="'basic'"
    [id]="'basic'"
    [totalRegisters]="totalRegisters"
    [perPage]="perPage"
    (onChangePage)="onChangePage($event)"
  ></bc-paginator>

  <mat-paginator
    class="pag-super d-none"
    [length]="100"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Select page">
  </mat-paginator>

  <section class="container">
    <div class="bc-padding-6 content">
      <bc-card-container
        cardType="default"
        borderClass="bc-border-radius-4-full"
      >
        <div class="row">
          <div class="col-12 pb-3">
            <bc-checkbox
              class="title-checkbox"
              (changeState)="state=$event"
              label="Agenciamiento aduanero"
              [isChecked]="true"
            ></bc-checkbox>
          </div>

          <div class="col-12">
            <bc-switch *ngFor="let item of labelSwitchRoles"
              class="col-12"
              (changeState)="state=$event"
              [id]="'myOwnRoles'"
              [disabled]=disabledState
              [required]=requiredState
              [isChecked]=checkedState
            >
              {{nameSwitch}}
            </bc-switch>
          </div>
        </div>
      </bc-card-container>
    </div>
  </section>
</div>

<div class="d-none">
  <section class="container">
    <div class="bc-padding-6 content">
      <bc-card-container
        cardType="default"
        borderClass="bc-border-radius-4-full"
      >
        <div class="row">
          <div class="col-12 pb-3">
            <bc-checkbox
              class="title-checkbox"
              (changeState)="state=$event"
              label="Consulta general de importaciones"
              [isChecked]="true"
            ></bc-checkbox>
          </div>

          <div class="col-12">
            <bc-switch *ngFor="let item of labelSwitchAdmin"
              class="col-12"
              (changeState)="state=$event"
              [id]="'myOwnAdmin'"
              [disabled]=disabledState
              [required]=requiredState
              [isChecked]=checkedState
            >
              {{labelSwitchAdmin}}
            </bc-switch>
          </div>
        </div>
      </bc-card-container>
    </div>
  </section>

  <mat-paginator
    class="pag-infer d-none"
    [length]="100"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Select page">
  </mat-paginator>

  <bc-paginator
    class="pag-infer"
    [type]="'basic'"
    [id]="'basic'"
    [totalRegisters]="totalRegisters"
    [perPage]="perPage"
    (onChangePage)="onChangePage($event)"
  ></bc-paginator>
</div>
