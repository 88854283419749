import { createAction, props } from '@ngrx/store';
import { IImportationData } from 'src/app/data/interfaces/IImportationData.interface';
import { IImportation, IImportationsPagGenerales } from 'src/app/data/interfaces/IImportation.interface';
import { IImportationDataAlly } from 'src/app/data/interfaces/IImportationDataAlly.interface';
import { IImportationFilter } from 'src/app/data/interfaces/IImportationFilter.interface';
import { CustomsAssetseDetails, ICustomsInquiry, ImpcustomsAssetses, MessageFromAssetsesCustoms } from 'src/app/data/interfaces/ICustomsInquiry.interface';
import { ICustomsDataUpdate } from 'src/app/data/interfaces/ICustomsDataUpdate.interface';
import { ICustomnsNationalization, PageAduanaDeclaraciones } from 'src/app/data/interfaces/ICustomsNationalization.interface';
import { ICustomsNatDataUpdate } from 'src/app/data/interfaces/ICustomsNatDataUpdate.interface';
import { IImpLocalTransport, MessageFromDeleteContainer, PageLocalTransportContainers, PageLocalTransportContainersDetail } from 'src/app/data/interfaces/IImpLocalTransport.interface';
import { IImpLocalTransportUpdate } from 'src/app/data/interfaces/IImpLocalTransportUpdate.interface';
import { IImportationFilterByDate } from '../../../../../data/interfaces/IImportationFilterByDate.interfaces';
import { IDispatch } from 'src/app/data/interfaces/IDispatch.interface';
import { IDispatchData } from 'src/app/data/interfaces/IDispatchData.interface';
import { IImpIntTransport, PageIntTransportCargaSuelta, PageIntTransportCargaSueltaDetail, PageIntTransportContainers, PageIntTransportContainersDetail } from 'src/app/data/interfaces/IImpIntTransport.interface';
import { IImpIntTransportUpdate } from 'src/app/data/interfaces/IImpIntTransportUpdate.interface';
import { ImpProcedures, ImpProceduresDetails } from '../../../../../data/interfaces/IImpProcedures.interface';
import { IImportationsPag } from '../../../../../data/interfaces/IImportationsPaginator.interface';
import { IImportPreData } from '../../../../../data/interfaces/IImportationDataPreUp.interface';

enum ImportationActionType {
  searchImports = '[Import Component] Search Import',
  searchImportsSuccess = '[Importation Component] Search Importation Success',

  updateImport = '[Importation Component] Update Importation',
  updateImportSuccess = '[Importation Component] Update Importation Success',

  updateTotal = '[Importation Component] Update Total',
  updateTotalSuccess = '[Importation Component] Update Total Success',

  onErrorAction = '[Importation Component] Ups Error',

  saveImportationDataAction = '[Importation Component] Save Importation Data',
  saveImportationDataSuccessAction = '[Importation Component] Save Importation Data Success ',

  saveImportationTransporteLocalContenedoresAction = '[Importation Component]   save Importation Transporte Local Contenedores',
  saveImportationTransporteLocalContenedoresSuccessAction = '[Importation Component] save Importation Transporte Local Contenedores Success ',

  saveContainerDataIntAction = '[Importation  Int Component] Save Container Data',
  saveContainerDataIntSuccessAction = '[Importation Int Component] Save Container Data Success ',

  saveCargaSueltaDataAction = '[Importation  saveCargaSueltaData Component] save Carga Suelta Data',
  saveCargaSueltaDataSuccessAction = '[Importation saveCargaSueltaDataSuccess Component] save Carga Suelta Data Success ',

  putImportationTransporteLocalContenedoresAction = '[Importation Component] Put Container Data',
  putImportationTransporteLocalContenedoresSuccessAction = '[Importation Component] Put Container Data Success ',

  deleteImportationContainerAction = '[Importation Component]  delete Importation Data',
  deleteImportationContainerSuccessAction = '[Importation Component] delete Importation Dataa Success ',

  deleteImportationCargaSueltaInternationalAction = '[Importation international carga suelta Component] delete Importation CargaSuelta International',
  deleteImportationCargaSueltaInternationalSuccessAction = '[Importation  international carga suelta Component] delete Importation CargaSuelta International Success ',

  deleteImportationContainerInternationalAction = '[Importation International Component] delete Importation Container International',
  deleteImportationContainerInternationalSuccessAction = '[Importation International Component] delete Importation Container International Success ',

  createImportationDataAction = '[Importation Component] Create Importation',
  createImportationDataSuccessAction = '[Importation Component] Create Importation Success',

  createDispatchDataDataAction = '[Dispatch Component] Create Dispatch',
  createDispatchDataSuccessAction = '[Dispatch Component] Create Dispatch Success',
  getDispatchDataDataAction = '[Dispatch Component] get Dispatch',

  onResetFlagsAction = '[Importations API] Importations reset flags',
  onResetImportationsTramitesFlags = '[Importations API Tramites] Importations reset flags for tramites',
  onResetFlagsTrueAction = '[Importations Component] Importations reset true',
  onResetFlagsErrorAction = '[Importations Component] Importations reset error',

  searchImportationByFilterAction = '[Importation Component] Search Importation By Filter',
  searchImportationByFilterSuccessAction = '[Importation Component] Search Importation By Filter Success',

  searchImportationByFilterToDeleteAction = '[Importation Component] Search Importation By Filter To Delete',
  searchImportationByFilterToDeleteSuccessAction = '[Importation Component] Search Importation By Filter To Delete Success',

  
  deleteImportationsAction = '[Importation Component] Delete Importations',
  deleteImportationsSuccessAction = '[Importation Component] Delete Importations Success',

  setImportationAction = '[Importations Component] Set Importation into Storage',

  onEditImportationAction = '[Importations Component] Set true edit importation',

  findImportationByParamsAction = '[Import Component] Find Importation By ParamsSi',
  findImportationByParamsSuccessAction = '[Import Component] Find Importation By ParamsSi Success',

  findImportationByImportNumberAction = '[Import Component] find a Import By ImportNumber',
  findImportationByImportNumberSuccessAction = '[Import Component] find Import By ImportNumber Success',

  findCustomsByImportNumberAction = '[Customs Component] find a Customs By ImportNumber',
  findCustomsByImportNumberSuccessAction = '[Customs Component] find Customs By ImportNumber Success',

  updateCustomsDataAction = '[Customs Component] Update Customs',
  updateCustomsDataSuccessAction = '[Customs Component] Update Customs Success',

  findCustomsNaturalizationByImportNumberAction = '[CustomsNaturalization Component] find a Customs Naturalization By ImportNumber',
  findCustomsNaturalizationByImportNumberSuccessAction = '[CustomsNaturalization Component] find a Customs Naturalization By ImportNumber Success',

  updateCustomsNatDataAction = '[CustomsNaturalization Component] Update Customs Naturalization',
  updateCustomsNatDataSuccessAction = '[CustomsNaturalization Component] Update Customs Naturalization Success',

  getLocalTranspByImportNumberAction = '[LocalTransportForm1Component Component] get a Local Transport Form1 Component By ImportNumber',
  getLocalTranspByImportNumberSuccessAction = '[LocalTransportForm1Component Component] get a Local Transport Form1 Component By ImportNumber Success',

  getImportationInternationalContainersPAction = '[InternationalTransportForm1Component Component] get a getImportationInternationalContainersP By ImportNumber Page Size and Page Index',
  getImportationInternationalContainersPSuccessAction = '[InternationalTransportForm1Component Component] get getImportationInternationalContainersPSuccess By ImportNumber Page Size and Page Index Success',

  getDeclarationsDataPAction = '[Aduanafromcomponent Component] get a getDeclarationsDataP By ImportNumber Page Size and Page Index',
  getDeclarationsDataPSuccessAction = '[Aduanafromcomponent Component] get getDeclarationsDataPSuccess By ImportNumber Page Size and Page Index Success',

  saveFileDeclraracionesAction = '[Importation  saveFileDeclraraciones Component] Save saveFileDeclraraciones Data',
  saveFileDeclraracionesSuccessAction = '[Importation saveFileDeclraracionesSuccess Component] Save saveFileDeclraracionesSuccess Data Success ',

  getImportatioInternationalCargaSueltaPAction = '[InternationalTransportForm1Component Component] get a getImportatioInternationalCargaSueltaP By ImportNumber Page Size and Page Index',
  getImportatioInternationalCargaSueltaPSuccessAction = '[InternationalTransportForm1Component Component] get a getImportatioInternationalCargaSueltaPSuccess By ImportNumber Page Size and Page Index Success',

  getImportationTransporteLocalContenedoresAction = '[LocalTransportForm1Component Component] get a getImportationTransporteLocalContenedores By ImportNumber Page Size and Page Index',
  getImportationTransporteLocalContenedoresSuccessAction = '[LocalTransportForm1Component Component] get a getImportationTransporteLocalContenedores By ImportNumber Page Size and Page Index Success',

  getImportationTransporteLocalCargaSueltaAction = '[LocalTransportForm1Component Component] get a getImportationTransporteLocalCargaSuelta By ImportNumber Page Size and Page Index',
  getImportationTransporteLocalCargaSueltaSuccessAction = '[LocalTransportForm1Component Component] get getImportationTransporteLocalCargaSueltaSuccess By ImportNumber Page Size and Page Index Success',

  putImpLocalTransportByIdAction = '[LocalTransportForm1Component Component] put LocalTransportForm1Component By ImportNumber',
  putImpLocalTransportByIdSuccessAction = '[LocalTransportForm1Component Component] put LocalTransportForm1Component By ImportNumber Success',

  getIntTranspByImportNumberAction = '[InternationalTransportForm1Component Component] get a InternationalTransportForm1Component By ImportNumber',
  getIntTranspByImportNumberSuccessAction = '[InternationalTransportForm1Component Component] get InternationalTransportForm1Component By ImportNumber Success',

  putImpIntTransportByIdAction = '[InternationalTransportForm1Component Component] put InternationalTransportForm1Component By ImportNumber',
  putImpIntTransportByIdSuccessAction = '[InternationalTransportForm1Component Component] put InternationalTransportForm1Component By ImportNumber Success',

  postFilterImpAssetsCustomsAction = '[CustomsFormComponent Component] post CustomsFormComponent By ImportNumber',
  postFilterImpAssetsCustomsSuccessAction = '[CustomsFormComponent Component] post CustomsFormComponent By ImportNumber Success',

  deleteImpAssetsCustomsAction = '[CustomsFormComponent Component] Delete Assetses Data',
  deleteImpAssetsCustomsSuccessAction = '[CustomsFormComponent Component] Delete Assetses Data Success ',

  putImpAssetsCustomsByIdAction = '[CustomsFormComponent Component] put CustomsFormComponent By Id',
  putImpAssetsCustomsByIdSuccessAction = '[CustomsFormComponent Component] put CustomsFormComponent By Id Success',

  saveImpAssetsCustomsDataAction = '[CustomsFormComponent Component] Save Assetses Data',
  saveImpAssetsCustomsDataSuccessAction = '[CustomsFormComponent Component] Save Assetses Data Success ',

  putImportationContainerInternationalAction = '[CustomsFormComponent Component] put  putImportation Container International By ImportNumber',
  putImportationContainerInternationalSuccessAction = '[CustomsFormComponent Component] put  putImportation Container International By ImportNumber Success',

  putImportationCargaSueltaInternationalAction = '[CustomsFormComponent Component] put putImportationCargaSueltaInternational By ImportNumber',
  putImportationCargaSueltaInternationalSuccessAction = '[CustomsFormComponent Component] put putImportationCargaSueltaInternationalSuccess By ImportNumber Success',

  getImpProceduresListFilterAction = '[ProceduresComponent Component] get a getImpProceduresFilter By ImportNumber',
  getImpProceduresListFilterSuccessAction = '[ProceduresComponent Component] get getImpProceduresFilterSuccess',

  getImpProceduresFilterAction = '[ProceduresComponent Component] get a getImpProceduresFilter By ImportNumber Page Size and Page Index',
  getImpProceduresFilterSuccessAction = '[ProceduresComponent Component] get getImpProceduresFilterSuccess By ImportNumber Page Index Page Size and Success',

  postImpProceduresDataAction = '[ProceduresComponent Component] post ProceduresComponent By ImportNumber',
  postImpProceduresDataSuccessAction = '[ProceduresComponent Component] post ProceduresComponent By ImportNumber Success',

  deleteImpProceduresDataAction = '[ProceduresComponent Component] Delete Tramites Data',
  deleteImpProceduresDataSuccessAction = '[ProceduresComponent Component] Delete Tramites Data Success ',

  putImpProceduresDataAction = '[ProceduresComponent Component] putImpProceduresData By Id',
  putImpProceduresDataSuccessAction = '[ProceduresComponent Component] putImpProceduresDataSuccess By Id Success',

  putImportationDataAction = '[Importation Component] Put Importation Data',
  putImportationDataSuccessAction = '[Importation Component] Put Importation Data Success',
}

const saveImportationData = createAction(
  ImportationActionType.saveImportationDataAction,
  props<{ importationData: IImportationData }>()
);
const saveImportationDataSuccess = createAction(
  ImportationActionType.saveImportationDataSuccessAction,
  props<{ importation: IImportation }>()
);

const putImportationDataUp = createAction(
  ImportationActionType.putImportationDataAction,
  props<{ importNumber: string, importationData: IImportPreData }>()
);
const putImportationDataUpSuccess = createAction(
  ImportationActionType.putImportationDataSuccessAction,
  props<{ importation: IImportation }>()
);

const saveImportationTransporteLocalContenedores = createAction(
  ImportationActionType.saveImportationTransporteLocalContenedoresAction,
  props<{ importId: number, imcontainerData: PageLocalTransportContainersDetail }>()
);
const saveImportationTransporteLocalContenedoresSuccess = createAction(
  ImportationActionType.saveImportationTransporteLocalContenedoresSuccessAction,
  props<{ container: PageLocalTransportContainersDetail }>()
);

const saveContainerDataInt = createAction(
  ImportationActionType.saveContainerDataIntAction,
  props<{ importIdInt: number, importDataIntCont: PageIntTransportContainersDetail }>()
);

const saveContainerDataIntSuccess = createAction(
  ImportationActionType.saveContainerDataIntSuccessAction,
  props<{ container: PageIntTransportContainersDetail }>()
);

const saveCargaSueltaData = createAction(
  ImportationActionType.saveCargaSueltaDataAction,
  props<{ importIdIntC: number, importDataIntCarg: PageIntTransportCargaSueltaDetail }>()
);

const saveCargaSueltaDataSuccess = createAction(
  ImportationActionType.saveCargaSueltaDataSuccessAction,
  props<{ container: PageIntTransportCargaSueltaDetail }>()
);


const deleteImportationContainerInternational = createAction(
  ImportationActionType.deleteImportationContainerInternationalAction,
  props<{ id: number }>()
);


const deleteImportationContainerInternationalSuccess = createAction(
  ImportationActionType.deleteImportationContainerInternationalSuccessAction,
  props<{ mess: MessageFromDeleteContainer }>()
);

const putImportationContainerInternational = createAction(
  ImportationActionType.putImportationContainerInternationalAction,
  props<{ contId: number, intContainerData: PageIntTransportContainersDetail }>()
);

const putImportationContainerInternationalSuccess = createAction(
  ImportationActionType.putImportationContainerInternationalSuccessAction,
  props<{ intContainerData: PageIntTransportContainersDetail }>()
);

const putImportationCargaSueltaInternational = createAction(
  ImportationActionType.putImportationCargaSueltaInternationalAction,
  props<{ loadId: number, intLoadData: PageIntTransportCargaSueltaDetail }>()
);

const putImportationCargaSueltaInternationalSuccess = createAction(
  ImportationActionType.putImportationCargaSueltaInternationalSuccessAction,
  props<{ intLoadData: PageIntTransportCargaSueltaDetail }>()
);


const deleteImportationCargaSueltaInternational = createAction(
  ImportationActionType.deleteImportationCargaSueltaInternationalAction,
  props<{ id: number }>()
);

const deleteImportationCargaSueltaInternationalSuccess = createAction(
  ImportationActionType.deleteImportationCargaSueltaInternationalSuccessAction,
  props<{ message: MessageFromDeleteContainer }>()
);

const deleteImportationContainer = createAction(
  ImportationActionType.deleteImportationContainerAction,
  props<{ containerId: number }>()
);

const deleteImportationContainerSuccess = createAction(
  ImportationActionType.deleteImportationContainerSuccessAction,
  props<{ message: MessageFromDeleteContainer }>()
);


const putImportationTransporteLocalContenedores = createAction(
  ImportationActionType.putImportationTransporteLocalContenedoresAction,
  props<{ containerId: number, imcontainerData: PageLocalTransportContainersDetail }>()
);
const putImportationTransporteLocalContenedoresSuccess = createAction(
  ImportationActionType.putImportationTransporteLocalContenedoresSuccessAction,
  props<{ container: PageLocalTransportContainersDetail }>()
);

const onError = createAction(
  ImportationActionType.onErrorAction,
  props<{ error: any }>()
);

const createImportationData = createAction(
  ImportationActionType.createImportationDataAction,
  props<{ importationDataAlly: IImportationDataAlly }>()
);
const createImportationDataSuccess = createAction(
  ImportationActionType.createImportationDataSuccessAction,
  props<{ importation: IImportation }>()
);

const createDispatchData = createAction(
  ImportationActionType.createDispatchDataDataAction,
  props<{ dispatchData: IDispatchData, dispatchNumber: string }>()
);
const createDispatchDataSuccess = createAction(
  ImportationActionType.createDispatchDataSuccessAction,
  props<{ dispatch: IDispatch }>()
);

const getDispatchData = createAction(
  ImportationActionType.getDispatchDataDataAction
);

const updateCustomsData = createAction(
  ImportationActionType.updateCustomsDataAction,
  props<{ id: number; customsDataUpdate: ICustomsDataUpdate }>()
);
const updateCustomsDataSuccess = createAction(
  ImportationActionType.updateCustomsDataSuccessAction,
  props<{ customs: ICustomsInquiry }>()
);

const updateCustomsNatData = createAction(
  ImportationActionType.updateCustomsNatDataAction,
  props<{ id: number; customsNatDataUpdate: ICustomsNatDataUpdate }>()
);
const updateCustomsNatDataSuccess = createAction(
  ImportationActionType.updateCustomsNatDataSuccessAction,
  props<{ customsNaturalization: ICustomnsNationalization }>()
);

const onResetFlags = createAction(ImportationActionType.onResetFlagsAction);
const onResetFlagsTrue = createAction(ImportationActionType.onResetFlagsTrueAction);
const onResetFlagsError = createAction(ImportationActionType.onResetFlagsErrorAction);

/** Buscar por Número de importación */
const findImportationByImportNumber = createAction(
  ImportationActionType.findImportationByImportNumberAction,
  props<{ importNumber: string }>()
);
const findImportationByImportNumberSuccess = createAction(
  ImportationActionType.findImportationByImportNumberSuccessAction,
  props<{ importation: IImportation }>()
);

/** Busqueda aduanera por número de importación */
const findCustomsByImportNumber = createAction(
  ImportationActionType.findCustomsByImportNumberAction,
  props<{ importNumber: string }>()
);
const findCustomsByImportNumberSuccess = createAction(
  ImportationActionType.findCustomsByImportNumberSuccessAction,
  props<{ customs: ICustomsInquiry }>()
);
/** Fin busqueda por número de importación */

const findCustomsNaturalizationByImportNumber = createAction(
  ImportationActionType.findCustomsNaturalizationByImportNumberAction,
  props<{ importNumber: string }>()
);

const findCustomsNaturalizationByImportNumberSuccess = createAction(
  ImportationActionType.findCustomsNaturalizationByImportNumberSuccessAction,
  props<{ customsNaturalization: ICustomnsNationalization }>()
);

const findImportationByParams = createAction(
  ImportationActionType.findImportationByParamsAction,
  props<{
    params: IImportationFilterByDate;
    page: number;
    size: number;
  }>()
);
const findImportationByParamsSuccess = createAction(
  ImportationActionType.findImportationByParamsSuccessAction,
  props<{ importationListPag: IImportationsPag }>()
);
// ADUANAS ACTIVOS
const postFilterImpAssetsCustoms = createAction(
  ImportationActionType.postFilterImpAssetsCustomsAction,
  props<{ importNumber: string; page: number; size: number; }>()
);
const postFilterImpAssetsCustomsSuccess = createAction(
  ImportationActionType.postFilterImpAssetsCustomsSuccessAction,
  props<{ impCustomsAssetses: ImpcustomsAssetses }>()
);

const deleteImpAssetsCustoms = createAction(
  ImportationActionType.deleteImpAssetsCustomsAction,
  props<{ id: number }>()
);

const deleteImpAssetsCustomsSuccess = createAction(
  ImportationActionType.deleteImpAssetsCustomsSuccessAction,
  props<{ message: MessageFromAssetsesCustoms }>()
);

const putImpAssetsCustomsById = createAction(
  ImportationActionType.putImpAssetsCustomsByIdAction,
  props<{ id: number, impAssetsCustomsDataUpdate: CustomsAssetseDetails }>()
);

const putImpAssetsCustomsByIdSuccess = createAction(
  ImportationActionType.putImpAssetsCustomsByIdSuccessAction,
  props<{ impAssetsCustomsDataUpdate: CustomsAssetseDetails }>()
);

const saveImpAssetsCustomsData = createAction(
  ImportationActionType.saveImpAssetsCustomsDataAction,
  props<{ importNumber: string, impAssetsCustomsData: CustomsAssetseDetails }>()
);
const saveImpAssetsCustomsDataSuccess = createAction(
  ImportationActionType.saveImpAssetsCustomsDataSuccessAction,
  props<{ impAssetsCustomsData: CustomsAssetseDetails }>()
);
// END ADUANAS ACTIVOS

// TRAMITES
const getImpProceduresFilter = createAction(
  ImportationActionType.getImpProceduresFilterAction,
  props<{ importNumber: string }>()
);
const getImpProceduresFilterSuccess = createAction(
  ImportationActionType.getImpProceduresFilterSuccessAction,
  props<{ impProceduresList: ImpProcedures }>()
);

const postImpProceduresData = createAction(
  ImportationActionType.postImpProceduresDataAction,
  props<{ importNumber: string, body: any }>()
);

const postImpProceduresDataSuccess = createAction(
  ImportationActionType.postImpProceduresDataSuccessAction,
  props<{ response: any }>()
);

const deleteImpProceduresData = createAction(
  ImportationActionType.deleteImpProceduresDataAction,
  props<{ id: number }>()
);

const deleteImpProceduresDataSuccess = createAction(
  ImportationActionType.deleteImpProceduresDataSuccessAction,
  props<{ message: string }>()
);

const putImpProceduresData = createAction(
  ImportationActionType.putImpProceduresDataAction,
  props<{ id: number, impProceduresDataUpdate: ImpProceduresDetails }>()
);
const putImpProceduresDataSuccess = createAction(
  ImportationActionType.putImpProceduresDataSuccessAction,
  props<{ impProceduresDataUpdate: ImpProceduresDetails }>()
);
// END TRAMITES

const getLocalTranspByImportNumber = createAction(
  ImportationActionType.getLocalTranspByImportNumberAction,
  props<{ importNumber: string }>()
);
const getLocalTranspByImportNumberSuccess = createAction(
  ImportationActionType.getLocalTranspByImportNumberSuccessAction,
  props<{ impLocalTransport: IImpLocalTransport }>()
);

const getImportationTransporteLocalCargaSuelta = createAction(
  ImportationActionType.getImportationTransporteLocalCargaSueltaAction,
  props<{ typeLoad: string, importNumber: string, pageSize: number, pageindex: number }>()
);
const getImportationTransporteLocalCargaSueltaSuccess = createAction(
  ImportationActionType.getImportationTransporteLocalCargaSueltaSuccessAction,
  props<{ pageLocalTransportContainers: PageLocalTransportContainers }>()
);

const getImportationTransporteLocalContenedores = createAction(
  ImportationActionType.getImportationTransporteLocalContenedoresAction,
  props<{ typeLoad: string, importNumber: string, pageSize: number, pageindex: number }>()
);

const getImportationTransporteLocalContenedoresSuccess = createAction(
  ImportationActionType.getImportationTransporteLocalContenedoresSuccessAction,
  props<{ pageLocalTransportContainers: PageLocalTransportContainers }>()
);

const getImportationInternationalContainersP = createAction(
  ImportationActionType.getImportationInternationalContainersPAction,
  props<{ importNumber: string, pageSize: number, pageindex: number }>()
);

const getImportationInternationalContainersPSuccess = createAction(
  ImportationActionType.getImportationInternationalContainersPSuccessAction,
  props<{ pageIntTranportContainer: PageIntTransportContainers }>()
);

const getDeclarationsDataP = createAction(
  ImportationActionType.getDeclarationsDataPAction,
  props<{ importNumber: string, pageSize: number, pageindex: number }>()
);

const getDeclarationsDataPSuccess = createAction(
  ImportationActionType.getDeclarationsDataPSuccessAction,
  props<{ pageAduanaDeclaraciones: PageAduanaDeclaraciones }>()
);

const saveFileDeclraraciones = createAction(
  ImportationActionType.saveFileDeclraracionesAction,
  props<{ importNumber: string, file: File }>()
);

const saveFileDeclracionesSuccess = createAction(
  ImportationActionType.saveFileDeclraracionesSuccessAction,
  props<{ saveFileDeclraracionesData: any }>()
);

const getImportatioInternationalCargaSueltaP = createAction(
  ImportationActionType.getImportatioInternationalCargaSueltaPAction,
  props<{ importNumber: string, pageSize: number, pageindex: number }>()
);

const getImportatioInternationalCargaSueltaPSuccess = createAction(
  ImportationActionType.getImportatioInternationalCargaSueltaPSuccessAction,
  props<{ pageIntTranportCargaSuelta: PageIntTransportCargaSuelta }>()
);

const getIntTranspByImportNumber = createAction(
  ImportationActionType.getIntTranspByImportNumberAction,
  props<{ importNumber: string }>()
);
const getIntTranspByImportNumberSuccess = createAction(
  ImportationActionType.getIntTranspByImportNumberSuccessAction,
  props<{ impIntTransport: IImpIntTransport }>()
);

const putImpLocalTransportById = createAction(
  ImportationActionType.putImpLocalTransportByIdAction,
  props<{ id: number; impLocalTransportUpdate: IImpLocalTransportUpdate }>()
);
const putImpLocalTransportByIdSuccess = createAction(
  ImportationActionType.putImpLocalTransportByIdSuccessAction,
  props<{ impLocalTransport: IImpLocalTransport }>()
);

const putImpIntTransportById = createAction(
  ImportationActionType.putImpIntTransportByIdAction,
  props<{ id: number; impIntTransportUpdate: IImpIntTransportUpdate }>()
);
const putImpIntTransportByIdSuccess = createAction(
  ImportationActionType.putImpIntTransportByIdSuccessAction,
  props<{ impIntTransport: IImpIntTransport }>()
);

const onResetImportationsTramitesFlags = createAction(ImportationActionType.onResetImportationsTramitesFlags);

const searchImportationByFilter = createAction(
  ImportationActionType.searchImportationByFilterAction,
  props<{ params: IImportationFilter; page: number; size: number, isImport?: string }>()
);
const searchImportationByFilterSuccess = createAction(
  ImportationActionType.searchImportationByFilterSuccessAction,
  props<{ importations: IImportationsPagGenerales }>()
);

const searchImportationByFilterToDelete = createAction(
  ImportationActionType.searchImportationByFilterToDeleteAction,
  props<{ params: IImportationFilter; page: number; size: number}>()
);
const searchImportationByFilterToDeleteSuccess = createAction(
  ImportationActionType.searchImportationByFilterToDeleteSuccessAction,
  props<{ importations: IImportationsPagGenerales }>()
);

const deleteImportations = createAction(
  ImportationActionType.deleteImportationsAction,
  props<{ body: any}>()
);
const deleteImportationsSuccess = createAction(
  ImportationActionType.deleteImportationsSuccessAction,
  props<{ edit: boolean }>()
);

const setImportation = createAction(
  ImportationActionType.setImportationAction,
  props<{ importation: IImportation }>()
);
const onEditImportation = createAction(
  ImportationActionType.onEditImportationAction,
  props<{ edit: boolean }>()
);

export const ImportationActions = {
  saveImportationData,
  onResetImportationsTramitesFlags,
  saveImportationDataSuccess,
  onError,
  createImportationData,
  createImportationDataSuccess,
  createDispatchData,
  createDispatchDataSuccess,
  getDispatchData,
  onResetFlags,
  onResetFlagsTrue,
  onResetFlagsError,
  findImportationByParams,
  findImportationByParamsSuccess,
  findImportationByImportNumber,
  findImportationByImportNumberSuccess,
  findCustomsByImportNumber,
  findCustomsByImportNumberSuccess,
  updateCustomsData,
  updateCustomsDataSuccess,
  findCustomsNaturalizationByImportNumber,
  findCustomsNaturalizationByImportNumberSuccess,
  updateCustomsNatData,
  updateCustomsNatDataSuccess,
  getLocalTranspByImportNumber,
  getImportationTransporteLocalCargaSuelta,
  getImportationTransporteLocalCargaSueltaSuccess,
  getImportationTransporteLocalContenedores,
  getImportationTransporteLocalContenedoresSuccess,
  saveImportationTransporteLocalContenedores,
  saveImportationTransporteLocalContenedoresSuccess,
  saveContainerDataInt,
  saveContainerDataIntSuccess,
  saveCargaSueltaData,
  saveCargaSueltaDataSuccess,
  putImportationTransporteLocalContenedores,
  putImportationTransporteLocalContenedoresSuccess,
  putImportationContainerInternational,
  putImportationContainerInternationalSuccess,
  putImportationCargaSueltaInternational,
  putImportationCargaSueltaInternationalSuccess,
  getImportationInternationalContainersP,
  getImportationInternationalContainersPSuccess,
  saveFileDeclraraciones,
  saveFileDeclracionesSuccess,
  getDeclarationsDataP,
  getDeclarationsDataPSuccess,
  getImportatioInternationalCargaSueltaP,
  getImportatioInternationalCargaSueltaPSuccess,
  deleteImportationContainer,
  deleteImportationContainerSuccess,
  deleteImportationContainerInternational,
  deleteImportationCargaSueltaInternational,
  deleteImportationCargaSueltaInternationalSuccess,
  deleteImportationContainerInternationalSuccess,
  getLocalTranspByImportNumberSuccess,
  putImpLocalTransportById,
  putImpLocalTransportByIdSuccess,
  searchImportationByFilter,
  searchImportationByFilterSuccess,
  searchImportationByFilterToDelete,
  searchImportationByFilterToDeleteSuccess,
  deleteImportations,
  deleteImportationsSuccess,
  setImportation,
  onEditImportation,
  getIntTranspByImportNumber,
  getIntTranspByImportNumberSuccess,
  putImpIntTransportById,
  putImpIntTransportByIdSuccess,
  postFilterImpAssetsCustoms,
  postFilterImpAssetsCustomsSuccess,
  deleteImpAssetsCustoms,
  deleteImpAssetsCustomsSuccess,
  putImpAssetsCustomsById,
  putImpAssetsCustomsByIdSuccess,
  saveImpAssetsCustomsData,
  saveImpAssetsCustomsDataSuccess,
  getImpProceduresFilter,
  getImpProceduresFilterSuccess,
  postImpProceduresData,
  postImpProceduresDataSuccess,
  deleteImpProceduresData,
  deleteImpProceduresDataSuccess,
  putImpProceduresData,
  putImpProceduresDataSuccess,
  putImportationDataUp,
  putImportationDataUpSuccess,
};
