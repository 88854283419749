import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { fromTemporary, State } from 'src/app/core/store/reducers';
import { TemporaryActions } from './actions';
import { ISearchDto } from '../../data/interfaces/isearch-response';
import { ISearchBody } from '../../data/interfaces/isearch-body';
import { ICustomTempResponse } from '../../data/interfaces/icustom-temp-response';
import { IStatement, IStatementResponse } from '../../data/interfaces/istatement';
import { IQuota, IQuotaResponse } from '../../data/interfaces/iquota';
import { ICustomTempDocumentCreate, ICustomTempDocumentCreateResponse, ICustomTempDocumentUpdate } from '../../data/interfaces/icustom-temp-document';
import { IInconsistencie } from '../../data/interfaces/Inconsistencie';

@Injectable({
  providedIn: 'root',
})
export class TemporaryFacadeService {
  constructor(private store: Store<State>) {
    // This is intentional
  }
  getErrors(): Observable<any> {
    return this.store.select(fromTemporary.getErrorTemporary);
  }

  setError() {
    this.store.dispatch(TemporaryActions.onError({ error: null }));
  }

  getTemporary(): Observable<any> {
    return this.store.select(fromTemporary.getTemporary);
  }

  setTemporary(row: ISearchDto) {
    this.store.dispatch(TemporaryActions.setTemporary({ temporaryData: row }));
  }

  getStatement(): Observable<IStatement | null> {
    return this.store.select(fromTemporary.getStatement);
  }

  setStatement(row: IStatement) {
    this.store.dispatch(TemporaryActions.setStatement({ statementData: row }));
  }

  getImports(SearchBody: ISearchBody, page: number, size: number, isClosure: boolean) {
    this.store.dispatch(
      TemporaryActions.getImports({ SearchBody, page, size, isClosure })
    );
  }

  getImportsSuccess(): Observable<any> {
    return this.store.select(fromTemporary.getImportsSuccess);
  }

  getCustomStatementTemps(importNumber: string, closure: boolean, page: number, size: number) {
    this.store.dispatch(
      TemporaryActions.getCustomStatementTemps({ importNumber, closure, page, size })
    );
  }
  getCustomStatementTempsSuccess(): Observable<ICustomTempResponse | null> {
    return this.store.select(fromTemporary.getCustomStatementTempsSuccess);
  }

  createCustomStatementTemp(importNumber: string, statement: IStatement) {
    this.store.dispatch(TemporaryActions.createCustomStatementTemp({ importNumber: importNumber, statement: statement }));
  }

  createCustomStatementTempSuccess(): Observable<any> {
    return this.store.select(fromTemporary.createCustomStatementTempSuccess);
  }

  getFileExcelCustomStatementTemp(importNumber: string, fileName: string) {
    this.store.dispatch(
      TemporaryActions.getFileExcelCustomStatementTemp({ importNumber, fileName })
    );
  }

  getFileExcelQuotas(declarationNumb: string, fileName: string) {
    this.store.dispatch(
      TemporaryActions.getFileExcelQuotas({ declarationNumb, fileName })
    );
  }

  updateCustomStatementTemp(customTempId: number, statement: IStatement) {
    this.store.dispatch(
      TemporaryActions.updateCustomStatementTemp({ customTempId, statement })
    );
  }

  updateCustomStatementTempSuccess(): Observable<IStatementResponse | null> {
    return this.store.select(fromTemporary.updateCustomStatementTempSuccess);
  }

  deleteCustomStatementTemp(customTempId: number) {
    this.store.dispatch(
      TemporaryActions.deleteCustomStatementTemp({ customTempId })
    );
  }

  deleteCustomStatementTempSuccess(): Observable<IStatementResponse | null> {
    return this.store.select(fromTemporary.deleteCustomStatementTempSuccess);
  }

  getQuotas(declarationNumb: any) {
    this.store.dispatch(TemporaryActions.getQuotas({ declarationNumb: declarationNumb }));
  }

  getQuotasSuccess(): Observable<any> {
    return this.store.select(fromTemporary.getQuotasSuccess);
  }

  updateQuotas(quotaId: number, quota: IQuota) {
    this.store.dispatch(
      TemporaryActions.updateQuotas({ quotaId, quota })
    );
  }

  updateQuotasSuccess(): Observable<IQuotaResponse | null> {
    return this.store.select(fromTemporary.updateQuotasSuccess);
  }
  getDocumentTypes() {
    this.store.dispatch(
      TemporaryActions.getDocumentTypes()
    );
  }
  getDocumentTypesSuccess(): Observable<any | null> {
    return this.store.select(fromTemporary.getDocumentTypesSuccess);
  }
  getCustomTempsDocuments(declarationNumber: string) {
    this.store.dispatch(
      TemporaryActions.getCustomTempsDocuments({ declarationNumber })
    );
  }
  resetTemporaryFlags(): void {
    this.store.dispatch(TemporaryActions.onResetFlags());
  }

  resetProperty(property: string): void {
    this.store.dispatch(TemporaryActions.onResetProperty({ nameProperty: property }));
  }
  getCustomTempsDocumentsSuccess(): Observable<ICustomTempDocumentCreateResponse | null> {
    return this.store.select(fromTemporary.getCustomTempsDocumentsSuccess);
  }

  createCustomTempDocument(importNumber: string, declarationNumber: string, customTempDocument: ICustomTempDocumentCreate) {
    this.store.dispatch(
      TemporaryActions.createCustomTempDocument({ importNumber, declarationNumber, customTempDocument })
    );
  }
  createCustomTempDocumentSuccess(): Observable<any | null> {
    return this.store.select(fromTemporary.createCustomTempDocumentSuccess);
  }
  updateCustomTempDocument(formalitiesDocid: number, customTempDocument: ICustomTempDocumentUpdate) {
    this.store.dispatch(
      TemporaryActions.updateCustomTempDocument({ formalitiesDocid, customTempDocument })
    );
  }
  updateCustomTempDocumentSuccess(): Observable<any | null> {
    return this.store.select(fromTemporary.updateCustomTempDocumentSuccess);
  }
  deleteCustomTempDocument(formalitiesDocid: number) {
    this.store.dispatch(
      TemporaryActions.deleteCustomTempDocument({ formalitiesDocid })
    );
  }
  deleteCustomTempDocumentSuccess(): Observable<any | null> {
    return this.store.select(fromTemporary.deleteCustomTempDocumentSuccess);
  }
  postFileProceduresData(importNumber: string, body: any) {
    this.store.dispatch(
      TemporaryActions.postFileProceduresData({ importNumber, body })
    );
  }
  postFileProceduresDataSuccess(): Observable<any | null> {
    return this.store.select(fromTemporary.postFileProceduresDataSuccess);
  }
  deleteFileProceduresData(id: number, updateAt: string) {
    this.store.dispatch(
      TemporaryActions.deleteFileProceduresData({ id, updateAt })
    );
  }
  deleteFileProceduresDataSuccess(): Observable<any | null> {
    return this.store.select(fromTemporary.deleteFileProceduresDataSuccess);
  }
  approvalCustomStatementTemps(customTempId: number) {
    this.store.dispatch(
      TemporaryActions.approvalCustomStatementTemps({ customTempId })
    );
  }
  approvalCustomStatementTempsSuccess(): Observable<any | null> {
    return this.store.select(fromTemporary.approvalCustomStatementTempsSuccess);
  }
  getNotifications() {
    this.store.dispatch(
      TemporaryActions.getNotifications()
    );
  }
  getNotificationsSuccess(): Observable<any | null> {
    return this.store.select(fromTemporary.getNotificationsSuccess);
  }
  inconsistencieCustomStatementTemps(inconsistencie: IInconsistencie) {
    this.store.dispatch(
      TemporaryActions.inconsistencieCustomStatementTemps({ inconsistencie })
    );
  }
  inconsistencieCustomStatementTempsSuccess(): Observable<any | null> {
    return this.store.select(fromTemporary.inconsistencieCustomStatementTempsSuccess);
  }
}
