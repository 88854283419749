import { Injectable } from '@angular/core';
import { ICotization } from 'src/app/data/interfaces/ICotization.interface';
import { CotizationTypeEnum } from '../core/enums/cotization-type.enum';
import { Numbers } from 'src/app/core/constants/numbers';


@Injectable({
  providedIn: 'root',
})
export class UtilsServices {
  public static loggerCallbackV2(message: any) {
    // This is intentional
  }
  public static consoleLog(message: any, value: any) {
    // This is intentional
  }

  public static validateTypeCotization(cotization: ICotization | null): CotizationTypeEnum {
    let result = CotizationTypeEnum.INVALIDO;
    if (cotization) {
      result = CotizationTypeEnum.BASICA;
      if (cotization.leasingTypeId.id == Numbers._63) { result = CotizationTypeEnum.CONVENIOS; }
      if (cotization.activeType.id == Numbers._68) { result = CotizationTypeEnum.CONVENIOS; }
    }
    return result;
  }
}
