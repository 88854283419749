import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';
import { ProfileFacadeService } from 'src/app/modules/master/profile-store/profile.facade.service';
import { Numbers } from '@constants/numbers';

const urlLeasen: string = "https://componenteseguridadsvl-leasing.apps.bancolombia.com/login";
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  helper: any;
  constructor(private router: Router, private msalService: MsalService,
    private profileFacadeService: ProfileFacadeService) {
    this.helper = new JwtHelperService();
    // This is intentional
  }

  public loggedIn(): boolean {
    let token;
    this.profileFacadeService.getTokenSession().subscribe(
      (tokenSession) => {
        if (tokenSession) {
          token = tokenSession;
        }
      }
    )
    if (token) {
      if (this.helper.isTokenExpired(token)) {
        this.profileFacadeService.resetState();
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  public logOut() {
    let role!: any;
    this.profileFacadeService.getCurretProfile().subscribe(data => {
      role = data?.authorities[Numbers._0];
    });

    this.msalService.logout().subscribe(data => {
      localStorage.clear();
    });
    if (role == 'ROL_ADUANAS' || role == 'ROL_CLIENTE' || role == 'ROL_TRANSPORTE_LOCAL'
      || role == 'ROL_TRANSPORTE_INTERNACIONAL' || role == 'ROL_RENTING') {
      window.open(urlLeasen, '_blank');
    } else {
      this.router.navigateByUrl(environment.postLogoutRedirectUri);
    }
  }
}
