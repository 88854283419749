<bc-table-container
  class="bc-col-12 bc-w-100"
  *ngIf="currentPage.length > 0"
  [dataTable]="currentPage"
  [cellOptions]="dropdownOptions"
>
  <bc-table-header
    [title]="tableTitle"
    [actionsSelections]="actions"
    (actionClicked)="onClickAction($event)"
  >
    <bc-paginator
      type="full"
      [totalRegisters]="'10'"
      [perPage]="'5'"
      (onChangePage)="onChangePageTableScroll($event)"
      [perMultiple]="'5'"
    ></bc-paginator>
  </bc-table-header>
  <bc-table-content>
    <table
      aria-label="Solicitudes"
      caption="tabla"
      bc-table
      [selection]="true"
      [scroll]="scroll"
      [sort]="true"
      [inlineActions]="false"
    >
      <thead>
        <tr>
          <th *ngFor="let column of tableColums" scope="row" bc-cell type="text">{{ column.label }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td bc-cell>data</td>
          <td bc-cell>data</td>
          <td bc-cell>data</td>
          <td bc-cell>data</td>
          <td bc-cell>data</td>
          <td bc-cell>data</td>
          <td bc-cell>data</td>
        </tr>
        <tr>
          <td bc-cell>data</td>
          <td bc-cell>data</td>
          <td bc-cell>data</td>
          <td bc-cell>data</td>
          <td bc-cell>data</td>
          <td bc-cell>data</td>
          <td bc-cell>data</td>
        </tr>
      </tbody>
    </table>
  </bc-table-content>
</bc-table-container>
