import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CardValuesDto, ICardValues } from 'src/app/modules/master/logistics-coordination/components/payments/interfaces/paymentsValuesCard.interface';


@Component({
  selector: 'app-values-summary-card',
  templateUrl: './values-summary-card.component.html',
  styleUrls: ['./values-summary-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ValuesSummaryCardComponent implements OnInit {
  @Input() showButton: boolean = false;
  @Input() resumeValues: ICardValues = new CardValuesDto();
  @Output() onClickdetails = new EventEmitter();

  constructor() {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

  onClick() {
    if (this.onClickdetails) { this.onClickdetails.emit(); }
  }
}
