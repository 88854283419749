<form autocomplete="off" form="formMessagePost" [formGroup]="formMessagePost" class="mb-5">
  <div class="active-container animate__fadeIn">
    <div>
      <div class="row p-2 msg-post">
        <div class="col-md-1"></div>
        <div class="col-md-10  container-msg" [appRecharge]="rechargeListMsg">
          <!-- Nuevo mensaje -->
          <ng-container *ngIf="validateScreen">
            <mat-accordion class="msg-post" formArrayName="blogMessages">
              <mat-expansion-panel
                [expanded]="expanded"
                class="msg"
                *ngFor="let item of blogMessages.controls; let i = index"
                [formGroupName]="i"
              >
                <mat-expansion-panel-header>
                  <mat-panel-description class="justify-content-center">
                    Asunto: <spam class="blogMessages__title">{{item.get('subject')?.value}}</spam>
                  </mat-panel-description>
                </mat-expansion-panel-header>
  
                <fieldset [readonly]="!item.get('edit')?.value && item.get('id')?.value">
                  <legend></legend>
                  <div class="d-flex encabezado-content">
                    <h6 class="encabezado-items">De:&nbsp;</h6>
                    <input autocomplete="off"
                      matInput
                      type="email"
                      formControlName="emailTo"
                      placeholder="cliente@mail.com..."
                      required
                      [submited]=submited
                      (blur)="validar()"
                      >
  
                  </div>
                  <mat-error class="mat-hint-error" *ngIf="isError">{{ errorText }}</mat-error>
                </fieldset>
  
                <fieldset [readonly]="!item.get('edit')?.value && item.get('id')?.value">
                  <legend></legend>
                  <div class="d-flex encabezado-content">
                    <h6 class="encabezado-items">Para:&nbsp;</h6>
                    <input autocomplete="off"
                      matInput
                      type="email"
                      formControlName="emailFrom"
                      placeholder="proveedor@mail.com..."
                      required
                      [submited]=submited
                      (blur)="validar()"
                      >
  
                  </div>
                  <mat-error class="mat-hint-error" *ngIf="isError">{{ errorText }}</mat-error>
                </fieldset>
  
                <fieldset [readonly]="!item.get('edit')?.value && item.get('id')?.value">
                  <legend></legend>
                  <div class="d-flex encabezado-content">
                    <h6 class="encabezado-items">Asunto:&nbsp;</h6>
                    <input autocomplete="off"
                      matInput
                      type="text"
                      formControlName="subject"
                      placeholder="Contacto 3.."
                      required
                      [submited]=submited>
                  </div>
                </fieldset>
  
                <fieldset [readonly]="!item.get('edit')?.value && item.get('id')?.value">
                  <legend></legend>
                  <textarea
                    matInput
                    name="message"
                    formControlName="message"
                    [maxlength] = "maxlength"
                    class="textarea"
                    placeholder="Se aprueba la solicitud basado en la evidencia y en los documentos adjuntados"
                    [submited]=submited
                  ></textarea>
                </fieldset>
  
                <mat-action-row>
                  <ng-container *ngIf ="!validateCreatorBy(item.get('creatorById')?.value)">
                    <ng-container *ngIf="!isJustViewer">
                      <button
                      mat-icon-button
                      (click)="editItem(item)"
                      *ngIf="item.get('id')?.value && !item.get('edit')?.value && tableButtonsEdit"
                    ><bc-icon
                        name="edit"
                        aria-hidden="true"
                        aria-label="Editar mensaje"
                      ></bc-icon>
                    </button>
                    </ng-container>
                  </ng-container>
                  <button
                  
                  mat-icon-button *ngIf="!item.get('id')?.value && tableButtonsErase">
                    <bc-icon
                      name="erase"
                      (click)="deleteItem(item.value, i)"
                      aria-hidden="true"
                      aria-label="Borrar mensaje"
                    ></bc-icon>
                  </button>
                  <button
                    mat-icon-button
                    (click)="updateItem(item, i)"
                    *ngIf="item.get('edit')?.value && tableButtonsUpdate"
                  ><bc-icon
                      name="save"
                      aria-hidden="true"
                      aria-label="Actualizar"
                    ></bc-icon>
                  </button>
                </mat-action-row>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
        </div>
        <div class="col-md-1"></div>
      </div>

      <div class="row p-2 animate__fadeIn">
        <div class="col-md-1"></div>
        <div class="col-10 d-flex justify-content-between add-blog">
          <ng-container *ngIf="!isJustViewer">
              <bcs-button
              *ngIf="addMessage"
              Type="button"
              Label="Agregar mensaje"
              TypeButton="second"
              (onClickEvent)="addBlogMessages()"
              [disabled]="formMessagePost.invalid"
            ></bcs-button>
            <bcs-button
            *ngIf="saveBlog"
              Label="Guardar blog"
              Icon="save"
              Type="button"
              (click)="saveBlogMessages()"
              [disabled]="formMessagePost.invalid"
            ></bcs-button>
          </ng-container>
          <ng-container *ngIf="dowloandMessages">
            <bcs-button
            *ngIf="downloadBlog"
            Label="Descargar Blog de msj"
            Icon="save"
            Type="button"
            (click)="dowloadMessages()"
          ></bcs-button>
          </ng-container>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
  </div>
</form>
