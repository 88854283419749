import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-payment-method-form',
  templateUrl: './payment-method-form.component.html',
  styleUrls: ['./payment-method-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentMethodFormComponent implements OnInit {

  constructor() {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

}
