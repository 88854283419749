import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'modal-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InfoComponent implements OnInit {

  @Input() titulo!: string;
  @Input() mensaje!: string;
  @Input() LabelButton: string = 'Ok';
  @Input() typeModal: string = 'ok';
  @Input() urlSrc!: string;
  @Input() Icon: string = '';
  @Input() textButton: string = 'Crear';
  @Output() response: EventEmitter<string> = new EventEmitter();

  constructor() {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

  onClick(opcion: string) {
    this.response.emit(opcion);
  }

  close() {
    this.response.emit('close');
  }
}
