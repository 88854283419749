import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Numbers } from '@constants/numbers';
import { OptionsMask } from '@constants/options-mask';

@Component({
  selector: 'app-table-transport',
  templateUrl: './table-transport.component.html',
  styleUrls: ['./table-transport.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableTransportComponent implements OnInit {

  @Input() transportInfo: any[] = [];
  @Input() title: string = 'Title';
  @Input() columns: any[] = [];
  @Input() totalElements: number = 0;
  @Input() readOnly: boolean = false;
  @Input() canAdd: boolean = true;
  @Input() canDelete: boolean = true;
  @Input() canEdit: boolean = true;
  @Input() selectArray: any = [];

  page: number = 0;
  editable: number = -1;

  @Output() PageChange = new EventEmitter<any>();
  @Output() UpdateRow = new EventEmitter<any>();
  @Output() AddRow = new EventEmitter<any>();
  @Output() CancelRow = new EventEmitter();
  @Output() DeleteRow = new EventEmitter<any>();

  optionCurrencyMask: any = OptionsMask.decimals;
  isEdit = false;
  constructor() {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

  isError(value: any): boolean {
    let isInputError = false;
    if (!value) {
      isInputError = true;
    }
    return isInputError;
  }

  delete(row: any) {
    const index = this.transportInfo.findIndex(objeto => objeto.id === row.id);
    if (index !== -1) {
      // Elimina el objeto del array
      this.transportInfo.splice(index, 1);
    }
    if (this.DeleteRow) {
      this.DeleteRow.emit(row);
    }
  }

  edit(i: number) {
    if (this.isEdit) {
      return
    }
    if (this.editable !== i) {
      this.editable = i;
      this.isEdit = true;
    }
  }

  add() {
    if (this.editable >= 0) {
      return
    }
    this.AddRow.emit();
    this.isEdit = true;
    this.editable = this.transportInfo.length - 1;
  }

  save(i: number, row: any) {    
    if(this.canSave(row)){
      this.editable = -1;
      this.UpdateRow.emit(row);
      this.isEdit=false;
    }
  }

  canSave(row: any):boolean{
    let isOk = true;
    Object.keys(row).forEach(key => {
      const value = row[key];
      if (value.length === 0){
        isOk = false;
      }
    });
    return isOk;
  }


  cancel() {
    this.editable = -1;
    this.isEdit = false;
    if (this.CancelRow) { this.CancelRow.emit(); }
  }

  handlePageEvent(event: any): void {
    if (this.PageChange) {
      this.PageChange.emit(event);
    }
  }

  validarNumero(event: any, numberType: any) {
    // Verifica si el valor contiene solo números y puntos
    const esValido = numberType == Numbers._1 ? /^[0-9]*$/.test(event.key) : /^[0-9.]*$/.test(event.key);

    if (!esValido && event.keyCode != Numbers._8) {
      event.preventDefault(); // Evita que se envíe el formulario o se realice la acción predeterminada
    }
  }
}
