<div class="files-uploader">
  <section class="files-uploader__header">
    <div class="files-uploader__title bc-mb-2">
      {{ title }}
    </div>
    <div class="files-uploader__description bc-mb-2">
      {{ description }}
    </div>
  </section>
  <div
    [class]="
      dragDrogClass ? 'files-uploader__body drag' : 'files-uploader__body'
    "
    bscDragAndDrop
    (onFilesSelected)="drop($event)"
    (mouseSobre)="dragDrogOver($event)"
  >
    <div class="files-uploader__ico bc-mb-2">
      <bc-icon class="bc-icon bc-xl">ico-cloud-upload</bc-icon>
    </div>
    <div class="files-uploader__message bc-mb-4">{{ dropAreaText }}</div>
    <div class="files-uploader__actions bc-mb-4">
      <input
        id="uploaderFiles"
        #uploaderFiles
        type="file"
        (change)="onFileSelected($event)"
        class="input-file"
        multiple
        [accept]="validType"
      />
      <button
        (click)="uploaderFiles.click()"
        class="files-uploader__actions-button"
        bc-button
        typeButton="terciary"
        sizeButton="small"
      >
        {{ buttonLabel }}
      </button>
    </div>
    <div class="files-uploader__info">
      <div class="files-uploader__info-text bc-mb-2">
        Puede contener máximo {{ maxFiles }} registros
      </div>
      <div class="files-uploader__info-text bc-mb-2">
        Formatos {{ validType }}
      </div>
      <div class="files-uploader__info-text bc-mb-2">
        {{ maxSize / 1024 }} MB máximo por todos los archivos
      </div>
      <mat-error class="mat-hint-error" *ngIf="isError != ''">{{
        isError
      }}</mat-error>
    </div>
  </div>
</div>
