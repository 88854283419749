import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

import { fromCotization, fromCotizationDetails, State, } from 'src/app/core/store/reducers';

import { ICotizationCreate } from 'src/app/data/interfaces/ICotizationCreate.interface';
import { ICotization } from 'src/app/data/interfaces/ICotization.interface';
import { ICotizationFilter } from 'src/app/data/interfaces/ICotizationFilter.interface';
import { CotizationsActions } from './actions';
import { cotizationsDetailsActions } from '../cotizations-details-store/actions';
import { ICotizationDetail } from 'src/app/data/interfaces/ICotizationDetail.interface';
import { ICotizationDetailUpdTranspLocal, } from '../../../../../../data/interfaces/IcotizationDetailUpdTranspLocal.interface';
import { ICustomData } from 'src/app/data/interfaces/ICustomsData.interface';
import { CotizationDetail } from 'src/app/data/interfaces/IcotizationDetailUpdTranspInternacional.interface';

@Injectable({
  providedIn: 'root',
})
export class CotizationsFacadeService {
  constructor(private store: Store<State>) {
    // This is intentional
  }
  // ======================================[COTIZATIONS]======================================\\
  //#region COTIZATIONS
  getCotization(): Observable<ICotization | null> {
    return this.store.select(fromCotization.getCotization);
  }

  createCotization(cotization: ICotizationCreate) {
    this.store.dispatch(CotizationsActions.createCotizations({ cotization }));
  }
  getStatusCreated(): Observable<any> {
    return this.store.select(fromCotization.getCotizationIsCreated);
  }

  searchCotizationByCIB(CIB: string, clientSection: boolean) {
    this.store.dispatch(CotizationsActions.searchCotizationByCIB({ CIB, clientSection }));
  }

  resetFlagsTrue() {
    this.store.dispatch(CotizationsActions.onResetFlagsTrue());
  }

  searchCotizationByFilter(
    params: ICotizationFilter,
    page: number,
    size: number
  ) {
    this.store.dispatch(
      CotizationsActions.SearchCotizationByFilter({ params, page, size })
    );
  }

  searchCotizationByFilterToDelete(
    params: ICotizationFilter,
    page: number,
    size: number
  ) {
    this.store.dispatch(
      CotizationsActions.SearchCotizationByFilterToDelete({ params, page, size })
    );
  }

  deleteCotizations(body: any) {
    this.store.dispatch(CotizationsActions.deleteCotizations({ body })
    );
  }

  onSearch(): Observable<any> {
    return this.store.select(fromCotization.getCotizationIsFound);
  }
  getListCotizations(): Observable<ICotization | null> {
    return this.store.select(fromCotization.getListCotizations);
  }

  getErrors(): Observable<any> {
    return this.store.select(fromCotization.getCotizationError);
  }

  setCotization(cotization: ICotization | null) {
    this.store.dispatch(CotizationsActions.SetCotization({ cotization }));
  }
  onEdit(edit: boolean) {
    this.store.dispatch(CotizationsActions.onEditCotization({ edit }));
  }
  getEditStatus(): Observable<boolean> {
    return this.store.select(fromCotization.getEditStatus);
  }
  resetCotizationsFlags(): void {
    this.store.dispatch(CotizationsActions.onResetFlags());
  }
  cotizationAdminAproval(cotizationId: number, adminAproval: boolean): void {
    this.store.dispatch(
      CotizationsActions.cotizationAdminAproval({ cotizationId, adminAproval })
    );
  }

  cotizationCustomHouseAproval(
    cotizationId: number,
    customHouseAproval: boolean
  ): void {
    this.store.dispatch(
      CotizationsActions.cotizationCustomHouseAproval({
        cotizationId,
        customHouseAproval,
      })
    );
  }

  getMessageFromCotization(): Observable<string> {
    return this.store.select(fromCotization.getCotizationMessage);
  }
  // #endregion

  // ======================================[cotizationDetail]======================================\\
  //#region cotizationDetail
  createCotizationDetail(cotizationsDetails: ICotizationDetail) {
    this.store.dispatch(
      cotizationsDetailsActions.createCotizationsDetails({ cotizationsDetails })
    );
  }

  getCotizationDetail(): Observable<ICotizationDetail | null> {
    return this.store.select(fromCotizationDetails.getCotizationDetail);
  }
  getCotizationDetailStatusCreated(): Observable<any> {
    return this.store.select(
      fromCotizationDetails.getCotizationDetailIsCreated
    );
  }
  getCotizationDetailErrors(): Observable<any> {
    return this.store.select(fromCotizationDetails.getCotizationDetailOnErrors);
  }
  setCotizationsDetailsToStore(cotizationsDetails: ICotizationDetail): void {
    this.store.dispatch(
      cotizationsDetailsActions.setCotizationsDetailsToStore({
        cotizationsDetails,
      })
    );
  }

  resetCotizationDetailFlags(): void {
    this.store.dispatch(cotizationsDetailsActions.resetFlags());
  }

  resetCotizationDetailFlagsTrue(): void {
    this.store.dispatch(cotizationsDetailsActions.resetFlagsTrue());
  }

  getCotizationDetailStatusIsSaved(): Observable<any> {
    return this.store.select(fromCotizationDetails.getCotizationDetailIsSaved);
  }

  findCotizationDetailByCIB(cib: string) {
    this.store.dispatch(
      cotizationsDetailsActions.findCotizationsDetailsByCIB({ cib })
    );
  }
  /** database/cotizationsDetailsTransport?transportType=LOCAL */
  findCotizationDetailLocalByCIBAlly(cib: string, allyId: number) {
    this.store.dispatch(
      cotizationsDetailsActions.findCotizationsDetailsLocalByCIBAlly({ cib, allyId })
    );
  }
  /** database/cotizationsDetailsTransport?transportType=INTERNATIONAL */
  findCotizationDetailInternationalByCIBAlly(cib: string, allyId: number) {
    this.store.dispatch(
      cotizationsDetailsActions.findCotizationsDetailsInternatByCIBAlly({ cib, allyId })
    );
  }

  updCotizationDetailTranspLocal(
    cotizationsDetail: ICotizationDetailUpdTranspLocal,
    cotizationId: number
  ) {
    this.store.dispatch(
      cotizationsDetailsActions.updateCotizationsDetailTransLocal({
        cotizationsDetail,
        cotizationId,
      })
    );
  }
  getUpdatedStatus(): Observable<boolean> {
    return this.store.select(fromCotizationDetails.getUpdateStatus);
  }

  updateCotization(cotization: any, cotizationId: number) {
    this.store.dispatch(
      CotizationsActions.updateCotization({ cotization, cotizationId })
    );
  }

  onSearchCotizationDetail(): Observable<any> {
    return this.store.select(fromCotizationDetails.onSearchCotizationsDetails);
  }

  updCotizationDetailTransInter(
    cotizationsDetail: CotizationDetail,
    cotizationId: number
  ) {
    this.store.dispatch(
      cotizationsDetailsActions.updateCotizationsDetailTransInter({
        cotizationsDetail,
        cotizationId,
      })
    );
  }

  updCotizationDetailCustoms(
    cotizationsDetail: ICustomData,
    cotizationId: number
  ) {
    this.store.dispatch(
      cotizationsDetailsActions.updateCotizationsDetailCustom({
        cotizationsDetail,
        cotizationId,
      })
    );
  }
  /** database/cotizationCustoms */
  getCotizationsCustom(cib: string, allyId: number) {
    this.store.dispatch(
      cotizationsDetailsActions.getCotizationsCustom({
        cib,
        allyId,
      })
    );
  }

  findCotizationByParams(
    documentType?: string,
    documentNumber?: string,
    clientName?: string,
    cib?: string,
    initDate?: string,
    endDate?: string,
    pagination?: any,
  ) {

    this.store.dispatch(
      CotizationsActions.findCotizationByParams({
        documentType,
        documentNumber,
        clientName,
        cib,
        initDate,
        endDate,
        pagination
      })
    );
  }

  updateZoneLeader(idZoneLeader: number, cib: string) {
    this.store.dispatch(
      CotizationsActions.updateZoneLeader({ idZoneLeader, cib })
    );
  }

  findTermsAndConditions() {
    this.store.dispatch(CotizationsActions.getTermsAndConditions());
  }

  getTermsAndConditions(): Observable<any> {
    return this.store.select(fromCotization.getTermsAndConditions);
  }

  public goToBackClient = new BehaviorSubject<string | null>(null);
  goToBackClientValues$ = this.goToBackClient.asObservable();

  setGoToBackClient({ step }: { step: string }): void {
    this.goToBackClient.next(step);
  }

  resetGoToBackClient(): void {
    this.goToBackClient.next(null);
  }
}
