import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PaymentsService } from 'src/app/data/services/apis/payments/payments.service';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PaymentsActions } from './actions';

@Injectable()
export class PaymentEffects {
  constructor(
    private paymentsService: PaymentsService,
    private actions$: Actions
  ) {
    // This is intentional
  }

  getPaymentsValuesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.getPaymentsValues),
      switchMap(({ ImportId }) =>
        this.paymentsService.getPaymentsValues(ImportId).pipe(
          map((cardValues) =>
            PaymentsActions.getPaymentsValuesSuccess({ cardValues })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );

  findCreditLetterEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.findCreditLetter),
      switchMap(({ importNumber, page, size }) =>
        this.paymentsService.getCreditLetter(importNumber, page, size).pipe(
          map((creditLetterDtoList) =>
            PaymentsActions.findCreditLetterSuccess({ creditLetterDtoList })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );

  findTransferEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.findTransfer),
      switchMap(({ importNumber, page, size }) =>
        this.paymentsService.getTransfer(importNumber, page, size).pipe(
          map((data) =>
            PaymentsActions.findTransferSuccess({ transferDtoList: data })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );

  findServicesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.findServices),
      switchMap(({ importNumber, page, size }) =>
        this.paymentsService.getServices(importNumber, page, size).pipe(
          map((data) =>
            PaymentsActions.findServicesSuccess({ servicesDtoList: data })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );
  addCommissionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.addCommission),
      switchMap(({ commissionCredit, importNumber, lcNumber }) =>
        this.paymentsService.addCommission(commissionCredit, importNumber, lcNumber).pipe(
          map((data) =>
            PaymentsActions.addCommissionSuccess({ data })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );

  findEveryCommissionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.findEveryCommission),
      switchMap(({ importNumber, lcNumber }) =>
        this.paymentsService.getEveryCommision(importNumber, lcNumber).pipe(
          map((commissionCredit) =>
            PaymentsActions.findEveryCommissionSuccess({ commissionCredit })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );

  updateCommissionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.updateCommission),
      switchMap(({ commissionCredit, importNumber, lcNumber }) =>
        this.paymentsService.updateCommission(commissionCredit, importNumber, lcNumber).pipe(
          map((data) =>
            PaymentsActions.updateCommissionSuccess({ data })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );

  deleteCommissionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.deleteCommission),
      switchMap(({ importNumber, lcNumber }) =>
        this.paymentsService.deleteCommission(importNumber, lcNumber).pipe(
          map((deleteComissionData) =>
            PaymentsActions.deleteCommissionSuccess({ deleteComissionData })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );

  historicCommissionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.historicCommission),
      switchMap(({ importNumber, lcNumber, event, page, size }) =>
        this.paymentsService.getHistoricCommission(importNumber, lcNumber, event, page, size).pipe(
          map((historicCommission) =>
            PaymentsActions.historicCommissionSuccess({ historicCommission })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );

  getTypeMethodEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.getTypeMethod),
      switchMap(() =>
        this.paymentsService.getTypeMethod().pipe(
          map((typeMethod) =>
            PaymentsActions.getTypeMethodSuccess({ typeMethodValues: typeMethod })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );

  getAgreedDocumentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.getAgreedDocument),
      switchMap(() =>
        this.paymentsService.getAgreedDocument().pipe(
          map((typeMethod) =>
            PaymentsActions.getAgreedDocumentSuccess({ agreedDocumentValues: typeMethod })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );

  createPaymentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.createPayments),
      switchMap(({ payments, importNumber }) =>
        this.paymentsService.createPayments(payments, importNumber).pipe(
          map((response) =>
            PaymentsActions.createPaymentsSuccess({ createdPaymentData: response })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );

  updatePaymentStatusEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.updatePaymentStatus),
      switchMap(({ payments, typeUpdate, customs }) =>
        this.paymentsService.updatePaymentStatus(payments, typeUpdate, customs).pipe(
          map((response) =>
            PaymentsActions.updatePaymentStatusSuccess({ updatePaymentStatusData: response })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );


  updatedPaymentLetterEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.updatedPaymentLetter),
      switchMap(({ payment }) =>
        this.paymentsService.updatedPaymentLetter(payment).pipe(
          map((response) =>
            PaymentsActions.updatedPaymentLetterSuccess({ updatedPaymentLetterData: response })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );

  updatedPaymentTransferEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.updatedPaymentTransfer),
      switchMap(({ payment }) =>
        this.paymentsService.updatedPaymentTransfer(payment).pipe(
          map((response) =>
            PaymentsActions.updatedPaymentTransferSuccess({ updatedPaymentTransferData: response })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );

  updatedPaymentServicesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.updatedPaymentServices),
      switchMap(({ payment }) =>
        this.paymentsService.updatedPaymentServices(payment).pipe(
          map((response) =>
            PaymentsActions.updatedPaymentServicesSuccess({ updatedPaymentServicesData: response })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );

  deletePaymentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.deletePayment),
      switchMap(({ payment }) =>
        this.paymentsService.deletePayment(payment).pipe(
          map((response) =>
            PaymentsActions.deletePaymentSuccess({ deletePaymentData: response })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );

  sendEmailCustomEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.sendEmailCustom),
      switchMap(({ importNumber, billNumber, DatePayment, customs }) =>
        this.paymentsService.sendEmailCustom(importNumber, billNumber, DatePayment, customs).pipe(
          map((response) =>
            PaymentsActions.sendEmailCustomSuccess({ sendEmailCustomData: response })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );

  getFilesPaymentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentsActions.getFilesPayment),
      switchMap(({ importNumber }) =>
        this.paymentsService.getFilesPayment(importNumber).pipe(
          map((response) =>
            PaymentsActions.getFilesPaymentSuccess({ getFilesPaymentData: response })
          ),
          catchError((error) => of(PaymentsActions.onErrors({ error: error.error })))
        )
      )
    )
  );
}
