import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Numbers } from '@constants/numbers';
import { Utils } from '../../utils';

@Component({
  selector: 'bsc-files-uploader',
  templateUrl: './bsc-files-uploader.component.html',
  styleUrls: ['./bsc-files-uploader.component.scss']
})
export class BscFilesUploaderComponent implements OnInit {
  @Input() title: string = 'Adjuntar Documentos';
  @Input() description: string = 'Agregue los documentos que desee vincular al proceso.';
  @Input() dropArea: boolean = true;
  @Input() dropAreaText: string = 'Arrastra aquí los archivos desde tu computador o has clic en el botón.';
  @Input() multipleFiles: boolean = true;
  @Input() validType = ".doc, .docx, .jpg, .pdf, .png, .xls, .xlsx, .tif, .zip, .msg";
  @Input() maxFiles: number = Numbers._30;
  @Input() maxSize: number = Numbers._10240;
  @Input() buttonLabel: string = 'Seleccionar un archivo.';
  @Input() componentId: string = 'InputConDropArea';
  @Input() show: boolean = true;
  @Output() changeStateMaxFiles = new EventEmitter<any>();
  @Output() getFilesAdded = new EventEmitter<File[]>();
  @Output() onErrorValidate = new EventEmitter<boolean>();
  private allFiles: File[] = [];
  public dragDrogClass: boolean = false;
  public isError: string = "";
  items = ['Item 1', 'Item 2', 'Item 3', 'Item 4'];
  @ViewChild('uploaderFiles') fileInput!: ElementRef;
  constructor(public utils: Utils) { }

  ngOnInit(): void {
    //this intentional
  }
  async onFileSelected(event: any) {
    this.isError = '';
    this.allFiles = [];
    const selectedFile: FileList = event.target.files;
    const list = Array.from(selectedFile)
    if (selectedFile && selectedFile.length > 0 && this.valid(list)) {
      const files = await this.filterRepeat(list)
      this.allFiles.push(...files)
      this.getFilesAdded.emit([...this.allFiles])

    }
    this.fileInput.nativeElement.value = null;
  }

  async filterRepeat(files: File[]) {
    const filesAdd: File[] = [];
    const promises = files.map(async (file) => {
      const arrayExt = file.name.split(".");
      if (arrayExt[arrayExt.length - 1] === 'pdf') {
        const data = await this.utils.validPdf(file);
        const result = this.allFiles.some((i) => i.name === file.name);
        const result2 = filesAdd.some((i) => i.name === file.name);
        if (!result && !result2) {
          filesAdd.push(data);
        }
      } else {
        const result = this.allFiles.some((i) => i.name === file.name);
        const result2 = filesAdd.some((i) => i.name === file.name);
        if (!result && !result2) {
          filesAdd.push(file);
        }
      }
    });
    await Promise.all(promises);
    return filesAdd;
  }

  valid(files: File[]) {
    let isValid = true;
    const list = Array.from(files)
    if (list.length > this.maxFiles) {
      this.isError = "Excedió la cantidad de archivos permitidos.";
      this.onErrorValidate.emit(false);
      return false;
    }
    let totalSize = 0;
    list.forEach(file => {
      totalSize = totalSize + file.size;
      }
    );
    if ((totalSize / Numbers._1000000) > Numbers._10) {
      this.isError = `Los archivos exceden las 10 MB permitidas.`
      isValid = false;
    }
    this.onErrorValidate.emit(isValid);
    return isValid;
  }

  dragAndDrop(event: any) {
    this.dragDrogClass = true;
  }

  dragDrogOver(event: any) {
    this.dragDrogClass = event
  }

  async drop(event: any[]) {
    this.allFiles = [];
    this.dragDrogClass = false;
    const files = await this.filterRepeat(event);
    if (this.valid(files)) {
      this.allFiles.push(...files);
      this.getFilesAdded.emit(this.allFiles);
    }
    event = [];
    this.fileInput.nativeElement.value = null;
  }

  dragEnd() {
    this.dragDrogClass = false
  }

}
