import { Component, OnInit, ViewEncapsulation, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Profiles } from 'src/app/core/mocks/profiles.enum';
import { IAlliesOptions } from 'src/app/data/interfaces/ICotization.interface';

@Component({
  selector: 'btn-admin-consults-quotes-allies',
  templateUrl: './btn-admin-consults-quotes-allies.component.html',
  styleUrls: ['./btn-admin-consults-quotes-allies.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BtnAdminConsultsQuotesAlliesComponent implements OnInit, OnChanges {

  rutaAdmin: string = 'app/cotizaciones/administrador/consultar-solicitud';
  rutaCustom: string = 'app/cotizaciones/aduanas';

  showInitialBtns: boolean = true;
  btnLocal: boolean = false;
  btnAduanas: boolean = false;
  btnIntern: boolean = false;

  @Input() resetBtn!: boolean;
  @Input() allies!: IAlliesOptions;
  @Input() profile!: string;
  @Output() rtaResetBtn = new EventEmitter<boolean>();
  @Input() showBtnRegresar!: boolean;
  @Output() rtaShowBtnRegresar = new EventEmitter<boolean>();
  @Output() typebtn = new EventEmitter<string>();

  constructor(
    private router: Router,
  ) {
    this.showBtnRegresar = window.location.href !== this.rutaAdmin ? false : true;
  }

  ngOnInit(): void {
    // This is intentional
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.resetBtn?.currentValue) {
      this.btnLocal = false;
      this.btnAduanas = false;
      this.btnIntern = false;
      this.showInitialBtns = true;
      this.rtaResetBtn.emit(false);
      this.rtaShowBtnRegresar.emit(false);
    }
  }

  changeTypeBtn(nameBtn: string) {
    if (nameBtn === 'local') {
      this.btnLocal = true;
      this.showInitialBtns = false;
      this.localTransport();
    }
    if (nameBtn === 'aduanas') {
      this.btnLocal = false;
      this.btnAduanas = true;
      this.showInitialBtns = false;
      this.customsAgency();
    }
    if (nameBtn === 'internacional') {
      this.btnLocal = false;
      this.btnAduanas = false;
      this.btnIntern = true;
      this.showInitialBtns = false;
      this.internationalTransport();
    }
    this.rtaShowBtnRegresar.emit(true);
    this.typebtn.emit(nameBtn);
  }

  internationalTransport() {
    if (this.profile == Profiles.ADMINISTRATOR) {
      this.router.navigate(
        [
          `${this.rutaAdmin}/consultar-editar-cotizacion/aliado-transporte-internacional`
        ]
      );
    } else {
      this.router.navigate(
        [
          `${this.rutaCustom}/aprobacion/aliado-transporte-internacional`
        ]
      );
    }
  }

  customsAgency() {
    if (this.profile == Profiles.ADMINISTRATOR) {
      this.router.navigate(
        [
          `${this.rutaAdmin}/consultar-editar-cotizacion/aliado-agenciamiento-aduanero`
        ]
      );
    } else {
      this.router.navigate(
        [
          `${this.rutaCustom}/aprobacion`
        ]
      );
    };
  }

  localTransport() {
    if (this.profile == Profiles.ADMINISTRATOR) {
      this.router.navigate(
        [
          `${this.rutaAdmin}/consultar-editar-cotizacion/aliado-transporte-local`
        ]
      );
    } else {
      this.router.navigate(
        [
          `${this.rutaCustom}/aprobacion/aliado-transporte-local`
        ]
      );
    }
  }

}
