import { IAlly } from 'src/app/data/interfaces/IAlly.interface';
import { createAction, props } from '@ngrx/store';
import { IAllies, IAlliesData } from '../../../../../../data/interfaces/IAlliesData.interface';
import { IAlliesUpdate } from '../../../../../../data/interfaces/IAlliesUpdate.interface';

enum AlliesActionType {
  createAlliesAction = '[Allies Component] Create Allies',
  createAlliesSuccessAction = '[Allies Component] Create Allies Success',
  findAllyActions = '[Allies Component] Find ally by name, nit and type',
  findAllySuccessAction = '[Allies Component] Find ally by name, nit and type Success',
  updateAlly = '[Allies Component] Update ally',
  updateAllySuccess = '[Allies Component] Update ally Success',
  deleteAlly = '[Allies Component] Delete ally',
  deleteAllySuccess = '[Allies Component]  Delete ally Success',
  selectAlliesAction = '[Allies Component]  Select allies ',
  selectAlliesSuccessAction = '[Allies Component]  Select allies Success',

  editAllyActionType = '[Allies Component] Edit Ally',
  editAllySuccessActionType = '[Allies Component]  Edit ally Success',
  getAllyNitActionType = '[Allies Component] Get Ally Nit',
  getAllyNitSuccessActionType = '[Allies Component]  Get Ally Nit Success',
  deactivateAllyIdActionType = '[Allies Component] Deactivate Ally Id',
  deactivateAllyIdSuccessActionType = '[Allies Component]  Deactivate Ally Id Success',

  onErrorAction = '[Allies Component] Oops something went wrong ',
  resetFlagsAction = '[Allies Component] Reset Flags',
}

/** Crear aliado */
const createAllies = createAction(
  AlliesActionType.createAlliesAction,
  props<{ ally: IAlliesData }>()
);
const createAlliesSuccess = createAction(
  AlliesActionType.createAlliesSuccessAction,
  props<{ ally: IAlly }>()
);

const selectAllies = createAction(
  AlliesActionType.selectAlliesAction,
  props<{ allies: IAlliesUpdate }>()
);
const selectAlliesSuccess = createAction(
  AlliesActionType.selectAlliesSuccessAction,
  props<{ allies: any }>()
);

const findAlly = createAction(
  AlliesActionType.findAllyActions,
  props<{ typeId: number }>()
);
const findAllySuccess = createAction(
  AlliesActionType.findAllySuccessAction,
  props<{ allies: IAlly[] }>()
);

/** Editar aliado */
const editAlly = createAction(
  AlliesActionType.editAllyActionType,
  props<{ allyId: number, allyData: IAlliesData }>()
);
const editAllySuccess = createAction(
  AlliesActionType.editAllySuccessActionType,
  props<{ allies: IAlliesData }>()
);

/** Buscar aliados por nit */
const getAllyNit = createAction(
  AlliesActionType.getAllyNitActionType,
  props<{ nit: string }>()
);
const getAllyNitSuccess = createAction(
  AlliesActionType.getAllyNitSuccessActionType,
  props<{ ally: IAlly }>()
);

/** Eliminar aliado por ID */
const deactivateAllyId = createAction(
  AlliesActionType.deactivateAllyIdActionType,
  props<{ allyId: number }>()
);
const deactivateAllyIdSuccess = createAction(
  AlliesActionType.deactivateAllyIdSuccessActionType,
  props<{ allied: IAllies }>()
);

const onError = createAction(AlliesActionType.onErrorAction, props<{ error: any }>());
const resetFlags = createAction(AlliesActionType.resetFlagsAction);

export const AlliesActions = {
  createAllies,
  createAlliesSuccess,
  onError,
  resetFlags,
  selectAllies,
  selectAlliesSuccess,
  findAlly,
  findAllySuccess,
  editAlly,
  editAllySuccess,
  getAllyNit,
  getAllyNitSuccess,
  deactivateAllyId,
  deactivateAllyIdSuccess,
};
