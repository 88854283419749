export enum Profiles {
  CLIENT = 'ROL_CLIENTE',
  CUSTOMS = 'ROL_ADUANAS',
  LOCAL_TRANSPORTATION = 'ROL_TRANSPORTE_LOCAL',
  INTERNATIONAL_TRANSPORT = 'ROL_TRANSPORTE_INTERNACIONAL',
  COMMERCIAL = 'ROL_COMERCIAL',
  RENTING = 'ROL_RENTING',
  ADMINISTRATOR = 'ADMINISTRATOR',
  GENERALES = 'GENERALES',
  INVALID = 'INVALID',
  COMMERCIAL_TRADE = 'ROL_COMERCIO_TRADE'
}
