<div [ngSwitch]="modalType">
  <div *ngSwitchDefault>
    <modal-info
      (response)="onClick($event)"
      [titulo]="titulo"
      [typeModal]="typeModal"
      [LabelButton]="LabelButton"
      [textButton]="textButton"
      [mensaje]="mensaje"
      [urlSrc]="urlSrc"
      [Icon]="Icon"
    ></modal-info>
  </div>

  <div *ngSwitchCase="'time'">
    <session-time
      (response)="onClick($event)"
      [titulo]="titulo"
      [typeModal]="typeModal"
      [LabelButton]="LabelButton"
      [textButton]="textButton"
      [mensaje]="mensaje"
      [urlSrc]="urlSrc"
      [Icon]="Icon"
    ></session-time>
  </div>

  <div *ngSwitchCase="'files'">
    <files-modal
      [paymentChildId]="paymentChildId"
      [importNumber]="importNumber"
      (closeModalEvent)="close()"
      ></files-modal>
  </div>
</div>
