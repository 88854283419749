import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ApiCotizationsService } from 'src/app/data/services/apis/cotization/api-cotizations.service';
import { cotizationsDetailsActions } from './actions';

@Injectable()
export class CotizationsDetailsEffects {
  constructor(
    private apiCotizationsService: ApiCotizationsService,
    private actions$: Actions
  ) {
    // This is intentional
  }

  createCotizationsDetailsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cotizationsDetailsActions.createCotizationsDetails),
      switchMap(({ cotizationsDetails }) =>
        this.apiCotizationsService
          .createCotizationDetails(cotizationsDetails)
          .pipe(
            map((cotizationsDetails) =>
              cotizationsDetailsActions.createCotizationsDetailsSuccess({
                cotizationsDetails,
              })
            ),
            catchError((error) => of(cotizationsDetailsActions.onError(error)))
          )
      )
    )
  );

  findCotizationsDetailsByCIBEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cotizationsDetailsActions.findCotizationsDetailsByCIB),
      switchMap(({ cib }) =>
        this.apiCotizationsService.findCotizationsDetailsByCIB(cib).pipe(
          map((cotizationsDetails) =>
            cotizationsDetailsActions.findCotizationsDetailsByCIBSuccess({
              cotizationsDetails,
            })
          ),
          catchError((error) => of(cotizationsDetailsActions.onError(error)))
        )
      )
    )
  );

  updCotizationDetailtarsnLocalEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cotizationsDetailsActions.updateCotizationsDetailTransLocal),
      switchMap(({ cotizationId, cotizationsDetail }) =>
        this.apiCotizationsService
          .updCotizationsDetailsTranspLocal(cotizationsDetail, cotizationId)
          .pipe(
            map((updated) =>
              cotizationsDetailsActions.updateCotizationsDetailTransLocalSuccess(
                {
                  updated,
                }
              )
            ),
            catchError((error) => of(cotizationsDetailsActions.onError(error)))
          )
      )
    )
  );

  updCotizationDetailTransInterEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cotizationsDetailsActions.updateCotizationsDetailTransInter),
      switchMap(({ cotizationId, cotizationsDetail }) =>
        this.apiCotizationsService
          .updCotizationsDetailsTransInter(cotizationsDetail, cotizationId)
          .pipe(
            map((updated) =>
              cotizationsDetailsActions.updateCotizationsDetailTransInterSuccess(
                {
                  updated,
                }
              )
            ),
            catchError((error) => of(cotizationsDetailsActions.onError(error)))
          )
      )
    )
  );
  updCotizationDetailCustomsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cotizationsDetailsActions.updateCotizationsDetailCustom),
      switchMap(({ cotizationId, cotizationsDetail }) =>
        this.apiCotizationsService
          .updCotizationsDetailsCustoms(cotizationsDetail, cotizationId)
          .pipe(
            map((updated) =>
              cotizationsDetailsActions.updateCotizationsDetailCustomSuccess({
                updated,
              })
            ),
            catchError((error) => of(cotizationsDetailsActions.onError(error)))
          )
      )
    )
  );
}
