<div class="wrapper" id="modal-info">
    <div class="bsc-header-modal">
      <span (click)="close()" class="close">
        <bc-icon name="error" aria-label="close"></bc-icon>
      </span>
    </div>

    <div class="bsc-body-modal">
      <img
        src="../../../../assets/logos/{{ Icon }}.svg"
        alt="action"
        class="action"
      />
      <h4 class="mb-4 text-center">{{ titulo }}</h4>
      <p class="message mt-2 text-center" [innerHTML]="mensaje"></p>
    </div>

    <div class="bsc-footer-modal">
      <div *ngIf="typeModal == 'ok'">
        <bcs-button
          [Label]="LabelButton"
          Type="button"
          (click)="onClick('ok')"
        ></bcs-button>
      </div>

      <div *ngIf="typeModal !== 'ok'" class="yesOrNoButtons">
        <bcs-button
          class="btn-modal"
          Label="Cancelar"
          Type="button"
          TypeButton="secondary"
          (click)="onClick('no')"
          [ngClass]="'info-msg'"
        ></bcs-button>
        <bcs-button
          class="btn-custom"
          Type="button"
          Label="{{ textButton }}"
          typeButton="primary"
          (click)="onClick('si')"
          [ngClass]="'info-msg'"
        >
          <bc-icon class="icon-float-rigth">arrow-right</bc-icon>
        </bcs-button>
      </div>
    </div>
  </div>
