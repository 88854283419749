import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardAD implements CanActivate {
  constructor(private router: Router) {
    // This is intentional

  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (route.queryParams['token']) {
      const token = route.queryParams['token'];
      const lc = route.queryParams['lc'];
      this.router.navigateByUrl(`auth/client?token=${token}&lc=${lc}`);
      return false;
    }
    return true;
  }
}
