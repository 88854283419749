import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-bcs-alert',
  templateUrl: './bcs-alert.component.html',
  styleUrls: ['./bcs-alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BcsAlertComponent implements OnInit {
  @Input() Show: boolean = false;
  @Input() AlertIcon: string = 'info';
  @Input() AlertType: string = 'bc-alert-error'; //-error, -success, -info, -warning, -inactive
  @Input() AlertTitle: string = 'Los valores del medio de pago no coinciden con el 100%';
  @Input() AlertText: string = 'Los valores que han sido ingresados dentro de los medios de pago deben sumar 100%, verifica que estos son correctos para poder continuar.';
  @Input() ShowClose: boolean = true;

  @Output() response: EventEmitter<string> = new EventEmitter();


  constructor() {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

  close() {
    this.Show = false;
  }

}
