<div class="pagos-files-container">
  <div class="modal-header">
    <h5>Por favor cargue los documentos soporte</h5>
  </div>

  <div class="file-manager-button-drop-section">
    <h5>Por favor cargue los documentos soporte</h5>
    <div class="button-box">
      <bsc-files-manager
        [files]="supportFiles"
        *ngIf="supportFiles.length"
      ></bsc-files-manager>
      <bcs-button
        TypeButton="secondary"
        Label="Cargar documentos"
        (click)="addNewFile('support')"
        *ngIf="!supportOtherFile"
      ></bcs-button>
    </div>

    <div class="drop-files-box d-flex justify-content-center" *ngIf="supportOtherFile">
      <bsc-files
        [description]="''"
        [maxFiles]=20
        (onFilesChange)="getfiles($event, 'support')"
      ></bsc-files>
    </div>
  </div>

  <div class="file-manager-button-drop-section">
    <h5>Por favor cargue los documentos de desembolso</h5>
    <div class="button-box">
      <bsc-files-manager
        [files]="paymentFiles"
        *ngIf="paymentFiles.length"
      ></bsc-files-manager>
      <bcs-button
        TypeButton="secondary"
        Label="Cargar documentos"
        (click)="addNewFile('payment')"
        *ngIf="!paymentOtherFile"
      ></bcs-button>
    </div>

    <div class="drop-files-box d-flex justify-content-center" *ngIf="paymentOtherFile">
      <bsc-files
        [description]="''"
        [maxFiles]=20
        (onFilesChange)="getfiles($event, 'payment')"
      ></bsc-files>
    </div>
  </div>

  <div class="regresar-button">
    <bcs-button
      Label="Regresar"
      (click)="closeModal()"
    ></bcs-button>
  </div>
</div>
