import { IClient } from 'src/app/data/interfaces/IClient.interface';
import { Action, createReducer, on } from '@ngrx/store';
import { newCustomerActions } from './actions';

interface NewCustomerState {
  customer: IClient | null;
  error: any;
  message: any;
  isLoading: boolean;
  created: boolean;
  found: { search: boolean; found: boolean };
  edit: boolean;
  updated: boolean;
}
const initialState: NewCustomerState = {
  customer: null,
  error: null,
  message: null,
  isLoading: false,
  created: false,
  found: { search: false, found: false },
  edit: false,
  updated: false,
};

const featureReducer = createReducer(
  initialState,
  on(newCustomerActions.createCustomer, (state) => ({
    ...state,
    isLoading: true,
    customer: null
  })),
  on(newCustomerActions.createCustomerSuccess, (state, { customer }) => ({
    ...updateFlags(state),
    customer,
    created: true,
  })),
  on(newCustomerActions.getCustomerByFilter, (state) => ({
    ...updateFlags(state),
    customer: null,
    isLoading: true,
  })),
  on(newCustomerActions.getCustomerByFilterSuccess, (state, { customer }) => ({
    ...updateFlags(state),
    customer: customer,
    found: { search: true, found: !!customer },
  })),
  on(newCustomerActions.onErrorByFilter, (state, { error }) => ({
    ...updateFlags(state),
    error,
    found: { search: true, found: false },
  })),
  on(newCustomerActions.onEditCustomer, (state, { edit }) => ({
    ...updateFlags(state),
    edit,
  })),
  on(newCustomerActions.updateCustomer, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(newCustomerActions.updateCustomerSuccess, (state, { customer }) => ({
    ...updateFlags(state),
    updated: true,
    customer : null
  })),
  on(newCustomerActions.updateCustomerCertifications, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    newCustomerActions.updateCustomerCertificationsSuccess,
    (state, { message }) => ({
      ...updateFlags(state),
      message,
      updated: true,
    })
  ),
  on(newCustomerActions.onError, (state, { error }) => ({
    ...state,
    error,
  })),
  on(newCustomerActions.resetFlags, (state) => ({
    ...updateFlags(state),
  }))
);

const updateFlags = (state: NewCustomerState): NewCustomerState => {
  return {
    ...state,
    error: null,
    isLoading: false,
    found: { search: false, found: false },
    edit: false,
    created: false,
    updated: false,
    message: null,
  };
};

function reducer(state: NewCustomerState | undefined, action: Action) {
  return featureReducer(state, action);
}
const getNewCustomerFromStore = (state: NewCustomerState) => state.customer;
const getError = (state: NewCustomerState) => state.error;
const getMessage = (state: NewCustomerState) => state.message;
const getIsLoading = (state: NewCustomerState) => state.isLoading;
const getIsCreated = (state: NewCustomerState) => state.created;
const getIsFound = (state: NewCustomerState) => state.found;
const getEdit = (state: NewCustomerState) => state.edit;
const getUdatedStatus = (state: NewCustomerState) => state.updated;

export const newCustomerReducer = {
  reducer,
  getNewCustomerFromStore,
  getError,
  getMessage,
  getIsLoading,
  getIsCreated,
  getIsFound,
  getEdit,
  getUdatedStatus,
};
export { NewCustomerState };
