import { createAction, props } from '@ngrx/store';
import { INewRole } from 'src/app/data/interfaces/IRoles.itnterface';
import { IAllRoles } from '../../../../../data/interfaces/IRolAllBD.itnterface';
import { ISearchScreens } from '../../../../../data/interfaces/ISearchScreens.interface';
import { IScreensUpdate } from '../../../../../data/interfaces/IScreensUpdate.interface';
import { IScreens } from '../../../../../data/interfaces/IScreens.interface';

enum AdministerRolesActionType {
  LoadAction = '[AdministerRoles Component] Load',
  errorAction = '[AdministerRoles Component] Execute action failure',
  onResetFlagsAction = '[AdministerRoles Component] AdministerRoles reset flags',
  createNewRolAction = '[AdministerRoles Component] Create New Rol',
  createNewRolActionSuccess = '[AdministerRoles Component] Create New Rol Success',
  deleteRolAction = '[AdministerRoles Component] Delete Rol',
  deleteRolActionSuccess = '[AdministerRoles Component] Delete Rol Success',
  getRolByIdAction = '[AdministerRoles Component] Get By Id Rol',
  getRolByIdActionSuccess = '[AdministerRoles Component] Get By Id Rol Success',
  listRolesAction = '[AdministerRoles Component] List Roles',
  searchScreensAction = '[AdministerRoles Component] Search Screens',
  searchScreensActionSuccess = '[AdministerRoles Component] Search Screens Success',
  updateScreensByRoleAction = '[AdministerRoles Component] UpdateScreens By Role ',
  updateScreensByRoleActionSuccess = '[AdministerRoles Component] Update Screens By Role Success',
  searchStaticScreensAction = '[AdministerRoles Component] Search Static Screens',
  searchStaticScreensActionSuccess = '[AdministerRoles Component] Search Static Screens Success',

  SearchAdministerRoless = '[AdministerRoles Component] Search AdministerRoles',
  UpdateAdministerRoles = '[AdministerRoles Component] Update AdministerRoles',
  UpdateTotal = '[AdministerRoles Component] Update Total',
  ActionFailure = '[AdministerRoles API] Execute action failure',
  ActionSuccess = '[AdministerRoles API] Execute action success',
  LoadSuccess = '[AdministerRoles API] Load Success',
  Refresh = '[AdministerRoles Page] Refresh',
  Selected = '[AdministerRoles Page] Select',
  SubmitSuccess = '[AdministerRoles API] Submit Success'
}

const load = createAction(AdministerRolesActionType.LoadAction);

const onError = createAction(
  AdministerRolesActionType.errorAction,
  props<{ error: any }>()
);
const onResetFlags = createAction(
  AdministerRolesActionType.onResetFlagsAction
);

/** Crear Nuevo Rol */
const createNewRol = createAction(
  AdministerRolesActionType.createNewRolAction,
  props<{ newRole: INewRole }>()
);
const createNewRolSuccess = createAction(
  AdministerRolesActionType.createNewRolActionSuccess,
  props<{ role: INewRole }>()
);

/** Eliminar Rol */
const deleteRol = createAction(
  AdministerRolesActionType.deleteRolAction,
  props<{ nameRole: string }>()
);
const deleteRolSuccess = createAction(
  AdministerRolesActionType.deleteRolActionSuccess,
  props<{ role: any }>()
);

/** Consultar Rol por Id */
const getRolById = createAction(
  AdministerRolesActionType.getRolByIdAction,
  props<{ rolId: number }>()
);
const getRolByIdSuccess = createAction(
  AdministerRolesActionType.getRolByIdActionSuccess,
  props<{ rol: IAllRoles }>()
);

/** Consultar por Rol, Lista de Screens por Módulo y/o Flujo */
const searchScreens = createAction(
  AdministerRolesActionType.searchScreensAction,
  props<{ requestScreens: ISearchScreens }>()
);
const solicitScreensSuccess = createAction(
  AdministerRolesActionType.searchScreensActionSuccess,
  props<{ screens: IScreens[] }>()
);

  /** Actualizar Rol por Nombre del Rol */
const updateScreensByRole = createAction(
  AdministerRolesActionType.updateScreensByRoleAction,
  props<{ nameRole: string, updateScreens: IScreensUpdate[] }>()
);
const updateScreensByRoleSuccess = createAction(
  AdministerRolesActionType.updateScreensByRoleActionSuccess,
  props<{ screens: any }>()
);

/** Consultar por Rol, Lista de Static-Screens por Módulo y/o Flujo */
const searchStaticScreens = createAction(
  AdministerRolesActionType.searchStaticScreensAction,
  props<{ requestScreens: ISearchScreens }>()
);
const solicitStaticScreensSuccess = createAction(
  AdministerRolesActionType.searchStaticScreensActionSuccess,
  props<{ screens: IScreens[] }>()
);

export const adminRolesAction = {
  load,
  onError,
  onResetFlags,
  createNewRol,
  createNewRolSuccess,
  deleteRol,
  deleteRolSuccess,
  getRolById,
  getRolByIdSuccess,
  searchScreens,
  solicitScreensSuccess,
  updateScreensByRole,
  updateScreensByRoleSuccess,
  searchStaticScreens,
  solicitStaticScreensSuccess
}
