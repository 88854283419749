<div class="files">
  <div class="row">
    <div class="col-12 mb-2">
      <div class="mb-2"
      [ngClass]="{'wrapper-included' : showIncluded, 'wrapper' : !showIncluded}" *ngFor="let item of files; let i = index">
        <mat-checkbox *ngIf="showIncluded" [(ngModel)]="item.include"></mat-checkbox>
        <bc-icon
          [name]="item.icon ? item.icon : 'document'"
          aria-hidden="false"
          [aria-label]="item.icon ? item.icon : 'document'"
          [size]="size"
          class="icon-info"
        ></bc-icon>

        <p class="text mx-2 pt-1" title="{{item.file.name}}" >{{ item.file.name }}</p>
        <bc-icon
          *ngIf="showDownload"
          name="download"
          aria-hidden="false"
          aria-label="download"
          [size]="size"
          class="icon-hover"
          (click)="onClickDownload(item.file.name)"
        ></bc-icon>
        <bc-icon
          class="ms-1"
          *ngIf="item.delete"
          name="erase"
          aria-hidden="false"
          aria-label="erase"
          [size]="size"
          class="icon-hover"
          (click)="delete(i)"
          ></bc-icon>
      </div>
    </div>
  </div> 
</div>
