import { IPage } from "../interfaces/igeneric-response";

export class PageDto implements IPage {
    constructor() {
        this.size = 5;
        this.totalElements = 0;
        this.totalPages = 0;
        this.number = 0;
    }

    public size!: number;
    totalElements: number;
    totalPages: number;
    number: number;

}