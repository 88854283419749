import { Action, createReducer, on } from '@ngrx/store';
import { PaymentsActions } from './actions';
import { ICreditLetterDtoList } from '../../interfaces/ICreditLetterDtoList.interface';
import { CommissionCredit, IHistoricCommission } from '../../interfaces/ICommissionCredit';
import { ParameterTypeList } from '../../interfaces/paymentMethod.interface';
import { ICardValues } from '../../interfaces/paymentsValuesCard.interface';
import { IFilesPayment } from 'src/app/data/interfaces/IFilesPayment.interface';

interface PaymentsState {
  creditLetterDtoList: ICreditLetterDtoList | null;
  getFilesPaymentData: IFilesPayment | null;
  transferDtoList: ICreditLetterDtoList | null;
  servicesDtoList: ICreditLetterDtoList | null;
  cardValues: ICardValues | null;
  commissionCredit: CommissionCredit[] | null;
  historicCommission: IHistoricCommission | null;
  deleteComissionData: any | null;
  typeMethodValues: ParameterTypeList[] | null;
  agreedDocumentValues: ParameterTypeList[] | null;
  error: any;
  message: any;
  isLoading: boolean;
  created: boolean;
  found: { search: boolean; found: boolean };
  edit: boolean;
  updated: boolean;
  createdPaymentData: any[] | null;
  updatedPaymentLetterData: any | null;
  updatedPaymentTransferData: any | null;
  updatedPaymentServicesData: any | null;
  updatePaymentStatusData: any | null;
  deletePaymentData: any | null;
  sendEmailCustomData: any | null;
}

const initialState: PaymentsState = {
  creditLetterDtoList: null,
  getFilesPaymentData: null,
  transferDtoList: null,
  servicesDtoList: null,
  cardValues: null,
  commissionCredit: null,
  historicCommission: null,
  deleteComissionData: null,
  typeMethodValues: null,
  agreedDocumentValues: null,
  error: null,
  message: null,
  isLoading: false,
  created: false,
  found: { search: false, found: false },
  edit: false,
  updated: false,
  createdPaymentData: null,
  updatedPaymentLetterData: null,
  updatedPaymentTransferData: null,
  updatedPaymentServicesData: null,
  updatePaymentStatusData: null,
  deletePaymentData: null,
  sendEmailCustomData: null,
};

const featureReducer = createReducer(
  initialState,

  on(PaymentsActions.onErrors, (state, { error }) => ({
    ...updateFlags(state),
    error,
  })),
  on(PaymentsActions.onResetFlags, (state) => ({
    ...updateFlags(state),
  })),
  on(PaymentsActions.getPaymentsValues, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),

  on(PaymentsActions.getPaymentsValuesSuccess, (state, { cardValues }) => ({
    ...updateFlags(state),
    cardValues,
  })),

  on(PaymentsActions.findCreditLetter, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),

  on(PaymentsActions.findCreditLetterSuccess, (state, { creditLetterDtoList }) => ({
    ...updateFlags(state),
    creditLetterDtoList
  })),

  on(PaymentsActions.findTransfer, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),

  on(PaymentsActions.findTransferSuccess, (state, { transferDtoList }) => ({
    ...updateFlags(state),
    transferDtoList
  })),

  on(PaymentsActions.findServices, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),

  on(PaymentsActions.findServicesSuccess, (state, { servicesDtoList }) => ({
    ...updateFlags(state),
    servicesDtoList
  })),

  on(PaymentsActions.findEveryCommission, (state) => ({
    ...updateFlags(state),
    commissionCredit: null,
    isLoading: true,
  })),

  on(PaymentsActions.findEveryCommissionSuccess, (state, { commissionCredit }) => ({
    ...updateFlags(state),
    commissionCredit
  })),

  on(PaymentsActions.addCommissionSuccess, (state, { data }) => ({
    ...updateFlags(state),
    message: data
  })),

  on(PaymentsActions.updateCommission, (state) => ({
    ...updateFlags(state)
  })),

  on(PaymentsActions.updateCommissionSuccess, (state) => ({
    ...updateFlags(state),
    edit: true
  })),

  on(PaymentsActions.historicCommission, (state) => ({
    ...updateFlags(state),
    historicCommission: null,
    isLoading: true,
  })),

  on(PaymentsActions.historicCommissionSuccess, (state, { historicCommission }) => ({
    ...updateFlags(state),
    historicCommission
  })),

  on(PaymentsActions.deleteCommission, (state) => ({
    ...updateFlags(state),
  })),

  on(PaymentsActions.deleteCommissionSuccess, (state, { deleteComissionData }) => ({
    ...updateFlags(state),
    deleteComissionData
  })),

  on(PaymentsActions.getTypeMethodSuccess, (state, { typeMethodValues }) => ({
    ...updateFlags(state),
    typeMethodValues
  })),
  on(PaymentsActions.getAgreedDocumentSuccess, (state, { agreedDocumentValues }) => ({
    ...updateFlags(state),
    agreedDocumentValues
  })),
  on(PaymentsActions.createPaymentsSuccess, (state, { createdPaymentData }) => ({
    ...updateFlags(state),
    createdPaymentData
  })),
  on(PaymentsActions.updatedPaymentLetterSuccess, (state, { updatedPaymentLetterData }) => ({
    ...updateFlags(state),
    updatedPaymentLetterData
  })),
  on(PaymentsActions.updatedPaymentTransferSuccess, (state, { updatedPaymentTransferData }) => ({
    ...updateFlags(state),
    updatedPaymentTransferData
  })),
  on(PaymentsActions.updatedPaymentServicesSuccess, (state, { updatedPaymentServicesData }) => ({
    ...updateFlags(state),
    updatedPaymentServicesData
  })),
  on(PaymentsActions.updatePaymentStatusSuccess, (state, { updatePaymentStatusData }) => ({
    ...updateFlags(state),
    updatePaymentStatusData
  })),
  on(PaymentsActions.deletePaymentSuccess, (state, { deletePaymentData }) => ({
    ...updateFlags(state),
    deletePaymentData
  })),
  on(PaymentsActions.sendEmailCustomSuccess, (state, { sendEmailCustomData }) => ({
    ...updateFlags(state),
    sendEmailCustomData
  })),

  on(PaymentsActions.resetSendEmailCustom, (state, { sendEmailCustomData }) => ({
    ...updateFlags(state),
    sendEmailCustomData
  })),
  on(PaymentsActions.getFilesPaymentSuccess, (state, { getFilesPaymentData }) => ({
    ...updateFlags(state),
    getFilesPaymentData
  })),
);

const updateFlags = (state: PaymentsState): PaymentsState => {
  return {
    ...state,
    error: null,
    isLoading: false,
    found: { search: false, found: false },
    edit: false,
    created: false,
    updated: false,
    message: null,
  };
};

function reducer(state: PaymentsState | undefined, action: Action) {
  return featureReducer(state, action);
}

const getError = (state: PaymentsState) => state.error;
const getCreditLetter = (state: PaymentsState) => state.creditLetterDtoList;
const getFilesPaymentDatas = (state: PaymentsState) => state.getFilesPaymentData;
const getTransfer = (state: PaymentsState) => state.transferDtoList;
const getServices = (state: PaymentsState) => state.servicesDtoList;
const getPaymentsValues = (state: PaymentsState) => state.cardValues;
const getEveryCommision = (state: PaymentsState) => state.commissionCredit;
const getHistoricCommission = (state: PaymentsState) => state.historicCommission;
const getDeleteCommissionData = (state: PaymentsState) => state.deleteComissionData;
const getTypeMethodData = (state: PaymentsState) => state.typeMethodValues;
const getAgreedDocumentData = (state: PaymentsState) => state.agreedDocumentValues;
const createdPaymentDatas = (state: PaymentsState) => state.createdPaymentData;
const updatedPaymentLetterDatas = (state: PaymentsState) => state.updatedPaymentLetterData;
const deletePaymentDatas = (state: PaymentsState) => state.deletePaymentData;
const updatedPaymentTransferDatas = (state: PaymentsState) => state.updatedPaymentTransferData;
const updatedPaymentServicesDatas = (state: PaymentsState) => state.updatedPaymentServicesData;
const updatePaymentStatusDatas = (state: PaymentsState) => state.updatePaymentStatusData;
const sendEmailCustomDatas = (state: PaymentsState) => state.sendEmailCustomData;


const getMessage = (state: PaymentsState) => state.message;
const getEdit = (state: PaymentsState) => state.edit;

export const PaymentsReducer = {
  createdPaymentDatas,
  updatePaymentStatusDatas,
  updatedPaymentLetterDatas,
  deletePaymentDatas,
  updatedPaymentTransferDatas,
  updatedPaymentServicesDatas,
  sendEmailCustomDatas,
  reducer,
  getError,
  getEdit,
  getPaymentsValues,
  getCreditLetter,
  getFilesPaymentDatas,
  getTransfer,
  getServices,
  getEveryCommision,
  getHistoricCommission,
  getDeleteCommissionData,
  getMessage,
  getTypeMethodData,
  getAgreedDocumentData,
};

export { PaymentsState };
