<mat-form-field class="w-100" appearance="legacy">
  <mat-label>{{ Label }}</mat-label>
  <input autocomplete="off"
    class="w-100"
    type="text"
    matInput
    [formControl]="InputFormControl"
    [matAutocomplete]="auto"
    [readonly]="readonly"
  />
  <mat-autocomplete #auto="matAutocomplete"  [displayWith]="displayFn">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="getId(option)">
      {{ getDescripcion(option) }}
    </mat-option>
  </mat-autocomplete>
  <mat-hint class="mat-hint" *ngIf="!isError">{{ HelpText }}</mat-hint>
  <mat-error class="mat-hint-error" *ngIf="isError">{{ errorText }}</mat-error>
</mat-form-field>
