import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MaterialModule } from './material/material.module';
import { BcsCardComponent } from './components/bcs-card/bcs-card.component';
import { BcsTextBoxComponent } from './components/bcs-text-box/bcs-text-box.component';
import { BcsSelectBoxComponent } from './components/bcs-select-box/bcs-select-box.component';
import { BcsModalComponent } from './components/bcs-modal/bcs-modal.component';
import { BcsButtonComponent } from './components/bcs-button/bcs-button.component';
import { RequestSearchResultTableComponent } from './components/request-search-result-table/request-search-result-table.component';
import { CapitalizeInputDirective } from '../core/directives/capitalize-input.directive';
import { BcsDynamicTableComponent } from './components/bcs-dynamic-table/bcs-dynamic-table.component';
import { ButtonLinkActionComponent } from './components/button-link-action/button-link-action.component';
import { BcHeaderModule } from 'bancolombia-design-system/bc-header';
import { BcIconModule } from 'bancolombia-design-system/bc-icon';
import { BcFooterModule } from 'bancolombia-design-system/bc-footer';
import { BcInputFileModule } from 'bancolombia-design-system/bc-input-file';
import { BcMenuModule } from 'bancolombia-design-system/bc-menu';
import { BcInputModule } from 'bancolombia-design-system/bc-input';
import { BcBreadcrumbModule } from 'bancolombia-design-system/bc-breadcrumb';
import { BcInputSelectModule } from 'bancolombia-design-system/bc-input-select';
import { BcProgressBarWebModule } from 'bancolombia-design-system/bc-progress-bar';
import { BcButtonModule } from 'bancolombia-design-system/bc-button';
import { BcStepperModule } from 'bancolombia-design-system/bc-stepper';
import { BcCardModule } from 'bancolombia-design-system/bc-card';
import { BcInputDateRangeModule } from 'bancolombia-design-system/bc-input-date-range';
import { BcModalModule } from 'bancolombia-design-system/bc-modal';
import { BcCheckboxModule } from 'bancolombia-design-system/bc-checkbox';
import { BcPaginatorModule } from 'bancolombia-design-system/bc-paginator';
import { BcTableModule } from 'bancolombia-design-system/bc-table';
import { BcsAutocompleteComponent } from './components/bcs-autocomplete/bcs-autocomplete.component';
import { BscFilesComponent } from './components/bsc-files/bsc-files.component';
import { BscFilesManagerComponent } from './components/bsc-files-manager/bsc-files-manager.component';
import { BcTooltipModule } from 'bancolombia-design-system/bc-tooltip';
import { SessionTimeComponent } from './components/bcs-modal/session-time/session-time.component';
import { InfoComponent } from './components/bcs-modal/info/info.component';
import { OnlylettersDirective } from '../core/directives/onlyletters.directive';
import { BcsMessagePostComponent } from './components/bcs-message-post/bcs-message-post.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { BcsAlertComponent } from './components/bcs-alert/bcs-alert.component';
import { BcsStepperComponent } from './components/bcs-stepper/bcs-stepper.component';
import { ValuesSummaryCardComponent } from './components/values-summary-card/values-summary-card.component';
import { PaymentMethodFormComponent } from './components/payment-method-form/payment-method-form.component';
import { BcTabsModule } from 'bancolombia-design-system/bc-tabs-group';
import { FilesModalComponent } from './components/bcs-modal/files/files-modal/files-modal.component';
import { BtnAdminConsultsQuotesAlliesComponent } from './components/btn-admin-consults-quotes-allies/btn-admin-consults-quotes-allies.component'
import { BcSwitchModule } from 'bancolombia-design-system/bc-switch';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { BcsPermissionsComponent } from './components/bcs-permissions/bcs-permissions.component';
import { OnlyNumbersDirective } from '../core/directives/only-numbers.directive';
import { BcLoaderModule } from 'bancolombia-design-system/bc-loader';
import { PaymentFilesComponent } from '../modules/master/logistics-coordination/components/payments/componentes/payment-files/payment-files.component';
import { BscFilesUploaderComponent } from './components/bsc-files-uploader/bsc-files-uploader.component';
import { DragAndDropDirective } from '../core/directives/drag-and-drop.directive';

const COMPONENTS = [
  BcsCardComponent,
  BcsTextBoxComponent,
  BcsSelectBoxComponent,
  BcsModalComponent,
  BcsButtonComponent,
  BcsCardComponent,
  BcsDynamicTableComponent,
  RequestSearchResultTableComponent,
  CapitalizeInputDirective,
  OnlylettersDirective,
  OnlyNumbersDirective,
  ButtonLinkActionComponent,
  BcsAutocompleteComponent,
  BscFilesComponent,
  BscFilesManagerComponent,
  SessionTimeComponent,
  InfoComponent,
  BcsMessagePostComponent,
  BcsAlertComponent,
  BcsStepperComponent,
  ValuesSummaryCardComponent,
  PaymentMethodFormComponent,
  FilesModalComponent,
  BtnAdminConsultsQuotesAlliesComponent,
  BcsPermissionsComponent,
  PaymentFilesComponent,
  DragAndDropDirective
];
const BC_MODULES = [
  BcFooterModule,
  BcHeaderModule,
  BcInputFileModule,
  BcInputModule,
  BcMenuModule,
  BcBreadcrumbModule,
  BcInputSelectModule,
  BcInputDateRangeModule,
  BcPaginatorModule,
  BcTableModule,
  BcButtonModule,
  BcStepperModule,
  BcCardModule,
  BcModalModule,
  BcCheckboxModule,
  BcTooltipModule,
  BcProgressBarWebModule,
  BcTabsModule,
  BcSwitchModule,
  BcIconModule,
  BcLoaderModule
];
const MODULES = [
  CommonModule,
  CurrencyMaskModule,
  MaterialModule,
  RouterModule,
  TextFieldModule,
];
@NgModule({
  declarations: [...COMPONENTS, BscFilesUploaderComponent],
  imports: [
    ...BC_MODULES,
    ...MODULES,
    BcIconModule.forRoot({
      path: 'https://library-sdb.apps.bancolombia.com/2.1.3',
    }),
  ],
  exports: [...BC_MODULES, ...MODULES, ...COMPONENTS],
  entryComponents: [BcsModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule { }
