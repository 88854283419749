<button
  bc-button
  [typeButton]="
    TypeButton == 'principal'
      ? 'primary'
      : TypeButton == 'secondary'
      ? 'secondary'
      : 'terciary'
  "
  [sizeButton]="size"
  [type]="Type"
  [ngStyle]="{'width': Width, 'max-width': MaxWidth}"
  (click)="onClick()"
  *ngIf="IconPosition == 'Left'"
  [disabled]="disabled"
>
  <bc-icon *ngIf="Icon">{{ Icon }}</bc-icon>
  <img *ngIf="urlSrc" src="{{urlSrc}}" class="mr-1" alt="icon-img">
  <span> {{ Label }}</span>
</button>

<button
  bc-button
  [typeButton]="
    TypeButton == 'principal'
      ? 'primary'
      : TypeButton == 'secondary'
      ? 'secondary'
      : 'terciary'
  "
  [sizeButton]="size"
  [type]="Type"
  (click)="onClick()"
  *ngIf="IconPosition == 'Right'"
  [disabled]="disabled"
>
  <span> {{ Label }}</span>
  <bc-icon *ngIf="Icon">{{ Icon }}</bc-icon>
</button>
