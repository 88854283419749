import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { IFilesManager } from 'src/app/data/interfaces/IFiles-manager.interface';
import { FilesService } from 'src/app/data/services/apis/files/files.service';
import { IconBCEnum } from 'src/app/core/enums/icon.enum';
import { PaymentsFacadeService } from '../payments-store/payments.facade.service';
import { Subscription } from 'rxjs';
import { IFilesPaymentDto } from 'src/app/data/interfaces/IFilesPayment.interface';

@Component({
  selector: 'app-payment-files',
  templateUrl: './payment-files.component.html',
  styleUrls: ['./payment-files.component.scss']
})
export class PaymentFilesComponent implements OnInit, OnDestroy {
  _importNumber!: string;

  @Input()
  get importNumber(): string {
    return this._importNumber;
  }
  set importNumber(value: string) {
    this._importNumber = value;
    this.paymentsFacadeService.getFilesPayment(this._importNumber);
  }

  listFiles: IFilesManager[] = [
    { icon: IconBCEnum.document, file: { name: "lorem.png" }, delete: false, download: true, edit: true }
  ];
  get listFilesSelected(): IFilesManager[] {
    return this.listFiles.filter(x => x.include);
  }
  subs: Subscription = new Subscription();

  setFile(file: IFilesPaymentDto): IFilesManager {
    return {
      icon: IconBCEnum.document,
      file: { name: `${file?.routeDocument}` },
      delete: false,
      download: true,
      edit: true
    };
  }
  @Input() showButtonDownloadAll = true;
  constructor(
    private paymentsFacadeService: PaymentsFacadeService,
    private files: FilesService,
  ) {
    this.subscriptions();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  ngOnInit(): void {
    // This is intentional
  }
  subscriptions() {
    this.subscriptionsGetFilePayments();
  }
  subscriptionsGetFilePayments() {
    const sub = this.paymentsFacadeService
      .getFilesPaymentSuccess()
      .subscribe((response) => {
        if (response) {
          this.listFiles = response.Files?.map(x => this.setFile(x))?? [];
        }
      });
    this.subs.add(sub);
  }
  downloadSelected() {
    this.files.downloadAll(this.listFilesSelected)
  }
}
