import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CotizationsActions } from './actions';
import { ApiCotizationsService } from 'src/app/data/services/apis/cotization/api-cotizations.service';

@Injectable()
export class CotizationsEffects {
  constructor(
    private apiCotizationsService: ApiCotizationsService,
    private actions$: Actions
  ) {
    // This is intentional
  }

  createCotizationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CotizationsActions.createCotizations),
      switchMap(({ cotization }) =>
        this.apiCotizationsService.createCotization(cotization).pipe(
          map((cotization) =>
            CotizationsActions.createCotizationsSuccess({ cotization })
          ),
          catchError((error) => of(CotizationsActions.onError(error)))
        )
      )
    )
  );

  searchCotizationByFilterEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CotizationsActions.SearchCotizationByFilter),
      switchMap(({ params, page, size }) =>
        this.apiCotizationsService.getCotizationByFilter(params, page, size).pipe(
          map((cotizations) =>
            CotizationsActions.SearchCotizationByFilterSuccess({ cotizations })
          ),
          catchError((error) => of(CotizationsActions.onError(error)))
        )
      )
    )
  );

  searchCotizationByFilterToDeleteEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CotizationsActions.SearchCotizationByFilterToDelete),
      switchMap(({ params, page, size }) =>
        this.apiCotizationsService.getCotizationByFilterToDelete(params, page, size).pipe(
          map((cotizations) =>
            CotizationsActions.SearchCotizationByFilterToDeleteSuccess({ cotizations })
          ),
          catchError((error) => of(CotizationsActions.onError(error)))
        )
      )
    )
  );

  deleteCotizationsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CotizationsActions.deleteCotizations),
      switchMap(({ body }) =>
        this.apiCotizationsService.deleteCotizations(body).pipe(
          map((cotizations) =>
            CotizationsActions.deleteCotizationsSuccess({ edit: true })
          ),
          catchError((error) => of(CotizationsActions.onError(error)))
        )
      )
    )
  );

  searchCotizationByCIBEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CotizationsActions.searchCotizationByCIB),
      switchMap(({ CIB, clientSection }) =>
        this.apiCotizationsService.getCotizationByCIB(CIB, clientSection).pipe(
          map((cotization) =>
            CotizationsActions.searchCotizationByCIBSuccess({ cotization })
          ),
          catchError((error) => of(CotizationsActions.onError(error)))
        )
      )
    )
  );

  findCotizationsByParamsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CotizationsActions.findCotizationByParams),
      switchMap(
        ({
          documentType,
          documentNumber,
          clientName,
          cib,
          initDate,
          endDate,
          pagination
        }) =>
          this.apiCotizationsService
            .findCotiztionByParasms(
              documentType,
              documentNumber,
              clientName,
              cib,
              initDate,
              endDate,
              pagination
            )
            .pipe(
              map((cotizations) =>
                CotizationsActions.findCotizationByParamsSuccess({
                  cotizations,
                })
              ),
              catchError((error) => of(CotizationsActions.onError(error)))
            )
      )
    )
  );

  cotizationAdminAprovalEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CotizationsActions.cotizationAdminAproval),
      switchMap(({ cotizationId, adminAproval }) =>
        this.apiCotizationsService
          .cotizationAdminAproval(cotizationId, adminAproval)
          .pipe(
            map((message) =>
              CotizationsActions.cotizationAdminAprovalSuccess({
                message,
              })
            ),
            catchError((error) => of(CotizationsActions.onError(error)))
          )
      )
    )
  );
  cotizationCustomHouseAprovalEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CotizationsActions.cotizationCustomHouseAproval),
      switchMap(({ cotizationId, customHouseAproval }) =>
        this.apiCotizationsService
          .cotizationCustomHouseAproval(cotizationId, customHouseAproval)
          .pipe(
            map((message) =>
              CotizationsActions.cotizationCustomHouseAprovalResponse({
                message,
              })
            ),
            catchError((error) => of(CotizationsActions.onError(error)))
          )
      )
    )
  );

  updateCotizationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CotizationsActions.updateCotization),
      switchMap(({ cotization, cotizationId }) =>
        this.apiCotizationsService
          .updateCotization(cotization, cotizationId)
          .pipe(
            map((message) =>
              CotizationsActions.updateCotizationSuccess({
                message,
              })
            ),
            catchError((error) => of(CotizationsActions.onError(error)))
          )
      )
    )
  );

  updateZoneLeaderEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CotizationsActions.updateZoneLeader),
      switchMap(({ idZoneLeader, cib }) =>
        this.apiCotizationsService
          .putUpdateZoneLeader(idZoneLeader, cib)
          .pipe(
            map((message) =>
              CotizationsActions.updateZoneLeaderSuccess({
                message,
              })
            ),
            catchError((error) => of(CotizationsActions.onError(error)))
          )
      )
    )
  );
  
  getTermsAndConditionsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CotizationsActions.getTermsAndConditions),
      switchMap(() =>
        this.apiCotizationsService
          .getTermsAndConditions()
          .pipe(
            map((termsAndConditions) =>
              CotizationsActions.getTermsAndConditionsSuccess({
                termsAndConditions
              })
            ),
            catchError((error) => of(CotizationsActions.onError(error)))
          )
      )
    )
  );
}
