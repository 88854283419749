import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { IFilesManager } from 'src/app/data/interfaces/IFiles-manager.interface';
import { FilesService } from 'src/app/data/services/apis/files/files.service';
import { IconBCEnum } from 'src/app/core/enums/icon.enum';
import { Utils } from 'src/app/shared/utils';
import { ModalService } from '@services/modal/modal.service';

@Component({
  selector: 'files-modal',
  templateUrl: './files-modal.component.html',
  styleUrls: ['./files-modal.component.scss']
})
export class FilesModalComponent implements OnInit {

  supportFiles: IFilesManager[] = [];
  paymentFiles: IFilesManager[] = [];
  supportOtherFile: boolean = false;
  paymentOtherFile: boolean = false;
  @Input() paymentChildId!: number;
  @Input() importNumber!: string;
  @Output() closeModalEvent = new EventEmitter();

  constructor(private utils: Utils,
    private files: FilesService,
    private modalService: ModalService,
  ) {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

  addNewFile(fileType: string) {
    if (fileType === 'support') {
      this.supportOtherFile = true;
    } else {
      this.paymentOtherFile = true;
    }
  }

  closeModal() {
    if (this.closeModalEvent) {
      this.closeModalEvent.emit();
    }
  }

  getfiles(archivos: File[], fileType: string) {
    if (this.utils.validateFiles(this.supportFiles, archivos) || this.utils.validateFiles(this.paymentFiles, archivos)) {
      this.modalService.showSiNo("Archivo repetido", `Esta a punto de agregar dos veces un mismo archivo`, "stop", "Continuar").then(data => {
        if (data === 'no') {
          return
        }
        this.addFiles(archivos, fileType)
      })
    } else {
      this.addFiles(archivos, fileType)
    }
  }

  addFiles(archivos: File[], fileType: string) {
    this.files
      .saveFilesPayment(
        this.utils.filesRaw(this.utils.filesManagerFormat(archivos, IconBCEnum.document, false, false)),
        this.paymentChildId,
        this.importNumber
      )
      .subscribe(
        (resp) => {
          if (resp?.Message === 'Se han guardado correctamente los archivos.') {
            archivos = (resp.Files.documentsDtoList as string[])
              .map((file: any) => { return { name: file.documentRoute.split("/")[1] }; }) as File[];
            if (fileType === 'support') {
              this.supportFiles = [...this.utils.filesManagerFormat(archivos, IconBCEnum.document, false, false)]
              this.supportOtherFile = false;
            } else {
              this.paymentFiles = [...this.utils.filesManagerFormat(archivos, IconBCEnum.document, false, false)];
              this.paymentOtherFile = false;
            }
          }
        },
        (error) => {
          this.utils.errorManager(
            error,
            'ExistingImportAddNewDispatchComponent'
          );
        }
      );
    this.supportOtherFile = false;
  }
}
