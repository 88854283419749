import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class TokenJWTService {

  constructor(
    private msalService: MsalService
  ) {
    // This is intentional
  }

  async getAccountActive(): Promise<any> {
    return this.msalService.instance.getActiveAccount();
  }

}
