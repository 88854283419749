import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Numbers } from 'src/app/core/constants/numbers';

@Component({
  selector: 'bsc-files',
  templateUrl: './bsc-files.component.html',
  styleUrls: ['./bsc-files.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BscFilesComponent implements OnInit {

  @Input() title: string = 'Adjuntar Documentos';
  @Input() description: string = 'Agregue los documentos que desee vincular al proceso.';
  @Input() dropArea: boolean = true;
  @Input() dropAreaText: string = 'Arrastra aquí los archivos desde tu computador o has clic en el botón.';
  @Input() multipleFiles: boolean = true;
  @Input() validType: string[] = ['png', 'jpg', 'pdf', 'doc', 'xls', 'docx', 'xlsx', 'tif', 'zip', 'msg'];
  @Input() maxFiles: number = Numbers._20;
  @Input() maxSize: number = Numbers._10240;
  @Input() buttonLabel: string = 'Seleccionar un archivo.';
  @Input() componentId: string = 'InputConDropArea';
  @Input() show: boolean = true;
  @Output() onFilesChange = new EventEmitter<any>();
  @Output() changeStateMaxFiles = new EventEmitter<any>();
  @Output() onErrorValidate = new EventEmitter<boolean>();

  constructor() {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

  getfiles(event: any) {
    this.onFilesChange.emit(event);
  }

  listenerMaxFiles(event: any) {
    // This is intentional
  }

  onError(event: any){
    this.onErrorValidate.emit(event);
  }

  @Input() url!: string;
  @Input() nameFile!: string;

  download() {
    this.url = './assets/images/banner.jpg';
    this.nameFile = 'nombre_archivo';
  }
}
