import { ICotizationDetail } from 'src/app/data/interfaces/ICotizationDetail.interface';
import { ICotizationDetailUpdTranspLocal } from 'src/app/data/interfaces/IcotizationDetailUpdTranspLocal.interface';
import { createAction, props } from '@ngrx/store';
import { ICustomData } from '../../../../../../data/interfaces/ICustomsData.interface';
import { CotizationDetail } from 'src/app/data/interfaces/IcotizationDetailUpdTranspInternacional.interface';

enum CotizationsDetailsActionType {
  Load = '[CotizationDetails Component] Load',
  searchCotizationsDetailsAction = '[CotizationsDetails Component] Search CotizationsDetails',
  searchCotizationsDetailsSuccessAction = '[CotizationsDetails Component] Search CotizationsDetails Success',
  updateCotizationsDetails = '[CotizationsDetails Component] Update CotizationsDetails',
  updateCotizationsDetailsSuccess = '[CotizationsDetails Component] Update CotizationsDetails Success',
  createCotizationsDetailsAction = '[CotizationsDetails Component] Create CotizationsDetails',
  createCotizationsDetailsSuccessAction = '[CotizationsDetails Component] Create CotizationsDetails Success',
  resetFlagsAction = '[CotizationsDetails Component] Cotization detail reset Flags',
  resetFlagsTrueAction = '[CotizationsDetails Component] Cotization detail reset Flags True',
  
  error = '[CotizationsDetails Component] error ',
  setCotizationsDetailsToStoreAction = '[CotizationsDetails Component] set into store cotization detail',
  saveCotizationsDetailsAction = '[CotizationsDetails Component] save Cotizations Details in Database',
  saveCotizationsDetailsSuccessAction = '[CotizationsDetails Component] save Cotizations Details in Database Success',
  findCotizationsDetailsByCIBAction = '[CotizationsDetails Component] find a Cotizations Details By CIB',
  findCotizationsDetailsByCIBSuccessAction = '[CotizationsDetails Component] find Cotizations Details By CIB Success',

  findCotizationsDetailsLocalByCIBAllyAction = '[CotizationsDetails Component] find a Cotizations Details Local By CIB Ally',
  findCotizationsDetailsLocalByCIBAllySuccessAction = '[CotizationsDetails Component] find Cotizations Details Local By CIB Ally Success',
  findCotizationsDetailsInternatByCIBAllyAction = '[CotizationsDetails Component] find a Cotizations Details International By CIB Ally',
  findCotizationsDetailsInternatByCIBAllySuccessAction = '[CotizationsDetails Component] find Cotizations Details International By CIB Ally Success',

  updateCotizationsDetailTransLocalAction = '[Cotizations Component] update Cotizations Detail Trans Local',
  updateCotizationsDetailTransLocalSuccessAction = '[Cotizations Component] update Cotizations Detail Trans Local Success',
  updateCotizationsDetailTransInterAction = '[Cotizations Component] update Cotizations Detail Trans International',
  updateCotizationsDetailTransInterSuccessAction = '[Cotizations Component] update Cotizations Detail Trans International Success',
  updateCotizationsDetailCustomAction = '[Cotizations Component] update Cotizations Detail Customs',
  updateCotizationsDetailCustomSuccessAction = '[Cotizations Component] update Cotizations Detail Customs Success',

  getCotizationsCustomAction = '[Cotizations Component] get Cotizations Customs',
  getCotizationsCustomSuccessAction = '[Cotizations Component] get Cotizations Customs Success',
}

const load = createAction(CotizationsDetailsActionType.Load);
const searchCotizationsDetails = createAction(
  CotizationsDetailsActionType.searchCotizationsDetailsAction,
  props<{ query: string }>()
);

const searchCotizationsDetailsSuccess = createAction(
  CotizationsDetailsActionType.searchCotizationsDetailsSuccessAction,
  props<{ query: string }>()
);

const createCotizationsDetails = createAction(
  CotizationsDetailsActionType.createCotizationsDetailsAction,
  props<{ cotizationsDetails: ICotizationDetail }>()
);

const setCotizationsDetailsToStore = createAction(
  CotizationsDetailsActionType.setCotizationsDetailsToStoreAction,
  props<{ cotizationsDetails: ICotizationDetail }>()
);
const createCotizationsDetailsSuccess = createAction(
  CotizationsDetailsActionType.createCotizationsDetailsSuccessAction,
  props<{ cotizationsDetails: ICotizationDetail }>()
);
const saveCotizationsDetails = createAction(
  CotizationsDetailsActionType.saveCotizationsDetailsAction,
  props<{ cotizationsDetails: ICotizationDetail }>()
);
const saveCotizationsDetailsSuccess = createAction(
  CotizationsDetailsActionType.saveCotizationsDetailsSuccessAction,
  props<{ cotizationsDetails: ICotizationDetail }>()
);
const onError = createAction(
  CotizationsDetailsActionType.error,
  props<{ error: any }>()
);

const findCotizationsDetailsByCIB = createAction(
  CotizationsDetailsActionType.findCotizationsDetailsByCIBAction,
  props<{ cib: string }>()
);
const findCotizationsDetailsByCIBSuccess = createAction(
  CotizationsDetailsActionType.findCotizationsDetailsByCIBSuccessAction,
  props<{ cotizationsDetails: ICotizationDetail }>()
);

/** database/cotizationsDetailsTransport?transportType=LOCAL */
const findCotizationsDetailsLocalByCIBAlly = createAction(
  CotizationsDetailsActionType.findCotizationsDetailsLocalByCIBAllyAction,
  props<{ cib: string, allyId: number }>()
);
const findCotizationsDetailsLocalByCIBAllySuccess = createAction(
  CotizationsDetailsActionType.findCotizationsDetailsLocalByCIBAllySuccessAction,
  props<{ cotizationsDetails: ICotizationDetail }>()
);
/** database/cotizationsDetailsTransport?transportType=INTERNATIONAL */
const findCotizationsDetailsInternatByCIBAlly = createAction(
  CotizationsDetailsActionType.findCotizationsDetailsInternatByCIBAllyAction,
  props<{ cib: string, allyId: number }>()
);
const findCotizationsDetailsInternatByCIBAllySuccess = createAction(
  CotizationsDetailsActionType.findCotizationsDetailsInternatByCIBAllySuccessAction,
  props<{ cotizationsDetails: ICotizationDetail }>()
);

const resetFlags = createAction(CotizationsDetailsActionType.resetFlagsAction);

const resetFlagsTrue = createAction(CotizationsDetailsActionType.resetFlagsTrueAction);

const updateCotizationsDetailTransLocal = createAction(
  CotizationsDetailsActionType.updateCotizationsDetailTransLocalAction,
  props<{
    cotizationsDetail: ICotizationDetailUpdTranspLocal;
    cotizationId: number;
  }>()
);

const updateCotizationsDetailTransLocalSuccess = createAction(
  CotizationsDetailsActionType.updateCotizationsDetailTransLocalSuccessAction,
  props<{ updated: boolean }>()
);

const updateCotizationsDetailTransInter = createAction(
  CotizationsDetailsActionType.updateCotizationsDetailTransInterAction,
  props<{
    cotizationsDetail: CotizationDetail;
    cotizationId: number;
  }>()
);

const updateCotizationsDetailTransInterSuccess = createAction(
  CotizationsDetailsActionType.updateCotizationsDetailTransInterSuccessAction,
  props<{ updated: boolean }>()
);

const updateCotizationsDetailCustom = createAction(
  CotizationsDetailsActionType.updateCotizationsDetailCustomAction,
  props<{
    cotizationsDetail: ICustomData;
    cotizationId: number;
  }>()
);

const updateCotizationsDetailCustomSuccess = createAction(
  CotizationsDetailsActionType.updateCotizationsDetailCustomSuccessAction,
  props<{ updated: boolean }>()
);

/** database/cotizationCustoms */
const getCotizationsCustom = createAction(
  CotizationsDetailsActionType.getCotizationsCustomAction,
  props<{ cib: string, allyId: number }>()
);
const getCotizationsCustomSuccess = createAction(
  CotizationsDetailsActionType.getCotizationsCustomSuccessAction,
  props<{ cotizationCustoms: ICustomData }>()
);

export const cotizationsDetailsActions = {
  load,
  searchCotizationsDetails,
  searchCotizationsDetailsSuccess,
  createCotizationsDetails,
  createCotizationsDetailsSuccess,
  onError,
  resetFlags,
  resetFlagsTrue,
  setCotizationsDetailsToStore,
  saveCotizationsDetails,
  saveCotizationsDetailsSuccess,
  findCotizationsDetailsByCIB,
  findCotizationsDetailsByCIBSuccess,
  findCotizationsDetailsLocalByCIBAlly,
  findCotizationsDetailsLocalByCIBAllySuccess,
  findCotizationsDetailsInternatByCIBAlly,
  findCotizationsDetailsInternatByCIBAllySuccess,
  updateCotizationsDetailTransLocal,
  updateCotizationsDetailTransLocalSuccess,
  updateCotizationsDetailTransInter,
  updateCotizationsDetailTransInterSuccess,
  updateCotizationsDetailCustom,
  updateCotizationsDetailCustomSuccess,
  getCotizationsCustom,
  getCotizationsCustomSuccess
};
