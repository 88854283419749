import { IClient } from 'src/app/data/interfaces/IClient.interface';
import { IClientCreate } from 'src/app/data/interfaces/IClientCreate.interface';
import { createAction, props } from '@ngrx/store';

enum NewCustomerActionType {
  createCustomerAction = '[NewCustomer Component] Request Create New Customer sent',
  createCustomerSuccessAction = '[NewCustomer Component] New Customer created success',
  getCustomerByIdAction = '[NewCustomer Component] Request Get Customer by Id sent',
  getCustomerByIdSuccessAction = '[NewCustomer Component] Get Customer by Id success',
  getCustomerByFilterAction = '[NewCustomer Component] Get Customer by filter typeDocument documentNumber name',
  getCustomerByFilterSuccessAction = '[NewCustomer Component] Get Customer by filter success',
  onErrorByFilterAction = '[NewCustomer Component] Ups Customer by filter is wrong',
  onEditCustomerAction = '[NewCustomer Component]Edit Customer',
  updateCustomerAction = '[NewCustomer Component]Update Customer',
  updateCustomerSuccessAction = '[NewCustomer Component]Update Customer Success',
  onErrorUpdateCustomerAction = '[NewCustomer Component] oops something went wrong while updating the Customer',
  updateCustomerCertificationsAction = '[NewCustomer Component] Update Customer Certifications',
  updateCustomerCertificationsSuccessAction = '[NewCustomer Component] Update Customer Certifications Success',
  onErrorAction = '[NewCustomer Component] oops something went wrong',
  resetFlagsAction = '[NewCustomer Component] Reset Flags',
}

const createCustomer = createAction(
  NewCustomerActionType.createCustomerAction,
  props<{ newCustomer: IClientCreate }>()
);

const createCustomerSuccess = createAction(
  NewCustomerActionType.createCustomerSuccessAction,
  props<{ customer: IClient }>()
);
const getCustomerById = createAction(
  NewCustomerActionType.getCustomerByIdAction,
  props<{ id: number }>()
);

const getCustomerByIdSuccess = createAction(
  NewCustomerActionType.getCustomerByIdSuccessAction,
  props<{ id: number }>()
);
const getCustomerByFilter = createAction(
  NewCustomerActionType.getCustomerByFilterAction,
  props<{ typeDocumentId: number; documentNumber: number; name?: string }>()
);
const getCustomerByFilterSuccess = createAction(
  NewCustomerActionType.getCustomerByFilterSuccessAction,
  props<{ customer: IClient }>()
);
const onErrorByFilter = createAction(
  NewCustomerActionType.onErrorByFilterAction,
  props<{ error: any }>()
);
const onEditCustomer = createAction(
  NewCustomerActionType.onEditCustomerAction,
  props<{ edit: boolean }>()
);
const updateCustomer = createAction(
  NewCustomerActionType.updateCustomerAction,
  props<{ customer: IClientCreate }>()
);

const updateCustomerSuccess = createAction(
  NewCustomerActionType.updateCustomerSuccessAction,
  props<{ customer: IClient }>()
);

const onErrorUpdateCustomer = createAction(
  NewCustomerActionType.onErrorUpdateCustomerAction,
  props<{ error: any }>()
);

const onError = createAction(
  NewCustomerActionType.onErrorAction,
  props<{ error: any }>()
);
const updateCustomerCertifications = createAction(
  NewCustomerActionType.updateCustomerCertificationsAction,
  props<{ clientId: number; certificationsId: number }>()
);
const updateCustomerCertificationsSuccess = createAction(
  NewCustomerActionType.updateCustomerCertificationsSuccessAction,
  props<{ message: string }>()
);

const resetFlags = createAction(NewCustomerActionType.resetFlagsAction);

export const newCustomerActions = {
  createCustomer,
  createCustomerSuccess,
  getCustomerById,
  getCustomerByIdSuccess,
  getCustomerByFilter,
  getCustomerByFilterSuccess,
  onErrorByFilter,
  onEditCustomer,
  updateCustomer,
  updateCustomerSuccess,
  onErrorUpdateCustomer,
  onError,
  updateCustomerCertifications,
  updateCustomerCertificationsSuccess,
  resetFlags,
};
