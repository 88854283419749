import { Injectable } from '@angular/core';
import { IClient } from 'src/app/data/interfaces/IClient.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIS_URL } from 'src/app/core/constants/url';
import { IClientCreate } from 'src/app/data/interfaces/IClientCreate.interface';
import { ApiHelperService } from 'src/app/data/services/api-helper/api-helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiClientsService {
  private endPoint = APIS_URL.clients;
  private domain = environment.DOMAIN_OPERATIONS;

  constructor(private apiHelperService: ApiHelperService) {
    // This is intentional
  }
  createClient(client: IClientCreate): Observable<IClient> {
    return this.apiHelperService.post<IClient>(this.endPoint, client).pipe(
      map((cust: any) => cust.Client)
    );
  }

  getClientById(clientId: number): Observable<IClient> {
    const params = `/by-id/${clientId}`;
    return this.apiHelperService.get(this.endPoint + params);
  }

  getClientByFilter(typeDocumentId: number, documentNumber: number, name?: string): Observable<IClient> {
    const url = `${this.endPoint}/filter`;
    const body = { typeDocumentId, documentNumber, name: name == '' ? null : name, };
    return this.apiHelperService.post<IClient>(url, body).pipe(map((cust: any) => cust.Client[0]));
  }

  updateClient(client: IClientCreate): Observable<IClient> {
    return this.apiHelperService.put<IClient>(this.endPoint, client);
  }

  updateCertifications(
    clientId: number,
    certificationsId: number
  ): Observable<any> {
    const params = `/certification/${clientId}/${certificationsId}`;
    return this.apiHelperService.patch(this.endPoint + params)
      .pipe
      ();
  }
}
