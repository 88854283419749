import { IProfile } from 'src/app/data/interfaces/IProfile.interface';
import { createAction, props } from '@ngrx/store';

enum ProfileActionType {
  Load = '[Profile Component] Load',
  setProfilesAction = '[Profile Component] set Profile',
  getProfileAction = '[Profile Component] get Profile',
  setTokenSessionAction = '[Profile Component] set TokenSession',
  getTokenSessionAction = '[Profile Component] get TokenSession',
  setTokenTmpAction = '[Profile Component] set TokenTmp',
  getTokenTmpAction = '[Profile Component] get TokenTmp',
  getLastUrlAction = '[Profile Component] set LastUrl',
  setLastUrlAction = '[Profile Component] get LastUrl',
  resetStateAction = '[Profile Component] reset State',
}

const load = createAction(ProfileActionType.Load);
const setProfile = createAction(
  ProfileActionType.setProfilesAction,
  props<{ profile: IProfile }>()
);
const getProfile = createAction(
  ProfileActionType.getProfileAction,
  props<{ profile: IProfile }>()
);
const setTokenSession = createAction(
  ProfileActionType.setTokenSessionAction,
  props<{ tokenSession: string }>()
);
const getTokenSession = createAction(
  ProfileActionType.getTokenSessionAction,
  props<{ tokenSession: string }>()
);
const setTokenTmp = createAction(
  ProfileActionType.setTokenTmpAction,
  props<{ tokenTmp: string }>()
);
const getTokenTmp = createAction(
  ProfileActionType.getTokenTmpAction,
  props<{ tokenTmp: string }>()
);
const setLastUrl = createAction(
  ProfileActionType.setLastUrlAction,
  props<{ lastUrl: string }>()
);
const getLastUrl = createAction(
  ProfileActionType.getLastUrlAction,
  props<{ lastUrl: string }>()
);

const resetState = createAction(
  ProfileActionType.resetStateAction
);
export const ProfileActions = {
  load,
  setProfile,
  getProfile,
  setTokenSession,
  getTokenSession,
  setTokenTmp,
  getTokenTmp,
  resetState,
  setLastUrl,
  getLastUrl,
};
