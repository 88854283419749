<bsc-files-uploader
  class="input-file text-center"
  [sizeTitle]="30"
  (getFilesAdded)="getfiles($event)"
  (changeStateMaxFiles)="listenerMaxFiles($event)"
  (onErrorValidate) = "onError($event)"
  [title]="title"
  [description]="description"
  [dropArea]="dropArea"
  [dropAreaText]="dropAreaText"
  [multipleFiles]="multipleFiles"

  [maxFiles]="maxFiles"
  [maxSize]="maxSize"
  [buttonLabel]="buttonLabel"
  [componentId]="componentId"
  *ngIf="show"
>
</bsc-files-uploader>
