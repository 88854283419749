import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IProfile } from '@interfaces/IProfile.interface';
import { Store } from '@ngrx/store';
import { MessagePostService } from '@services/apis/message-post/message-post.service';
import { Subscription, forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { State } from 'src/app/core/store/reducers';
import { ICotizationDetail } from 'src/app/data/interfaces/ICotizationDetail.interface';
import { IMessagePost, IMessagePostPut } from 'src/app/data/interfaces/IMessagePost.interface';
import { ModalService } from 'src/app/data/services/modal/modal.service';
import { ProfileFacadeService } from 'src/app/modules/master/profile-store/profile.facade.service';
import { BlogMessagesActions } from 'src/app/modules/master/quotation/blog-messages-store/actions';
import { BlogMessagesFacadeService } from 'src/app/modules/master/quotation/blog-messages-store/blog-messages.facade.service';
import { CotizationsFacadeService } from 'src/app/modules/master/quotation/commercial/components/cotizations-store/cotizations.facade.service';
import { Utils } from 'src/app/shared/utils';
import { Numbers } from '../../../core/constants/numbers';
import { ImportationsFacadeService } from '../../../modules/master/general/components/import-store/importations.facade.service';
import { UtilsServices } from '../../utils.services';

@Component({
  selector: 'bcs-message-post',
  templateUrl: './bcs-message-post.component.html',
  styleUrls: ['./bcs-message-post.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BcsMessagePostComponent implements OnInit, OnDestroy {
  @Input() saveBlog           = true;
  @Input() downloadBlog       = true;
  @Input() addMessage         = true;
  @Input() tableButtonsEdit   = true;
  @Input() tableButtonsErase  = true;
  @Input() tableButtonsUpdate = true;
  @Output() updateMsgData = new EventEmitter<any>();
  @Input() dowloandMessages: boolean = false;
  @Input() isJustViewer: boolean = false;
  inter: any;

  formMessagePost!: UntypedFormGroup;

  panelOpenState = false;
  submited: boolean = false;
  profile!: IProfile;
  id = new UntypedFormControl('', []);
  emailTo = new UntypedFormControl('', []);
  emailFrom = new UntypedFormControl('', []);
  subject = new UntypedFormControl('', []);
  message = new UntypedFormControl('', []);

  date!: Date | string;
  readonly = true;
  saveIndex = -1
  page = '0';
  size = '100';
  cotizationId!: number;
  cotizationCib!: string;
  importNumber: string = '';

  subs: Subscription = new Subscription();
  dataMessage!: IMessagePost;
  maxlength: number = Numbers._255;
  messageList!: IMessagePost[];
  blogMessageList!: IMessagePost[];
  cotizationDetail!: ICotizationDetail | null;
  rechargeListMsg: any = this.fb.array([]);
  expanded: boolean = false;
  edit = false;
  showFormEdit: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private utils: Utils,
    private blogMessagesFacadeService: BlogMessagesFacadeService,
    public profileFacadeService: ProfileFacadeService,
    private cotizationFacadeService: CotizationsFacadeService,
    public store: Store<State>,
    public modalService: ModalService,
    public messagePostService: MessagePostService,
    private importationsFacadeService: ImportationsFacadeService
  ) {
    this.date = this.utils.formatDate(new Date(), Numbers._9);
    this.expanded = true;
    const sub5: Subscription = this.profileFacadeService.getCurretProfile().subscribe((profile) => {
      if (profile) {
        this.profile = profile;
        if(!this.isJustViewer){
          this.formMessagePost = this.fb.group({
            blogMessages: this.fb.array([this.newBlogMessages()]),
          });
        }
        this.subsGetBlogMessageList()
      }
    });
    this.subs.add(sub5);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.store.dispatch(BlogMessagesActions.onResetFlagsBlogMessage());
  }

  ngOnInit(): void {
    this.subcriptions();
  }


  subGetCotization() {
    const subs = this.cotizationFacadeService.getCotization().subscribe((cotization) => {
      if (cotization) {
        this.cotizationCib = cotization.cib;
        this.cotizationId = cotization.id;
        this.blogMessagesFacadeService.getlistBlogMessages(this.cotizationCib, Numbers._1);
      }
    })
    this.subs.add(subs);
  }

  subsGetBlogMessageList() {
    const subs = this.blogMessagesFacadeService.getListBlogMessage()
      .subscribe((blogList) => {
          if (blogList) {
              this.messageList = [...blogList]
              this.blogMessages.clear()
              this.blogMessageList = [...this.messageList];
              this.addMessagesToblog(blogList);
        }
      })
    this.subs.add(subs);
  }

  subsGetImportation() {
    const subs = this.importationsFacadeService.getImportation()
      .subscribe((importation) => {
        if (importation) {
          this.importNumber = importation.importNumber
          this.blogMessagesFacadeService.getlistBlogMessages(this.importNumber, Numbers._2);
        }
      })
    this.subs.add(subs);
  }

  addMessagesToblog(blogMessageList: IMessagePost[]) {
    blogMessageList.forEach(blog => {
      this.blogMessages.push(
        this.newBlogMessagesWithData(blog)
      )
    });
    if(!this.isJustViewer){
      this.blogMessages.push(this.newBlogMessages());
    }
  }

  subcriptions() {
    this.subGetCotization()
    this.subsGetImportation()
  }

  validateCreatorBy(creatorById: number){
    return creatorById != this.profile.userId;
  }

  newBlogMessagesWithData(blog: IMessagePost): UntypedFormGroup {
    const formGroup = this.fb.group({
      id: new UntypedFormControl(blog.id, []),
      emailTo: new UntypedFormControl(blog.emailTo, [Validators.required, Validators.email]),
      emailFrom: new UntypedFormControl(blog.emailFrom, [Validators.required, Validators.email]),
      subject: new UntypedFormControl(blog.subject, []),
      message: new UntypedFormControl(blog.message, []),
      creatorById: new UntypedFormControl(blog.creatorById, []),
      edit: new UntypedFormControl(false, []),
    });
    formGroup.get('id')?.disable();
    formGroup.get('emailTo')?.disable();
    formGroup.get('emailFrom')?.disable();
    formGroup.get('subject')?.disable();
    formGroup.get('message')?.disable();
    return formGroup;
  }

  /** Crear formulario para nuevo mensages */
  newBlogMessages(): UntypedFormGroup {
    return this.fb.group({
      emailTo: new UntypedFormControl('', [Validators.required, Validators.email]),
      emailFrom: new UntypedFormControl('', [Validators.required, Validators.email]),
      subject: new UntypedFormControl('', []),
      message: new UntypedFormControl(`${this.date}, ` + '', []),
      creatorById: new UntypedFormControl(this.profile.userId, []),
      edit: new UntypedFormControl(false, []),
    });
  }

  get blogMessages() {
    return this.formMessagePost?.get('blogMessages') as UntypedFormArray;
  }

  changeReadonly(index: number): boolean {
    return this.saveIndex == index ? false : true;
  }

  editBlogMessages(index: number) {
    this.saveIndex = index;
    this.readonly = false;
  }

  addBlogMessages() {
    const object = this.blogMessages.value[this.blogMessages.value.length - 1];
    this.blogMessages.value[this.blogMessages.value.length - 1] = this.fb.group({
      emailTo: new UntypedFormControl(object.emailTo, [Validators.required, Validators.email]),
      emailFrom: new UntypedFormControl(object.emailFrom, [Validators.required, Validators.email]),
      subject: new UntypedFormControl(object.subject, []),
      message: new UntypedFormControl(object.message, []),
      creatorById: new UntypedFormControl(object.creatorById, []),
      edit: new UntypedFormControl(object.edit, []),
    });;
    if(!this.isJustViewer){
      this.blogMessages.push(this.newBlogMessages());
    }
  }
  get validateScreen(){
    if(!this.isJustViewer){
      return true;
    }else{
      if(this.isJustViewer && this.messageList && this.messageList.length == 0){
        return false;
      }else{
        return true;
      }
    }
  }
  messageData(): IMessagePost[] {
    const blogMessages: IMessagePost[] = this.formMessagePost.value.blogMessages;
    const newMessage: IMessagePost[] = [];
    blogMessages.forEach(item => {
      if (this.filter(item)) {
        newMessage.push(item)
      }
    })
    return newMessage
  }

  filter(item: IMessagePost): boolean {
    if (!this.blogMessageList || this.blogMessageList.length <= 0) {
      return true;
    }
    if(item.message == null || item.emailFrom == null || item.emailTo == null){
      return false;
    }
    const resp = this.blogMessageList.find(msg => msg.emailFrom == item.emailFrom &&
       msg.creatorById == item.creatorById && msg.emailTo == item.emailTo && msg.subject == item.subject &&
        item.message == msg.message)
    return resp === undefined
  }

  dowloadMessages(){
    if (this.cotizationId) {
      this.messagePostService.downdloadMessages(this.cotizationCib, Numbers._1).pipe(
        tap((blob: any) => {
          const stringArray: string[] = `${'blog '}${this.cotizationCib}-${this.date}`.split('/');
          const enlace = document.createElement('a');
          enlace.download = stringArray[stringArray.length - 1];
          enlace.href = URL.createObjectURL(blob);
          enlace.click();
        })
      ).subscribe();
    }
    if( this.importNumber != null && this.importNumber != ""){
      this.messagePostService.downdloadMessages(this.importNumber, Numbers._2).pipe(
        tap((blob: any) => {
          const stringArray: string[] = `${'blog '}${this.importNumber}-${this.date}`.split('/');
          const enlace = document.createElement('a');
          enlace.download = stringArray[stringArray.length - 1];
          enlace.href = URL.createObjectURL(blob);
          enlace.click();
        })
      ).subscribe();
    }
  }
saveBlogMessages() {
  if (this.formMessagePost.invalid) { return; }
  this.submited = true;

  if (this.cotizationId || this.importNumber) {
    const requests = this.messageData().map(blog =>
      this.messagePostService.createMessagePost(this.cotizationId, this.importNumber, blog).pipe(
        map((result) => ({ success: true, data: result })),
      )
    );
    forkJoin(requests).subscribe(
      (results) => {
        let countSuccess = 0;
        results.forEach((result, index) => {
          if (result.success) {
            this.store.dispatch(
              BlogMessagesActions.createMessageDataSuccess({ blogMessagesData: result.data })
            );
            countSuccess++;
          }
        });
        this.modalService.showOk('Información', "Se han guardado exitosamente "+countSuccess+" mensajes", 'Aceptar', 'check')
      },
      (error) => {

      }
    );
  }
}



  updateMessage(): IMessagePostPut[] {
    const blogMessages = this.formMessagePost.value;
    return blogMessages.blogMessages
  }


  showInfoModal(title: string, text: string, labelButton: string, icon: string) {
    this.modalService.showOk(title, text, labelButton, icon);
    this.modalService.modalRef.result
      .then((result) => {
        UtilsServices.loggerCallbackV2(result);
      })
      .catch((error) => {
        UtilsServices.loggerCallbackV2(error);
      });
  }

  deleteItem(item: IMessagePost, index: number) {
    if (!item?.id) {
      this.blogMessages.removeAt(index);
    }
  }

  updateItem(item: AbstractControl, index: number) {
    item.get('edit')?.setValue(false)
    const values = item.value
    delete values.edit
    const adit: any = { ...values }
    this.blogMessagesFacadeService.updateBlogMessagesData(values.id, adit)
    this.messagePostService.updateMessagePost(values.id, adit).subscribe(message => {
        this.store.dispatch( BlogMessagesActions.updateBlogMessagesDataSuccess({ message }))
        item.get('id')?.disable();
        item.get('emailTo')?.disable();
        item.get('emailFrom')?.disable();
        item.get('subject')?.disable();
        item.get('message')?.disable();
        if (this.cotizationId) {
          this.blogMessagesFacadeService.getlistBlogMessages(this.cotizationCib, Numbers._1);
        }
        if( this.importNumber != null && this.importNumber != ""){
          this.blogMessagesFacadeService.getlistBlogMessages(this.importNumber, Numbers._2);
        }
        this.modalService.showOk('Información', "Se ha actualizado exitosamente el mensaje", 'Aceptar', 'check')
    }, error => {
        this.modalService.showOk('Información', "No se ha podido actualizar el mensaje", 'Aceptar', 'error')
    })
  }

  editItem(item: AbstractControl) {
    item.get('edit')?.setValue(true)
    item.get('id')?.enable();
    item.get('emailTo')?.enable();
    item.get('emailFrom')?.enable();
    item.get('subject')?.enable();
    item.get('message')?.enable();
    this.edit = true;
  }

  get isError(): boolean {
    return ((this.submited || this.formMessagePost.touched) && this.formMessagePost?.invalid) ?? false;
  }

  get errorText(): string {
    return ''
  }

  validar() {
    // This is intentional
  }
}
