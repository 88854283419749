import { createSelector, createFeatureSelector, Action, ActionReducerMap } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { newCustomerReducer, NewCustomerState, } from 'src/app/modules/master/quotation/commercial/components/new-customer/new-customer-store/reducer';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
import { CotizationsReducer, CotizationsState, } from '../../../modules/master/quotation/commercial/components/cotizations-store/reducer';
import { CotizationsDetailsReducer, CotizationsDetailsState, } from 'src/app/modules/master/quotation/commercial/components/cotizations-details-store/reducer';
import { ProfileState, ProfileReducer, } from 'src/app/modules/master/profile-store/reducer';
import { AlliesReducer, AlliesState, } from 'src/app/modules/master/quotation/admin/components/allies-store/reducer';
import { ImportationsReducer, ImportationsState, } from 'src/app/modules/master/general/components/import-store/reducer';
import { BlogMessagesReducer, BlogMessagesState } from 'src/app/modules/master/quotation/blog-messages-store/reducer';
import { liquidationsReducer, LiquidationsState } from 'src/app/modules/master/general/components/liquidation-store/reducer';
import { PostProductionReducer, PostProductionState } from 'src/app/modules/master/quotation/post-production-store/reducer';
import { RoleState, adminRolesReducer } from 'src/app/modules/master/general/components/administer-roles-store/reducer';
import { PaymentsReducer, PaymentsState } from 'src/app/modules/master/logistics-coordination/components/payments/componentes/payments-store/reducer';
import { TemporaryReducer, ITemporaryState } from 'src/app/modules/master/temporary/components/temporary-store/reducer';

interface State {
  customer: NewCustomerState;
  cotization: CotizationsState;
  cotizationDetail: CotizationsDetailsState;
  profile: ProfileState;
  ally: AlliesState;
  importation: ImportationsState;
  blogMessages: BlogMessagesState;
  postProductionState: PostProductionState;
  liquidationState: LiquidationsState;
  role: RoleState;
  payments: PaymentsState;
}

export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
>('Root reducers token', {
  factory: () => ({
    customer: newCustomerReducer.reducer,
    cotization: CotizationsReducer.reducer,
    cotizationDetail: CotizationsDetailsReducer.reducer,
    profile: ProfileReducer.reducer,
    ally: AlliesReducer.reducer,
    importation: ImportationsReducer.reducer,
    blogMessages: BlogMessagesReducer.reducer,
    postProductionState: PostProductionReducer.reducer,
    liquidationState: liquidationsReducer.reducer,
    role: adminRolesReducer.reducer,
    payments: PaymentsReducer.reducer,
    temporary: TemporaryReducer.reducer,
  }),
});

// ======================================[NEW CUSTOMER]======================================\\
//#region NEW CUSTOMER
const getNewCustomerState = createFeatureSelector<NewCustomerState>('customer');
const getCustomer = createSelector(getNewCustomerState, newCustomerReducer.getNewCustomerFromStore);
const getCustomerMessage = createSelector(getNewCustomerState, newCustomerReducer.getMessage);
const getCustomerCreated = createSelector(getNewCustomerState, newCustomerReducer.getIsCreated);
const getSearchResult = createSelector(getNewCustomerState, newCustomerReducer.getIsFound);
const getEdit = createSelector(getNewCustomerState, newCustomerReducer.getEdit);
const getUdatedStatus = createSelector(getNewCustomerState, newCustomerReducer.getUdatedStatus);
const getErrors = createSelector(getNewCustomerState, newCustomerReducer.getError);

export const fromCustomer = {
  getNewCustomerState, getCustomer, getCustomerMessage, getCustomerCreated,
  getSearchResult, getEdit, getUdatedStatus, getErrors,
};
// #endregion

// ======================================[COTIZATIONS]======================================\\
//#region COTIZATIONS

const getCotizationState =
  createFeatureSelector<CotizationsState>('cotization');
const getCotization = createSelector(getCotizationState, CotizationsReducer.getCotization);
const getCotizationError = createSelector(getCotizationState, CotizationsReducer.getError);
const getCotizationIsCreated = createSelector(getCotizationState, CotizationsReducer.getIsCreated);
const getCotizationIsFound = createSelector(getCotizationState, CotizationsReducer.getIsFound);
const getListCotizations = createSelector(getCotizationState, CotizationsReducer.getIListCotizations);
const getEditStatus = createSelector(getCotizationState, CotizationsReducer.onEdit);
const getCotizationMessage = createSelector(getCotizationState, CotizationsReducer.getMessage);
const getTermsAndConditions = createSelector(getCotizationState, CotizationsReducer.getTermsAndConditions);

export const fromCotization = {
  getCotizationState,
  getCotization,
  getCotizationError,
  getCotizationIsCreated,
  getCotizationIsFound,
  getListCotizations,
  getEditStatus,
  getCotizationMessage,
  getTermsAndConditions,
};
// #endregion

// ======================================[cotizationDetail]======================================\\
//#region cotizationDetail
const getCotizationDetailState = createFeatureSelector<CotizationsDetailsState>('cotizationDetail');
const getCotizationDetail = createSelector(getCotizationDetailState, CotizationsDetailsReducer.getCotizationsDetails);
const getCotizationDetailEditStatus = createSelector(getCotizationDetailState, CotizationsDetailsReducer.onEdit);
const getCotizationDetailIsCreated = createSelector(getCotizationDetailState, CotizationsDetailsReducer.getIsCreated);
const getCotizationDetailIsSaved = createSelector(getCotizationDetailState, CotizationsDetailsReducer.getIsSaved);
const getCotizationDetailOnErrors = createSelector(getCotizationDetailState, CotizationsDetailsReducer.getError);
const getUpdateStatus = createSelector(getCotizationDetailState, CotizationsDetailsReducer.onUpdated);
const onSearchCotizationsDetails = createSelector(getCotizationDetailState, CotizationsDetailsReducer.getIsFound);

export const fromCotizationDetails = {
  getCotizationDetailState,
  getCotizationDetail,
  getCotizationDetailEditStatus,
  getCotizationDetailIsCreated,
  getCotizationDetailOnErrors,
  getCotizationDetailIsSaved,
  onSearchCotizationsDetails,
  getUpdateStatus,
};
// #endregion

// ======================================[importations]======================================\\
//#region importations
const getImportationsState = createFeatureSelector<ImportationsState>('importation');
const getImportation = createSelector(getImportationsState, ImportationsReducer.getImportation);
const getCustoms = createSelector(getImportationsState, ImportationsReducer.getCustoms);
const getAssetsCustoms = createSelector(getImportationsState, ImportationsReducer.getAssetsCustoms);
const getImpProcedures = createSelector(getImportationsState, ImportationsReducer.getImpProcedures);
const getCustomsNaturalization = createSelector(getImportationsState, ImportationsReducer.getCustomsNaturalization);
const getListImportations = createSelector(getImportationsState, ImportationsReducer.getListImportations);
const getImportations = createSelector(getImportationsState, ImportationsReducer.getImportations);
const getImportationEditStatus = createSelector(getImportationsState, ImportationsReducer.onEdit);
const getImportIsCreated = createSelector(getImportationsState, ImportationsReducer.getIsCreated);
const getError = createSelector(getImportationsState, ImportationsReducer.getError);
const getOnEdit = createSelector(getImportationsState, ImportationsReducer.onEdit);
const resetImportationsFlags = createSelector(getImportationsState, ImportationsReducer.getError);
const getImportationIsFound = createSelector(getImportationsState, ImportationsReducer.getIsFound);
const getImportationDetailOnErrors = createSelector(getImportationsState, ImportationsReducer.getError);
const getImpLocalTransport = createSelector(getImportationsState, ImportationsReducer.getImpLocalTransport);
const getImportationListPag = createSelector(getImportationsState, ImportationsReducer.getImportationListPag);
const getDispatch = createSelector(getImportationsState, ImportationsReducer.getDispatch);
const getImpLocalTransportContainers = createSelector(getImportationsState, ImportationsReducer.getImportationTransporteLocalContenedores);
const getImpIntTrasportContainers = createSelector(getImportationsState, ImportationsReducer.getImportationTransporteIntContenedores);
const getImpIntTrasportCargaSuelta = createSelector(getImportationsState, ImportationsReducer.getImportationTransporteIntCargaSuelta);
const getPagAduanasDeclaracion = createSelector(getImportationsState, ImportationsReducer.getAduanaDeclaracionesPag);
const getImpLocalTransportCargaSuelta = createSelector(getImportationsState, ImportationsReducer.getImportationTransporteLocalCargaSuelta);
const getImpIntTransport = createSelector(getImportationsState, ImportationsReducer.getImpIntTransport);
const getImpIntTransportMessages = createSelector(getImportationsState, ImportationsReducer.getMessage);
const getImpProcedurestMessages = createSelector(getImportationsState, ImportationsReducer.getMessage);
const saveFileDeclraracionesSuccess = createSelector(getImportationsState, ImportationsReducer.saveFileDeclaracionesData);
export const fromImportations = {
  getImportation,
  getListImportations,
  getImportationEditStatus,
  getImportIsCreated,
  getError,
  getOnEdit,
  resetImportationsFlags,
  getCustoms,
  getAssetsCustoms,
  getCustomsNaturalization,
  getImpLocalTransport,
  getImpLocalTransportContainers,
  getImpLocalTransportCargaSuelta,
  getImpIntTrasportContainers,
  getImpIntTrasportCargaSuelta,
  getPagAduanasDeclaracion,
  getImportationIsFound,
  getImportationDetailOnErrors,
  getDispatch,
  getImportations,
  getImpIntTransport,
  getImpProcedures,
  getImportationListPag,
  getImpIntTransportMessages,
  saveFileDeclraracionesSuccess,
  getImpProcedurestMessages
};
// #endregion

// ======================================[Profile]======================================\\
//#region Profile
const getProfileState = createFeatureSelector<ProfileState>('profile');

const getProfile = createSelector(getProfileState, ProfileReducer.getProfile);
const getTokenSession = createSelector(getProfileState, ProfileReducer.getTokenSession);
const getTokenTmp = createSelector(getProfileState, ProfileReducer.getTokenTmp);
const getLastUrl = createSelector(getProfileState, ProfileReducer.getLastUrl);

export const fromProfile = {
  getProfile,
  getProfileState,
  getTokenSession,
  getTokenTmp,
  getLastUrl,
};
// #endregion

// ======================================[Allies]======================================\\
//#region Allies
const getAlliesState = createFeatureSelector<AlliesState>('ally');

const getAlly = createSelector(getAlliesState, AlliesReducer.getAlly);
const getAllyNit = createSelector(getAlliesState, AlliesReducer.getListAlly);
const getAllyStatusCreated = createSelector(
  getAlliesState,
  AlliesReducer.getIsCreated
);
const getAllyOnError = createSelector(
  getAlliesState,
  AlliesReducer.getError
);

export const fromAllies = {
  getAlly,
  getAllyNit,
  getAlliesState,
  getAllyStatusCreated,
  getAllyOnError,
};
//#endRegion_Allies

// ======================================[BlogMessages]======================================\\
//#region BlogMessages
const getBlogMessagesState = createFeatureSelector<BlogMessagesState>('blogMessages');

const getBlogMessage = createSelector(
  getBlogMessagesState,
  BlogMessagesReducer.getBlogMessage
);
const getListBlogMessage = createSelector(
  getBlogMessagesState,
  BlogMessagesReducer.getListBlogMessage
);
const getErrorBlogMessage = createSelector(
  getBlogMessagesState,
  BlogMessagesReducer.getError
);
const getMessage = createSelector(
  getBlogMessagesState,
  BlogMessagesReducer.getMessage
);
export const fromBlogMessages = {
  getBlogMessage,
  getListBlogMessage,
  getErrorBlogMessage,
  getMessage
};
//#region BlogMessages

// ======================================[Liquidation]======================================\\
//#region BlogMessages
const getLiquidationsState =
  createFeatureSelector<LiquidationsState>('liquidationState');
const getSettlementImportSummary = createSelector(
  getLiquidationsState,
  liquidationsReducer.getSettlementImportSummary
);

const getExchangeAudit = createSelector(
  getLiquidationsState,
  liquidationsReducer.getExchangeAudit
);
const getFormalitiesDocuments = createSelector(
  getLiquidationsState,
  liquidationsReducer.getFormalitiesDocuments
);
const getDispatchs = createSelector(
  getLiquidationsState,
  liquidationsReducer.getDispatchs
);
const getSettlementsSteps = createSelector(
  getLiquidationsState,
  liquidationsReducer.getSettlementsSteps
);
const getLiquidationIsFound = createSelector(
  getLiquidationsState,
  liquidationsReducer.getIsFound
);

const getLiquidationError = createSelector(
  getLiquidationsState,
  liquidationsReducer.getError
);

const getLiquidationEdit = createSelector(
  getLiquidationsState,
  liquidationsReducer.onEdit
);

const getLiquidationUpdate = createSelector(
  getLiquidationsState,
  liquidationsReducer.getIsUpdate
);

const getInformation = createSelector(
  getLiquidationsState,
  liquidationsReducer.getInformation
);

const getIntTransportContainerAll = createSelector(
  getLiquidationsState,
  liquidationsReducer.getIntTransportContainerAll
);

const getCommissionValue = createSelector(
  getLiquidationsState,
  liquidationsReducer.getCommissionValue
);

const getLegalProvPolicies = createSelector(
  getLiquidationsState,
  liquidationsReducer.getLegalProvPolicies
);

const getContractActivation = createSelector(
  getLiquidationsState,
  liquidationsReducer.getContractActivation
);

export const fromLiquidation = {
  getSettlementImportSummary,
  getExchangeAudit,
  getDispatchs,
  getFormalitiesDocuments,
  getSettlementsSteps,
  getLiquidationIsFound,
  getLiquidationError,
  getIntTransportContainerAll,
  getCommissionValue,
  getLegalProvPolicies,
  getLiquidationEdit,
  getLiquidationUpdate,
  getInformation,
  getContractActivation,
}

export { State };


// ======================================[PostProduction]======================================\\

const getPostProductionState = createFeatureSelector<PostProductionState>('postProductionState');

const getSearchCotizationsLocal = createSelector(
  getPostProductionState,
  PostProductionReducer.getSearchCotizationsLocal
);
const getUpCotizationsLocalData = createSelector(
  getPostProductionState,
  PostProductionReducer.getUpCotizationsLocalData
)
const getSearchgetCotizationInternational = createSelector(
  getPostProductionState,
  PostProductionReducer.getSearchgetCotizationInternational
)
const getUpCotizationInternationalData = createSelector(
  getPostProductionState,
  PostProductionReducer.getUpCotizationInternationalData
)
const getSearchCotizationAduanasData = createSelector(
  getPostProductionState,
  PostProductionReducer.getSearchCotizationAduanasData
)
const getUpCotizationAduanasData = createSelector(
  getPostProductionState,
  PostProductionReducer.getUpCotizationAduanasData
)
const getSearchCotizationsAduanasData = createSelector(
  getPostProductionState,
  PostProductionReducer.getSearchCotizationsAduanasData
)
const getSearchTaxesDutyAduanasData = createSelector(
  getPostProductionState,
  PostProductionReducer.getSearchTaxesDutyAduanasData
)
const getUpTaxesDutyAduanasData = createSelector(
  getPostProductionState,
  PostProductionReducer.getUpTaxesDutyAduanasData
)
const getSearchListCotizationsTranspInternationalDetails = createSelector(
  getPostProductionState,
  PostProductionReducer.getSearchListCotizationsTranspInternationalDetails
)
const getSearchListCotizationsTranspLocalDetails = createSelector(
  getPostProductionState,
  PostProductionReducer.getSearchListCotizationsTranspLocalDetails
)
const getSearchListCotizationsAduanasDetails = createSelector(
  getPostProductionState,
  PostProductionReducer.getSearchListCotizationsAduanasDetails
)

export const fromPostProduction = {
  getPostProductionState,
  getSearchCotizationsLocal,
  getUpCotizationsLocalData,
  getSearchgetCotizationInternational,
  getUpCotizationInternationalData,
  getSearchCotizationAduanasData,
  getUpCotizationAduanasData,
  getSearchCotizationsAduanasData,
  getSearchTaxesDutyAduanasData,
  getUpTaxesDutyAduanasData,
  getSearchListCotizationsTranspInternationalDetails,
  getSearchListCotizationsTranspLocalDetails,
  getSearchListCotizationsAduanasDetails,
};

/** ************************************* ADMINISTER ROLES ************************************* */

const getRoleState = createFeatureSelector<RoleState>('role');

const getRoleError = createSelector(
  getRoleState,
  adminRolesReducer.getError
);
const getRoleIsFound = createSelector(
  getRoleState,
  adminRolesReducer.getIsFound
);
const getRoleEdit = createSelector(
  getRoleState,
  adminRolesReducer.onEdit
);
const getRoleIsCreated = createSelector(
  getRoleState,
  adminRolesReducer.getIsCreated
);
const getAdminRoleMessage = createSelector(
  getRoleState,
  adminRolesReducer.getMessage
);

const getRole = createSelector(
  getRoleState,
  adminRolesReducer.getRole
);
const getRol = createSelector(
  getRoleState,
  adminRolesReducer.getRol
);
const getRolesList = createSelector(
  getRoleState,
  adminRolesReducer.getRolesList
);
const getScreensList = createSelector(
  getRoleState,
  adminRolesReducer.getScreensList
);
const postScreensListByRole = createSelector(
  getRoleState,
  adminRolesReducer.postScreensListByRole
);
const getStaticScreensList = createSelector(
  getRoleState,
  adminRolesReducer.getStaticScreensList
);

export const fromAdminRole = {
  getRoleState,
  getRoleError,
  getRoleIsFound,
  getRoleEdit,
  getRoleIsCreated,
  getAdminRoleMessage,
  getRole,
  getRol,
  getRolesList,
  getScreensList,
  postScreensListByRole,
  getStaticScreensList,
};
/** #endregion ADMINISTER ROLES */

// ======================================[Payments]======================================\\

const getPaymentState = createFeatureSelector<PaymentsState>('payments');

const getPaymentsValuesCardData = createSelector(
  getPaymentState,
  PaymentsReducer.getPaymentsValues
);

const getCreditLetter = createSelector(
  getPaymentState,
  PaymentsReducer.getCreditLetter
);
const getFilesPaymentSuccess = createSelector(
  getPaymentState,
  PaymentsReducer.getFilesPaymentDatas
);

const getTransfer = createSelector(
  getPaymentState,
  PaymentsReducer.getTransfer
);

const getServices = createSelector(
  getPaymentState,
  PaymentsReducer.getServices
);

const getEveryCommision = createSelector(
  getPaymentState,
  PaymentsReducer.getEveryCommision
);

const getHistoricCommission = createSelector(
  getPaymentState,
  PaymentsReducer.getHistoricCommission
);

const getDeleteCommissionData = createSelector(
  getPaymentState,
  PaymentsReducer.getDeleteCommissionData
);

const getMessagePayment = createSelector(
  getPaymentState,
  PaymentsReducer.getMessage
);

const getEditPayment = createSelector(
  getPaymentState,
  PaymentsReducer.getEdit
);


const getTypeMethodData = createSelector(
  getPaymentState,
  PaymentsReducer.getTypeMethodData
);

const getAgreedDocumentData = createSelector(
  getPaymentState,
  PaymentsReducer.getAgreedDocumentData
);
const getErrorPayments = createSelector(
  getPaymentState,
  PaymentsReducer.getError
);

const createdPaymentData = createSelector(
  getPaymentState,
  PaymentsReducer.createdPaymentDatas
);

const updatePaymentStatusData = createSelector(
  getPaymentState,
  PaymentsReducer.updatePaymentStatusDatas
);


const updatedPaymentLetterData = createSelector(
  getPaymentState,
  PaymentsReducer.updatedPaymentLetterDatas
);
const deletePaymentData = createSelector(
  getPaymentState,
  PaymentsReducer.deletePaymentDatas
);
const updatedPaymentTransferData = createSelector(
  getPaymentState,
  PaymentsReducer.updatedPaymentTransferDatas
);

const updatedPaymentServicesData = createSelector(
  getPaymentState,
  PaymentsReducer.updatedPaymentServicesDatas
);

const sendEmailCustomData = createSelector(
  getPaymentState,
  PaymentsReducer.sendEmailCustomDatas
);

export const fromPayments = {
  sendEmailCustomData,
  createdPaymentData,
  updatePaymentStatusData,
  updatedPaymentLetterData,
  deletePaymentData,
  updatedPaymentTransferData,
  updatedPaymentServicesData,
  getErrorPayments,
  getAgreedDocumentData,
  getTypeMethodData,
  getPaymentState,
  getPaymentsValuesCardData,
  getCreditLetter,
  getFilesPaymentSuccess,
  getTransfer,
  getServices,
  getEveryCommision,
  getHistoricCommission,
  getDeleteCommissionData,
  getMessagePayment,
  getEditPayment,
};


// ======================================[Temporary]======================================\\

const getTemporaryState = createFeatureSelector<ITemporaryState>('temporary');

const getErrorTemporary = createSelector(
  getTemporaryState,
  TemporaryReducer.getError
);

const getTemporary = createSelector(
  getTemporaryState,
  TemporaryReducer.temporaryData
);

const getStatement = createSelector(
  getTemporaryState,
  TemporaryReducer.statementData
);

const getCustomStatementTempsSuccess = createSelector(
  getTemporaryState,
  TemporaryReducer.customTempResponseData
);

const createCustomStatementTempSuccess = createSelector(
  getTemporaryState,
  TemporaryReducer.createCustomStatementTempData
);

const updateCustomStatementTempSuccess = createSelector(
  getTemporaryState,
  TemporaryReducer.updateCustomStatementTempData
);

const deleteCustomStatementTempSuccess = createSelector(
  getTemporaryState,
  TemporaryReducer.deleteCustomStatementTempData
);

const getImportsSuccess = createSelector(
  getTemporaryState,
  TemporaryReducer.searchResponseData
);

const getQuotasSuccess = createSelector(
  getTemporaryState,
  TemporaryReducer.quotasData
);


const updateQuotasSuccess = createSelector(
  getTemporaryState,
  TemporaryReducer.updateQuotasData
);
const getDocumentTypesSuccess = createSelector(
  getTemporaryState,
  TemporaryReducer.documentTypesData
);
const getCustomTempsDocumentsSuccess = createSelector(
  getTemporaryState,
  TemporaryReducer.customTempsDocumentsData
);

const createCustomTempDocumentSuccess = createSelector(
  getTemporaryState,
  TemporaryReducer.createCustomTempDocumentData
);
const updateCustomTempDocumentSuccess = createSelector(
  getTemporaryState,
  TemporaryReducer.updateCustomTempDocumentData
);
const deleteCustomTempDocumentSuccess = createSelector(
  getTemporaryState,
  TemporaryReducer.deleteCustomTempDocumentData
);
const postFileProceduresDataSuccess = createSelector(
  getTemporaryState,
  TemporaryReducer.postFileProceduresData
);
const deleteFileProceduresDataSuccess = createSelector(
  getTemporaryState,
  TemporaryReducer.deleteFileProceduresData
);
const approvalCustomStatementTempsSuccess = createSelector(
  getTemporaryState,
  TemporaryReducer.approvalCustomStatementTempsData
);
const inconsistencieCustomStatementTempsSuccess = createSelector(
  getTemporaryState,
  TemporaryReducer.inconsistencieCustomStatementTempsData
);
const getNotificationsSuccess = createSelector(
  getTemporaryState,
  TemporaryReducer.notificationsData
);
export const fromTemporary = {
  getErrorTemporary,
  getTemporary,
  getStatement,
  getCustomStatementTempsSuccess,
  getImportsSuccess,
  createCustomStatementTempSuccess,
  updateCustomStatementTempSuccess,
  deleteCustomStatementTempSuccess,
  getQuotasSuccess,
  updateQuotasSuccess,
  getDocumentTypesSuccess,
  getCustomTempsDocumentsSuccess,
  createCustomTempDocumentSuccess,
  updateCustomTempDocumentSuccess,
  deleteCustomTempDocumentSuccess,
  postFileProceduresDataSuccess,
  deleteFileProceduresDataSuccess,
  approvalCustomStatementTempsSuccess,
  inconsistencieCustomStatementTempsSuccess,
  getNotificationsSuccess,
};
