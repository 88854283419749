import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { fromBlogMessages, State } from 'src/app/core/store/reducers';
import { BlogMessagesActions } from './actions';
import { IMessagePost, IMessagePostPut } from 'src/app/data/interfaces/IMessagePost.interface';

@Injectable({
  providedIn: 'root',
})
export class BlogMessagesFacadeService {
  constructor(private store: Store<State>) {
    // This is intentional
  }
  // ======================================[BlogMessages]======================================\\

  getBlogMessages(): Observable<IMessagePost | null> {
    return this.store.select(fromBlogMessages.getBlogMessage);
  }

  getMessage(): Observable<any> {
    return this.store.select(fromBlogMessages.getMessage);
  }
  getListBlogMessage(): Observable<IMessagePost[] | null> {
    return this.store.select(fromBlogMessages.getListBlogMessage);
  }

  getErrors(): Observable<any> {
    return this.store.select(fromBlogMessages.getErrorBlogMessage);
  }

  resetBlogMessagesFlags(): void {
    this.store.dispatch(BlogMessagesActions.onResetFlagsBlogMessage());
  }

  getlistBlogMessages(criterianDescription: string, criterianNumber: number) {
    this.store.dispatch(
      BlogMessagesActions.listBlogMessages({ criterianDescription, criterianNumber })
    );
  }

  createMessageData(cotizationId: number, importNumber: string, blogMessageData: IMessagePost) {
    this.store.dispatch(BlogMessagesActions.createMessageData({ cotizationId, importNumber, blogMessageData }));
  }

  updateBlogMessagesData(messageId: number, blogMessageUpData: IMessagePostPut) {
    this.store.dispatch(BlogMessagesActions.updateBlogMessagesData({ messageId, blogMessageUpData }));
  }

  setListBlogMessage(blogMessageList: IMessagePost[]) {
    this.store.dispatch(BlogMessagesActions.setBlogList({ blogMessageList }))
  }
}
